@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap);
:root {
    --body-bg-rgb: 240, 241, 247;
    --primary-rgb: 132, 90, 223;
    --secondary-rgb: 35, 183, 229;
    --warning-rgb: 245, 184, 73;
    --info-rgb: 73, 182, 245;
    --success-rgb: 38, 191, 148;
    --danger-rgb: 230, 83, 60;
    --light-rgb: 243, 246, 248;
    --dark-rgb: 35, 35, 35;
    --orange-rgb: 255, 165, 5;
    --pink-rgb: 231, 145, 188;
    --teal-rgb: 18, 194, 194;
    --purple-rgb: 137, 32, 173;
    --default-body-bg-color: rgb(var(--body-bg-rgb));
    --primary-color: rgb(var(--primary-rgb));
    --primary-border: rgb(var(--primary-rgb));
    --primary01: rgba(var(--primary-rgb), 0.1);
    --primary02: rgba(var(--primary-rgb), 0.2);
    --primary03: rgba(var(--primary-rgb), 0.3);
    --primary04: rgba(var(--primary-rgb), 0.4);
    --primary05: rgba(var(--primary-rgb), 0.5);
    --primary06: rgba(var(--primary-rgb), 0.6);
    --primary07: rgba(var(--primary-rgb), 0.7);
    --primary08: rgba(var(--primary-rgb), 0.8);
    --primary09: rgba(var(--primary-rgb), 0.9);
    --primary005: rgba(var(--primary-rgb), 0.05);
    --default-font-family: "Inter", sans-serif;
    --default-font-weight: 400;
    --default-text-color: #333335;
    --default-border: #f3f3f3;
    --default-background: #f7f8f9;
    --menu-bg: #fff;
    --menu-prime-color: #536485;
    --menu-border-color: #f3f3f3;
    --header-bg: #fff;
    --header-prime-color: #536485;
    --header-border-color: #f3f3f3;
    --custom-white: #fff;
    --custom-black: #000;
    --bootstrap-card-border: #f3f3f3;
    --list-hover-focus-bg: #f5f6f7;
    --text-muted: #8c9097;
    --input-border: #e9edf6;
    --form-control-bg: #ffffff;
    --gray-1: #f9fafb;
    --gray-2: #f2f4f5;
    --gray-3: #e6eaeb;
    --gray-4: #dbdfe1;
    --gray-5: #949eb7;
    --gray-6: #7987a1;
    --gray-7: #4d5875;
    --gray-8: #383853;
    --gray-9: #323251;
    --white-1: rgba(255, 255, 255, 0.1);
    --white-2: rgba(255, 255, 255, 0.2);
    --white-3: rgba(255, 255, 255, 0.3);
    --white-4: rgba(255, 255, 255, 0.4);
    --white-5: rgba(255, 255, 255, 0.5);
    --white-6: rgba(255, 255, 255, 0.6);
    --white-7: rgba(255, 255, 255, 0.7);
    --white-8: rgba(255, 255, 255, 0.8);
    --white-9: rgba(255, 255, 255, 0.9);
    --black-1: rgba(0, 0, 0, 0.1);
    --black-2: rgba(0, 0, 0, 0.2);
    --black-3: rgba(0, 0, 0, 0.3);
    --black-4: rgba(0, 0, 0, 0.4);
    --black-5: rgba(0, 0, 0, 0.5);
    --black-6: rgba(0, 0, 0, 0.6);
    --black-7: rgba(0, 0, 0, 0.7);
    --black-8: rgba(0, 0, 0, 0.8);
    --black-9: rgba(0, 0, 0, 0.9)
}

[data-theme-mode=dark] {
    --body-bg-rgb: 26, 28, 30;
    --body-bg-rgb2: 37, 39, 41;
    --menu-bg: rgb(var(--body-bg-rgb));
    --menu-border-color: rgba(255, 255, 255, 0.1);
    --menu-prime-color: rgba(255, 255, 255, 0.6);
    --header-bg: rgb(var(--body-bg-rgb));
    --header-prime-color: rgba(255, 255, 255, 0.6);
    --header-border-color: rgba(255, 255, 255, 0.1);
    --custom-white: rgb(var(--body-bg-rgb));
    --custom-black: #fff;
    --default-border: rgba(255, 255, 255, 0.1);
    --default-text-color: rgba(255, 255, 255, 0.7);
    --light-rgb: 43, 46, 49;
    --dark-rgb: 240, 245, 248;
    --bootstrap-card-border: rgba(255, 255, 255, 0.1);
    --list-hover-focus-bg: rgba(255, 255, 255, 0.1);
    --default-background: rgba(255, 255, 255, 0.07);
    --default-body-bg-color: rgb(var(--body-bg-rgb2));
    --text-muted: rgba(255, 255, 255, 0.5);
    --input-border: #313335;
    --form-control-bg: #232628;
    --gray-100: #110f0f;
    --gray-200: #17171c;
    --gray-300: #393946;
    --gray-400: #505062;
    --gray-500: #73738c;
    --gray-600: #8f8fa3;
    --gray-700: #ababba;
    --gray-800: #c7c7d1;
    --gray-900: #e3e3e8;
    --white-1: rgba(0, 0, 0, 0.1);
    --white-2: rgba(0, 0, 0, 0.2);
    --white-3: rgba(0, 0, 0, 0.3);
    --white-4: rgba(0, 0, 0, 0.4);
    --white-5: rgba(0, 0, 0, 0.5);
    --white-6: rgba(0, 0, 0, 0.6);
    --white-7: rgba(0, 0, 0, 0.7);
    --white-8: rgba(0, 0, 0, 0.8);
    --white-9: rgba(0, 0, 0, 0.9);
    --black-1: rgba(255, 255, 255, 0.05);
    --black-2: rgba(255, 255, 255, 0.2);
    --black-3: rgba(255, 255, 255, 0.3);
    --black-4: rgba(255, 255, 255, 0.4);
    --black-5: rgba(255, 255, 255, 0.5);
    --black-6: rgba(255, 255, 255, 0.6);
    --black-7: rgba(255, 255, 255, 0.7);
    --black-8: rgba(255, 255, 255, 0.8);
    --black-9: rgba(255, 255, 255, 0.9)
}

@media (max-width:991.98px) {
    .sidemenu-layout-styles {
        display: none
    }
}

@media (max-width:991.98px) {
    .app-header .horizontal-logo .header-logo img {
        height: 2rem;
        line-height: 2rem
    }
}

[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
    display: block
}

[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-theme-mode=dark] .btn-close {
    filter: invert(1)
}

[data-theme-mode=dark] .alert .btn-close {
    filter: unset
}

[data-theme-mode=dark] .dropdown-menu {
    box-shadow: 0 1rem 1.125rem rgba(40, 40, 40, .15)
}

[data-theme-mode=dark] button.gridjs-sort,
[data-theme-mode=dark] button.gridjs-sort-asc,
[data-theme-mode=dark] button.gridjs-sort-desc {
    filter: invert(1)
}

[data-theme-mode=dark] .choices__list--dropdown,
[data-theme-mode=dark] .choices__list[aria-expanded] {
    box-shadow: rgba(10, 10, 10, .2) 0 .5rem 1.5rem !important
}

@media (max-width:991.98px) {
    [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-white,
    [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-white {
        display: none
    }
    [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-dark {
        display: block
    }
}

@media (max-width:991.98px) {
    [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-logo {
        display: none
    }
    [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-white,
    [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-white {
        display: block
    }
}

@media (max-width:991.98px) {
    [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-logo {
        display: block
    }
    [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-white,
    [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-white {
        display: none
    }
}

@media (max-width:991.98px) {
    [data-toggled=close] .app-sidebar {
        transition: all .3s ease;
        transform: translateX(-15rem)
    }
    [data-toggled=close] .app-header {
        padding-inline-start: 0
    }
    [data-toggled=close] .app-header .horizontal-logo {
        display: block
    }
    [data-toggled=close][dir=rtl] .app-sidebar {
        transition: all .3s ease;
        transform: translateX(15rem)
    }
}

@media (max-width:991.98px) {
    [data-toggled=open] .app-sidebar {
        transition: all .3s ease;
        transform: translateX(0)
    }
    [data-toggled=open] .app-header {
        padding-inline-start: 0
    }
    [data-toggled=open] .app-header .horizontal-logo {
        display: block
    }
}

[data-page-style=classic] {
    --default-border: #e1e9e8;
    --header-border-color: #e1e9e8;
    --menu-border-color: #e1e9e8
}

[data-page-style=classic][data-theme-mode=dark] {
    --default-border: rgba(255, 255, 255, 0.1);
    --header-border-color: rgba(255, 255, 255, 0.1);
    --menu-border-color: rgba(255, 255, 255, 0.1)
}

[data-page-style=classic] body {
    background-color: var(--custom-white)
}

[data-page-style=classic] .card.custom-card {
    box-shadow: none;
    border: 1px solid var(--default-border)
}

[data-page-style=classic] .app-header,
[data-page-style=classic] .app-sidebar,
[data-page-style=classic] .app-sidebar .main-sidebar,
[data-page-style=classic] .footer {
    box-shadow: none
}

[data-page-style=classic][data-nav-layout=horizontal] .app-sidebar {
    border-block-end: 1px solid var(--default-border)
}

[data-page-style=modern] {
    --light-rgb: 255, 255, 255;
    --custom-white: rgb(243, 246, 248);
    --default-border: #e6ebf1;
    --input-border: rgba(0, 0, 0, 0.1)
}

[data-page-style=modern] .dropdown-menu {
    background-color: #fff
}

[data-page-style=modern] body {
    background-color: #fff
}

[data-page-style=modern] .card.custom-card {
    box-shadow: none;
    background-color: #f3f6f8
}

[data-page-style=modern][data-theme-mode=dark] {
    --light-rgb: 20, 20, 20;
    --custom-white: rgb(var(--body-bg-rgb));
    --default-border: rgb(255, 255, 255, 0.1)
}

[data-page-style=modern][data-theme-mode=dark] .card.custom-card {
    box-shadow: none;
    background-color: var(--custom-white)
}

[data-page-style=modern][data-theme-mode=dark] .dropdown-menu {
    background-color: rgb(var(--light-rgb))
}

[data-page-style=modern][data-theme-mode=dark] body {
    background-color: var(--default-body-bg-color)
}

[data-page-style=modern][data-theme-mode=dark] .btn-light {
    background-color: rgba(255, 255, 255, .1) !important;
    border-color: rgba(255, 255, 255, .1) !important
}

[data-page-style=modern][data-theme-mode=dark] .btn-light:active,
[data-page-style=modern][data-theme-mode=dark] .btn-light:focus,
[data-page-style=modern][data-theme-mode=dark] .btn-light:hover {
    background-color: rgba(255, 255, 255, .1) !important;
    border-color: rgba(255, 255, 255, .1) !important
}

[data-page-style=modern] .app-header,
[data-page-style=modern] .app-sidebar,
[data-page-style=modern] .app-sidebar .main-sidebar,
[data-page-style=modern] .footer {
    box-shadow: none
}

@media (min-width:1400px) {
    [data-width=boxed] body {
        background-color: var(--default-background)
    }
    [data-width=boxed] .page {
        width: 1400px;
        margin: 0 auto;
        background-color: var(--default-body-bg-color);
        position: relative;
        box-shadow: 0 0 1rem var(--black-1)
    }
    [data-width=boxed] .page .app-header {
        width: 1400px;
        margin: 0 auto
    }
    [data-width=boxed] .page .app-sidebar {
        inset-inline-start: auto
    }
    [data-width=boxed][data-nav-layout=horizontal] .app-sidebar {
        width: 1400px !important
    }
    [data-width=boxed][data-page-style=classic] .page {
        background-color: var(--custom-white)
    }
    [data-width=boxed][data-page-style=classic] .card {
        box-shadow: none
    }
    [data-width=boxed][data-theme-mode=dark] {
        --default-body-bg-color: rgba(var(--body-bg-rgb), 0.85)
    }
    [data-width=boxed][data-theme-mode=dark] body {
        background-color: rgba(var(--body-bg-rgb), .6)
    }
    [data-width=boxed][data-vertical-style=detached] .page {
        width: 1400px;
        box-shadow: none
    }
    [data-width=boxed][data-vertical-style=detached] .page .app-header {
        width: 1400px
    }
    [data-width=boxed][data-vertical-style=detached][data-toggled=detached-close] .app-content {
        margin-inline-start: 5rem
    }
    [data-width=boxed][data-vertical-style=detached] .app-header .main-header-container {
        width: 99%
    }
    [data-width=boxed][data-vertical-style=detached] .app-content {
        margin-inline-start: 15rem
    }
    [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .main-sidebar {
        height: 100%
    }
    [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .simplebar-content-wrapper {
        position: initial
    }
    [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .simplebar-mask {
        position: inherit
    }
    [data-width=boxed] .mail-info-body {
        max-height: calc(100vh - 19.3rem)
    }
    [data-width=boxed] #Invoice .list-group-item>a>div {
        display: block !important
    }
    [data-width=boxed] .leads-source-labels .col {
        flex: 100%
    }
}

[data-menu-position=scrollable][data-nav-layout=vertical] .app-sidebar {
    position: absolute
}

[data-menu-position=scrollable][data-nav-layout=vertical] .app-sidebar .main-sidebar-header {
    position: absolute
}

[data-menu-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
    position: relative;
    z-index: 102
}

[data-menu-position=scrollable][data-nav-layout=horizontal] .app-content {
    margin-block-start: 4rem
}

[data-menu-position=scrollable][data-nav-layout=horizontal][data-header-position=scrollable] .app-sidebar {
    position: relative
}

[data-menu-position=scrollable][data-nav-layout=horizontal][data-header-position=scrollable] .app-header {
    position: relative
}

@media (max-width:991.98px) {
    [data-menu-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
        position: absolute
    }
    [data-menu-position=scrollable][data-nav-layout=horizontal] .main-content {
        margin-block-start: 0
    }
    [data-menu-position=scrollable][data-nav-layout=horizontal] .main-sidebar {
        height: 100%
    }
}

@media (min-width:992px) {
    [data-menu-position=scrollable] .app-content {
        min-height: calc(100vh - 2.5rem)
    }
}

[data-header-position=scrollable][data-nav-layout=vertical] .app-header {
    position: absolute
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
    position: relative;
    top: 0
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar.sticky {
    position: sticky;
    top: 0
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-content {
    margin-block-start: 0
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-header {
    position: relative
}

[data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=scrollable] .app-sidebar {
    position: relative
}

[data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=scrollable] .app-header {
    position: relative
}

[data-header-position=scrollable][data-nav-layout=horizontal][data-width=boxed] .app-header {
    margin: 0
}

@media (max-width:991.98px) {
    [data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
        position: absolute !important;
        z-index: 102
    }
    [data-header-position=scrollable][data-nav-layout=horizontal] .main-content {
        padding-block-start: 0
    }
    [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar {
        position: fixed !important
    }
    [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar .main-sidebar {
        margin-block-start: 3.72rem
    }
    [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar.sticky .main-sidebar {
        margin-block-start: 0
    }
    [data-header-position=scrollable][data-nav-layout=vertical] .app-sidebar.sticky {
        inset-block-start: 0
    }
}

[data-menu-styles=light] {
    --menu-bg: #fff;
    --menu-prime-color: #536485;
    --menu-border-color: #f3f3f3
}

[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block
}

[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=light] .side-menu__label1 a {
    color: #222528;
    font-weight: 600
}

[data-menu-styles=light] .side-menu__label1 {
    border-block-end: 1px solid #f3f3f3 !important
}

[data-menu-styles=light] .app-sidebar .side-menu__item.active,
[data-menu-styles=light] .app-sidebar .side-menu__item:hover {
    background-color: #f3f6f8
}

@media (min-width:992px) {
    [data-menu-styles=light][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
        border-inline-end: 1px solid #f3f3f3
    }
}

[data-menu-styles=dark] {
    --menu-bg: var(--custom-white);
    --menu-prime-color: #8f9bb3;
    --menu-border-color: rgba(255, 255, 255, 0.1)
}

[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
    display: block
}

[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
    display: block
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=dark] .side-menu__label1 a {
    color: #fff;
    font-weight: 600
}

[data-menu-styles=dark] .side-menu__label1 {
    border-block-end: 1px solid rgba(255, 255, 255, .1) !important
}

[data-menu-styles=dark] .app-sidebar .side-menu__item.active,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover {
    background-color: rgba(255, 255, 255, .05)
}

[data-menu-styles=dark] .app-sidebar .side-menu__item.active .side-menu__angle,
[data-menu-styles=dark] .app-sidebar .side-menu__item.active .side-menu__icon,
[data-menu-styles=dark] .app-sidebar .side-menu__item.active .side-menu__label,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover .side-menu__angle,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover .side-menu__icon,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover .side-menu__label {
    color: #fff;
    fill: #fff
}

[data-menu-styles=dark] .app-sidebar .slide-menu.child1 .side-menu__item:hover,
[data-menu-styles=dark] .app-sidebar .slide-menu.child2 .side-menu__item:hover,
[data-menu-styles=dark] .app-sidebar .slide-menu.child3 .side-menu__item:hover {
    color: #fff
}

[data-menu-styles=dark] .app-sidebar .slide-menu.child1 .side-menu__item:hover:before,
[data-menu-styles=dark] .app-sidebar .slide-menu.child2 .side-menu__item:hover:before,
[data-menu-styles=dark] .app-sidebar .slide-menu.child3 .side-menu__item:hover:before {
    border: 1px solid #fff;
    opacity: 1
}

[data-menu-styles=dark] .app-sidebar .side-menu__item.active,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover {
    color: #fff
}

[data-menu-styles=dark] .app-sidebar .slide-menu.child1 .side-menu__item.active:before,
[data-menu-styles=dark] .app-sidebar .slide-menu.child2 .side-menu__item.active:before,
[data-menu-styles=dark] .app-sidebar .slide-menu.child3 .side-menu__item.active:before {
    border-color: #fff
}

[data-menu-styles=dark][data-theme-mode=light] {
    --menu-bg: #011626
}

[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] {
    --menu-bg: url(../images/media/4.jpg)
}

@media (min-width:992px) {
    [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-left,
    [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-right {
        background-color: rgba(255, 255, 255, .1);
        border-color: rgba(255, 255, 255, .1)
    }
    [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
    [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
        fill: #fff
    }
    [data-menu-styles=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
        border-inline-end: 1px solid rgba(255, 255, 255, .1)
    }
}

[data-menu-styles=dark][data-theme-mode=light] {
    --menu-bg: #111c43;
    --menu-prime-color: #a3aed1
}

[data-menu-styles=color] {
    --menu-bg: var(--primary-color);
    --menu-prime-color: rgba(255, 255, 255, 0.6);
    --menu-border-color: rgba(255, 255, 255, 0.1)
}

[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
    display: block
}

[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: block
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
    display: block
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: block !important
}

[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=color] .side-menu__item.active {
    color: #fff !important
}

[data-menu-styles=color] .side-menu__item.active:before {
    border: 1px solid #fff !important
}

[data-menu-styles=color] .side-menu__item.active .side-menu__angle,
[data-menu-styles=color] .side-menu__item.active .side-menu__icon,
[data-menu-styles=color] .side-menu__item.active .side-menu__label {
    color: #fff !important;
    fill: #fff !important
}

[data-menu-styles=color] .side-menu__item:hover {
    color: #fff !important
}

[data-menu-styles=color] .side-menu__item:hover:before {
    border: 1px solid #fff !important
}

[data-menu-styles=color] .side-menu__item:hover .side-menu__angle,
[data-menu-styles=color] .side-menu__item:hover .side-menu__icon,
[data-menu-styles=color] .side-menu__item:hover .side-menu__label {
    color: #fff !important;
    fill: #fff !important
}

[data-menu-styles=color] .slide-menu {
    --menu-bg: var(--primary-color)
}

[data-menu-styles=color] .side-menu__label1 a {
    color: #fff;
    font-weight: 600
}

[data-menu-styles=color] .side-menu__label1 {
    border-block-end: 1px solid rgba(255, 255, 255, .1) !important
}

[data-menu-styles=color] .app-sidebar .main-menu>.slide>.side-menu__item.active,
[data-menu-styles=color] .app-sidebar .main-menu>.slide>.side-menu__item:hover {
    background-color: rgba(255, 255, 255, .1)
}

[data-menu-styles=color] .app-sidebar .main-menu .slide .side-menu__item:hover {
    background-color: rgba(255, 255, 255, .1)
}

@media (min-width:992px) {
    [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-left,
    [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-right {
        background-color: rgba(255, 255, 255, .1);
        border-color: rgba(255, 255, 255, .1)
    }
    [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
    [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
        fill: var(--primary-color)
    }
    [data-menu-styles=color][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
        border-inline-end: 1px solid rgba(255, 255, 255, .1)
    }
}

[data-menu-styles=gradient] {
    --menu-bg: linear-gradient(to bottom, var(--primary-color) 0%, #00b9da 100%);
    --menu-prime-color: rgba(255, 255, 255, 0.7);
    --menu-border-color: rgba(255, 255, 255, 0.1)
}

[data-menu-styles=gradient][data-nav-layout=horizontal] {
    --menu-bg: linear-gradient(to right, var(--primary-color) 0%, #00b9da 100%)
}

[data-menu-styles=gradient] .app-sidebar .main-sidebar-header {
    background: var(--primary-color)
}

[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
    display: block
}

[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: block
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
    display: block
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=gradient] .side-menu__item.active {
    color: #fff !important
}

[data-menu-styles=gradient] .side-menu__item.active:before {
    border: 1px solid #fff !important
}

[data-menu-styles=gradient] .side-menu__item.active .side-menu__angle,
[data-menu-styles=gradient] .side-menu__item.active .side-menu__icon,
[data-menu-styles=gradient] .side-menu__item.active .side-menu__label {
    color: #fff !important;
    fill: #fff !important
}

[data-menu-styles=gradient] .side-menu__item:hover {
    color: #fff !important
}

[data-menu-styles=gradient] .side-menu__item:hover:before {
    border: 1px solid #fff !important
}

[data-menu-styles=gradient] .side-menu__item:hover .side-menu__angle,
[data-menu-styles=gradient] .side-menu__item:hover .side-menu__icon,
[data-menu-styles=gradient] .side-menu__item:hover .side-menu__label {
    color: #fff !important;
    fill: #fff !important
}

[data-menu-styles=gradient] .slide-menu {
    --menu-bg: var(--primary-color)
}

[data-menu-styles=gradient] .side-menu__label1 a {
    color: #fff;
    font-weight: 600
}

[data-menu-styles=gradient] .side-menu__label1 {
    border-block-end: 1px solid rgba(255, 255, 255, .1) !important
}

[data-menu-styles=gradient] .app-sidebar .side-menu__item.active,
[data-menu-styles=gradient] .app-sidebar .side-menu__item:hover {
    background-color: rgba(255, 255, 255, .05)
}

@media (min-width:992px) {
    [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-left,
    [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-right {
        background-color: rgba(255, 255, 255, .1);
        border-color: rgba(255, 255, 255, .1)
    }
    [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
    [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
        fill: var(--primary-color)
    }
    [data-menu-styles=gradient][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
        border-inline-end: 1px solid rgba(255, 255, 255, .1)
    }
}

[data-menu-styles=transparent] {
    --menu-bg: var(--default-body-bg-color)
}

[data-menu-styles=transparent][data-theme-mode=light] {
    --menu-border-color: rgba(0, 0, 0, 0.07)
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
    display: block
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
    display: block
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

[data-menu-styles=transparent] .app-sidebar .side-menu__item.active,
[data-menu-styles=transparent] .app-sidebar .side-menu__item:hover {
    background-color: rgba(0, 0, 0, .05)
}

[data-menu-styles=transparent][data-theme-mode=dark] {
    --menu-prime-color: rgba(255, 255, 255, 0.6);
    --menu-border-color: rgba(255, 255, 255, 0.1);
    --menu-bg: rgb(var(--body-bg-rgb2))
}

[data-menu-styles=transparent][data-theme-mode=dark][icon-overlay=open] {
    --menu-bg: rgb(var(--body-bg-rgb2))
}

[data-menu-styles=transparent][data-theme-mode=dark] .app-sidebar .side-menu__item.active,
[data-menu-styles=transparent][data-theme-mode=dark] .app-sidebar .side-menu__item:hover {
    background-color: rgba(255, 255, 255, .05)
}

[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=icon-click] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=menu-click] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=menu-hover] .app-sidebar .slide.has-sub .slide-menu {
    background-color: var(--custom-white)
}

[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu {
    background-color: var(--custom-white)
}

@media (min-width:992px) {
    [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-left,
    [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-right {
        background-color: rgba(255, 255, 255, .1);
        border-color: rgba(255, 255, 255, .1)
    }
    [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
    [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
        fill: var(--primary-color)
    }
}

[data-header-styles=light] {
    --header-bg: #fff;
    --header-border-color: #f3f3f3;
    --header-prime-color: #536485
}

[data-header-styles=light] .main-header-container .header-link-icon:hover {
    background-color: #f3f6f8
}

[data-header-styles=dark] {
    --header-bg: var(--custom-white);
    --header-border-color: rgba(255, 255, 255, 0.1);
    --header-prime-color: rgba(255, 255, 255, 0.6)
}

@media (min-width:992px) {
    [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark {
        display: block
    }
    [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white {
        display: none
    }
}

[data-header-styles=dark] .main-header-container .header-link.show .header-link-icon,
[data-header-styles=dark] .main-header-container .header-link:hover .header-link-icon {
    fill: #fff
}

[data-header-styles=dark] .main-header-container .header-link-icon:hover {
    background-color: rgba(255, 255, 255, .1)
}

[data-header-styles=dark][data-theme-mode=light] {
    --header-bg: #011626
}

[data-header-styles=color] {
    --header-bg: var(--primary-color);
    --header-border-color: rgba(255, 255, 255, 0.1);
    --header-prime-color: rgba(255, 255, 255, 0.6)
}

@media (min-width:992px) {
    [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white {
        display: block
    }
    [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white {
        display: none
    }
}

[data-header-styles=color] .main-header-container .header-link.show .header-link-icon,
[data-header-styles=color] .main-header-container .header-link:hover .header-link-icon {
    fill: #fff
}

[data-header-styles=color] .main-header-container .header-link-icon:hover {
    background-color: rgba(255, 255, 255, .1)
}

[data-header-styles=gradient] {
    --header-bg: linear-gradient(to right, var(--primary-color) 0%, #00b9da 100%);
    --header-border-color: rgba(255, 255, 255, 0.1);
    --header-prime-color: rgba(255, 255, 255, 0.6)
}

@media (min-width:992px) {
    [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white {
        display: block
    }
    [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white {
        display: none
    }
}

[data-header-styles=gradient] .main-header-container .header-link.show .header-link-icon,
[data-header-styles=gradient] .main-header-container .header-link:hover .header-link-icon {
    fill: #fff
}

[data-header-styles=gradient] .main-header-container .header-link-icon:hover {
    background-color: rgba(255, 255, 255, .1)
}

[data-header-styles=transparent] {
    --header-bg: rgb(var(--body-bg-rgb2))
}

[data-header-styles=transparent] .main-header-container .header-link-icon:hover {
    background-color: rgba(0, 0, 0, .05)
}

[data-header-styles=transparent][data-theme-mode=light] {
    --header-border-color: rgba(0, 0, 0, 0.07);
    --header-bg: rgb(var(--body-bg-rgb))
}

[data-header-styles=transparent][data-theme-mode=dark] {
    --header-prime-color: rgba(255, 255, 255, 0.6);
    --header-border-color: rgba(255, 255, 255, 0.1)
}

[data-header-styles=transparent][data-theme-mode=dark] .main-header-container .header-link-icon:hover {
    background-color: rgba(255, 255, 255, .05)
}

@media (min-width:992px) {
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-dark,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-white {
        display: none
    }
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-logo {
        display: block
    }
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-dark {
        display: block
    }
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-logo,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-white,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-dark,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-logo,
    [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-white {
        display: none
    }
}

.bg-img .app-sidebar:before,
[data-bg-img=bgimg1] .app-sidebar:before,
[data-bg-img=bgimg2] .app-sidebar:before,
[data-bg-img=bgimg3] .app-sidebar:before,
[data-bg-img=bgimg4] .app-sidebar:before,
[data-bg-img=bgimg5] .app-sidebar:before {
    background-color: rgba(var(--body-bg-rgb), .8)
}

@media (min-width:992px) {
    .bg-img[data-nav-layout=horizontal] ul.slide-menu,
    [data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu,
    [data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
    [data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
    [data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
    [data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu {
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important
    }
    .bg-img[data-nav-layout=horizontal] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before {
        content: "";
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        border-radius: 0 0 .25rem .25rem;
        opacity: .9;
        background: rgb(var(--body-bg-rgb))
    }
    .bg-img[data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
    .bg-img[data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
    .bg-img[data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg5] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg5] ul.slide-menu:before {
        display: none
    }
    .bg-img[data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before {
        background: #f5f6f7
    }
    .bg-img[data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before {
        display: block
    }
    .bg-img[data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
        background: #011626
    }
}

.bg-img[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: #fff
}

.bg-img[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: var(--primary-color)
}

.bg-img[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background: linear-gradient(to right, var(--primary-color) 0, rgb(var(--secondary-rgb)) 100%)
}

.bg-img[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: transparent
}

.bg-img[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: rgb(var(--body-bg-rgb))
}

.bg-img[data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3 {
    background-color: transparent !important
}

.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3 {
    background-color: var(--custom-white) !important
}

.bg-img[data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before {
    background-color: rgba(0, 0, 0, .8)
}

.bg-img[data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu {
    background-color: #fff;
    background-image: none
}

.bg-img[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
    background-color: #fff;
    background-image: none
}

.bg-img[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar,
.bg-img[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar,
.bg-img[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar {
    position: fixed
}

.bg-img[data-menu-styles=light] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg1] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg5] .app-sidebar:before {
    background-color: rgba(255, 255, 255, .85)
}

@media (min-width:992px) {
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal] ul.slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu {
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important
    }
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before {
        content: "";
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        border-radius: 0 0 .25rem .25rem;
        opacity: .9;
        background: rgb(var(--body-bg-rgb))
    }
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before {
        display: none
    }
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before {
        background: #f5f6f7
    }
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before {
        display: block
    }
    .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
        background: #011626
    }
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: #fff
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: var(--primary-color)
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background: linear-gradient(to right, var(--primary-color) 0, rgb(var(--secondary-rgb)) 100%)
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: transparent
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: rgb(var(--body-bg-rgb))
}

.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3 {
    background-color: transparent !important
}

.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3 {
    background-color: var(--custom-white) !important
}

.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before {
    background-color: rgba(0, 0, 0, .8)
}

.bg-img[data-menu-styles=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu {
    background-color: var(--custom-white);
    background-image: none
}

.bg-img[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu {
    background-color: #011626
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
    background-color: #011626;
    background-image: none
}

.bg-img[data-theme-mode=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu {
    background-color: rgb(var(--body-bg-rgb))
}

.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
    background-color: rgb(var(--body-bg-rgb));
    background-image: none
}

.bg-img[data-menu-styles=color] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg1] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg5] .app-sidebar:before {
    background-color: var(--primary-color);
    opacity: .9
}

.bg-img[data-menu-styles=color][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu {
    background-color: var(--primary-color);
    background-image: none
}

.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
    background-color: var(--primary-color);
    background-image: none
}

.bg-img[data-menu-styles=gradient] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg1] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg5] .app-sidebar:before {
    background: linear-gradient(to right, var(--primary-color) 0, rgb(var(--secondary-rgb)) 100%);
    opacity: .9
}

.bg-img[data-menu-styles=gradient][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu {
    background-color: var(--primary-color);
    background-image: none
}

.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
    background-color: var(--primary-color);
    background-image: none
}

.bg-img[data-menu-styles=transparent][data-theme-mode=dark],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg1],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg2],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg3],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg4],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg5] {
    --menu-prime-color: rgba(255, 255, 255, 0.6)
}

.bg-img[data-menu-styles=transparent] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar:before {
    background-color: var(--default-body-bg-color);
    opacity: .9
}

@media (min-width:992px) {
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal] ul.slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu {
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important
    }
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before {
        content: "";
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        border-radius: 0 0 .25rem .25rem;
        opacity: .9;
        background: rgb(var(--body-bg-rgb))
    }
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg5] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg5] ul.slide-menu:before {
        display: none
    }
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before {
        background: #f5f6f7
    }
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before {
        display: block
    }
    .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
    [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
        background: #011626
    }
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: #fff
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: var(--primary-color)
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background: linear-gradient(to right, var(--primary-color) 0, rgb(var(--secondary-rgb)) 100%)
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: transparent
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background-color: rgb(var(--body-bg-rgb))
}

.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3 {
    background-color: transparent !important
}

.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3 {
    background-color: var(--custom-white) !important
}

.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before {
    background-color: rgba(0, 0, 0, .8)
}

.bg-img[data-menu-styles=transparent][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu {
    background-color: transparent;
    background-image: none
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu {
    background-color: var(--default-body-bg-color);
    background-image: none
}

.bg-img .app-sidebar,
[data-bg-img=bgimg1] .app-sidebar,
[data-bg-img=bgimg2] .app-sidebar,
[data-bg-img=bgimg3] .app-sidebar,
[data-bg-img=bgimg4] .app-sidebar,
[data-bg-img=bgimg5] .app-sidebar {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-inline-end: 0
}

.bg-img .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg5] .app-sidebar .main-sidebar-header {
    background: 0 0;
    border-inline-end: 0
}

.bg-img .app-sidebar:before,
[data-bg-img=bgimg1] .app-sidebar:before,
[data-bg-img=bgimg2] .app-sidebar:before,
[data-bg-img=bgimg3] .app-sidebar:before,
[data-bg-img=bgimg4] .app-sidebar:before,
[data-bg-img=bgimg5] .app-sidebar:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

[data-bg-img=bgimg1] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg1][data-menu-styles=transparent][data-theme-mode=dark] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg2] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg2][data-menu-styles=transparent][data-theme-mode=dark] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg3] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg3][data-menu-styles=transparent][data-theme-mode=dark] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg4] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg4][data-menu-styles=transparent][data-theme-mode=dark] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg5] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-bg-img=bgimg5][data-menu-styles=transparent][data-theme-mode=dark] {
    --menu-bg: url(../images/media/4.jpg)
}

[data-vertical-style=icontext][data-bg-img=bgimg1] .app-sidebar.sticky.sticky-pin .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg2] .app-sidebar.sticky.sticky-pin .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg3] .app-sidebar.sticky.sticky-pin .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg4] .app-sidebar.sticky.sticky-pin .main-sidebar-header,
[data-vertical-style=icontext][data-bg-img=bgimg5] .app-sidebar.sticky.sticky-pin .main-sidebar-header {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px)
}

[loader=disable] #loader {
    display: none
}

.accordion-button {
    background-color: var(--custom-white);
    color: var(--default-text-color);
    padding: .75rem 1rem;
    font-size: .85rem;
    font-weight: 500
}

.accordion-button:not(.collapsed) {
    color: var(--default-text-color);
    background-color: var(--default-background);
    box-shadow: none
}

.accordion-button:focus {
    border-color: var(--primary03);
    box-shadow: none
}

.accordion-button:after,
.accordion-button:not(.collapsed)::after {
    background-image: none
}

.accordion-button:after {
    content: "\ea78";
    font-family: remixicon !important;
    font-size: 1rem;
    line-height: 1;
    background-color: var(--default-background);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    color: var(--default-text-color)
}

.accordion-body {
    padding: .75rem 1rem;
    font-size: .8125rem;
    color: var(--text-muted)
}

.accordion-item {
    color: var(--default-text-color);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border)
}

.accordions-items-seperate .accordion-item:not(:first-of-type) {
    margin-block-start: .5rem
}

.accordions-items-seperate .accordion-item {
    border-block-start: 1px solid var(--default-border);
    border-radius: .5rem;
    overflow: hidden
}

.accordion-item:last-of-type {
    border-bottom-right-radius: .35rem;
    border-bottom-left-radius: .35rem
}

.accordion-item:first-of-type {
    border-top-left-radius: .35rem;
    border-top-right-radius: .35rem
}

.accordion.accordion-primary .accordion-button {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.accordion.accordion-primary .accordion-button:after {
    background-color: var(--primary-color);
    color: #fff
}

.accordion.accordion-primary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-primary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-secondary .accordion-button {
    background-color: rgba(var(--secondary-rgb), .1);
    color: rgb(var(--secondary-rgb))
}

.accordion.accordion-secondary .accordion-button:after {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.accordion.accordion-secondary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-secondary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-success .accordion-button {
    background-color: rgba(var(--success-rgb), .1);
    color: rgb(var(--success-rgb))
}

.accordion.accordion-success .accordion-button:after {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.accordion.accordion-success .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-success .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-danger .accordion-button {
    background-color: rgba(var(--danger-rgb), .1);
    color: rgb(var(--danger-rgb))
}

.accordion.accordion-danger .accordion-button:after {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.accordion.accordion-danger .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-danger .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-warning .accordion-button {
    background-color: rgba(var(--warning-rgb), .1);
    color: rgb(var(--warning-rgb))
}

.accordion.accordion-warning .accordion-button:after {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.accordion.accordion-warning .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-warning .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-info .accordion-button {
    background-color: rgba(var(--info-rgb), .1);
    color: rgb(var(--info-rgb))
}

.accordion.accordion-info .accordion-button:after {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.accordion.accordion-info .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-info .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-primary .accordion-button {
    background-color: var(--primary-color);
    color: #fff
}

.accordion.accordion-solid-primary .accordion-button:after {
    background-color: var(--custom-white);
    color: var(--primary-color)
}

.accordion.accordion-solid-primary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-primary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-secondary .accordion-button {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.accordion.accordion-solid-secondary .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--secondary-rgb))
}

.accordion.accordion-solid-secondary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-secondary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-success .accordion-button {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.accordion.accordion-solid-success .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--success-rgb))
}

.accordion.accordion-solid-success .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-success .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-danger .accordion-button {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.accordion.accordion-solid-danger .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--danger-rgb))
}

.accordion.accordion-solid-danger .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-danger .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-warning .accordion-button {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.accordion.accordion-solid-warning .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--warning-rgb))
}

.accordion.accordion-solid-warning .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-warning .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-info .accordion-button {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.accordion.accordion-solid-info .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--info-rgb))
}

.accordion.accordion-solid-info .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-info .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-border-primary .accordion-item {
    border: 1px solid var(--primary-color)
}

.accordion.accordion-border-primary .accordion-button {
    background-color: var(--custom-white);
    color: var(--primary-color);
    border-block-end: 0
}

.accordion.accordion-border-primary .accordion-button:after {
    background-color: var(--custom-white);
    color: var(--primary-color);
    border: 1px solid var(--primary-color)
}

.accordion.accordion-border-primary .accordion-button:not(.collapsed) {
    border-block-end: 1px solid var(--primary-color)
}

.accordion.accordion-border-secondary .accordion-item {
    border: 1px solid rgb(var(--secondary-rgb))
}

.accordion.accordion-border-secondary .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--secondary-rgb));
    border-block-end: 0
}

.accordion.accordion-border-secondary .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--secondary-rgb));
    border: 1px solid rgb(var(--secondary-rgb))
}

.accordion.accordion-border-secondary .accordion-button:not(.collapsed) {
    border-block-end: 1px solid rgb(var(--secondary-rgb))
}

.accordion.accordion-border-success .accordion-item {
    border: 1px solid rgb(var(--success-rgb))
}

.accordion.accordion-border-success .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--success-rgb));
    border-block-end: 0
}

.accordion.accordion-border-success .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--success-rgb));
    border: 1px solid rgb(var(--success-rgb))
}

.accordion.accordion-border-success .accordion-button:not(.collapsed) {
    border-block-end: 1px solid rgb(var(--success-rgb))
}

.accordion.accordion-border-danger .accordion-item {
    border: 1px solid rgb(var(--danger-rgb))
}

.accordion.accordion-border-danger .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--danger-rgb));
    border-block-end: 0
}

.accordion.accordion-border-danger .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--danger-rgb));
    border: 1px solid rgb(var(--danger-rgb))
}

.accordion.accordion-border-danger .accordion-button:not(.collapsed) {
    border-block-end: 1px solid rgb(var(--danger-rgb))
}

.accordion.accordion-border-warning .accordion-item {
    border: 1px solid rgb(var(--warning-rgb))
}

.accordion.accordion-border-warning .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--warning-rgb));
    border-block-end: 0
}

.accordion.accordion-border-warning .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--warning-rgb));
    border: 1px solid rgb(var(--warning-rgb))
}

.accordion.accordion-border-warning .accordion-button:not(.collapsed) {
    border-block-end: 1px solid rgb(var(--warning-rgb))
}

.accordion.accordion-border-info .accordion-item {
    border: 1px solid rgb(var(--info-rgb))
}

.accordion.accordion-border-info .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--info-rgb));
    border-block-end: 0
}

.accordion.accordion-border-info .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--info-rgb));
    border: 1px solid rgb(var(--info-rgb))
}

.accordion.accordion-border-info .accordion-button:not(.collapsed) {
    border-block-end: 1px solid rgb(var(--info-rgb))
}

.accordion.accordionicon-none .accordion-button:after {
    display: none
}

.accordion.accordionicon-left .accordion-button {
    padding-inline-start: 2.5rem
}

.accordion.accordionicon-left .accordion-button:after {
    position: absolute;
    inset-inline-start: .625rem
}

.accordion.accordion-customicon1 .accordion-button:after {
    content: "\ea13";
    font-family: remixicon !important
}

.accordion.accordion-customicon1 .accordion-button:not(.collapsed)::after {
    content: "\f1af";
    font-family: remixicon !important
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-button {
    background-color: var(--primary01);
    border-inline-start: .25rem solid var(--primary06)
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-button:after {
    background-color: var(--primary06);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-body {
    background-color: var(--primary01);
    padding-block-start: 0;
    border-inline-start: .25rem solid var(--primary06)
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button {
    background-color: rgba(var(--secondary-rgb), .1);
    border-inline-start: .25rem solid rgba(var(--secondary-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button:after {
    background-color: rgba(var(--secondary-rgb), .6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-body {
    background-color: rgba(var(--secondary-rgb), .1);
    padding-block-start: 0;
    border-inline-start: .25rem solid rgba(var(--secondary-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button {
    background-color: rgba(var(--warning-rgb), .1);
    border-inline-start: .25rem solid rgba(var(--warning-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button:after {
    background-color: rgba(var(--warning-rgb), .6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-body {
    background-color: rgba(var(--warning-rgb), .1);
    padding-block-start: 0;
    border-inline-start: .25rem solid rgba(var(--warning-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button {
    background-color: rgba(var(--info-rgb), .1);
    border-inline-start: .25rem solid rgba(var(--info-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button:after {
    background-color: rgba(var(--info-rgb), .6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-body {
    background-color: rgba(var(--info-rgb), .1);
    padding-block-start: 0;
    border-inline-start: .25rem solid rgba(var(--info-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button {
    background-color: rgba(var(--success-rgb), .1);
    border-inline-start: .25rem solid rgba(var(--success-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button:after {
    background-color: rgba(var(--success-rgb), .6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-body {
    background-color: rgba(var(--success-rgb), .1);
    padding-block-start: 0;
    border-inline-start: .25rem solid rgba(var(--success-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button {
    background-color: rgba(var(--danger-rgb), .1);
    border-inline-start: .25rem solid rgba(var(--danger-rgb), .6)
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button:after {
    background-color: rgba(var(--danger-rgb), .6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-body {
    background-color: rgba(var(--danger-rgb), .1);
    padding-block-start: 0;
    border-inline-start: .25rem solid rgba(var(--danger-rgb), .6)
}

.alert {
    padding: .625rem .85rem;
    border-radius: .5rem;
    font-size: .8125rem
}

.alert.alert-dismissible {
    padding: .625rem 2.25rem .625rem .85rem
}

.alert .btn-close {
    background-image: none;
    padding: .95rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.alert .btn-close i {
    font-size: 1.5rem;
    line-height: 1.5rem
}

.alert:last-child {
    margin-bottom: 0
}

.alert .alert-link {
    font-weight: 600
}

[dir=rtl] .alert.alert-dismissible {
    padding: .625rem .85rem .625rem 2.25rem
}

.alert-warning {
    background-color: rgba(var(--warning-rgb), .1);
    color: rgb(var(--warning-rgb));
    border-color: rgba(var(--warning-rgb), .1)
}

.alert-warning .alert-link {
    color: rgb(var(--warning-rgb))
}

.alert-warning .btn-close {
    color: rgb(var(--warning-rgb))
}

.alert-warning .btn-close.custom-close {
    background-color: rgb(var(--warning-rgb));
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-primary {
    background-color: var(--primary01);
    color: var(--primary-color);
    border-color: var(--primary01)
}

.alert-primary .alert-link {
    color: var(--primary-color)
}

.alert-primary .btn-close {
    color: var(--primary-color)
}

.alert-primary .btn-close.custom-close {
    background-color: var(--primary-color);
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-secondary {
    background-color: rgba(var(--secondary-rgb), .1);
    color: rgb(var(--secondary-rgb));
    border-color: rgba(var(--secondary-rgb), .1)
}

.alert-secondary .alert-link {
    color: rgb(var(--secondary-rgb))
}

.alert-secondary .btn-close {
    color: rgb(var(--secondary-rgb))
}

.alert-secondary .btn-close.custom-close {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-success {
    background-color: rgba(var(--success-rgb), .1);
    color: rgb(var(--success-rgb));
    border-color: rgba(var(--success-rgb), .1)
}

.alert-success .alert-link {
    color: rgb(var(--success-rgb))
}

.alert-success .btn-close {
    color: rgb(var(--success-rgb))
}

.alert-success .btn-close.custom-close {
    background-color: rgb(var(--success-rgb));
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-info {
    background-color: rgba(var(--info-rgb), .1);
    color: rgb(var(--info-rgb));
    border-color: rgba(var(--info-rgb), .1)
}

.alert-info .alert-link {
    color: rgb(var(--info-rgb))
}

.alert-info .btn-close {
    color: rgb(var(--info-rgb))
}

.alert-info .btn-close.custom-close {
    background-color: rgb(var(--info-rgb));
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-danger {
    background-color: rgba(var(--danger-rgb), .1);
    color: rgb(var(--danger-rgb));
    border-color: rgba(var(--danger-rgb), .1)
}

.alert-danger .alert-link {
    color: rgb(var(--danger-rgb))
}

.alert-danger .btn-close {
    color: rgb(var(--danger-rgb))
}

.alert-danger .btn-close.custom-close {
    background-color: rgb(var(--danger-rgb));
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-light {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    border-color: rgb(var(--light-rgb))
}

.alert-light .alert-link {
    color: var(--default-text-color)
}

.alert-light .btn-close {
    color: var(--default-text-color)
}

.alert-light .btn-close.custom-close {
    background-color: rgb(var(--light-rgb));
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-dark {
    background-color: rgba(var(--dark-rgb), .1);
    color: var(--default-text-color);
    border-color: rgba(var(--dark-rgb), .1)
}

.alert-dark .alert-link {
    color: var(--default-text-color)
}

.alert-dark .btn-close {
    color: #fff
}

.alert-dark .btn-close.custom-close {
    background-color: rgb(var(--dark-rgb));
    color: #fff;
    opacity: 1;
    border-radius: 50px;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    padding: .85rem
}

.alert-solid-primary {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color)
}

.alert-solid-primary .btn-close {
    color: #fff
}

.alert-solid-secondary {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    border-color: rgb(var(--secondary-rgb))
}

.alert-solid-secondary .btn-close {
    color: #fff
}

.alert-solid-warning {
    background-color: rgb(var(--warning-rgb));
    color: #fff;
    border-color: rgb(var(--warning-rgb))
}

.alert-solid-warning .btn-close {
    color: #fff
}

.alert-solid-info {
    background-color: rgb(var(--info-rgb));
    color: #fff;
    border-color: rgb(var(--info-rgb))
}

.alert-solid-info .btn-close {
    color: #fff
}

.alert-solid-success {
    background-color: rgb(var(--success-rgb));
    color: #fff;
    border-color: rgb(var(--success-rgb))
}

.alert-solid-success .btn-close {
    color: #fff
}

.alert-solid-danger {
    background-color: rgb(var(--danger-rgb));
    color: #fff;
    border-color: rgb(var(--danger-rgb))
}

.alert-solid-danger .btn-close {
    color: #fff
}

.alert-solid-light {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    border-color: var(--default-border)
}

.alert-solid-dark {
    background-color: rgb(var(--dark-rgb));
    color: #fff;
    border-color: rgb(var(--dark-rgb))
}

.alert-solid-dark .btn-close {
    color: #fff
}

.alert-outline-primary {
    background-color: var(--custom-white);
    color: var(--primary-color);
    border-color: var(--primary-color)
}

.alert-outline-primary .btn-close {
    color: var(--primary-color)
}

.alert-outline-secondary {
    background-color: var(--custom-white);
    color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb))
}

.alert-outline-secondary .btn-close {
    color: rgb(var(--secondary-rgb))
}

.alert-outline-info {
    background-color: var(--custom-white);
    color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb))
}

.alert-outline-info .btn-close {
    color: rgb(var(--info-rgb))
}

.alert-outline-warning {
    background-color: var(--custom-white);
    color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb))
}

.alert-outline-warning .btn-close {
    color: rgb(var(--warning-rgb))
}

.alert-outline-success {
    background-color: var(--custom-white);
    color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb))
}

.alert-outline-success .btn-close {
    color: rgb(var(--success-rgb))
}

.alert-outline-danger {
    background-color: var(--custom-white);
    color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb))
}

.alert-outline-danger .btn-close {
    color: rgb(var(--danger-rgb))
}

.alert-outline-light {
    background-color: var(--custom-white);
    color: var(--default-text-color);
    border-color: rgb(var(--light-rgb))
}

.alert-outline-dark {
    background-color: var(--custom-white);
    color: rgb(var(--dark-rgb));
    border-color: rgb(var(--dark-rgb))
}

.alert-outline-dark .btn-close {
    color: rgb(var(--dark-rgb))
}

.alert-primary.custom-alert-icon {
    border-inline-start: .313rem solid var(--primary-color) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-primary.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.alert-secondary.custom-alert-icon {
    border-inline-start: .313rem solid rgb(var(--secondary-rgb)) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-secondary.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.alert-warning.custom-alert-icon {
    border-inline-start: .313rem solid rgb(var(--warning-rgb)) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-warning.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.alert-danger.custom-alert-icon {
    border-inline-start: .313rem solid rgb(var(--danger-rgb)) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-danger.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.alert-success.custom-alert-icon {
    border-inline-start: .313rem solid rgb(var(--success-rgb)) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-success.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.alert-info.custom-alert-icon {
    border-inline-start: .313rem solid rgb(var(--info-rgb)) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-info.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.alert-light.custom-alert-icon {
    border-inline-start: .313rem solid rgb(var(--light-rgb)) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-light.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.alert-dark.custom-alert-icon {
    border-inline-start: .313rem solid rgb(var(--dark-rgb)) !important;
    color: var(--text-muted);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    font-size: .813rem
}

.alert-dark.custom-alert-icon .btn-close {
    color: var(--default-text-color)
}

.custom-alert1 {
    margin-block-end: 0;
    background-color: var(--custom-white);
    border: 0;
    padding: 1.25rem;
    color: var(--default-text-color)
}

.custom-alert1 p {
    margin-block-end: 2.5rem;
    color: var(--text-muted);
    font-size: .8rem
}

.custom-alert1 .custom-alert-icon {
    width: 3.125rem;
    height: 3.125rem;
    margin-bottom: .85rem
}

.custom-alert1 .btn-close {
    padding: 0;
    margin-block-end: 1rem
}

.custom-alert1.alert-primary {
    border-block-start: .313rem solid var(--primary-color)
}

.custom-alert1.alert-secondary {
    border-block-start: .313rem solid rgb(var(--secondary-rgb))
}

.custom-alert1.alert-warning {
    border-block-start: .313rem solid rgb(var(--warning-rgb))
}

.custom-alert1.alert-danger {
    border-block-start: .313rem solid rgb(var(--danger-rgb))
}

.alert-img {
    display: flex;
    align-items: center
}

.alert-img .avatar {
    border: 1px solid rgba(0, 0, 0, .1)
}

@media screen and (max-width:400px) {
    .btn-close.custom-close {
        margin: 9px 9px 9px 0
    }
}

.badge {
    padding: .25rem .45rem;
    font-weight: 600;
    border-radius: .25rem
}

.badge-sm {
    font-weight: 400;
    font-size: .625rem
}

.btn.btn-outline-primary .badge {
    background-color: var(--primary-color);
    color: #fff
}

.btn.btn-outline-primary:active .badge,
.btn.btn-outline-primary:focus .badge,
.btn.btn-outline-primary:hover .badge {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.btn.btn-outline-secondary .badge {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.btn.btn-outline-secondary:active .badge,
.btn.btn-outline-secondary:focus .badge,
.btn.btn-outline-secondary:hover .badge {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.btn.btn-outline-warning .badge {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.btn.btn-outline-warning:active .badge,
.btn.btn-outline-warning:focus .badge,
.btn.btn-outline-warning:hover .badge {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.btn.btn-outline-info .badge {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.btn.btn-outline-info:active .badge,
.btn.btn-outline-info:focus .badge,
.btn.btn-outline-info:hover .badge {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.btn.btn-outline-success .badge {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.btn.btn-outline-success:active .badge,
.btn.btn-outline-success:focus .badge,
.btn.btn-outline-success:hover .badge {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.btn.btn-outline-danger .badge {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.btn.btn-outline-danger:active .badge,
.btn.btn-outline-danger:focus .badge,
.btn.btn-outline-danger:hover .badge {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.custom-badge {
    font-size: .7rem;
    font-weight: 600
}

.icon-badge {
    position: relative
}

.icon-badge .icon {
    width: 2rem;
    height: 2rem;
    fill: var(--text-muted);
    font-size: 2rem
}

.icon-badge .badge {
    position: absolute;
    inset-inline-end: -.5rem
}

.text-badge {
    position: relative
}

.text-badge .badge {
    position: absolute;
    inset-inline-end: -1rem;
    inset-block-start: -1rem
}

.page-header-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea65");
    font-family: tabler-icons !important
}

[dir=rtl] .page-header-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea64");
    font-family: tabler-icons !important
}

.breadcrumb .breadcrumb-item a {
    color: var(--primary-color)
}

.breadcrumb .breadcrumb-item.active {
    color: var(--default-text-color);
    font-weight: 600
}

.breadcrumb-item+.breadcrumb-item::before {
    color: var(--text-muted)
}

.breadcrumb-example1 .breadcrumb-item+.breadcrumb-item::before {
    color: var(--text-muted);
    content: var(--bs-breadcrumb-divider, "\ea65");
    font-family: tabler-icons !important
}

.breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before {
    color: var(--text-muted);
    content: var(--bs-breadcrumb-divider, "\ea1c");
    font-family: tabler-icons !important
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
    color: var(--text-muted);
    content: var(--bs-breadcrumb-divider, "\ea61");
    font-family: tabler-icons !important
}

.embedded-breadcrumb:before {
    opacity: .7
}

[data-theme-mode=dark] .embedded-breadcrumb:before {
    filter: invert(1)
}

[dir=rtl] .breadcrumb-example1 .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea64")
}

[dir=rtl] .breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea1b")
}

[dir=rtl] .breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea60")
}

[dir=rtl] .embedded-breadcrumb:before {
    transform: scaleX(-1);
    padding-inline-start: .5rem;
    padding-inline-end: 0 !important
}

.btn {
    font-size: .85rem;
    border-radius: .35rem;
    padding: .5rem .85rem;
    box-shadow: none;
    font-weight: 500
}

.btn.btn-sm {
    padding: .26rem .5rem;
    border-radius: .25rem;
    font-size: .75rem
}

.btn.btn-lg {
    padding: .65rem 1rem;
    border-radius: .5rem;
    font-size: .95rem
}

.btn-group .btn {
    padding: .45rem .75rem
}

.btn-group-lg .btn {
    padding: .65rem 1rem
}

.btn-group-sm .btn {
    padding: .25rem .5rem
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
    color: #fff
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none
}

.btn-primary {
    background-color: rgb(var(--primary-rgb)) !important;
    border-color: rgb(var(--primary-rgb)) !important;
    color: #fff !important
}

.btn-primary:hover {
    background-color: rgba(var(--primary-rgb), .9) !important;
    border-color: rgb(var(--primary-rgb)) !important;
    color: #fff
}

.btn-primary:focus {
    background-color: rgb(var(--primary-rgb));
    border-color: rgb(var(--primary-rgb));
    color: #fff
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    background-color: rgb(var(--primary-rgb));
    border-color: rgb(var(--primary-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary.show:focus,
.btn-primary:active:focus {
    box-shadow: 0 .25rem 1rem var(--primary05)
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary.show,
.btn-primary:active {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-border) !important
}

.btn-secondary {
    background-color: rgb(var(--secondary-rgb)) !important;
    border-color: rgb(var(--secondary-rgb)) !important;
    color: #fff !important
}

.btn-secondary:hover {
    background-color: rgba(var(--secondary-rgb), .9) !important;
    border-color: rgb(var(--secondary-rgb)) !important;
    color: #fff
}

.btn-secondary:focus {
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb));
    color: #fff
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary.show:focus,
.btn-secondary:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb), .5)
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary.show,
.btn-secondary:active {
    background-color: rgb(var(--secondary-rgb)) !important;
    border-color: rgb(var(--secondary-rgb)) !important
}

.btn-warning {
    background-color: rgb(var(--warning-rgb)) !important;
    border-color: rgb(var(--warning-rgb)) !important;
    color: #fff !important
}

.btn-warning:hover {
    background-color: rgba(var(--warning-rgb), .9) !important;
    border-color: rgb(var(--warning-rgb)) !important;
    color: #fff
}

.btn-warning:focus {
    background-color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb));
    color: #fff
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    background-color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning.show:focus,
.btn-warning:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--warning-rgb), .5)
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning.show,
.btn-warning:active {
    background-color: rgb(var(--warning-rgb)) !important;
    border-color: rgb(var(--warning-rgb)) !important
}

.btn-info {
    background-color: rgb(var(--info-rgb)) !important;
    border-color: rgb(var(--info-rgb)) !important;
    color: #fff !important
}

.btn-info:hover {
    background-color: rgba(var(--info-rgb), .9) !important;
    border-color: rgb(var(--info-rgb)) !important;
    color: #fff
}

.btn-info:focus {
    background-color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb));
    color: #fff
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    background-color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info.show:focus,
.btn-info:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--info-rgb), .5)
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info.show,
.btn-info:active {
    background-color: rgb(var(--info-rgb)) !important;
    border-color: rgb(var(--info-rgb)) !important
}

.btn-danger {
    background-color: rgb(var(--danger-rgb)) !important;
    border-color: rgb(var(--danger-rgb)) !important;
    color: #fff !important
}

.btn-danger:hover {
    background-color: rgba(var(--danger-rgb), .9) !important;
    border-color: rgb(var(--danger-rgb)) !important;
    color: #fff
}

.btn-danger:focus {
    background-color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb));
    color: #fff
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    background-color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger.show:focus,
.btn-danger:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--danger-rgb), .5)
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger.show,
.btn-danger:active {
    background-color: rgb(var(--danger-rgb)) !important;
    border-color: rgb(var(--danger-rgb)) !important
}

.btn-success {
    background-color: rgb(var(--success-rgb)) !important;
    border-color: rgb(var(--success-rgb)) !important;
    color: #fff !important
}

.btn-success:hover {
    background-color: rgba(var(--success-rgb), .9) !important;
    border-color: rgb(var(--success-rgb)) !important;
    color: #fff
}

.btn-success:focus {
    background-color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb));
    color: #fff
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    background-color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success.show:focus,
.btn-success:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--success-rgb), .5)
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success.show,
.btn-success:active {
    background-color: rgb(var(--success-rgb)) !important;
    border-color: rgb(var(--success-rgb)) !important
}

.btn-dark {
    background-color: rgb(var(--dark-rgb));
    border-color: rgb(var(--dark-rgb));
    color: var(--custom-white)
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
    background-color: rgb(var(--dark-rgb));
    border-color: rgb(var(--dark-rgb));
    color: var(--custom-white) !important
}

.btn-dark svg {
    fill: var(--custom-black)
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    background-color: rgb(var(--dark-rgb));
    border-color: rgb(var(--dark-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark.show:focus,
.btn-dark:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--dark-rgb), .5)
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark.show,
.btn-dark:active {
    background-color: rgb(var(--dark-rgb)) !important;
    border-color: rgb(var(--dark-rgb)) !important;
    color: var(--custom-white)
}

.btn-light {
    background-color: rgb(var(--light-rgb));
    border-color: rgb(var(--light-rgb));
    color: var(--default-text-color)
}

.btn-light:hover {
    background-color: #e4ecf2;
    border-color: rgb(var(--light-rgb));
    color: var(--default-text-color)
}

.btn-light:focus {
    background-color: rgb(var(--light-rgb));
    border-color: rgb(var(--light-rgb));
    box-shadow: none;
    color: var(--default-text-color)
}

[data-theme-mode=dark] .btn-light:hover {
    background-color: rgba(var(--light-rgb), .8)
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    background-color: rgb(var(--light-rgb));
    border-color: rgb(var(--light-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light.show:focus,
.btn-light:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--light-rgb), .5)
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light.show,
.btn-light:active {
    background-color: rgb(var(--light-rgb)) !important;
    border-color: rgb(var(--light-rgb)) !important;
    color: var(--default-text-color) !important
}

.btn-teal {
    background-color: rgb(var(--teal-rgb)) !important;
    border-color: rgb(var(--teal-rgb)) !important;
    color: #fff !important
}

.btn-teal:hover {
    background-color: rgba(var(--teal-rgb), .9) !important;
    border-color: rgb(var(--teal-rgb)) !important;
    color: #fff
}

.btn-teal:focus {
    background-color: rgb(var(--teal-rgb));
    border-color: rgb(var(--teal-rgb));
    color: #fff
}

.btn-check:focus+.btn-teal,
.btn-teal:focus {
    background-color: rgb(var(--teal-rgb));
    border-color: rgb(var(--teal-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-teal:focus,
.btn-check:checked+.btn-teal:focus,
.btn-teal.active:focus,
.btn-teal.show:focus,
.btn-teal:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--teal-rgb), .5)
}

.btn-check:active+.btn-teal,
.btn-check:checked+.btn-teal,
.btn-teal.active,
.btn-teal.show,
.btn-teal:active {
    background-color: rgb(var(--teal-rgb)) !important;
    border-color: rgb(var(--teal-rgb)) !important
}

.btn-purple {
    background-color: rgb(var(--purple-rgb)) !important;
    border-color: rgb(var(--purple-rgb)) !important;
    color: #fff !important
}

.btn-purple:hover {
    background-color: rgba(var(--purple-rgb), .9) !important;
    border-color: rgb(var(--purple-rgb)) !important;
    color: #fff
}

.btn-purple:focus {
    background-color: rgb(var(--purple-rgb));
    border-color: rgb(var(--purple-rgb));
    color: #fff
}

.btn-check:focus+.btn-purple,
.btn-purple:focus {
    background-color: rgb(var(--purple-rgb));
    border-color: rgb(var(--purple-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-purple:focus,
.btn-check:checked+.btn-purple:focus,
.btn-purple.active:focus,
.btn-purple.show:focus,
.btn-purple:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--purple-rgb), .5)
}

.btn-check:active+.btn-purple,
.btn-check:checked+.btn-purple,
.btn-purple.active,
.btn-purple.show,
.btn-purple:active {
    background-color: rgb(var(--purple-rgb)) !important;
    border-color: rgb(var(--purple-rgb)) !important
}

.btn-orange {
    background-color: rgb(var(--orange-rgb)) !important;
    border-color: rgb(var(--orange-rgb)) !important;
    color: #fff !important
}

.btn-orange:hover {
    background-color: rgba(var(--orange-rgb), .9) !important;
    border-color: rgb(var(--orange-rgb)) !important;
    color: #fff
}

.btn-orange:focus {
    background-color: rgb(var(--orange-rgb));
    border-color: rgb(var(--orange-rgb));
    color: #fff
}

.btn-check:focus+.btn-orange,
.btn-orange:focus {
    background-color: rgb(var(--orange-rgb));
    border-color: rgb(var(--orange-rgb));
    outline: 0;
    box-shadow: none
}

.btn-check:active+.btn-orange:focus,
.btn-check:checked+.btn-orange:focus,
.btn-orange.active:focus,
.btn-orange.show:focus,
.btn-orange:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--orange-rgb), .5)
}

.btn-check:active+.btn-orange,
.btn-check:checked+.btn-orange,
.btn-orange.active,
.btn-orange.show,
.btn-orange:active {
    background-color: rgb(var(--orange-rgb)) !important;
    border-color: rgb(var(--orange-rgb)) !important
}

.btn-outline-primary {
    color: rgb(var(--primary-rgb));
    border-color: rgb(var(--primary-rgb))
}

.btn-outline-primary:active,
.btn-outline-primary:hover {
    color: #fff;
    background-color: rgb(var(--primary-rgb));
    border-color: rgb(var(--primary-rgb))
}

.btn-outline-primary:focus {
    color: #fff;
    background-color: rgb(var(--primary-rgb));
    border-color: rgb(var(--primary-rgb));
    box-shadow: none
}

.btn-outline-primary:focus:active {
    box-shadow: none
}

.btn-outline-secondary {
    color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb))
}

.btn-outline-secondary:active,
.btn-outline-secondary:hover {
    color: #fff;
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb))
}

.btn-outline-secondary:focus {
    color: #fff;
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb));
    box-shadow: none
}

.btn-outline-secondary:focus:active {
    box-shadow: none
}

.btn-outline-warning {
    color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb))
}

.btn-outline-warning:active,
.btn-outline-warning:hover {
    color: #fff;
    background-color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb))
}

.btn-outline-warning:focus {
    color: #fff;
    background-color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb));
    box-shadow: none
}

.btn-outline-warning:focus:active {
    box-shadow: none
}

.btn-outline-danger {
    color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb))
}

.btn-outline-danger:active,
.btn-outline-danger:hover {
    color: #fff;
    background-color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb))
}

.btn-outline-danger:focus {
    color: #fff;
    background-color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb));
    box-shadow: none
}

.btn-outline-danger:focus:active {
    box-shadow: none
}

.btn-outline-success {
    color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb))
}

.btn-outline-success:active,
.btn-outline-success:hover {
    color: #fff;
    background-color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb))
}

.btn-outline-success:focus {
    color: #fff;
    background-color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb));
    box-shadow: none
}

.btn-outline-success:focus:active {
    box-shadow: none
}

.btn-outline-info {
    color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb))
}

.btn-outline-info:active,
.btn-outline-info:hover {
    color: #fff;
    background-color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb))
}

.btn-outline-info:focus {
    color: #fff;
    background-color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb));
    box-shadow: none
}

.btn-outline-info:focus:active {
    box-shadow: none
}

.btn-outline-light {
    color: var(--default-text-color) !important;
    border-color: rgb(var(--light-rgb))
}

.btn-outline-light:active,
.btn-outline-light:hover {
    color: var(--default-text-color);
    background-color: rgb(var(--light-rgb));
    border-color: rgb(var(--light-rgb))
}

.btn-outline-light:focus {
    color: var(--default-text-color);
    background-color: rgb(var(--light-rgb));
    border-color: rgb(var(--light-rgb));
    box-shadow: none
}

.btn-outline-light:focus:active {
    box-shadow: none
}

.btn-outline-dark {
    color: rgb(var(--dark-rgb));
    border-color: rgb(var(--dark-rgb))
}

.btn-outline-dark:active,
.btn-outline-dark:hover {
    color: var(--custom-white);
    background-color: var(--custom-black);
    border-color: var(--custom-black)
}

.btn-outline-dark:focus {
    color: var(--custom-white);
    background-color: rgb(var(--dark-rgb));
    border-color: rgb(var(--dark-rgb));
    box-shadow: none
}

.btn-outline-dark:focus:active {
    box-shadow: none
}

.btn-outline-teal {
    color: rgb(var(--teal-rgb));
    border-color: rgb(var(--teal-rgb))
}

.btn-outline-teal:active,
.btn-outline-teal:hover {
    color: #fff;
    background-color: rgb(var(--teal-rgb));
    border-color: rgb(var(--teal-rgb))
}

.btn-outline-teal:focus {
    color: #fff;
    background-color: rgb(var(--teal-rgb));
    border-color: rgb(var(--teal-rgb));
    box-shadow: none
}

.btn-outline-teal:focus:active {
    box-shadow: none
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb))
}

.btn-primary-transparent {
    background-color: rgba(var(--primary-rgb), .1);
    border-color: rgba(var(--primary-rgb), .1);
    color: rgb(var(--primary-rgb))
}

.btn-primary-transparent:focus:hover,
.btn-primary-transparent:hover {
    background-color: rgb(var(--primary-rgb));
    border-color: rgb(var(--primary-rgb));
    color: #fff
}

.btn-check:focus+.btn-primary-transparent,
.btn-primary-transparent:focus {
    background-color: rgba(var(--primary-rgb), .1);
    border-color: rgba(var(--primary-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--primary-rgb), .3);
    color: rgb(var(--primary-rgb))
}

.btn-check:active+.btn-primary-transparent:focus,
.btn-check:checked+.btn-primary-transparent:focus,
.btn-primary-transparent.active:focus,
.btn-primary-transparent.show:focus,
.btn-primary-transparent:active:focus {
    box-shadow: 0 .25rem 1rem var(--primary05)
}

.btn-check:active+.btn-primary-transparent,
.btn-check:checked+.btn-primary-transparent,
.btn-primary-transparent.active,
.btn-primary-transparent.show,
.btn-primary-transparent:active {
    background-color: var(--primary-color);
    border-color: var(--primary01);
    color: #fff
}

.btn-secondary-transparent {
    background-color: rgba(var(--secondary-rgb), .1);
    border-color: rgba(var(--secondary-rgb), .1);
    color: rgb(var(--secondary-rgb))
}

.btn-secondary-transparent:focus:hover,
.btn-secondary-transparent:hover {
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb));
    color: #fff
}

.btn-check:focus+.btn-secondary-transparent,
.btn-secondary-transparent:focus {
    background-color: rgba(var(--secondary-rgb), .1);
    border-color: rgba(var(--secondary-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb), .3);
    color: rgb(var(--secondary-rgb))
}

.btn-check:active+.btn-secondary-transparent:focus,
.btn-check:checked+.btn-secondary-transparent:focus,
.btn-secondary-transparent.active:focus,
.btn-secondary-transparent.show:focus,
.btn-secondary-transparent:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb), .5)
}

.btn-check:active+.btn-secondary-transparent,
.btn-check:checked+.btn-secondary-transparent,
.btn-secondary-transparent.active,
.btn-secondary-transparent.show,
.btn-secondary-transparent:active {
    background-color: rgb(var(--secondary-rgb));
    border-color: rgba(var(--secondary-rgb), .1);
    color: #fff
}

.btn-warning-transparent {
    background-color: rgba(var(--warning-rgb), .1);
    border-color: rgba(var(--warning-rgb), .1);
    color: rgb(var(--warning-rgb))
}

.btn-warning-transparent:focus:hover,
.btn-warning-transparent:hover {
    background-color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb));
    color: #fff
}

.btn-check:focus+.btn-warning-transparent,
.btn-warning-transparent:focus {
    background-color: rgba(var(--warning-rgb), .1);
    border-color: rgba(var(--warning-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--warning-rgb), .3);
    color: rgb(var(--warning-rgb))
}

.btn-check:active+.btn-warning-transparent:focus,
.btn-check:checked+.btn-warning-transparent:focus,
.btn-warning-transparent.active:focus,
.btn-warning-transparent.show:focus,
.btn-warning-transparent:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--warning-rgb), .5)
}

.btn-check:active+.btn-warning-transparent,
.btn-check:checked+.btn-warning-transparent,
.btn-warning-transparent.active,
.btn-warning-transparent.show,
.btn-warning-transparent:active {
    background-color: rgb(var(--warning-rgb));
    border-color: rgba(var(--warning-rgb), .1);
    color: #fff
}

.btn-info-transparent {
    background-color: rgba(var(--info-rgb), .1);
    border-color: rgba(var(--info-rgb), .1);
    color: rgb(var(--info-rgb))
}

.btn-info-transparent:focus:hover,
.btn-info-transparent:hover {
    background-color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb));
    color: #fff
}

.btn-check:focus+.btn-info-transparent,
.btn-info-transparent:focus {
    background-color: rgba(var(--info-rgb), .1);
    border-color: rgba(var(--info-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--info-rgb), .3);
    color: rgb(var(--info-rgb))
}

.btn-check:active+.btn-info-transparent:focus,
.btn-check:checked+.btn-info-transparent:focus,
.btn-info-transparent.active:focus,
.btn-info-transparent.show:focus,
.btn-info-transparent:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--info-rgb), .5)
}

.btn-check:active+.btn-info-transparent,
.btn-check:checked+.btn-info-transparent,
.btn-info-transparent.active,
.btn-info-transparent.show,
.btn-info-transparent:active {
    background-color: rgb(var(--info-rgb));
    border-color: rgba(var(--info-rgb), .1);
    color: #fff
}

.btn-success-transparent {
    background-color: rgba(var(--success-rgb), .1);
    border-color: rgba(var(--success-rgb), .1);
    color: rgb(var(--success-rgb))
}

.btn-success-transparent:focus:hover,
.btn-success-transparent:hover {
    background-color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb));
    color: #fff
}

.btn-check:focus+.btn-success-transparent,
.btn-success-transparent:focus {
    background-color: rgba(var(--success-rgb), .1);
    border-color: rgba(var(--success-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--success-rgb), .3);
    color: rgb(var(--success-rgb))
}

.btn-check:active+.btn-success-transparent:focus,
.btn-check:checked+.btn-success-transparent:focus,
.btn-success-transparent.active:focus,
.btn-success-transparent.show:focus,
.btn-success-transparent:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--success-rgb), .5)
}

.btn-check:active+.btn-success-transparent,
.btn-check:checked+.btn-success-transparent,
.btn-success-transparent.active,
.btn-success-transparent.show,
.btn-success-transparent:active {
    background-color: rgb(var(--success-rgb));
    border-color: rgba(var(--success-rgb), .1);
    color: #fff
}

.btn-danger-transparent {
    background-color: rgba(var(--danger-rgb), .1);
    border-color: rgba(var(--danger-rgb), .1);
    color: rgb(var(--danger-rgb))
}

.btn-danger-transparent:focus:hover,
.btn-danger-transparent:hover {
    background-color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb));
    color: #fff
}

.btn-check:focus+.btn-danger-transparent,
.btn-danger-transparent:focus {
    background-color: rgba(var(--danger-rgb), .1);
    border-color: rgba(var(--danger-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--danger-rgb), .3);
    color: rgb(var(--danger-rgb))
}

.btn-check:active+.btn-danger-transparent:focus,
.btn-check:checked+.btn-danger-transparent:focus,
.btn-danger-transparent.active:focus,
.btn-danger-transparent.show:focus,
.btn-danger-transparent:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--danger-rgb), .5)
}

.btn-check:active+.btn-danger-transparent,
.btn-check:checked+.btn-danger-transparent,
.btn-danger-transparent.active,
.btn-danger-transparent.show,
.btn-danger-transparent:active {
    background-color: rgb(var(--danger-rgb));
    border-color: rgba(var(--danger-rgb), .1);
    color: #fff
}

.btn-teal-transparent {
    background-color: rgba(var(--teal-rgb), .1);
    border-color: rgba(var(--teal-rgb), .1);
    color: rgb(var(--teal-rgb))
}

.btn-teal-transparent:focus:hover,
.btn-teal-transparent:hover {
    background-color: rgb(var(--teal-rgb));
    border-color: rgb(var(--teal-rgb));
    color: #fff
}

.btn-check:focus+.btn-teal-transparent,
.btn-teal-transparent:focus {
    background-color: rgba(var(--teal-rgb), .1);
    border-color: rgba(var(--teal-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--teal-rgb), .3);
    color: rgb(var(--teal-rgb))
}

.btn-check:active+.btn-teal-transparent:focus,
.btn-check:checked+.btn-teal-transparent:focus,
.btn-teal-transparent.active:focus,
.btn-teal-transparent.show:focus,
.btn-teal-transparent:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--teal-rgb), .5)
}

.btn-check:active+.btn-teal-transparent,
.btn-check:checked+.btn-teal-transparent,
.btn-teal-transparent.active,
.btn-teal-transparent.show,
.btn-teal-transparent:active {
    background-color: rgb(var(--teal-rgb));
    border-color: rgba(var(--teal-rgb), .1);
    color: #fff
}

.btn-purple-transparent {
    background-color: rgba(var(--purple-rgb), .1);
    border-color: rgba(var(--purple-rgb), .1);
    color: rgb(var(--purple-rgb))
}

.btn-purple-transparent:focus:hover,
.btn-purple-transparent:hover {
    background-color: rgb(var(--purple-rgb));
    border-color: rgb(var(--purple-rgb));
    color: #fff
}

.btn-check:focus+.btn-purple-transparent,
.btn-purple-transparent:focus {
    background-color: rgba(var(--purple-rgb), .1);
    border-color: rgba(var(--purple-rgb), .1);
    outline: 0;
    box-shadow: 0 .25rem 1rem rgba(var(--purple-rgb), .3);
    color: rgb(var(--purple-rgb))
}

.btn-check:active+.btn-purple-transparent:focus,
.btn-check:checked+.btn-purple-transparent:focus,
.btn-purple-transparent.active:focus,
.btn-purple-transparent.show:focus,
.btn-purple-transparent:active:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--purple-rgb), .5)
}

.btn-check:active+.btn-purple-transparent,
.btn-check:checked+.btn-purple-transparent,
.btn-purple-transparent.active,
.btn-purple-transparent.show,
.btn-purple-transparent:active {
    background-color: rgb(var(--purple-rgb));
    border-color: rgba(var(--purple-rgb), .1);
    color: #fff
}

.btn-group-sm>.btn,
.btn-sm {
    font-size: .8rem;
    padding: .25rem .8rem;
    border-radius: .5rem
}

.btn-icon {
    width: 2.700rem;
    height: 2.700rem;
    font-size: .95rem;
    flex-shrink: 0
}

.btn-icon i {
    padding: 0;
    margin: 0 -.5rem
}

.btn-icon.btn-sm {
    width: 1.75rem;
    height: 1.75rem;
    font-size: .8rem
}

.btn-icon.btn-lg {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.2rem
}

.btn-list a,
.btn-list button,
.btn-list div,
.btn-list input {
    margin: 0 .375rem .375rem 0
}

[dir=rtl] .btn-list a,
[dir=rtl] .btn-list button,
[dir=rtl] .btn-list div,
[dir=rtl] .btn-list input {
    margin: 0 0 .375rem .375rem
}

.btn-list {
    margin-block-end: -.5rem
}

.btn-bd-primary {
    --bs-btn-font-weight: 600;
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary-color);
    --bs-btn-border-color: var(--primary-color);
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-hover-color: var(--primary-color);
    --bs-btn-hover-bg: rgba(var(--primary-color), 0.9);
    --bs-btn-hover-border-color: rgba(var(--primary-color), 0.9);
    --bs-btn-active-color: var(--primary-color);
    --bs-btn-active-bg: rgba(var(--primary-color), 0.9);
    --bs-btn-active-border-color: rgba(var(--primary-color), 0.9)
}

.btn-check+.btn.btn-outline-primary:hover,
.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff !important;
    background-color: rgb(var(--primary-rgb)) !important;
    border-color: rgb(var(--primary-rgb)) !important
}

.btn-check+.btn.btn-outline-secondary:hover,
.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
    color: #fff !important;
    background-color: rgb(var(--secondary-rgb)) !important;
    border-color: rgb(var(--secondary-rgb)) !important
}

.btn-check+.btn.btn-outline-info:hover,
.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: #fff !important;
    background-color: rgb(var(--info-rgb)) !important;
    border-color: rgb(var(--info-rgb)) !important
}

.btn-check+.btn.btn-outline-warning:hover,
.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
    color: #fff !important;
    background-color: rgb(var(--warning-rgb)) !important;
    border-color: rgb(var(--warning-rgb)) !important
}

.btn-check+.btn.btn-outline-danger:hover,
.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    color: #fff !important;
    background-color: rgb(var(--danger-rgb)) !important;
    border-color: rgb(var(--danger-rgb)) !important
}

.btn-check+.btn.btn-outline-success:hover,
.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #fff !important;
    background-color: rgb(var(--success-rgb)) !important;
    border-color: rgb(var(--success-rgb)) !important
}

.btn-check+.btn.btn-outline-light:hover,
.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active,
.btn-outline-light:hover {
    color: var(--default-text-color) !important;
    background-color: rgb(var(--light-rgb)) !important;
    border-color: rgb(var(--light-rgb)) !important
}

.btn-check+.btn.btn-outline-dark:hover,
.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
    color: var(--custom-white) !important;
    background-color: var(--custom-black) !important;
    border-color: var(--custom-black) !important
}

.btn-check+.btn.btn-outline-teal:hover,
.btn-check:active+.btn-outline-teal,
.btn-check:checked+.btn-outline-teal,
.btn-outline-teal.active,
.btn-outline-teal.dropdown-toggle.show,
.btn-outline-teal:active {
    color: #fff !important;
    background-color: rgb(var(--teal-rgb)) !important;
    border-color: rgb(var(--teal-rgb)) !important
}

.btn-check:checked+.btn-outline-primary:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--primary-color), .5)
}

.btn-check:checked+.btn-outline-secondary:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb), .5)
}

.btn-check:checked+.btn-outline-success:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--success-rgb), .5)
}

.btn-check:checked+.btn-outline-warning:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--warning-rgb), .5)
}

.btn-check:checked+.btn-outline-info:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--info-rgb), .5)
}

.btn-check:checked+.btn-outline-danger:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--danger-rgb), .5)
}

.btn-check:checked+.btn-outline-light:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--light-rgb), .5)
}

.btn-check:checked+.btn-outline-dark:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--dark-rgb), .5)
}

.btn-check:checked+.btn-outline-teal:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--teal-rgb), .5)
}

.btn-check:focus+.btn.btn-outline-primary {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: none
}

.btn-check:checked+.btn.btn-outline-secondary {
    color: #fff;
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb));
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb), .3)
}

.btn.show.btn-outline-secondary {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    border-color: rgb(var(--secondary-rgb))
}

.btn.btn-primary-light {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.btn.btn-primary-light:active,
.btn.btn-primary-light:focus,
.btn.btn-primary-light:hover {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color)
}

.btn.btn-secondary-light {
    background-color: rgba(var(--secondary-rgb), .1);
    color: rgb(var(--secondary-rgb))
}

.btn.btn-secondary-light:active,
.btn.btn-secondary-light:focus,
.btn.btn-secondary-light:hover {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    border-color: rgb(var(--secondary-rgb))
}

.btn.btn-warning-light {
    background-color: rgba(var(--warning-rgb), .1);
    color: rgb(var(--warning-rgb))
}

.btn.btn-warning-light:active,
.btn.btn-warning-light:focus,
.btn.btn-warning-light:hover {
    background-color: rgb(var(--warning-rgb));
    color: #fff;
    border-color: rgb(var(--warning-rgb))
}

.btn.btn-info-light {
    background-color: rgba(var(--info-rgb), .1);
    color: rgb(var(--info-rgb))
}

.btn.btn-info-light:active,
.btn.btn-info-light:focus,
.btn.btn-info-light:hover {
    background-color: rgb(var(--info-rgb));
    color: #fff;
    border-color: rgb(var(--info-rgb))
}

.btn.btn-success-light {
    background-color: rgba(var(--success-rgb), .1);
    color: rgb(var(--success-rgb))
}

.btn.btn-success-light:active,
.btn.btn-success-light:focus,
.btn.btn-success-light:hover {
    background-color: rgb(var(--success-rgb));
    color: #fff;
    border-color: rgb(var(--success-rgb))
}

.btn.btn-danger-light {
    background-color: rgba(var(--danger-rgb), .1);
    color: rgb(var(--danger-rgb))
}

.btn.btn-danger-light:active,
.btn.btn-danger-light:focus,
.btn.btn-danger-light:hover {
    background-color: rgb(var(--danger-rgb));
    color: #fff;
    border-color: rgb(var(--danger-rgb))
}

.btn.btn-purple-light {
    background-color: rgba(var(--purple-rgb), .1);
    color: rgb(var(--purple-rgb))
}

.btn.btn-purple-light:active,
.btn.btn-purple-light:focus,
.btn.btn-purple-light:hover {
    background-color: rgb(var(--purple-rgb));
    color: #fff;
    border-color: rgb(var(--purple-rgb))
}

.btn.btn-teal-light {
    background-color: rgba(var(--teal-rgb), .1);
    color: rgb(var(--teal-rgb))
}

.btn.btn-teal-light:active,
.btn.btn-teal-light:focus,
.btn.btn-teal-light:hover {
    background-color: rgb(var(--teal-rgb));
    color: #fff;
    border-color: rgb(var(--teal-rgb))
}

.btn.btn-orange-light {
    background-color: rgba(var(--orange-rgb), .1);
    color: rgb(var(--orange-rgb))
}

.btn.btn-orange-light:active,
.btn.btn-orange-light:focus,
.btn.btn-orange-light:hover {
    background-color: rgb(var(--orange-rgb));
    color: #fff;
    border-color: rgb(var(--orange-rgb))
}

.btn.btn-primary-gradient {
    background: linear-gradient(to right, var(--primary-color) 0, #0086ed 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-primary-gradient:hover {
    border: 0
}

.btn.btn-secondary-gradient {
    background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0, #6789d8 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-secondary-gradient:hover {
    border: 0
}

.btn.btn-warning-gradient {
    background: linear-gradient(to right, rgb(var(--warning-rgb)) 0, #7fa53a 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-warning-gradient:hover {
    border: 0
}

.btn.btn-info-gradient {
    background: linear-gradient(to right, rgb(var(--info-rgb)) 0, #52f0ce 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-info-gradient:hover {
    border: 0
}

.btn.btn-success-gradient {
    background: linear-gradient(to right, rgb(var(--success-rgb)) 0, #00a1c0 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-success-gradient:hover {
    border: 0
}

.btn.btn-danger-gradient {
    background: linear-gradient(to right, rgb(var(--danger-rgb)) 0, #a34a88 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-danger-gradient:hover {
    border: 0
}

.btn.btn-orange-gradient {
    background: linear-gradient(to right, rgb(var(--orange-rgb)) 0, #9ba815 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-orange-gradient:hover {
    border: 0
}

.btn.btn-teal-gradient {
    background: linear-gradient(to right, rgb(var(--teal-rgb)) 0, #0695dd 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-teal-gradient:hover {
    border: 0
}

.btn.btn-purple-gradient {
    background: linear-gradient(to right, rgb(var(--purple-rgb)) 0, #ff496d 100%);
    color: #fff !important;
    border: 0
}

.btn.btn-purple-gradient:hover {
    border: 0
}

.btn.btn-primary-ghost {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color)
}

.btn.btn-primary-ghost:active {
    color: var(--primary-color);
    border: 1px solid var(--primary-color)
}

.btn.btn-secondary-ghost {
    background-color: transparent;
    color: rgb(var(--secondary-rgb));
    border: 1px solid rgb(var(--secondary-rgb))
}

.btn.btn-secondary-ghost:active {
    color: rgb(var(--secondary-rgb));
    border: 1px solid rgb(var(--secondary-rgb))
}

.btn.btn-warning-ghost {
    background-color: transparent;
    color: rgb(var(--warning-rgb));
    border: 1px solid rgb(var(--warning-rgb))
}

.btn.btn-warning-ghost:active {
    color: rgb(var(--warning-rgb));
    border: 1px solid rgb(var(--warning-rgb))
}

.btn.btn-info-ghost {
    background-color: transparent;
    color: rgb(var(--info-rgb));
    border: 1px solid rgb(var(--info-rgb))
}

.btn.btn-info-ghost:active {
    color: rgb(var(--info-rgb));
    border: 1px solid rgb(var(--info-rgb))
}

.btn.btn-success-ghost {
    background-color: transparent;
    color: rgb(var(--success-rgb));
    border: 1px solid rgb(var(--success-rgb))
}

.btn.btn-success-ghost:active {
    color: rgb(var(--success-rgb));
    border: 1px solid rgb(var(--success-rgb))
}

.btn.btn-danger-ghost {
    background-color: transparent;
    color: rgb(var(--danger-rgb));
    border: 1px solid rgb(var(--danger-rgb))
}

.btn.btn-danger-ghost:active {
    color: rgb(var(--danger-rgb));
    border: 1px solid rgb(var(--danger-rgb))
}

.btn.btn-orange-ghost {
    background-color: transparent;
    color: rgb(var(--orange-rgb));
    border: 1px solid rgb(var(--orange-rgb))
}

.btn.btn-orange-ghost:active {
    color: rgb(var(--orange-rgb));
    border: 1px solid rgb(var(--orange-rgb))
}

.btn.btn-teal-ghost {
    background-color: transparent;
    color: rgb(var(--teal-rgb));
    border: 1px solid rgb(var(--teal-rgb))
}

.btn.btn-teal-ghost:active {
    color: rgb(var(--teal-rgb));
    border: 1px solid rgb(var(--teal-rgb))
}

.btn.btn-purple-ghost {
    background-color: transparent;
    color: rgb(var(--purple-rgb));
    border: 1px solid rgb(var(--purple-rgb))
}

.btn.btn-purple-ghost:active {
    color: rgb(var(--purple-rgb));
    border: 1px solid rgb(var(--purple-rgb))
}

.btn.btn-w-xs {
    min-width: 5.625rem
}

.btn.btn-w-sm {
    min-width: 6.975rem
}

.btn.btn-w-md {
    min-width: 8.125rem
}

.btn.btn-w-lg {
    min-width: 9.375rem
}

.btn-loader {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center
}

.btn-loader .loading {
    margin-inline-end: .75rem
}

.btn-loader i {
    animation: loader 1s infinite linear;
    position: absolute !important;
    top: .25rem
}

@keyframes loader {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(360deg)
    }
}

.btn-raised-shadow:hover {
    box-shadow: 0 .5rem 1rem .5rem var(--black-1)
}

.label-btn {
    position: relative;
    padding-inline-start: 2.6rem
}

.label-btn-icon {
    position: absolute;
    width: 2.25rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    inset-inline-start: -1px;
    inset-block-start: -1px;
    inset-block-end: -1px;
    background-color: var(--white-2)
}

.label-btn.label-end {
    padding-inline-start: 1rem;
    padding-inline-end: 2.6rem
}

.label-btn.label-end .label-btn-icon {
    inset-inline-end: -1px;
    inset-inline-start: auto
}

.custom-button {
    position: relative;
    padding-inline-start: 2.75rem
}

.custom-button .custom-btn-icons {
    box-shadow: 0 0 1px .25rem rgba(0, 0, 0, .1);
    position: absolute;
    inset-inline-start: -.125rem;
    inset-block-start: 0;
    background-color: var(--custom-white);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: .375rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    width: 2.25rem;
    height: 2.25rem
}

.custom-button .custom-btn-icons i {
    position: absolute
}

.btn-hover {
    position: relative
}

.btn-hover.btn-hover-animate {
    transition: all .2s linear 0s
}

.btn-hover.btn-hover-animate:before {
    content: "\f417";
    font-family: bootstrap-icons !important;
    font-size: .8125rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    inset-inline-end: 0;
    inset-block-start: 0;
    opacity: 0;
    height: 100%;
    width: 2rem;
    transition: all .2s linear 0s
}

.btn-hover.btn-hover-animate:hover {
    padding-inline-end: 2rem
}

.btn-hover.btn-hover-animate:hover:before {
    opacity: 1;
    text-indent: 0
}

.btn-glare {
    border: 1px solid;
    overflow: hidden;
    position: relative
}

.btn-glare span {
    z-index: 20
}

.btn-glare:after {
    background: var(--custom-white);
    content: "";
    height: 4.6875rem;
    inset-inline-start: -1.625rem;
    opacity: .15;
    position: absolute;
    inset-block-start: -3.125rem;
    transform: rotate(35deg);
    transition: all .6s cubic-bezier(.2, 1, .25, 1);
    width: 1.875rem;
    z-index: 30
}

.btn-glare:hover:after {
    inset-inline-start: 100%;
    inset-block-start: 0;
    transition: all .6s cubic-bezier(.2, 1, .25, 1)
}

.btn-darken-hover {
    position: relative
}

.btn-darken-hover:hover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
    inset-block-start: 0;
    inset-inline-start: 0
}

.btn-custom-border {
    border: 0;
    box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), 0);
    outline: 1px solid;
    outline-color: rgba(var(--warning-rgb), .5);
    outline-offset: 0;
    text-shadow: none;
    transition: all 1.25s cubic-bezier(.19, 1, .22, 1)
}

.btn-custom-border:hover {
    border: 0 solid;
    box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), .5), 0 0 1.25rem rgba(var(--warning-rgb), .2);
    outline-color: rgba(var(--warning-rgb), 0);
    outline-offset: .938rem
}

.btn-border-down.btn-primary-light {
    border-block-end: .1875rem solid var(--primary-color)
}

.btn-border-down.btn-secondary-light {
    border-block-end: .1875rem solid rgb(var(--secondary-rgb))
}

.btn-border-down.btn-info-light {
    border-block-end: .1875rem solid rgb(var(--info-rgb))
}

.btn-border-down.btn-warning-light {
    border-block-end: .1875rem solid rgb(var(--warning-rgb))
}

.btn-border-down.btn-success-light {
    border-block-end: .1875rem solid rgb(var(--success-rgb))
}

.btn-border-down.btn-danger-light {
    border-block-end: .1875rem solid rgb(var(--danger-rgb))
}

.btn-border-down.btn-dark-light {
    border-block-end: .1875rem solid var(--primary-color)
}

.btn-border-down.btn-teal-light {
    border-block-end: .1875rem solid rgb(var(--teal-rgb))
}

.btn-border-down.btn-purple-light {
    border-block-end: .1875rem solid rgb(var(--purple-rgb))
}

.btn-border-start.btn-primary-light {
    border-inline-start: .1875rem solid var(--primary-color)
}

.btn-border-start.btn-secondary-light {
    border-inline-start: .1875rem solid rgb(var(--secondary-rgb))
}

.btn-border-start.btn-info-light {
    border-inline-start: .1875rem solid rgb(var(--info-rgb))
}

.btn-border-start.btn-warning-light {
    border-inline-start: .1875rem solid rgb(var(--warning-rgb))
}

.btn-border-start.btn-success-light {
    border-inline-start: .1875rem solid rgb(var(--success-rgb))
}

.btn-border-start.btn-danger-light {
    border-inline-start: .1875rem solid rgb(var(--danger-rgb))
}

.btn-border-start.btn-dark-light {
    border-inline-start: .1875rem solid var(--primary-color)
}

.btn-border-start.btn-teal-light {
    border-inline-start: .1875rem solid rgb(var(--teal-rgb))
}

.btn-border-start.btn-purple-light {
    border-inline-start: .1875rem solid rgb(var(--purple-rgb))
}

.btn-border-end.btn-primary-light {
    border-inline-end: .1875rem solid var(--primary-color)
}

.btn-border-end.btn-secondary-light {
    border-inline-end: .1875rem solid rgb(var(--secondary-rgb))
}

.btn-border-end.btn-info-light {
    border-inline-end: .1875rem solid rgb(var(--info-rgb))
}

.btn-border-end.btn-warning-light {
    border-inline-end: .1875rem solid rgb(var(--warning-rgb))
}

.btn-border-end.btn-success-light {
    border-inline-end: .1875rem solid rgb(var(--success-rgb))
}

.btn-border-end.btn-danger-light {
    border-inline-end: .1875rem solid rgb(var(--danger-rgb))
}

.btn-border-end.btn-dark-light {
    border-inline-end: .1875rem solid var(--primary-color)
}

.btn-border-end.btn-teal-light {
    border-inline-end: .1875rem solid rgb(var(--teal-rgb))
}

.btn-border-end.btn-purple-light {
    border-inline-end: .1875rem solid rgb(var(--purple-rgb))
}

.btn-border-top.btn-primary-light {
    border-block-start: .1875rem solid var(--primary-color)
}

.btn-border-top.btn-secondary-light {
    border-block-start: .1875rem solid rgb(var(--secondary-rgb))
}

.btn-border-top.btn-info-light {
    border-block-start: .1875rem solid rgb(var(--info-rgb))
}

.btn-border-top.btn-warning-light {
    border-block-start: .1875rem solid rgb(var(--warning-rgb))
}

.btn-border-top.btn-success-light {
    border-block-start: .1875rem solid rgb(var(--success-rgb))
}

.btn-border-top.btn-danger-light {
    border-block-start: .1875rem solid rgb(var(--danger-rgb))
}

.btn-border-top.btn-dark-light {
    border-block-start: .1875rem solid var(--primary-color)
}

.btn-border-top.btn-teal-light {
    border-block-start: .1875rem solid rgb(var(--teal-rgb))
}

.btn-border-top.btn-purple-light {
    border-block-start: .1875rem solid rgb(var(--purple-rgb))
}

.btn-facebook {
    background-color: #3b5998;
    color: #fff;
    border: 1px solid #3b5998
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
    background-color: #3b5998 !important;
    color: #fff !important;
    border: 1px solid #3b5998 !important
}

.btn-google {
    background-color: #cf4e43;
    color: #fff;
    border: 1px solid #cf4e43
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
    background-color: #cf4e43 !important;
    color: #fff !important;
    border: 1px solid #cf4e43 !important
}

.btn-twitter {
    background-color: #00acee;
    color: #fff;
    border: 1px solid #00acee
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
    background-color: #00acee !important;
    color: #fff !important;
    border: 1px solid #00acee !important
}

.btn-github {
    background-color: #333;
    color: #fff;
    border: 1px solid #333
}

.btn-github:active,
.btn-github:focus,
.btn-github:hover {
    background-color: #333 !important;
    color: #fff !important;
    border: 1px solid #333 !important
}

.btn-youtube {
    background-color: red;
    color: #fff;
    border: 1px solid red
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
    background-color: red !important;
    color: #fff !important;
    border: 1px solid red !important
}

.btn-instagram {
    background: #f09433;
    background: linear-gradient(45deg, #f09433 0, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    color: #fff;
    border: 1px solid transparent
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
    color: #fff;
    border: 1px solid transparent
}

@media (max-width:575.98px) {
    .checkbox-button-group .btn-group label,
    .radio-button-group .btn-group label {
        font-size: .563rem
    }
}

.btn-outline-secondary {
    --bs-btn-hover-color: rgb(var(--secondary-rgb));
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: rgb(var(--secondary-rgb));
    --bs-btn-active-color: rgb(var(--secondary-rgb))
}

.card {
    margin-block-end: 1.5rem
}

.card .card-footer,
.card .card-header {
    background-color: var(--custom-white) !important;
    color: var(--default-text-color)
}

.card.custom-card {
    border-radius: .5rem;
    border: 0;
    background-color: var(--custom-white);
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    position: relative;
    margin-block-end: 1.5rem;
    width: 100%
}

.card.custom-card .card-header {
    padding: 1rem 1.25rem;
    background-color: transparent !important;
    border-block-end: 1px solid var(--default-border);
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    gap: .25rem
}

.card.custom-card .card-header:first-child {
    border-radius: .625rem .625rem 0 0
}

.card.custom-card .card-header .card-title:before {
    content: "";
    position: absolute;
    height: 1rem;
    width: .2rem;
    inset-block-start: .15rem;
    inset-inline-start: -.65rem;
    background: linear-gradient(to bottom, var(--primary05) 50%, rgba(var(--secondary-rgb), .5) 50%);
    border-radius: .5rem
}

.card.custom-card .card-header .card-title {
    position: relative;
    margin-block-end: 0;
    font-size: .9375rem;
    font-weight: 700;
    text-transform: capitalize
}

.card.custom-card .card-header .card-title .subtitle {
    margin-block-end: 0;
    text-transform: initial
}

.card.custom-card .card-footer {
    background-color: transparent !important;
    border-block-start: 1px solid var(--default-border);
    padding: 1rem 1.25rem;
    font-size: .813rem
}

.card.custom-card .card-body {
    padding: 1.25rem;
    color: var(--default-text-color)
}

.card.custom-card .card-link {
    font-size: .75rem
}

.card.custom-card .card-text {
    font-size: .813rem
}

.card.custom-card .footer-card-icon {
    width: 60px;
    height: 60px
}

.card.custom-card.overlay-card {
    position: relative;
    overflow: hidden;
    color: rgba(255, 255, 255, .9)
}

.card.custom-card.overlay-card .card-header {
    border-block-end: 1px solid rgba(255, 255, 255, .1)
}

.card.custom-card.overlay-card .card-footer {
    border-block-start: 1px solid rgba(255, 255, 255, .1)
}

.card.custom-card.overlay-card .over-content-bottom {
    top: auto
}

.card.custom-card.overlay-card:before {
    content: "";
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
    background-color: rgba(0, 0, 0, .2);
    position: absolute
}

.card.custom-card.card-bg-primary {
    background-color: var(--primary-color);
    color: #fff
}

.card.custom-card.card-bg-primary .card-header {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-end: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-primary .card-header:before {
    background-color: #fff
}

.card.custom-card.card-bg-primary .card-body {
    color: #fff
}

.card.custom-card.card-bg-primary .card-footer {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-start: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-secondary {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.card.custom-card.card-bg-secondary .card-header {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-end: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-secondary .card-header:before {
    background-color: #fff
}

.card.custom-card.card-bg-secondary .card-body {
    color: #fff
}

.card.custom-card.card-bg-secondary .card-footer {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-start: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-warning {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.card.custom-card.card-bg-warning .card-header {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-end: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-warning .card-header:before {
    background-color: #fff
}

.card.custom-card.card-bg-warning .card-body {
    color: #fff
}

.card.custom-card.card-bg-warning .card-footer {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-start: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-info {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.card.custom-card.card-bg-info .card-header {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-end: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-info .card-header:before {
    background-color: #fff
}

.card.custom-card.card-bg-info .card-body {
    color: #fff
}

.card.custom-card.card-bg-info .card-footer {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-start: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-success {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.card.custom-card.card-bg-success .card-header {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-end: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-success .card-header:before {
    background-color: #fff
}

.card.custom-card.card-bg-success .card-body {
    color: #fff
}

.card.custom-card.card-bg-success .card-footer {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-start: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-danger {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.card.custom-card.card-bg-danger .card-header {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-end: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-danger .card-header:before {
    background-color: #fff
}

.card.custom-card.card-bg-danger .card-body {
    color: #fff
}

.card.custom-card.card-bg-danger .card-footer {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-start: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-light {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color)
}

.card.custom-card.card-bg-light .card-header {
    background-color: rgba(0, 0, 0, .05) !important;
    color: var(--default-text-color);
    border-block-end: var(--default-border)
}

.card.custom-card.card-bg-light .card-body {
    color: var(--default-text-color)
}

.card.custom-card.card-bg-light .card-footer {
    background-color: rgba(0, 0, 0, .05) !important;
    color: var(--default-text-color);
    border-block-start: var(--default-border)
}

.card.custom-card.card-bg-dark {
    background-color: rgb(var(--dark-rgb));
    color: var(--custom-white)
}

.card.custom-card.card-bg-dark .card-header {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-end: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card.card-bg-dark .card-header:before {
    background-color: var(--custom-white)
}

.card.custom-card.card-bg-dark .card-body {
    color: #fff
}

.card.custom-card.card-bg-dark .card-footer {
    background-color: rgba(255, 255, 255, .1) !important;
    color: #fff;
    border-block-start: 1px solid rgba(255, 255, 255, .2)
}

.card.custom-card .card-anchor {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    pointer-events: auto;
    content: ""
}

.card.custom-card .collapse-close {
    display: none
}

.card.custom-card.card .collapsed .collapse-close {
    display: block
}

.card.custom-card.card .collapsed .collapse-open {
    display: none
}

.card.custom-card.card-fullscreen {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    inset-inline-start: 0;
    bottom: 0;
    z-index: 9999;
    margin: 0
}

@media (max-width:575px) {
    .overlay-card .card-text {
        font-size: .613rem !important
    }
}

.app-header .dropdown-toggle {
    position: relative
}

.dropdown-menu {
    border-color: var(--default-border);
    box-shadow: 0 1rem 1.125rem rgba(169, 169, 169, .15);
    font-size: .875rem;
    color: var(--default-text-color);
    background-color: var(--custom-white);
    padding: 0;
    z-index: 10
}

.dropdown-divider {
    border-color: var(--default-border)
}

.dropdown-item-text {
    color: var(--default-text-color)
}

.dropdown-header {
    color: var(--default-text-color)
}

.dropdown-item {
    color: var(--default-text-color);
    white-space: unset;
    padding: .5rem .9375rem;
    font-size: .8125rem;
    font-weight: 500
}

.dropdown-item:not(.active):active,
.dropdown-item:not(.active):focus,
.dropdown-item:not(.active):hover {
    color: var(--primary-color);
    background-color: var(--primary005)
}

.dropdown-item.active {
    background-color: var(--primary-color) !important
}

.dropdown-toggle::after {
    display: inline-block;
    margin-inline-start: .25rem;
    vertical-align: 0;
    content: "\f282";
    font-family: bootstrap-icons !important;
    border: 0;
    font-size: .6rem;
    font-weight: 600
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-inline-start: .25rem;
    vertical-align: 0;
    content: "\f286";
    font-family: bootstrap-icons !important;
    border: 0;
    font-size: .6rem;
    font-weight: 600
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-inline-start: .25rem;
    vertical-align: 0;
    content: "\f285";
    font-family: bootstrap-icons !important;
    border: 0;
    font-size: .6rem;
    font-weight: 600
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-inline-start: .25rem;
    vertical-align: 0;
    content: "\f284";
    font-family: bootstrap-icons !important;
    border: 0;
    font-size: .6rem;
    font-weight: 600
}

.btn.dropdown-toggle-split {
    opacity: .85
}

.dropdown-menu-dark {
    background-color: rgb(var(--dark-rgb));
    border-color: rgba(255, 255, 255, .1)
}

.dropdown-menu-dark li a {
    color: var(--custom-white)
}

.btn-list a.dropdown-item {
    margin-block-end: 0
}

.dropdown-menu.dropdown-menu-primary {
    background-color: var(--primary-color)
}

.dropdown-menu.dropdown-menu-primary li a {
    color: rgba(255, 255, 255, .9)
}

.dropdown-menu.dropdown-menu-primary .dropdown-item.active,
.dropdown-menu.dropdown-menu-primary .dropdown-item:active,
.dropdown-menu.dropdown-menu-primary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-primary .dropdown-item:hover {
    color: #fff
}

.dropdown-menu.dropdown-menu-secondary {
    background-color: rgb(var(--secondary-rgb))
}

.dropdown-menu.dropdown-menu-secondary li a {
    color: rgba(255, 255, 255, .9)
}

.dropdown-menu.dropdown-menu-secondary .dropdown-item.active,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:active,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:hover {
    color: #fff
}

.dropdown-menu.dropdown-menu-warning {
    background-color: rgb(var(--warning-rgb))
}

.dropdown-menu.dropdown-menu-warning li a {
    color: rgba(255, 255, 255, .9)
}

.dropdown-menu.dropdown-menu-warning .dropdown-item.active,
.dropdown-menu.dropdown-menu-warning .dropdown-item:active,
.dropdown-menu.dropdown-menu-warning .dropdown-item:focus,
.dropdown-menu.dropdown-menu-warning .dropdown-item:hover {
    color: #fff
}

.dropdown-menu.dropdown-menu-info {
    background-color: rgb(var(--info-rgb))
}

.dropdown-menu.dropdown-menu-info li a {
    color: rgba(255, 255, 255, .9)
}

.dropdown-menu.dropdown-menu-info .dropdown-item.active,
.dropdown-menu.dropdown-menu-info .dropdown-item:active,
.dropdown-menu.dropdown-menu-info .dropdown-item:focus,
.dropdown-menu.dropdown-menu-info .dropdown-item:hover {
    color: #fff
}

.dropdown-menu.dropdown-menu-success {
    background-color: rgb(var(--success-rgb))
}

.dropdown-menu.dropdown-menu-success li a {
    color: rgba(255, 255, 255, .9)
}

.dropdown-menu.dropdown-menu-success .dropdown-item.active,
.dropdown-menu.dropdown-menu-success .dropdown-item:active,
.dropdown-menu.dropdown-menu-success .dropdown-item:focus,
.dropdown-menu.dropdown-menu-success .dropdown-item:hover {
    color: #fff
}

.dropdown-menu.dropdown-menu-danger {
    background-color: rgb(var(--danger-rgb))
}

.dropdown-menu.dropdown-menu-danger li a {
    color: rgba(255, 255, 255, .9)
}

.dropdown-menu.dropdown-menu-danger .dropdown-item.active,
.dropdown-menu.dropdown-menu-danger .dropdown-item:active,
.dropdown-menu.dropdown-menu-danger .dropdown-item:focus,
.dropdown-menu.dropdown-menu-danger .dropdown-item:hover {
    color: #fff
}

.dropdown-menu.dropmenu-item-primary .dropdown-item.active,
.dropdown-menu.dropmenu-item-primary .dropdown-item:active,
.dropdown-menu.dropmenu-item-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-primary .dropdown-item:hover {
    background-color: var(--primary01) !important;
    color: var(--primary-color)
}

.dropdown-menu.dropmenu-item-secondary .dropdown-item.active,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:hover {
    background-color: rgba(var(--secondary-rgb), .1) !important;
    color: rgb(var(--secondary-rgb))
}

.dropdown-menu.dropmenu-item-warning .dropdown-item.active,
.dropdown-menu.dropmenu-item-warning .dropdown-item:active,
.dropdown-menu.dropmenu-item-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-item-warning .dropdown-item:hover {
    background-color: rgba(var(--warning-rgb), .1) !important;
    color: rgb(var(--warning-rgb))
}

.dropdown-menu.dropmenu-item-info .dropdown-item.active,
.dropdown-menu.dropmenu-item-info .dropdown-item:active,
.dropdown-menu.dropmenu-item-info .dropdown-item:focus,
.dropdown-menu.dropmenu-item-info .dropdown-item:hover {
    background-color: rgba(var(--info-rgb), .1) !important;
    color: rgb(var(--info-rgb))
}

.dropdown-menu.dropmenu-item-success .dropdown-item.active,
.dropdown-menu.dropmenu-item-success .dropdown-item:active,
.dropdown-menu.dropmenu-item-success .dropdown-item:focus,
.dropdown-menu.dropmenu-item-success .dropdown-item:hover {
    background-color: rgba(var(--success-rgb), .1) !important;
    color: rgb(var(--success-rgb))
}

.dropdown-menu.dropmenu-item-danger .dropdown-item.active,
.dropdown-menu.dropmenu-item-danger .dropdown-item:active,
.dropdown-menu.dropmenu-item-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-item-danger .dropdown-item:hover {
    background-color: rgba(var(--danger-rgb), .1) !important;
    color: rgb(var(--danger-rgb))
}

.dropdown-menu.dropmenu-light-primary {
    background-color: var(--primary01);
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem)
}

.dropdown-menu.dropmenu-light-primary .dropdown-item.active,
.dropdown-menu.dropmenu-light-primary .dropdown-item:active,
.dropdown-menu.dropmenu-light-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-primary .dropdown-item:hover {
    color: #fff;
    background-color: var(--primary-color) !important
}

.dropdown-menu.dropmenu-light-secondary {
    background-color: rgba(var(--secondary-rgb), .1);
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem)
}

.dropdown-menu.dropmenu-light-secondary .dropdown-item.active,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:hover {
    color: #fff;
    background-color: rgb(var(--secondary-rgb)) !important
}

.dropdown-menu.dropmenu-light-info {
    background-color: rgba(var(--info-rgb), .1);
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem)
}

.dropdown-menu.dropmenu-light-info .dropdown-item.active,
.dropdown-menu.dropmenu-light-info .dropdown-item:active,
.dropdown-menu.dropmenu-light-info .dropdown-item:focus,
.dropdown-menu.dropmenu-light-info .dropdown-item:hover {
    color: #fff;
    background-color: rgb(var(--info-rgb)) !important
}

.dropdown-menu.dropmenu-light-warning {
    background-color: rgba(var(--warning-rgb), .1);
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem)
}

.dropdown-menu.dropmenu-light-warning .dropdown-item.active,
.dropdown-menu.dropmenu-light-warning .dropdown-item:active,
.dropdown-menu.dropmenu-light-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-light-warning .dropdown-item:hover {
    color: #fff;
    background-color: rgb(var(--warning-rgb)) !important
}

.dropdown-menu.dropmenu-light-success {
    background-color: rgba(var(--success-rgb), .1);
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem)
}

.dropdown-menu.dropmenu-light-success .dropdown-item.active,
.dropdown-menu.dropmenu-light-success .dropdown-item:active,
.dropdown-menu.dropmenu-light-success .dropdown-item:focus,
.dropdown-menu.dropmenu-light-success .dropdown-item:hover {
    color: #fff;
    background-color: rgb(var(--success-rgb)) !important
}

.dropdown-menu.dropmenu-light-danger {
    background-color: rgba(var(--danger-rgb), .1);
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem)
}

.dropdown-menu.dropmenu-light-danger .dropdown-item.active,
.dropdown-menu.dropmenu-light-danger .dropdown-item:active,
.dropdown-menu.dropmenu-light-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-light-danger .dropdown-item:hover {
    color: #fff;
    background-color: rgb(var(--danger-rgb)) !important
}

.dropdown-divider {
    margin: 0
}

@media (max-width:575.98px) {
    .main-header-container .header-element .header-link {
        padding: 1rem .5rem
    }
}

.form-control {
    border-color: var(--input-border);
    color: var(--default-text-color);
    background-color: var(--form-control-bg);
    font-size: .875rem;
    font-weight: var(--default-font-weight);
    line-height: 1.6;
    border-radius: .35rem
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--input-border);
    background-color: var(--custom-white);
    box-shadow: 0 .0625rem .125rem 0 rgba(0, 0, 0, .05);
    color: var(--default-text-color)
}

.form-label {
    font-size: .8rem;
    font-weight: 600;
    color: var(--default-text-color)
}

.form-text {
    color: var(--text-muted)
}

.form-control-light {
    background-color: rgb(var(--light-rgb));
    border: 0
}

.form-control-light:focus {
    background-color: rgb(var(--light-rgb))
}

.form-control-primary {
    background-color: var(--primary01);
    border: 0;
    color: var(--primary-color)
}

.form-control-primary:focus {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.form-input-color {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: .5rem;
    overflow: hidden;
    padding: 0
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    color: var(--text-muted)
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: var(--text-muted)
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
    background-color: transparent
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
    background-color: transparent
}

.form-select {
    background-color: var(--form-control-bg);
    border: 1px solid var(--input-border);
    color: var(--default-text-color);
    font-size: .813rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-size: 1.25rem
}

.form-select option {
    background-color: transparent;
    padding: .35rem .75rem;
    border-radius: .25rem
}

.form-select option:checked {
    background-color: var(--primary02);
    color: var(--primary-color)
}

.form-check-input {
    width: 1.1em;
    height: 1.1em;
    background-color: var(--custom-white);
    border: 1px solid var(--input-border)
}

.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.form-check-input.form-checked-outline:checked {
    background-color: transparent;
    border-color: var(--primary-color)
}

.form-check-input.form-checked-secondary:checked {
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb))
}

.form-check-input.form-checked-warning:checked {
    background-color: rgb(var(--warning-rgb));
    border-color: rgb(var(--warning-rgb))
}

.form-check-input.form-checked-info:checked {
    background-color: rgb(var(--info-rgb));
    border-color: rgb(var(--info-rgb))
}

.form-check-input.form-checked-success:checked {
    background-color: rgb(var(--success-rgb));
    border-color: rgb(var(--success-rgb))
}

.form-check-input.form-checked-danger:checked {
    background-color: rgb(var(--danger-rgb));
    border-color: rgb(var(--danger-rgb))
}

.form-check-input.form-checked-light:checked {
    background-color: rgb(var(--light-rgb));
    border-color: rgb(var(--light-rgb))
}

.form-check-input.form-checked-dark:checked {
    background-color: rgb(var(--dark-rgb));
    border-color: rgb(var(--dark-rgb))
}

.form-check-input:focus {
    box-shadow: none
}

.form-checked-outline:checked[type=checkbox] {
    background-image: none;
    position: relative;
    background-color: transparent
}

.form-checked-outline:checked[type=checkbox]:before {
    content: "\f633";
    font-family: bootstrap-icons !important;
    position: absolute;
    color: var(--primary-color);
    width: .625rem;
    height: .625rem;
    inset-block-start: -1px;
    inset-inline-start: 0;
    font-size: .688rem
}

.form-checked-outline:checked[type=checkbox].form-checked-secondary:before {
    color: rgb(var(--secondary-rgb))
}

.form-checked-outline:checked[type=checkbox].form-checked-warning:before {
    color: rgb(var(--warning-rgb))
}

.form-checked-outline:checked[type=checkbox].form-checked-info:before {
    color: rgb(var(--info-rgb))
}

.form-checked-outline:checked[type=checkbox].form-checked-success:before {
    color: rgb(var(--success-rgb))
}

.form-checked-outline:checked[type=checkbox].form-checked-danger:before {
    color: rgb(var(--danger-rgb))
}

.form-checked-outline:checked[type=checkbox].form-checked-light:before {
    color: rgb(var(--light-rgb))
}

.form-checked-outline:checked[type=checkbox].form-checked-dark:before {
    color: rgb(var(--dark-rgb))
}

.form-checked-outline:checked[type=radio] {
    background-image: none;
    position: relative;
    background-color: transparent
}

.form-checked-outline:checked[type=radio]:before {
    content: "\f309";
    font-family: bootstrap-icons !important;
    position: absolute;
    color: var(--primary-color);
    width: .625rem;
    height: .625rem;
    inset-block-start: -1.125rem;
    inset-inline-start: -.65rem;
    font-size: 2rem
}

.form-checked-outline:checked[type=radio].form-checked-secondary:before {
    color: rgb(var(--secondary-rgb))
}

.form-checked-outline:checked[type=radio].form-checked-warning:before {
    color: rgb(var(--warning-rgb))
}

.form-checked-outline:checked[type=radio].form-checked-info:before {
    color: rgb(var(--info-rgb))
}

.form-checked-outline:checked[type=radio].form-checked-success:before {
    color: rgb(var(--success-rgb))
}

.form-checked-outline:checked[type=radio].form-checked-danger:before {
    color: rgb(var(--danger-rgb))
}

.form-checked-outline:checked[type=radio].form-checked-light:before {
    color: rgb(var(--light-rgb))
}

.form-checked-outline:checked[type=radio].form-checked-dark:before {
    color: rgb(var(--dark-rgb))
}

fieldset:disabled .btn {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.form-select:focus {
    border-color: var(--primary05);
    outline: 0;
    box-shadow: none
}

.btn-check:focus+.btn,
.btn:focus {
    background-color: none;
    border-color: none;
    outline: 0
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
    box-shadow: none
}

.form-control:disabled,
.form-select:disabled {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color)
}

.form-control-plaintext {
    color: var(--default-text-color)
}

.form-control::-webkit-file-upload-button {
    color: var(--default-text-color);
    background-color: var(--default-background)
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--default-background)
}

.form-control::-webkit-input-placeholder {
    font-size: .8rem;
    font-weight: 500;
    opacity: .4;
    color: var(--default-text-color);
    padding-inline-start: 0;
    -webkit-transition: padding-inline-start .1s ease-in;
    transition: padding-inline-start .1s ease-in
}

.form-control:-moz-placeholder {
    font-size: .8rem;
    font-weight: 500;
    opacity: .4;
    color: var(--default-text-color)
}

.form-control::-moz-placeholder {
    font-size: .8rem;
    font-weight: 500;
    opacity: .4;
    color: var(--default-text-color)
}

.form-control:-ms-input-placeholder {
    font-size: .8rem;
    font-weight: 500;
    opacity: .4;
    color: var(--default-text-color)
}

.form-control-sm {
    font-size: .8rem;
    padding: .25rem .8rem;
    line-height: inherit
}

.form-control-lg {
    font-size: 1rem;
    padding: .5rem 1rem
}

.form-check-md .form-check-input {
    width: 1.15rem;
    height: 1.15rem
}

.form-check-md.form-switch .form-check-input {
    width: 2.25rem
}

.form-check-md label {
    margin-inline-start: .5rem;
    font-size: .95rem;
    margin-block-start: 3px
}

.form-check-lg .form-check-input {
    width: 1.35rem;
    height: 1.35rem
}

.form-check-lg.form-switch .form-check-input {
    width: 2.5rem
}

.form-check-lg label {
    margin-inline-start: .5rem;
    font-size: 1rem;
    margin-block-start: 3px
}

.toggle {
    width: 3.75rem;
    height: 1.563rem;
    background-color: rgb(var(--light-rgb));
    margin-inline-start: .625rem;
    margin-block-end: .313rem;
    padding: .125rem;
    border-radius: .188rem;
    position: relative;
    overflow: hidden;
    transition: all .2s ease
}

.toggle span {
    position: absolute;
    inset-block-start: .188rem;
    inset-block-end: .25rem;
    inset-inline-start: .188rem;
    display: block;
    width: 1.25rem;
    border-radius: .125rem;
    background-color: var(--custom-white);
    box-shadow: 0 .125rem .125rem 0 rgba(0, 0, 0, .45);
    cursor: pointer;
    transition: all .2s ease
}

.toggle span::before {
    content: "on";
    inset-inline-start: -1.563rem
}

.toggle span::after {
    content: "off";
    inset-inline-end: -1.813rem;
    color: var(--text-muted)
}

.toggle span::after,
.toggle span::before {
    position: absolute;
    font-size: .625rem;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
    inset-block-start: .188rem;
    line-height: 1.38;
    transition: all .2s ease
}

.toggle.on {
    background-color: var(--primary03)
}

.toggle.on span {
    background-color: var(--primary-color)
}

.toggle.on span::before {
    color: var(--primary-color)
}

.toggle.on.toggle-secondary {
    background-color: rgba(var(--secondary-rgb), .4)
}

.toggle.on.toggle-secondary span {
    background-color: rgb(var(--secondary-rgb))
}

.toggle.on.toggle-secondary span::before {
    color: rgb(var(--secondary-rgb))
}

.toggle.on.toggle-warning {
    background-color: rgba(var(--warning-rgb), .4)
}

.toggle.on.toggle-warning span {
    background-color: rgb(var(--warning-rgb))
}

.toggle.on.toggle-warning span::before {
    color: rgb(var(--warning-rgb))
}

.toggle.on.toggle-info {
    background-color: rgba(var(--info-rgb), .4)
}

.toggle.on.toggle-info span {
    background-color: rgb(var(--info-rgb))
}

.toggle.on.toggle-info span::before {
    color: rgb(var(--info-rgb))
}

.toggle.on.toggle-success {
    background-color: rgba(var(--success-rgb), .4)
}

.toggle.on.toggle-success span {
    background-color: rgb(var(--success-rgb))
}

.toggle.on.toggle-success span::before {
    color: rgb(var(--success-rgb))
}

.toggle.on.toggle-danger {
    background-color: rgba(var(--danger-rgb), .4)
}

.toggle.on.toggle-danger span {
    background-color: rgb(var(--danger-rgb))
}

.toggle.on.toggle-danger span::before {
    color: rgb(var(--danger-rgb))
}

.toggle.on.toggle-light {
    background-color: rgba(var(--light-rgb), .4)
}

.toggle.on.toggle-light span {
    background-color: rgb(var(--light-rgb))
}

.toggle.on.toggle-light span::after,
.toggle.on.toggle-light span::before {
    color: var(--text-muted)
}

.toggle.on.toggle-dark {
    background-color: rgba(var(--dark-rgb), .4)
}

.toggle.on.toggle-dark span {
    background-color: rgb(var(--dark-rgb))
}

.toggle.on.toggle-dark span::before {
    color: var(--custom-white)
}

.toggle.on span {
    inset-inline-start: 2.313rem
}

.toggle.on.toggle-sm span {
    inset-inline-start: 2.313rem
}

.toggle.on.toggle-sm span::before {
    inset-block-start: -1px;
    inset-inline-start: -1.563rem
}

.toggle.on.toggle-lg span {
    inset-inline-start: 2.563rem
}

.toggle.on.toggle-lg span::before {
    inset-block-start: .5rem;
    inset-inline-start: -1.75rem
}

.toggle.toggle-sm {
    height: 1.063rem;
    width: 3.125rem
}

.toggle.toggle-sm span {
    width: .625rem;
    height: .625rem
}

.toggle.toggle-sm span::after {
    inset-inline-end: -1.875rem;
    inset-block-start: -1px
}

.toggle.toggle-lg {
    height: 2.125rem;
    width: 4.5rem
}

.toggle.toggle-lg span {
    width: 1.75rem
}

.toggle.toggle-lg span::after {
    inset-block-start: .5rem;
    inset-inline-end: -1.938rem
}

.custom-toggle-switch>input[type=checkbox] {
    display: none
}

.custom-toggle-switch>label {
    cursor: pointer;
    height: 0;
    position: relative;
    width: 2.5rem
}

.label-primary {
    background: var(--primary-color);
    color: #fff
}

.label-secondary {
    background: rgb(var(--secondary-rgb));
    color: #fff
}

.label-warning {
    background: rgb(var(--warning-rgb));
    color: #fff
}

.label-info {
    background: rgb(var(--info-rgb));
    color: #fff
}

.label-success {
    background: rgb(var(--success-rgb));
    color: #fff
}

.label-danger {
    background: rgb(var(--danger-rgb));
    color: #fff
}

.label-light {
    background: rgb(var(--light-rgb));
    color: #fff
}

.label-dark {
    background: rgb(var(--dark-rgb));
    color: #fff
}

.custom-toggle-switch>input[type=checkbox]:checked+label::before {
    background: inherit;
    opacity: .5
}

.custom-toggle-switch>label::before {
    background: var(--text-muted);
    box-shadow: inset 0 0 .625rem var(--white-5);
    border-radius: .5rem;
    content: "";
    height: 1rem;
    margin-block-start: -.5rem;
    position: absolute;
    opacity: .3;
    transition: all .4s ease-in-out;
    width: 2.5rem
}

.custom-toggle-switch>label::after {
    background: var(--custom-white);
    border-radius: 1rem;
    box-shadow: 0 0 .313rem rgba(228, 229, 237, .8);
    content: "";
    height: 1.5rem;
    inset-inline-start: -.25rem;
    margin-block-start: -.5rem;
    position: absolute;
    inset-block-start: -.25rem;
    transition: all .3s ease-in-out;
    width: 1.5rem
}

.custom-toggle-switch>input[type=checkbox]:checked+label::after {
    background: inherit;
    inset-inline-start: 50%
}

.custom-toggle-switch.toggle-sm>label::before {
    height: 10px;
    width: 27px;
    border-radius: 10px
}

.custom-toggle-switch.toggle-sm input[type=checkbox]:checked+label::after {
    inset-inline-start: 13px
}

.custom-toggle-switch.toggle-sm>label::after {
    height: 17px;
    width: 17px;
    border-radius: 50%
}

.custom-toggle-switch.toggle-lg>label::before {
    height: 27px;
    width: 55px;
    border-radius: 20px
}

.custom-toggle-switch.toggle-lg input[type=checkbox]:checked+label::after {
    inset-inline-start: 77%
}

.custom-toggle-switch.toggle-lg>label::after {
    height: 35px;
    width: 35px;
    margin-block-start: -8px;
    border-radius: 50%
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(3.5rem - 4px)
}

.form-floating.floating-primary label {
    color: var(--primary-color);
    opacity: 1
}

.form-floating.floating-primary input {
    border: 1px solid var(--primary-color)
}

.form-floating.floating-primary>.form-control:focus~label {
    color: var(--primary-color) !important
}

.form-floating.floating-secondary label {
    color: rgb(var(--secondary-rgb));
    opacity: 1
}

.form-floating.floating-secondary input {
    border: 1px solid rgb(var(--secondary-rgb))
}

.form-floating.floating-secondary>.form-control:focus~label {
    color: rgb(var(--secondary-rgb)) !important
}

.form-floating.floating-warning label {
    color: rgb(var(--warning-rgb));
    opacity: 1
}

.form-floating.floating-warning input {
    border: 1px solid rgb(var(--warning-rgb))
}

.form-floating.floating-warning>.form-control:focus~label {
    color: rgb(var(--warning-rgb)) !important
}

.form-floating.floating-info label {
    color: rgb(var(--info-rgb));
    opacity: 1
}

.form-floating.floating-info input {
    border: 1px solid rgb(var(--info-rgb))
}

.form-floating.floating-info>.form-control:focus~label {
    color: rgb(var(--info-rgb)) !important
}

.form-floating.floating-success label {
    color: rgb(var(--success-rgb));
    opacity: 1
}

.form-floating.floating-success input {
    border: 1px solid rgb(var(--success-rgb))
}

.form-floating.floating-success>.form-control:focus~label {
    color: rgb(var(--success-rgb)) !important
}

.form-floating.floating-danger label {
    color: rgb(var(--danger-rgb));
    opacity: 1
}

.form-floating.floating-danger input {
    border: 1px solid rgb(var(--danger-rgb))
}

.form-floating.floating-danger>.form-control:focus~label {
    color: rgb(var(--danger-rgb)) !important
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
    background-color: var(--custom-white);
    border-radius: .5rem
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
    background-color: var(--custom-white);
    border-radius: .5rem
}

.form-wizard-1 {
    border-bottom: 0;
    border-radius: 50px
}

.form-wizard-1 .nav-link {
    border: 0
}

.form-wizard-1.nav-tabs .nav-item {
    margin-inline-end: 1rem;
    position: relative
}

.form-wizard-1.nav-tabs .nav-link {
    padding: .25rem .4rem;
    color: var(--text-muted);
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: 2px solid transparent
}

.form-wizard-1.nav-tabs .nav-link i {
    font-size: 12px;
    padding: 4px;
    line-height: 1;
    border-radius: 50px;
    border: 2px solid var(--default-border)
}

.form-wizard-1.nav-tabs .nav-link:focus,
.form-wizard-1.nav-tabs .nav-link:hover {
    border: 2px solid transparent
}

.form-wizard-1.nav-tabs .nav-link.active {
    border: 2px solid var(--primary-color);
    border-radius: 50px;
    color: var(--primary-color);
    background-color: transparent
}

.form-wizard-1.nav-tabs .nav-link.active i {
    border: 2px solid var(--primary-color)
}

.form-wizard-1 .nav-item:focus-visible {
    outline: 0
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: rgb(var(--success-rgb));
    box-shadow: none
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: rgb(var(--success-rgb))
}

.form-control-color {
    width: 1.75rem;
    height: 1.75rem;
    overflow: hidden;
    padding: 0
}

.example-picker .pcr-button {
    border-radius: .35rem !important;
    overflow: hidden
}

[dir=rtl] [type=email],
[dir=rtl] [type=number],
[dir=rtl] [type=tel],
[dir=rtl] [type=url] {
    direction: rtl
}

.form-select.is-invalid:not([multiple]):not([size]) {
    padding-right: 1.125rem;
    background-size: 16px 28px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.invoice-quantity-container .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0
}

.input-group.input-btn-outline .form-control:focus {
    z-index: 2
}

input[type=range]::-webkit-slider-runnable-track {
    background-color: rgb(var(--light-rgb))
}

[dir=rtl] .form-checked-outline:checked[type=checkbox]:before {
    inset-inline-start: 1px
}

.form-check {
    min-height: inherit !important
}

.col-form-label,
.col-form-label-lg,
.col-form-label-sm {
    font-weight: 600
}

#exampleColorInput::-webkit-color-swatch-wrapper {
    direction: rtl
}

[data-theme-mode=dark] input[type=date]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=datetime-local]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=month]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=time]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=week]::-webkit-calendar-picker-indicator {
    filter: invert(1)
}

[dir=rtl] input[type=date],
[dir=rtl] input[type=datetime-local],
[dir=rtl] input[type=month],
[dir=rtl] input[type=time],
[dir=rtl] input[type=week] {
    text-align: end
}

.input-group-text {
    border-color: var(--input-border);
    font-size: .875rem;
    border-radius: .3125rem;
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    letter-spacing: 0
}

.input-group-text .form-control {
    border: 0;
    border-start-start-radius: 0;
    border-end-start-radius: 0
}

.list-group-item {
    color: var(--default-text-color);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    padding: .75rem 1.25rem
}

.list-group-item.active {
    color: #fff !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color)
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: var(--default-text-color);
    background-color: rgb(var(--light-rgb));
    opacity: .5
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    color: var(--default-text-color);
    background-color: var(--list-hover-focus-bg)
}

.list-group-item-primary {
    color: var(--primary-color);
    background-color: var(--primary01)
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: var(--primary-color);
    background-color: var(--primary02)
}

.list-group-item-secondary {
    color: rgb(var(--secondary-rgb));
    background-color: rgba(var(--secondary-rgb), .1)
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: rgb(var(--secondary-rgb));
    background-color: rgba(var(--secondary-rgb), .2)
}

.list-group-item-info {
    color: rgb(var(--info-rgb));
    background-color: rgba(var(--info-rgb), .1)
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: rgb(var(--info-rgb));
    background-color: rgba(var(--info-rgb), .2)
}

.list-group-item-warning {
    color: rgb(var(--warning-rgb));
    background-color: rgba(var(--warning-rgb), .1)
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: rgb(var(--warning-rgb));
    background-color: rgba(var(--warning-rgb), .2)
}

.list-group-item-danger {
    color: rgb(var(--danger-rgb));
    background-color: rgba(var(--danger-rgb), .1)
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: rgb(var(--danger-rgb));
    background-color: rgba(var(--danger-rgb), .2)
}

.list-group-item-success {
    color: rgb(var(--success-rgb));
    background-color: rgba(var(--success-rgb), .1)
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: rgb(var(--success-rgb));
    background-color: rgba(var(--success-rgb), .2)
}

.list-group-item-dark {
    color: rgb(var(--dark-rgb));
    background-color: rgba(var(--dark-rgb), .1)
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: rgb(var(--dark-rgb));
    background-color: rgba(var(--dark-rgb), .2)
}

.list-group-item-light {
    color: var(--default-text-color);
    background-color: rgba(var(--light-rgb), .1)
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: var(--default-text-color);
    background-color: rgba(var(--light-rgb), .2)
}

.list-item-solid-primary {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color)
}

.list-item-solid-secondary {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    border-color: rgb(var(--secondary-rgb))
}

.list-item-solid-success {
    background-color: rgb(var(--success-rgb));
    color: #fff;
    border-color: rgb(var(--success-rgb))
}

.list-item-solid-info {
    background-color: rgb(var(--info-rgb));
    color: #fff;
    border-color: rgb(var(--info-rgb))
}

.list-item-solid-warning {
    background-color: rgb(var(--warning-rgb));
    color: #fff;
    border-color: rgb(var(--warning-rgb))
}

.list-item-solid-danger {
    background-color: rgb(var(--danger-rgb));
    color: #fff;
    border-color: rgb(var(--danger-rgb))
}

.list-item-solid-light {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    border-color: rgb(var(--light-rgb))
}

.list-item-solid-dark {
    background-color: rgb(var(--dark-rgb));
    color: #fff;
    border-color: rgb(var(--dark-rgb))
}

.modal {
    z-index: 105
}

.modal-backdrop {
    z-index: 104
}

.modal-content {
    color: var(--default-text-color);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    border-radius: .5rem
}

.modal-header {
    padding: 1rem 1.25rem;
    border-block-end: 1px solid var(--default-border);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.modal-header .modal-title {
    font-weight: 600;
    line-height: 1
}

.modal-header .btn-close {
    font-size: .625rem
}

.modal-footer {
    padding: 1rem 1.25rem;
    border-block-start: 1px solid var(--default-border)
}

.modal.animated .modal-dialog {
    transform: translate(0, 0)
}

.modal.effect-scale .modal-dialog {
    transform: scale(.7);
    opacity: 0;
    transition: all .3s
}

.modal.effect-scale.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.effect-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all .3s cubic-bezier(.25, .5, .5, .9)
}

.modal.effect-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1
}

.modal.effect-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all .3s
}

.modal.effect-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1
}

.modal.effect-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0
}

.modal.effect-newspaper .modal-dialog,
.modal.effect-newspaper.show~.modal-backdrop {
    transition: all .5s
}

.modal.effect-newspaper.show .modal-dialog {
    transform: scale(1) rotate(0);
    opacity: 1
}

.modal.effect-fall {
    perspective: 1300px
}

.modal.effect-fall .modal-dialog {
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0
}

.modal.effect-fall.show .modal-dialog {
    transition: all .3s ease-in;
    transform: translateZ(0) rotateX(0);
    opacity: 1
}

.modal.effect-flip-horizontal {
    perspective: 1300px
}

.modal.effect-flip-horizontal .modal-dialog {
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all .3s;
    opacity: 0
}

.modal.effect-flip-horizontal.show .modal-dialog {
    transform: rotateY(0);
    opacity: 1
}

.modal.effect-flip-vertical {
    perspective: 1300px
}

.modal.effect-flip-vertical .modal-dialog {
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all .3s;
    opacity: 0
}

.modal.effect-flip-vertical.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1
}

.modal.effect-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all .3s
}

.modal.effect-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.effect-sign {
    perspective: 1300px
}

.modal.effect-sign .modal-dialog {
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all .3s
}

.modal.effect-sign.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1
}

.modal.effect-rotate-bottom {
    perspective: 1300px
}

.modal.effect-rotate-bottom .modal-dialog {
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all .3s ease-out
}

.modal.effect-rotate-bottom.show .modal-dialog {
    transform: translateY(0) rotateX(0);
    opacity: 1
}

.modal.effect-rotate-left {
    perspective: 1300px
}

.modal.effect-rotate-left .modal-dialog {
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all .3s
}

.modal.effect-rotate-left.show .modal-dialog {
    transform: translateZ(0) translateX(0) rotateY(0);
    opacity: 1
}

.navbar {
    border-radius: .5rem
}

.navbar .navbar-nav .nav-link {
    line-height: 1;
    padding: .4rem 1rem;
    font-weight: 500
}

.navbar .navbar-toggler {
    padding: .4rem;
    font-size: 1rem;
    line-height: 1;
    color: var(--custom-black);
    border: 1px solid var(--default-border);
    border-radius: .5rem
}

.navbar .navbar-toggler .navbar-toggler-icon {
    width: 1rem;
    height: 1rem;
    position: relative;
    background-image: none
}

.navbar .navbar-toggler .navbar-toggler-icon:before {
    content: "\f479";
    font-family: bootstrap-icons !important;
    position: absolute;
    font-size: 1rem;
    color: var(--default-text-color);
    inset-inline-start: 0
}

.navbar .navbar-toggler:focus {
    box-shadow: none
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: var(--custom-black)
}

.navbar-brand {
    margin-inline-end: 1.5rem
}

.navbar-brand img {
    height: 2rem;
    line-height: 2rem
}

.navbar-primary-transparent {
    background-color: var(--primary01);
    color: var(--default-text-color)
}

.navbar-primary-transparent .nav-link.active {
    background-color: var(--primary-color);
    color: #fff !important
}

.navbar-primary-transparent .nav-link:hover {
    color: var(--default-text-color)
}

.navbar-primary-transparent .form-control:focus {
    border-color: var(--primary-color)
}

.navbar-secondary-transparent {
    background-color: rgba(var(--secondary-rgb), .1);
    color: var(--default-text-color)
}

.navbar-secondary-transparent .nav-link.active {
    background-color: rgb(var(--secondary-rgb));
    color: #fff !important
}

.navbar-secondary-transparent .nav-link:hover {
    color: var(--default-text-color)
}

.navbar-secondary-transparent .form-control:focus {
    border-color: rgb(var(--secondary-rgb))
}

.navbar-warning-transparent {
    background-color: rgba(var(--warning-rgb), .1);
    color: var(--default-text-color)
}

.navbar-warning-transparent .nav-link.active {
    background-color: rgb(var(--warning-rgb));
    color: #fff !important
}

.navbar-warning-transparent .nav-link:hover {
    color: var(--default-text-color)
}

.navbar-warning-transparent .form-control:focus {
    border-color: rgb(var(--warning-rgb))
}

.navbar-info-transparent {
    background-color: rgba(var(--info-rgb), .1);
    color: var(--default-text-color)
}

.navbar-info-transparent .nav-link.active {
    background-color: rgb(var(--info-rgb));
    color: #fff !important
}

.navbar-info-transparent .nav-link:hover {
    color: var(--default-text-color)
}

.navbar-info-transparent .form-control:focus {
    border-color: rgb(var(--info-rgb))
}

.navbar-success-transparent {
    background-color: rgba(var(--success-rgb), .1);
    color: var(--default-text-color)
}

.navbar-success-transparent .nav-link.active {
    background-color: rgb(var(--success-rgb));
    color: #fff !important
}

.navbar-success-transparent .nav-link:hover {
    color: var(--default-text-color)
}

.navbar-success-transparent .form-control:focus {
    border-color: rgb(var(--success-rgb))
}

.navbar-danger-transparent {
    background-color: rgba(var(--danger-rgb), .1);
    color: var(--default-text-color)
}

.navbar-danger-transparent .nav-link.active {
    background-color: rgb(var(--danger-rgb));
    color: #fff !important
}

.navbar-danger-transparent .nav-link:hover {
    color: var(--default-text-color)
}

.navbar-danger-transparent .form-control:focus {
    border-color: rgb(var(--danger-rgb))
}

.navbar-dark-transparent {
    background-color: rgba(var(--dark-rgb), .1);
    color: var(--default-text-color)
}

.navbar-dark-transparent .nav-link.active {
    background-color: var(--custom-black);
    color: var(--custom-white) !important
}

.navbar-dark-transparent .nav-link:hover {
    color: var(--default-text-color)
}

.navbar-dark-transparent .form-control:focus {
    border-color: rgb(var(--dark-rgb))
}

.navbar-primary {
    background-color: var(--primary-color);
    color: #fff
}

.navbar-primary .nav-link {
    color: #fff !important
}

.navbar-primary .nav-link.active {
    background-color: var(--white-2);
    color: #fff !important
}

.navbar-primary .nav-link:hover {
    color: #fff !important
}

.navbar-primary .form-control:focus {
    border-color: var(--white-2)
}

.navbar-primary .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, .2)
}

.navbar-primary .navbar-toggler .navbar-toggler-icon:before {
    color: #fff
}

.navbar-secondary {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.navbar-secondary .nav-link {
    color: #fff !important
}

.navbar-secondary .nav-link.active {
    background-color: var(--white-2);
    color: #fff !important
}

.navbar-secondary .nav-link:hover {
    color: #fff !important
}

.navbar-secondary .form-control:focus {
    border-color: var(--white-2)
}

.navbar-secondary .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, .2)
}

.navbar-secondary .navbar-toggler .navbar-toggler-icon:before {
    color: #fff
}

.navbar-warning {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.navbar-warning .nav-link {
    color: #fff !important
}

.navbar-warning .nav-link.active {
    background-color: var(--white-2);
    color: #fff !important
}

.navbar-warning .nav-link:hover {
    color: #fff !important
}

.navbar-warning .form-control:focus {
    border-color: var(--white-2)
}

.navbar-warning .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, .2)
}

.navbar-warning .navbar-toggler .navbar-toggler-icon:before {
    color: #fff
}

.navbar-info {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.navbar-info .nav-link {
    color: #fff !important
}

.navbar-info .nav-link.active {
    background-color: var(--white-2);
    color: #fff !important
}

.navbar-info .nav-link:hover {
    color: #fff !important
}

.navbar-info .form-control:focus {
    border-color: var(--white-2)
}

.navbar-info .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, .2)
}

.navbar-info .navbar-toggler .navbar-toggler-icon:before {
    color: #fff
}

.navbar-success {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.navbar-success .nav-link {
    color: #fff !important
}

.navbar-success .nav-link.active {
    background-color: var(--white-2);
    color: #fff !important
}

.navbar-success .nav-link:hover {
    color: #fff !important
}

.navbar-success .form-control:focus {
    border-color: var(--white-2)
}

.navbar-success .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, .2)
}

.navbar-success .navbar-toggler .navbar-toggler-icon:before {
    color: #fff
}

.navbar-danger {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.navbar-danger .nav-link {
    color: #fff !important
}

.navbar-danger .nav-link.active {
    background-color: var(--white-2);
    color: #fff !important
}

.navbar-danger .nav-link:hover {
    color: #fff !important
}

.navbar-danger .form-control:focus {
    border-color: var(--white-2)
}

.navbar-danger .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, .2)
}

.navbar-danger .navbar-toggler .navbar-toggler-icon:before {
    color: #fff
}

.navbar-dark {
    background-color: rgb(var(--dark-rgb));
    color: var(--custom-white)
}

.navbar-dark .nav-link {
    color: var(--custom-white) !important
}

.navbar-dark .nav-link.active {
    background-color: var(--white-2);
    color: var(--custom-white) !important
}

.navbar-dark .nav-link:hover {
    color: var(--custom-white) !important
}

.navbar-dark .form-control:focus {
    border-color: var(--white-2)
}

.navbar-dark .navbar-toggler {
    border: 1px solid var(--white-1)
}

.navbar-dark .navbar-toggler .navbar-toggler-icon:before {
    color: var(--custom-white)
}

.nav-item {
    margin-inline-end: .5rem
}

.nav-item:last-child {
    margin-inline-end: 0
}

.nav-link {
    color: var(--default-text-color);
    border-radius: .5rem;
    font-weight: 600
}

.nav-link.active {
    /* background-color: var(--primary01); */
    color: var(--primary-color)
}

.navbar-brand {
    color: var(--default-text-color)
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: var(--primary-color)
}

.nav-link.disabled {
    color: var(--default-text-color);
    opacity: .3
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
    color: rgba(var(--dark-rgb), .5)
}

.navbar-text {
    color: var(--default-text-color)
}

.navbar-dark.bg-dark .nav-link,
.navbar-dark.bg-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: var(--primary-color)
}

.nav.nav-style-1 {
    border: 0
}

.nav.nav-style-1 .nav-item {
    margin-inline-end: .25rem
}

.nav.nav-style-1 .nav-item:last-child {
    margin-inline-end: 0
}

.nav.nav-style-1 .nav-link.active {
    background-color: var(--primary-color);
    color: #fff
}

.nav.nav-style-2 .nav-item .nav-link {
    border-block-start: 3px solid transparent
}

.nav.nav-style-2 .nav-item .nav-link.active {
    background-color: var(--primary01);
    border-block-start: 3px solid var(--primary-color);
    color: var(--primary-color)
}

.nav.nav-style-3 .nav-link {
    border-radius: 0
}

.nav.nav-style-3 .nav-link {
    border-block-end: 3px solid transparent
}

.nav.nav-style-3 .nav-link.active {
    background-color: transparent;
    border-block-end: 3px solid var(--primary-color);
    color: var(--primary-color)
}

.nav.nav-style-4 {
    border: 0
}

.nav.nav-style-4 .nav-link {
    color: var(--text-muted);
    border: 0
}

.nav.nav-style-4 .nav-link.active {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    border: 0
}

.nav.nav-style-4 .nav-link:focus,
.nav.nav-style-4 .nav-link:hover {
    border: 0
}

.nav-tabs.nav-style-5 {
    border: 0
}

.nav-tabs.nav-style-5 .nav-link {
    color: var(--text-muted);
    border: 0
}

.nav-tabs.nav-style-5 .nav-link.active {
    background-color: var(--primary-color);
    color: #fff;
    border: 0
}

.nav-tabs.nav-style-5 .nav-link:focus,
.nav-tabs.nav-style-5 .nav-link:hover {
    border: 0
}

.nav-style-6 {
    border: 0
}

.nav-style-6.nav-pills .nav-link.active {
    border: 1px solid var(--primary-color);
    border-block-end: 2px solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color)
}

.nav-tabs-header {
    border: 0
}

.nav-tabs-header .nav-item {
    margin-inline-end: .5rem
}

.nav-tabs-header .nav-item:last-child {
    margin-inline-end: 0
}

.nav-tabs-header .nav-item .nav-link {
    border: 1px solid transparent;
    font-size: .8rem;
    padding: .4rem .5rem;
    font-weight: 500
}

.nav-tabs-header .nav-item .nav-link.active {
    background: var(--primary01);
    color: var(--primary-color);
    border: 1px solid transparent
}

.nav-tabs-header .nav-item .nav-link:focus,
.nav-tabs-header .nav-item .nav-link:hover {
    border: 1px solid transparent
}

.nav-tabs-footer {
    border: 0
}

.nav-tabs-footer .nav-item {
    margin-inline-end: .5rem
}

.nav-tabs-footer .nav-item:last-child {
    margin-inline-end: 0
}

.nav-tabs-footer .nav-item .nav-link {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: .8rem
}

.nav-tabs-footer .nav-item .nav-link.active {
    background: var(--primary-color);
    color: #fff;
    border: 1px solid var(--primary-color)
}

.nav-tabs-footer .nav-item .nav-link:focus,
.nav-tabs-footer .nav-item .nav-link:hover {
    border: 1px solid var(--primary-color)
}

.vertical-tabs-2 {
    border: 0
}

.vertical-tabs-2 .nav-item {
    margin-inline-end: .5rem
}

.vertical-tabs-2 .nav-item:last-child {
    margin-inline-end: 0
}

.vertical-tabs-2 .nav-item .nav-link {
    min-width: 7.5rem;
    max-width: 7.5rem;
    text-align: center;
    border: 1px solid var(--default-border);
    margin-bottom: .5rem;
    color: var(--text-muted);
    background-color: rgb(var(--light-rgb))
}

.vertical-tabs-2 .nav-item .nav-link i {
    font-size: 1rem
}

.vertical-tabs-2 .nav-item .nav-link.active {
    background-color: var(--primary-color);
    color: #fff;
    position: relative
}

.vertical-tabs-2 .nav-item .nav-link.active:before {
    content: "";
    position: absolute;
    inset-inline-end: -.5rem;
    inset-block-start: 38%;
    transform: rotate(45deg);
    width: 1rem;
    height: 1rem;
    background-color: var(--primary-color)
}

.vertical-tabs-3 {
    border: 0
}

.vertical-tabs-3 .nav-item {
    border: 0;
    margin-inline-end: .5rem
}

.vertical-tabs-3 .nav-item:last-child {
    margin-inline-end: 0
}

.vertical-tabs-3 .nav-item .nav-link {
    min-width: 160px;
    max-width: 160px;
    border: 1px solid var(--primary-color);
    padding: 1.3rem;
    margin-block-end: .5rem
}

.vertical-tabs-3 .nav-item .nav-link i {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1rem;
    background: var(--primary-color);
    color: #fff;
    border-radius: 3.125rem;
    font-weight: 500
}

.vertical-tabs-3 .nav-item .nav-link.active {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #fff
}

.vertical-tabs-3 .nav-item .nav-link.active i {
    background: #fff;
    color: var(--primary-color)
}

.vertical-tabs-3 .nav-item .nav-link:focus,
.vertical-tabs-3 .nav-item .nav-link:hover {
    border: 1px solid var(--primary-color)
}

.nav-link:focus,
.nav-link:hover {
    color: var(--primary-color)
}

.nav-tabs {
    border-color: var(--default-border)
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--default-text-color);
    background-color: rgb(var(--light-rgb));
    border-color: var(--default-border)
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: var(--black-1)
}

.nav.tab-style-1 {
    border-block-end: 0;
    padding: .65rem;
    background-color: rgb(var(--light-rgb));
    border-radius: .5rem;
    margin-block-end: 1rem
}

.nav.tab-style-1 .nav-item {
    margin-inline-end: .5rem
}

.nav.tab-style-1 .nav-item:last-child {
    margin-inline-end: 0
}

.nav.tab-style-1 .nav-link {
    border-radius: .5rem;
    padding: .35rem 1rem
}

.nav.tab-style-1 .nav-link.active {
    background-color: var(--primary-color);
    color: #fff;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    transition: color .15s ease-in
}

.tab-content .tab-pane {
    padding: 1rem;
    border: 1px solid var(--default-border);
    border-radius: .5rem;
    color: var(--default-text-color)
}

.tab-style-2 {
    border-block-end: 0
}

.tab-style-2 .nav-item {
    margin-inline-end: .5rem
}

.tab-style-2 .nav-item:last-child {
    margin-inline-end: 0
}

.tab-style-2 .nav-item .nav-link {
    border: 0
}

.tab-style-2 .nav-item .nav-link i {
    width: 1.875rem;
    height: 1.875rem;
    padding: .4rem;
    border-radius: 50px;
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    display: inline-block
}

.tab-style-2 .nav-item .nav-link:hover {
    border: 0
}

.tab-style-2 .nav-item .nav-link.active {
    background-color: transparent;
    position: relative;
    border: 0;
    color: var(--primary-color)
}

.tab-style-2 .nav-item .nav-link.active i {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.tab-style-2 .nav-item .nav-link.active:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    width: 100%;
    height: .175rem;
    background-color: var(--primary-color);
    border-radius: 50px
}

.tab-style-3 {
    border-block-end: 0
}

.tab-style-3 .nav-item {
    position: relative;
    margin-inline-end: .5rem
}

.tab-style-3 .nav-item:last-child {
    margin-inline-end: 0
}

.tab-style-3 .nav-item .nav-link {
    position: relative;
    border: 0;
    font-size: .75rem
}

.tab-style-3 .nav-item .nav-link:before {
    position: absolute;
    content: "";
    inset-block-end: -1.063rem;
    inset-inline-end: 0;
    inset-inline-start: 0;
    width: 100%;
    height: .1rem;
    background-color: rgb(var(--light-rgb));
    border-radius: 50%
}

.tab-style-3 .nav-item .nav-link:after {
    position: absolute;
    content: "";
    inset-block-end: -2rem;
    inset-inline-start: 1.75rem;
    inset-inline-end: 0;
    inset-inline-start: 30%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--custom-white);
    border-radius: 50%;
    border: 2px solid var(--default-border)
}

.tab-style-3 .nav-item .nav-link.home:after {
    content: "1"
}

.tab-style-3 .nav-item .nav-link.about:after {
    content: "2"
}

.tab-style-3 .nav-item .nav-link.services:after {
    content: "3"
}

.tab-style-3 .nav-item .nav-link:hover {
    border: 0
}

.tab-style-3 .nav-item .nav-link.active {
    background-color: transparent;
    border: 0;
    color: var(--primary-color)
}

.tab-style-3 .nav-item .nav-link.active:before {
    background-color: var(--primary-color)
}

.tab-style-3 .nav-item .nav-link.active:after {
    border-color: var(--primary-color);
    color: var(--primary-color)
}

.tab-style-4.nav-tabs {
    border: 0
}

.tab-style-4.nav-tabs .nav-item {
    margin-inline-end: .5rem
}

.tab-style-4.nav-tabs .nav-item:last-child {
    margin-inline-end: 0
}

.tab-style-4.nav-tabs .nav-link {
    border: 0
}

.tab-style-4.nav-tabs .nav-link:hover {
    border: 0
}

.tab-style-4.nav-tabs .nav-link.active {
    background-color: transparent;
    border: 0;
    color: var(--primary-color);
    position: relative;
    overflow: hidden
}

.tab-style-4.nav-tabs .nav-link.active::before {
    position: absolute;
    content: "";
    width: .75rem;
    inset-block-start: 31px;
    transform: rotate(45deg);
    inset-inline-start: 47%;
    overflow: hidden;
    z-index: 1;
    height: .75rem;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color)
}

.tab-style-4.nav-tabs .nav-link.active:after {
    position: absolute;
    content: "";
    width: 40%;
    height: 1px;
    inset-inline-start: 30%;
    inset-block-end: 0;
    background-color: var(--primary-color)
}

#tab-style-4 .tab-pane.active {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #fff
}

.tab-style-5 .nav-item {
    margin-inline-end: .5rem
}

.tab-style-5 .nav-item:last-child {
    margin-inline-end: 0
}

.tab-style-5 .nav-item .nav-link.active {
    background: var(--primary-color)
}

.tab-style-6 {
    border: 0;
    background-color: var(--custom-white);
    border-radius: .5rem;
    color: var(--primary-color);
    padding: .5rem
}

.tab-style-6 .nav-item {
    border: 0;
    margin-inline-end: .5rem
}

.tab-style-6 .nav-item:last-child {
    margin-inline-end: 0
}

.tab-style-6 .nav-item .nav-link {
    color: var(--primary-color);
    padding: .5rem 1rem;
    font-size: .813rem;
    border: 0;
    font-weight: 500
}

.tab-style-6 .nav-item .nav-link.active {
    border: 0;
    background-color: var(--primary-color);
    color: #fff;
    border: 0;
    box-shadow: 0 3px 10px 0 rgba(var(--dark-rgb), .05)
}

.tab-style-6 .nav-item .nav-link:focus,
.tab-style-6 .nav-item .nav-link:hover {
    border: 0
}

.tab-style-7 .nav-link {
    margin-block-end: .5rem;
    font-size: .813rem;
    color: var(--text-muted);
    text-align: left
}

.tab-style-7 .nav-link.active,
.tab-style-7 .nav-link:active {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.nav-style-1 .nav-item .nav-link.active {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.page-link {
    color: var(--default-text-color);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border)
}

.page-link:focus {
    box-shadow: none;
    background-color: rgb(var(--light-rgb))
}

.page-link:hover {
    color: var(--primary-color);
    background-color: rgb(var(--light-rgb));
    border-color: var(--default-border)
}

.page-item.active .page-link {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color)
}

.disabled>.page-link,
.page-link.disabled {
    color: var(--default-text-color);
    background-color: var(--custom-white);
    border-color: var(--default-border);
    opacity: .7
}

[dir=rtl] .pagination .page-link .bx-chevron-left::before {
    content: "\ea50"
}

[dir=rtl] .pagination .page-link .bx-chevron-right::before {
    content: "\ea4d"
}

[dir=rtl] .pagination .page-link .ri-arrow-right-s-line:before {
    content: "\ea64"
}

[dir=rtl] .pagination .page-link .ri-arrow-left-s-line:before {
    content: "\ea6e"
}

.pagination-style-1 .pagination .page-item {
    margin: 0 .25rem
}

.pagination-style-1 .pagination .page-item .page-link {
    border: 0;
    border-radius: .5rem;
    font-size: .8125rem
}

.pagination-style-1 .pagination .page-item .page-link i {
    font-weight: 600
}

.pagination-style-1 .pagination .page-item.active .page-link {
    border-radius: .5rem;
    background-color: var(--primary-color);
    color: #fff
}

.pagination-style-1 .pagination .page-item.active:hover .page-link {
    border-radius: .5rem;
    background-color: var(--primary-color);
    color: #fff
}

.pagination-style-1 .pagination .page-item:hover .page-link {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color)
}

.pagination-style-2 .pagination {
    border-radius: .5rem
}

.pagination-style-2 .pagination .page-item {
    margin: 0 .25rem
}

.pagination-style-2 .pagination .page-item .page-link {
    border: 0 !important;
    font-size: .8125rem
}

.pagination-style-2 .pagination .page-item.active .page-link {
    background-color: var(--custom-white);
    color: var(--primary-color);
    position: relative;
    font-weight: 700
}

.pagination-style-2 .pagination .page-item.active .page-link:before {
    position: absolute;
    content: "";
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    height: .1rem;
    background-color: var(--primary-color)
}

.pagination-style-2 .pagination .page-item:hover .page-link {
    background-color: transparent
}

.pagination-style-3 .pagination {
    border-radius: 50px;
    padding: .25rem;
    align-items: center
}

.pagination-style-3 .pagination .page-item {
    margin: 0 .25rem
}

.pagination-style-3 .pagination .page-item .page-link {
    border: 0;
    border-radius: 50px;
    font-size: .8125rem
}

.pagination-style-3 .pagination .page-item .page-link i {
    font-weight: 600
}

.pagination-style-3 .pagination .page-item.active .page-link {
    background-color: var(--primary-color);
    color: #fff
}

.pagination-style-3 .pagination .page-item.active:hover .page-link {
    background-color: var(--primary-color)
}

.pagination-style-3 .pagination .page-item:hover .page-link {
    background-color: rgb(var(--light-rgb))
}

.pagination-style-4 .pagination .page-item .page-link {
    border: 0 !important;
    font-size: .8125rem;
    border-radius: .5rem
}

.pagination-style-4 .pagination .page-item .page-link i {
    font-weight: 600
}

.pagination-style-4 .pagination .page-item.active .page-link {
    border: 0;
    border-radius: .5rem;
    background-color: var(--primary-color);
    color: #fff
}

.pagination-style-4 .pagination .page-item.active:hover .page-link {
    background-color: var(--primary-color)
}

.pagination-style-4 .pagination .page-item:hover .page-link {
    background-color: transparent
}

.custom-popover {
    --bs-popover-max-width: 12.5rem;
    --bs-popover-border-color: var(--primary-color);
    --bs-popover-header-bg: var(--primary-color);
    --bs-popover-header-color: #fff;
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: 0.5rem
}

.popover {
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    font-size: .8rem;
    border-radius: .5rem;
    z-index: 999
}

.popover .popover-header {
    background-color: var(--custom-white);
    border-block-end: 1px solid var(--default-border)
}

.popover .popover-body {
    color: var(--default-text-color)
}

.popover.only-body {
    border-radius: .3rem
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--light-rgb))
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--light-rgb))
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--light-rgb))
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom .popover-header::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--light-rgb))
}

.header-primary .popover-header {
    background-color: var(--primary-color);
    color: #fff
}

.header-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.header-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-bottom-color: var(--primary-color)
}

.header-secondary .popover-header {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.header-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.header-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-bottom-color: rgb(var(--secondary-rgb))
}

.header-warning .popover-header {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.header-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.header-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-bottom-color: rgb(var(--warning-rgb))
}

.header-info .popover-header {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.header-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.header-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-bottom-color: rgb(var(--info-rgb))
}

.header-success .popover-header {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.header-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.header-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-bottom-color: rgb(var(--success-rgb))
}

.header-danger .popover-header {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.header-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.header-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-bottom-color: rgb(var(--danger-rgb))
}

.popover-primary.popover {
    border: 1px solid var(--primary-color)
}

.popover-primary.popover .popover-header {
    background-color: var(--primary-color);
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-primary.popover .popover-body {
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-primary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-primary.bs-popover-top>.popover-arrow::after,
.popover-primary.bs-popover-top>.popover-arrow::before {
    border-top-color: var(--primary-color)
}

.popover-primary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-primary.bs-popover-end>.popover-arrow::after,
.popover-primary.bs-popover-end>.popover-arrow::before {
    border-right-color: var(--primary-color)
}

.popover-primary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-primary.bs-popover-start>.popover-arrow::after,
.popover-primary.bs-popover-start>.popover-arrow::before {
    border-left-color: var(--primary-color)
}

.popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-primary.bs-popover-bottom .popover-header::before,
.popover-primary.bs-popover-bottom>.popover-arrow::after,
.popover-primary.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: var(--primary-color)
}

.popover-secondary.popover {
    border: 1px solid rgb(var(--secondary-rgb))
}

.popover-secondary.popover .popover-header {
    background-color: rgb(var(--secondary-rgb));
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-secondary.popover .popover-body {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-secondary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-secondary.bs-popover-top>.popover-arrow::after,
.popover-secondary.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--secondary-rgb))
}

.popover-secondary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-secondary.bs-popover-end>.popover-arrow::after,
.popover-secondary.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--secondary-rgb))
}

.popover-secondary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-secondary.bs-popover-start>.popover-arrow::after,
.popover-secondary.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--secondary-rgb))
}

.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-secondary.bs-popover-bottom .popover-header::before,
.popover-secondary.bs-popover-bottom>.popover-arrow::after,
.popover-secondary.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--secondary-rgb))
}

.popover-warning.popover {
    border: 1px solid rgb(var(--warning-rgb))
}

.popover-warning.popover .popover-header {
    background-color: rgb(var(--warning-rgb));
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-warning.popover .popover-body {
    background-color: rgb(var(--warning-rgb));
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-warning.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-warning.bs-popover-top>.popover-arrow::after,
.popover-warning.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--warning-rgb))
}

.popover-warning.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-warning.bs-popover-end>.popover-arrow::after,
.popover-warning.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--warning-rgb))
}

.popover-warning.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-warning.bs-popover-start>.popover-arrow::after,
.popover-warning.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--warning-rgb))
}

.popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-warning.bs-popover-bottom .popover-header::before,
.popover-warning.bs-popover-bottom>.popover-arrow::after,
.popover-warning.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--warning-rgb))
}

.popover-info.popover {
    border: 1px solid rgb(var(--info-rgb))
}

.popover-info.popover .popover-header {
    background-color: rgb(var(--info-rgb));
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-info.popover .popover-body {
    background-color: rgb(var(--info-rgb));
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-info.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-info.bs-popover-top>.popover-arrow::after,
.popover-info.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--info-rgb))
}

.popover-info.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-info.bs-popover-end>.popover-arrow::after,
.popover-info.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--info-rgb))
}

.popover-info.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-info.bs-popover-start>.popover-arrow::after,
.popover-info.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--info-rgb))
}

.popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-info.bs-popover-bottom .popover-header::before,
.popover-info.bs-popover-bottom>.popover-arrow::after,
.popover-info.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--info-rgb))
}

.popover-success.popover {
    border: 1px solid rgb(var(--success-rgb))
}

.popover-success.popover .popover-header {
    background-color: rgb(var(--success-rgb));
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-success.popover .popover-body {
    background-color: rgb(var(--success-rgb));
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-success.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-success.bs-popover-top>.popover-arrow::after,
.popover-success.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--success-rgb))
}

.popover-success.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-success.bs-popover-end>.popover-arrow::after,
.popover-success.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--success-rgb))
}

.popover-success.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-success.bs-popover-start>.popover-arrow::after,
.popover-success.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--success-rgb))
}

.popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-success.bs-popover-bottom .popover-header::before,
.popover-success.bs-popover-bottom>.popover-arrow::after,
.popover-success.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--success-rgb))
}

.popover-danger.popover {
    border: 1px solid rgb(var(--danger-rgb))
}

.popover-danger.popover .popover-header {
    background-color: rgb(var(--danger-rgb));
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-danger.popover .popover-body {
    background-color: rgb(var(--danger-rgb));
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-danger.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-danger.bs-popover-top>.popover-arrow::after,
.popover-danger.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--danger-rgb))
}

.popover-danger.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-danger.bs-popover-end>.popover-arrow::after,
.popover-danger.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--danger-rgb))
}

.popover-danger.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-danger.bs-popover-start>.popover-arrow::after,
.popover-danger.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--danger-rgb))
}

.popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-danger.bs-popover-bottom .popover-header::before,
.popover-danger.bs-popover-bottom>.popover-arrow::after,
.popover-danger.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--danger-rgb))
}

.popover-purple.popover {
    border: 1px solid rgb(var(--purple-rgb))
}

.popover-purple.popover .popover-header {
    background-color: rgb(var(--purple-rgb));
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-purple.popover .popover-body {
    background-color: rgb(var(--purple-rgb));
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-purple.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-purple.bs-popover-top>.popover-arrow::after,
.popover-purple.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--purple-rgb))
}

.popover-purple.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-purple.bs-popover-end>.popover-arrow::after,
.popover-purple.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--purple-rgb))
}

.popover-purple.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-purple.bs-popover-start>.popover-arrow::after,
.popover-purple.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--purple-rgb))
}

.popover-purple.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-purple.bs-popover-bottom .popover-header::before,
.popover-purple.bs-popover-bottom>.popover-arrow::after,
.popover-purple.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--purple-rgb))
}

.popover-teal.popover {
    border: 1px solid rgb(var(--teal-rgb))
}

.popover-teal.popover .popover-header {
    background-color: rgb(var(--teal-rgb));
    border-block-end: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: .3rem .3rem 0 0
}

.popover-teal.popover .popover-body {
    background-color: rgb(var(--teal-rgb));
    color: #fff;
    border-radius: 0 0 .3rem .3rem
}

.popover-teal.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-teal.bs-popover-top>.popover-arrow::after,
.popover-teal.bs-popover-top>.popover-arrow::before {
    border-top-color: rgb(var(--teal-rgb))
}

.popover-teal.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-teal.bs-popover-end>.popover-arrow::after,
.popover-teal.bs-popover-end>.popover-arrow::before {
    border-right-color: rgb(var(--teal-rgb))
}

.popover-teal.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-teal.bs-popover-start>.popover-arrow::after,
.popover-teal.bs-popover-start>.popover-arrow::before {
    border-left-color: rgb(var(--teal-rgb))
}

.popover-teal.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-teal.bs-popover-bottom .popover-header::before,
.popover-teal.bs-popover-bottom>.popover-arrow::after,
.popover-teal.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgb(var(--teal-rgb))
}

.popover-primary-light.popover {
    border: 1px solid var(--primary01)
}

.popover-primary-light.popover .popover-header {
    background-color: var(--primary01);
    border-block-end: 1px solid var(--primary01);
    color: var(--primary-color);
    border-radius: 0
}

.popover-primary-light.popover .popover-body {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-primary-light.bs-popover-top>.popover-arrow::after,
.popover-primary-light.bs-popover-top>.popover-arrow::before {
    border-top-color: var(--primary01)
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-primary-light.bs-popover-end>.popover-arrow::after,
.popover-primary-light.bs-popover-end>.popover-arrow::before {
    border-right-color: var(--primary01)
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-primary-light.bs-popover-start>.popover-arrow::after,
.popover-primary-light.bs-popover-start>.popover-arrow::before {
    border-left-color: var(--primary01)
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-primary-light.bs-popover-bottom .popover-header::before,
.popover-primary-light.bs-popover-bottom>.popover-arrow::after,
.popover-primary-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: var(--primary01)
}

.popover-secondary-light.popover {
    border: 1px solid rgba(var(--secondary-rgb), .1)
}

.popover-secondary-light.popover .popover-header {
    background-color: rgba(var(--secondary-rgb), .1);
    border-block-end: 1px solid rgba(var(--secondary-rgb), .1);
    color: rgb(var(--secondary-rgb));
    border-radius: 0
}

.popover-secondary-light.popover .popover-body {
    background-color: rgba(var(--secondary-rgb), .1);
    color: rgb(var(--secondary-rgb))
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-secondary-light.bs-popover-top>.popover-arrow::after,
.popover-secondary-light.bs-popover-top>.popover-arrow::before {
    border-top-color: rgba(var(--secondary-rgb), .1)
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-secondary-light.bs-popover-end>.popover-arrow::after,
.popover-secondary-light.bs-popover-end>.popover-arrow::before {
    border-right-color: rgba(var(--secondary-rgb), .1)
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-secondary-light.bs-popover-start>.popover-arrow::after,
.popover-secondary-light.bs-popover-start>.popover-arrow::before {
    border-left-color: rgba(var(--secondary-rgb), .1)
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-secondary-light.bs-popover-bottom .popover-header::before,
.popover-secondary-light.bs-popover-bottom>.popover-arrow::after,
.popover-secondary-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgba(var(--secondary-rgb), .1)
}

.popover-warning-light.popover {
    border: 1px solid rgba(var(--warning-rgb), .1)
}

.popover-warning-light.popover .popover-header {
    background-color: rgba(var(--warning-rgb), .1);
    border-block-end: 1px solid rgba(var(--warning-rgb), .1);
    color: rgb(var(--warning-rgb));
    border-radius: 0
}

.popover-warning-light.popover .popover-body {
    background-color: rgba(var(--warning-rgb), .1);
    color: rgb(var(--warning-rgb))
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-warning-light.bs-popover-top>.popover-arrow::after,
.popover-warning-light.bs-popover-top>.popover-arrow::before {
    border-top-color: rgba(var(--warning-rgb), .1)
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-warning-light.bs-popover-end>.popover-arrow::after,
.popover-warning-light.bs-popover-end>.popover-arrow::before {
    border-right-color: rgba(var(--warning-rgb), .1)
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-warning-light.bs-popover-start>.popover-arrow::after,
.popover-warning-light.bs-popover-start>.popover-arrow::before {
    border-left-color: rgba(var(--warning-rgb), .1)
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-warning-light.bs-popover-bottom .popover-header::before,
.popover-warning-light.bs-popover-bottom>.popover-arrow::after,
.popover-warning-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgba(var(--warning-rgb), .1)
}

.popover-info-light.popover {
    border: 1px solid rgba(var(--info-rgb), .1)
}

.popover-info-light.popover .popover-header {
    background-color: rgba(var(--info-rgb), .1);
    border-block-end: 1px solid rgba(var(--info-rgb), .1);
    color: rgb(var(--info-rgb));
    border-radius: 0
}

.popover-info-light.popover .popover-body {
    background-color: rgba(var(--info-rgb), .1);
    color: rgb(var(--info-rgb))
}

.popover-info-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-info-light.bs-popover-top>.popover-arrow::after,
.popover-info-light.bs-popover-top>.popover-arrow::before {
    border-top-color: rgba(var(--info-rgb), .1)
}

.popover-info-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-info-light.bs-popover-end>.popover-arrow::after,
.popover-info-light.bs-popover-end>.popover-arrow::before {
    border-right-color: rgba(var(--info-rgb), .1)
}

.popover-info-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-info-light.bs-popover-start>.popover-arrow::after,
.popover-info-light.bs-popover-start>.popover-arrow::before {
    border-left-color: rgba(var(--info-rgb), .1)
}

.popover-info-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-info-light.bs-popover-bottom .popover-header::before,
.popover-info-light.bs-popover-bottom>.popover-arrow::after,
.popover-info-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgba(var(--info-rgb), .1)
}

.popover-success-light.popover {
    border: 1px solid rgba(var(--success-rgb), .1)
}

.popover-success-light.popover .popover-header {
    background-color: rgba(var(--success-rgb), .1);
    border-block-end: 1px solid rgba(var(--success-rgb), .1);
    color: rgb(var(--success-rgb));
    border-radius: 0
}

.popover-success-light.popover .popover-body {
    background-color: rgba(var(--success-rgb), .1);
    color: rgb(var(--success-rgb))
}

.popover-success-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-success-light.bs-popover-top>.popover-arrow::after,
.popover-success-light.bs-popover-top>.popover-arrow::before {
    border-top-color: rgba(var(--success-rgb), .1)
}

.popover-success-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-success-light.bs-popover-end>.popover-arrow::after,
.popover-success-light.bs-popover-end>.popover-arrow::before {
    border-right-color: rgba(var(--success-rgb), .1)
}

.popover-success-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-success-light.bs-popover-start>.popover-arrow::after,
.popover-success-light.bs-popover-start>.popover-arrow::before {
    border-left-color: rgba(var(--success-rgb), .1)
}

.popover-success-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-success-light.bs-popover-bottom .popover-header::before,
.popover-success-light.bs-popover-bottom>.popover-arrow::after,
.popover-success-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgba(var(--success-rgb), .1)
}

.popover-danger-light.popover {
    border: 1px solid rgba(var(--danger-rgb), .1)
}

.popover-danger-light.popover .popover-header {
    background-color: rgba(var(--danger-rgb), .1);
    border-block-end: 1px solid rgba(var(--danger-rgb), .1);
    color: rgb(var(--danger-rgb));
    border-radius: 0
}

.popover-danger-light.popover .popover-body {
    background-color: rgba(var(--danger-rgb), .1);
    color: rgb(var(--danger-rgb))
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-danger-light.bs-popover-top>.popover-arrow::after,
.popover-danger-light.bs-popover-top>.popover-arrow::before {
    border-top-color: rgba(var(--danger-rgb), .1)
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-danger-light.bs-popover-end>.popover-arrow::after,
.popover-danger-light.bs-popover-end>.popover-arrow::before {
    border-right-color: rgba(var(--danger-rgb), .1)
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-danger-light.bs-popover-start>.popover-arrow::after,
.popover-danger-light.bs-popover-start>.popover-arrow::before {
    border-left-color: rgba(var(--danger-rgb), .1)
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-danger-light.bs-popover-bottom .popover-header::before,
.popover-danger-light.bs-popover-bottom>.popover-arrow::after,
.popover-danger-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgba(var(--danger-rgb), .1)
}

.popover-purple-light.popover {
    border: 1px solid rgba(var(--purple-rgb), .1)
}

.popover-purple-light.popover .popover-header {
    background-color: rgba(var(--purple-rgb), .1);
    border-block-end: 1px solid rgba(var(--purple-rgb), .1);
    color: rgb(var(--purple-rgb));
    border-radius: 0
}

.popover-purple-light.popover .popover-body {
    background-color: rgba(var(--purple-rgb), .1);
    color: rgb(var(--purple-rgb))
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-purple-light.bs-popover-top>.popover-arrow::after,
.popover-purple-light.bs-popover-top>.popover-arrow::before {
    border-top-color: rgba(var(--purple-rgb), .1)
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-purple-light.bs-popover-end>.popover-arrow::after,
.popover-purple-light.bs-popover-end>.popover-arrow::before {
    border-right-color: rgba(var(--purple-rgb), .1)
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-purple-light.bs-popover-start>.popover-arrow::after,
.popover-purple-light.bs-popover-start>.popover-arrow::before {
    border-left-color: rgba(var(--purple-rgb), .1)
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-purple-light.bs-popover-bottom .popover-header::before,
.popover-purple-light.bs-popover-bottom>.popover-arrow::after,
.popover-purple-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgba(var(--purple-rgb), .1)
}

.popover-teal-light.popover {
    border: 1px solid rgba(var(--teal-rgb), .1)
}

.popover-teal-light.popover .popover-header {
    background-color: rgba(var(--teal-rgb), .1);
    border-block-end: 1px solid rgba(var(--teal-rgb), .1);
    color: rgb(var(--teal-rgb));
    border-radius: 0
}

.popover-teal-light.popover .popover-body {
    background-color: rgba(var(--teal-rgb), .1);
    color: rgb(var(--teal-rgb))
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-teal-light.bs-popover-top>.popover-arrow::after,
.popover-teal-light.bs-popover-top>.popover-arrow::before {
    border-top-color: rgba(var(--teal-rgb), .1)
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-teal-light.bs-popover-end>.popover-arrow::after,
.popover-teal-light.bs-popover-end>.popover-arrow::before {
    border-right-color: rgba(var(--teal-rgb), .1)
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-teal-light.bs-popover-start>.popover-arrow::after,
.popover-teal-light.bs-popover-start>.popover-arrow::before {
    border-left-color: rgba(var(--teal-rgb), .1)
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-teal-light.bs-popover-bottom .popover-header::before,
.popover-teal-light.bs-popover-bottom>.popover-arrow::after,
.popover-teal-light.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: rgba(var(--teal-rgb), .1)
}

.progress {
    background-color: rgb(var(--light-rgb));
    height: .75rem;
    border-radius: .25rem
}

.progress-stacked {
    background-color: rgb(var(--light-rgb))
}

.progress-bar {
    color: #fff;
    background-color: var(--primary-color)
}

@keyframes progress-animate {
    0% {
        width: 0
    }
}

.progress-animate {
    position: relative;
    border-radius: .625rem
}

.progress-animate .progress-bar {
    position: relative;
    border-radius: .625rem;
    animation: 2s progress-animate
}

.progress-stacked.progress-xs,
.progress.progress-xs {
    height: .3125rem
}

.progress-stacked.progress-sm,
.progress.progress-sm {
    height: .5rem
}

.progress-stacked.progress-lg,
.progress.progress-lg {
    height: 1rem
}

.progress-stacked.progress-xl,
.progress.progress-xl {
    height: 1.25rem
}

.progress-custom {
    overflow: visible;
    position: relative
}

.progress-custom .progress-bar {
    position: relative;
    overflow: visible
}

.progress-custom .progress-bar:after {
    content: "";
    width: 1.15rem;
    height: 1.15rem;
    border-radius: 50%;
    box-shadow: 0 .313rem .313rem var(--primary02);
    background: #fff;
    position: absolute;
    inset-inline-end: -.375rem;
    inset-block-start: -.375rem;
    border: .25rem solid var(--primary-color)
}

.progress-custom .progress-bar.bg-secondary:after {
    border: .25rem solid rgb(var(--secondary-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--secondary-rgb), .2)
}

.progress-custom .progress-bar.bg-warning:after {
    border: .25rem solid rgb(var(--warning-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--warning-rgb), .2)
}

.progress-custom .progress-bar.bg-info:after {
    border: .25rem solid rgb(var(--info-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--info-rgb), .2)
}

.progress-custom .progress-bar.bg-success:after {
    border: .25rem solid rgb(var(--success-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--success-rgb), .2)
}

.progress-custom .progress-bar.bg-danger:after {
    border: .25rem solid rgb(var(--danger-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--danger-rgb), .2)
}

.progress-custom .progress-bar-title {
    padding: .313rem .625rem;
    margin: 0;
    background: var(--primary-color);
    border-radius: .313rem 0 0 .313rem;
    font-size: .625rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    inset-block-start: -.438rem;
    inset-inline-start: 0;
    z-index: 1
}

.progress-custom .progress-bar-title:after {
    content: "";
    border-inline-start: 1.063rem solid var(--primary-color);
    border-block-start: .688rem solid transparent;
    border-block-end: .688rem solid transparent;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: -1.063rem
}

.progress-custom .progress-bar-title.bg-secondary {
    background-color: rgb(var(--secondary-rgb))
}

.progress-custom .progress-bar-title.bg-secondary:after {
    border-inline-start: 1.063rem solid rgb(var(--secondary-rgb))
}

.progress-custom .progress-bar-title.bg-success {
    background-color: rgb(var(--success-rgb))
}

.progress-custom .progress-bar-title.bg-success:after {
    border-inline-start: 1.063rem solid rgb(var(--success-rgb))
}

.progress-custom .progress-bar-title.bg-warning {
    background-color: rgb(var(--warning-rgb))
}

.progress-custom .progress-bar-title.bg-warning:after {
    border-inline-start: 1.063rem solid rgb(var(--warning-rgb))
}

.progress-custom .progress-bar-title.bg-danger {
    background-color: rgb(var(--danger-rgb))
}

.progress-custom .progress-bar-title.bg-danger:after {
    border-inline-start: 1.063rem solid rgb(var(--danger-rgb))
}

.progress-custom .progress-bar-title.bg-info {
    background-color: rgb(var(--info-rgb))
}

.progress-custom .progress-bar-title.bg-info:after {
    border-inline-start: 1.063rem solid rgb(var(--info-rgb))
}

.progress-custom .progress-bar-value {
    width: 1.875rem;
    height: 1.25rem;
    line-height: 1.25rem;
    border-radius: .188rem;
    background: var(--primary-color);
    box-shadow: 0 .313rem .313rem rgba(0, 0, 0, .4);
    font-size: .625rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    position: absolute;
    inset-block-end: 1.5rem;
    inset-inline-end: -.688rem
}

.progress-custom .progress-bar-value:after {
    content: "";
    border-block-start: .438rem solid var(--primary-color);
    border-inline-start: .438rem solid transparent;
    border-inline-end: .438rem solid transparent;
    position: absolute;
    inset-block-end: -.375rem;
    inset-inline-start: 28%
}

.progress-custom .progress-bar-value.bg-secondary {
    background-color: rgb(var(--secondary-rgb))
}

.progress-custom .progress-bar-value.bg-secondary:after {
    border-block-start: .438rem solid rgb(var(--secondary-rgb))
}

.progress-custom .progress-bar-value.bg-success {
    background-color: rgb(var(--success-rgb))
}

.progress-custom .progress-bar-value.bg-success:after {
    border-block-start: .438rem solid rgb(var(--success-rgb))
}

.progress-custom .progress-bar-value.bg-warning {
    background-color: rgb(var(--warning-rgb))
}

.progress-custom .progress-bar-value.bg-warning:after {
    border-block-start: .438rem solid rgb(var(--warning-rgb))
}

.progress-custom .progress-bar-value.bg-danger {
    background-color: rgb(var(--danger-rgb))
}

.progress-custom .progress-bar-value.bg-danger:after {
    border-block-start: .438rem solid rgb(var(--danger-rgb))
}

.progress-custom .progress-bar-value.bg-info {
    background-color: rgb(var(--info-rgb))
}

.progress-custom .progress-bar-value.bg-info:after {
    border-block-start: .438rem solid rgb(var(--info-rgb))
}

[dir=rtl] .progress-bar-title {
    border-radius: 0 .313rem .313rem 0
}

.progress-item-1,
.progress-item-2,
.progress-item-3 {
    position: absolute;
    margin-block-start: -.25rem;
    z-index: 1;
    height: .938rem;
    width: .938rem;
    border-radius: 1.563rem;
    background-color: rgb(var(--light-rgb))
}

.progress-item-1 {
    inset-inline-start: 25%
}

.progress-item-2 {
    inset-inline-start: 50%
}

.progress-item-3 {
    inset-inline-start: 75%
}

.custom-progress-3 {
    overflow: visible;
    border-radius: .625rem
}

.custom-progress-3 .progress-bar {
    position: relative;
    overflow: visible;
    margin: .313rem;
    border-radius: .25rem
}

.custom-progress-3 .progress-bar:before {
    content: "";
    position: absolute;
    width: .25rem;
    height: .25rem;
    inset-inline-start: .125rem;
    background-color: var(--custom-white);
    border-radius: 50%
}

.custom-progress-3 .progress-bar .progress-bar-value {
    border: .125rem solid var(--primary-color);
    width: 2.5rem;
    height: 1.563rem;
    line-height: 1.313rem;
    border-radius: 3.125rem;
    background: var(--custom-white);
    font-size: .625rem;
    font-weight: 600;
    color: var(--primary-color);
    text-align: center;
    position: absolute;
    inset-inline-end: -.625rem
}

.custom-progress-3 .progress-bar .progress-bar-value.secondary {
    border: .125rem solid rgb(var(--secondary-rgb));
    color: rgb(var(--secondary-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.warning {
    border: .125rem solid rgb(var(--warning-rgb));
    color: rgb(var(--warning-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.info {
    border: .125rem solid rgb(var(--info-rgb));
    color: rgb(var(--info-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.success {
    border: .125rem solid rgb(var(--success-rgb));
    color: rgb(var(--success-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.danger {
    border: .125rem solid rgb(var(--danger-rgb));
    color: rgb(var(--danger-rgb))
}

.custom-progress-4.progress {
    background-color: var(--primary01);
    border-radius: .25rem
}

.custom-progress-4.progress.secondary {
    background-color: rgba(var(--secondary-rgb), .1)
}

.custom-progress-4.progress.secondary .progress-bar-label {
    color: rgb(var(--secondary-rgb))
}

.custom-progress-4.progress.warning {
    background-color: rgba(var(--warning-rgb), .1)
}

.custom-progress-4.progress.warning .progress-bar-label {
    color: rgb(var(--warning-rgb))
}

.custom-progress-4.progress.info {
    background-color: rgba(var(--info-rgb), .1)
}

.custom-progress-4.progress.info .progress-bar-label {
    color: rgb(var(--info-rgb))
}

.custom-progress-4.progress.success {
    background-color: rgba(var(--success-rgb), .1)
}

.custom-progress-4.progress.success .progress-bar-label {
    color: rgb(var(--success-rgb))
}

.custom-progress-4.progress.danger {
    background-color: rgba(var(--danger-rgb), .1)
}

.custom-progress-4.progress.danger .progress-bar-label {
    color: rgb(var(--danger-rgb))
}

.custom-progress-4.progress .progress-bar {
    border-radius: .25rem
}

.custom-progress-4.progress .progress-bar-label {
    position: absolute;
    inset-inline-end: .313rem;
    font-weight: 600;
    font-size: .813rem;
    color: var(--primary-color)
}

caption {
    color: var(--text-muted);
    font-weight: 700
}

.table {
    color: var(--default-text-color);
    border-color: var(--default-border);
    margin-block-end: 0
}

.table tbody tr th {
    font-weight: 500
}

.table td,
.table th {
    padding: .25rem;
    vertical-align: middle;
    line-height: 1.462;
    font-size: .813rem;
    font-weight: 500
}

.table thead tr th {
    font-weight: 600;
    font-size: .85rem
}

.table.table-sm>:not(caption)>*>* {
    padding: .3rem
}

.table.table-dark {
    color: var(--white-7);
    border-color: rgba(255, 255, 255, .1)
}

.table.table-primary {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--primary-rgb), 0.1);
    --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--primary-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--primary-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--primary-rgb), 0.2)
}

.table.table-primary>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--primary-rgb), 0.1);
    color: #000
}

.table.table-primary.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--primary-rgb), 0.2);
    color: #000
}

.table.table-primary.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--primary-rgb), 0.4);
    color: #fff
}

.table.table-primary .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--primary-rgb));
    color: #fff
}

.table.table-secondary {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
    --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--secondary-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.2)
}

.table.table-secondary>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
    color: #000
}

.table.table-secondary.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.2);
    color: #000
}

.table.table-secondary.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.4);
    color: #fff
}

.table.table-secondary .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--secondary-rgb));
    color: #fff
}

.table.table-warning {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--warning-rgb), 0.1);
    --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--warning-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--warning-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--warning-rgb), 0.2)
}

.table.table-warning>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--warning-rgb), 0.1);
    color: #000
}

.table.table-warning.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--warning-rgb), 0.2);
    color: #000
}

.table.table-warning.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--warning-rgb), 0.4);
    color: #fff
}

.table.table-warning .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--warning-rgb));
    color: #fff
}

.table.table-success {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--success-rgb), 0.1);
    --bs-table-border-color: rgba(var(--success-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--success-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--success-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--success-rgb), 0.2)
}

.table.table-success>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--success-rgb), 0.1);
    color: #000
}

.table.table-success.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--success-rgb), 0.2);
    color: #000
}

.table.table-success.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--success-rgb), 0.4);
    color: #fff
}

.table.table-success .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--success-rgb));
    color: #fff
}

.table.table-danger {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--danger-rgb), 0.1);
    --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--danger-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--danger-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--danger-rgb), 0.2)
}

.table.table-danger>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--danger-rgb), 0.1);
    color: #000
}

.table.table-danger.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--danger-rgb), 0.2);
    color: #000
}

.table.table-danger.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--danger-rgb), 0.4);
    color: #fff
}

.table.table-danger .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--danger-rgb));
    color: #fff
}

.table.table-info {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--info-rgb), 0.1);
    --bs-table-border-color: rgba(var(--info-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--info-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--info-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--info-rgb), 0.2)
}

.table.table-info>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--info-rgb), 0.1);
    color: #000
}

.table.table-info.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--info-rgb), 0.2);
    color: #000
}

.table.table-info.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--info-rgb), 0.4);
    color: #fff
}

.table.table-info .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--info-rgb));
    color: #fff
}

.table.table-light {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--light-rgb), 0.1);
    --bs-table-border-color: rgba(var(--light-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--light-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--light-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--light-rgb), 0.2)
}

.table.table-light>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--light-rgb), 0.1);
    color: #000
}

.table.table-light.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--light-rgb), 0.2);
    color: #000
}

.table.table-light.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--light-rgb), 0.4);
    color: #fff
}

.table.table-light .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--light-rgb));
    color: #fff
}

.table.table-dark {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--dark-rgb), 0.1);
    --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--dark-rgb), 0.2);
    --bs-table-active-bg: rgba(var(--dark-rgb), 0.2);
    --bs-table-hover-bg: rgba(var(--dark-rgb), 0.2)
}

.table.table-dark>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--dark-rgb), 0.1);
    color: #000
}

.table.table-dark.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--dark-rgb), 0.2);
    color: #000
}

.table.table-dark.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--dark-rgb), 0.4);
    color: #fff
}

.table.table-dark .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--dark-rgb));
    color: #fff
}

.table.table-bordered.border-primary tbody,
.table.table-bordered.border-primary td,
.table.table-bordered.border-primary tfoot,
.table.table-bordered.border-primary th,
.table.table-bordered.border-primary thead,
.table.table-bordered.border-primary tr {
    border-color: rgba(var(--primary-rgb), .1)
}

.table.table-bordered.border-info tbody,
.table.table-bordered.border-info td,
.table.table-bordered.border-info tfoot,
.table.table-bordered.border-info th,
.table.table-bordered.border-info thead,
.table.table-bordered.border-info tr {
    border-color: rgba(var(--info-rgb), .1)
}

.table.table-bordered.border-secondary tbody,
.table.table-bordered.border-secondary td,
.table.table-bordered.border-secondary tfoot,
.table.table-bordered.border-secondary th,
.table.table-bordered.border-secondary thead,
.table.table-bordered.border-secondary tr {
    border-color: rgba(var(--secondary-rgb), .1)
}

.table.table-bordered.border-warning tbody,
.table.table-bordered.border-warning td,
.table.table-bordered.border-warning tfoot,
.table.table-bordered.border-warning th,
.table.table-bordered.border-warning thead,
.table.table-bordered.border-warning tr {
    border-color: rgba(var(--warning-rgb), .1)
}

.table.table-bordered.border-success tbody,
.table.table-bordered.border-success td,
.table.table-bordered.border-success tfoot,
.table.table-bordered.border-success th,
.table.table-bordered.border-success thead,
.table.table-bordered.border-success tr {
    border-color: rgba(var(--success-rgb), .1)
}

.table.table-bordered.border-danger tbody,
.table.table-bordered.border-danger td,
.table.table-bordered.border-danger tfoot,
.table.table-bordered.border-danger th,
.table.table-bordered.border-danger thead,
.table.table-bordered.border-danger tr {
    border-color: rgba(var(--danger-rgb), .1)
}

.table.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgba(var(--dark-rgb), 0.0125);
    color: var(--default-text-color)
}

.table.table-striped-columns>:not(caption)>tr>:nth-child(2n) {
    --bs-table-accent-bg: rgba(var(--dark-rgb), 0.025);
    color: var(--default-text-color)
}

.table tbody.table-group-divider {
    border-top: 1px solid var(--default-border)
}

.table.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--default-background);
    --bs-table-bg-state: var(--default-background);
    color: var(--default-text-color)
}

.table .table-active {
    --bs-table-accent-bg: rgb(var(--light-rgb));
    color: var(--default-text-color)
}

.table thead.table-primary {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--primary-rgb), 0.1);
    --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--primary-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--primary-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--primary-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-secondary {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
    --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--secondary-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-warning {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--warning-rgb), 0.1);
    --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--warning-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--warning-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--warning-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-success {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--success-rgb), 0.1);
    --bs-table-border-color: rgba(var(--success-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--success-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--success-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--success-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-danger {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--danger-rgb), 0.1);
    --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--danger-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--danger-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--danger-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-info {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--info-rgb), 0.1);
    --bs-table-border-color: rgba(var(--info-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--info-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--info-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--info-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-light {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--light-rgb), 0.1);
    --bs-table-border-color: rgba(var(--light-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--light-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--light-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--light-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-dark {
    background-color: #fff;
    color: var(--bs-table-color);
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--dark-rgb), 0.1);
    --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--dark-rgb), 0.5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--dark-rgb), 0.5);
    --bs-table-hover-bg: rgba(var(--dark-rgb), 0.5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-primary {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--primary-rgb), 0.1);
    --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--primary-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--primary-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--primary-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--primary-rgb), .1);
    background-color: #fff
}

.table-secondary {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
    --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--secondary-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--secondary-rgb), .1);
    background-color: #fff
}

.table-warning {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--warning-rgb), 0.1);
    --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--warning-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--warning-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--warning-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--warning-rgb), .1);
    background-color: #fff
}

.table-success {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--success-rgb), 0.1);
    --bs-table-border-color: rgba(var(--success-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--success-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--success-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--success-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--success-rgb), .1);
    background-color: #fff
}

.table-info {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--info-rgb), 0.1);
    --bs-table-border-color: rgba(var(--info-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--info-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--info-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--info-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--info-rgb), .1);
    background-color: #fff
}

.table-danger {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--danger-rgb), 0.1);
    --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--danger-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--danger-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--danger-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--danger-rgb), .1);
    background-color: #fff
}

.table-dark {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--dark-rgb), 0.1);
    --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--dark-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--dark-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--dark-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--dark-rgb), .1);
    background-color: #fff
}

.table-light {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--light-rgb), 0.1);
    --bs-table-border-color: rgba(var(--light-rgb), 0.1);
    --bs-table-striped-bg: rgba(var(--light-rgb), 0.3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--light-rgb), 0.3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--light-rgb), 0.3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--light-rgb), .1);
    background-color: #fff
}

[data-theme-mode=dark] .table-dark {
    border-color: rgba(0, 0, 0, .025)
}

[data-theme-mode=dark] div.dtr-modal div.dtr-modal-display {
    background-color: var(--custom-white)
}

[dir=rtl] .table {
    margin-inline-end: 1px
}

.toast {
    background-color: var(--custom-white);
    border: 1px solid var(--default-border);
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
    border-radius: .5rem
}

.toast .toast-header {
    background-color: rgb(var(--light-rgb));
    padding: .375rem .75rem
}

.toast .toast-header img {
    width: 1.25rem;
    height: 1.25rem
}

.toast .toast-header {
    border-bottom: 1px solid var(--default-border)
}

.toast .toast-header .btn-close {
    margin-inline-end: .125rem
}

.toast .toast-body {
    font-size: .8rem
}

.toast .btn-close {
    font-size: .563rem
}

.toast.colored-toast {
    border: 0 !important;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px)
}

.toast.colored-toast .btn-close {
    filter: invert(1)
}

.toast.colored-toast .toast-header {
    border-block-end: 1px solid rgba(255, 255, 255, .1)
}

.bd-example-toasts {
    min-height: 15rem
}

.tooltip {
    z-index: 105
}

.tooltip.tooltip-primary .tooltip-inner {
    background-color: var(--primary-color)
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: var(--primary-color)
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: var(--primary-color)
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: var(--primary-color)
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: var(--primary-color)
}

.tooltip.tooltip-secondary .tooltip-inner {
    background-color: rgb(var(--secondary-rgb))
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(var(--secondary-rgb))
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--secondary-rgb))
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: rgb(var(--secondary-rgb))
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: rgb(var(--secondary-rgb))
}

.tooltip.tooltip-warning .tooltip-inner {
    background-color: rgb(var(--warning-rgb))
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(var(--warning-rgb))
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--warning-rgb))
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: rgb(var(--warning-rgb))
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: rgb(var(--warning-rgb))
}

.tooltip.tooltip-info .tooltip-inner {
    background-color: rgb(var(--info-rgb))
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(var(--info-rgb))
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--info-rgb))
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: rgb(var(--info-rgb))
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: rgb(var(--info-rgb))
}

.tooltip.tooltip-success .tooltip-inner {
    background-color: rgb(var(--success-rgb))
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(var(--success-rgb))
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--success-rgb))
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: rgb(var(--success-rgb))
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: rgb(var(--success-rgb))
}

.tooltip.tooltip-danger .tooltip-inner {
    background-color: rgb(var(--danger-rgb))
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(var(--danger-rgb))
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--danger-rgb))
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: rgb(var(--danger-rgb))
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: rgb(var(--danger-rgb))
}

.tooltip.tooltip-dark .tooltip-inner {
    background-color: rgb(var(--dark-rgb))
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(var(--dark-rgb))
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--dark-rgb))
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: rgb(var(--dark-rgb))
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: rgb(var(--dark-rgb))
}

.tooltip.tooltip-dark .tooltip-inner {
    color: var(--custom-white)
}

.tooltip.tooltip-light .tooltip-inner {
    background-color: rgb(var(--light-rgb))
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: rgb(var(--light-rgb))
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: rgb(var(--light-rgb))
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: rgb(var(--light-rgb))
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
    border-bottom-color: rgb(var(--light-rgb))
}

.tooltip.tooltip-light .tooltip-inner {
    color: var(--default-text-color)
}

.authentication {
    min-height: 100vh
}

.authentication .authentication-brand.desktop-logo {
    display: block
}

.authentication .authentication-brand.desktop-dark {
    display: none
}

.authentication .swiper-button-next,
.authentication .swiper-button-prev {
    background-color: rgba(255, 255, 255, .05);
    color: rgba(255, 255, 255, .5) !important
}

.authentication .swiper-pagination-bullet {
    opacity: .1
}

.authentication .swiper-pagination-bullet-active {
    opacity: .5
}

.authentication .google-svg {
    width: .75rem;
    height: .75rem;
    margin-inline-end: .5rem
}

.authentication .authentication-barrier {
    position: relative
}

.authentication .authentication-barrier span {
    position: relative;
    z-index: 2
}

.authentication .authentication-barrier:before {
    position: absolute;
    content: "";
    width: 45%;
    height: .125rem;
    background-image: linear-gradient(to left, transparent, rgb(var(--light-rgb)));
    border-radius: 50%;
    inset-inline-end: 0;
    inset-block-start: .563rem;
    z-index: 1
}

.authentication .authentication-barrier:after {
    position: absolute;
    content: "";
    width: 45%;
    height: .125rem;
    background-image: linear-gradient(to left, rgb(var(--light-rgb)), transparent);
    border-radius: 50%;
    inset-inline-start: 0;
    inset-block-start: .563rem;
    z-index: 1
}

.authentication.coming-soon .authentication-cover,
.authentication.under-maintenance .authentication-cover {
    background-image: url(../images/media/4.jpg)
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content {
    width: 100%;
    height: 100%;
    padding: 3rem;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background-color: var(--custom-white)
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content:after,
.authentication.coming-soon .authentication-cover .aunthentication-cover-content:before,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content:after,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content:before {
    display: none
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content .coming-soon-time,
.authentication.coming-soon .authentication-cover .aunthentication-cover-content .under-maintenance-time,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .coming-soon-time,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .under-maintenance-time {
    border: 2px dashed var(--black-1)
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content .authentication-brand,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .authentication-brand {
    width: auto;
    height: auto;
    border: 0
}

.authentication .coming-soom-image-container img,
.authentication .under-maintenance-image-container img {
    width: 100%;
    height: auto
}

.authentication .authentication-cover {
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative
}

.authentication .authentication-cover:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
    background-color: var(--primary02)
}

.authentication .authentication-cover .aunthentication-cover-content {
    width: 70%;
    height: 37.5rem;
    background-color: rgba(0, 0, 0, .2);
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem);
    padding: 2rem;
    position: relative
}

.authentication .authentication-cover .aunthentication-cover-content img {
    margin: 0 auto;
    width: 13.75rem;
    height: 13.75rem
}

.authentication.authentication-basic .desktop-dark {
    display: none
}

.authentication.authentication-basic .desktop-logo {
    display: block
}

.authentication .form-control-lg#four,
.authentication .form-control-lg#one,
.authentication .form-control-lg#three,
.authentication .form-control-lg#two {
    padding-inline: 0
}

[data-theme-mode=dark] .authentication.authentication-basic .desktop-dark {
    display: block
}

[data-theme-mode=dark] .authentication.authentication-basic .desktop-logo {
    display: none
}

[data-theme-mode=dark] .authentication .authentication-brand.desktop-logo {
    display: none
}

[data-theme-mode=dark] .authentication .authentication-brand.desktop-dark {
    display: block
}

.authentication .desktop-dark,
.authentication .desktop-logo {
    height: 2rem;
    line-height: 2rem
}

body {
    font-size: .813rem;
    font-family: var(--default-font-family);
    font-weight: var(--default-font-weight);
    /* background-color: white; */
    color: var(--default-text-color);
    line-height: 1.5;
    text-align: start;
    overflow-x: clip;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}

::-webkit-scrollbar {
    width: 0;
    height: .25rem;
    -webkit-transition: all ease 50ms;
    transition: all ease 50ms;
    background: rgb(var(--light-rgb))
}

:hover::-webkit-scrollbar-thumb {
    background: rgb(var(--light-rgb))
}

a,
button {
    outline: 0 !important
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh
}

@media (min-width:992px) {
    .app-content {
        min-height: calc(100vh - 7.5rem);
        margin-block-end: 0;
        margin-block-start: 3.75rem;
        margin-inline-start: 15rem;
        transition: all 50ms ease
    }
}

.main-content {
    padding: 0 .5rem
}

.bd-example>.dropdown-menu {
    position: static;
    display: block
}

.min-w-fit-content {
    min-width: -moz-fit-content;
    min-width: fit-content
}

.scrollspy-example {
    height: 12.5rem;
    margin-block-start: .5rem;
    overflow: auto
}

.scrollspy-example-2 {
    height: 21.875;
    overflow: auto
}

.scrollspy-example-3 {
    height: 13.75rem;
    overflow: auto
}

.simple-list-example-scrollspy .active {
    background-color: var(--primary-color);
    color: #fff
}

.scrollspy-example-4 {
    height: 12.5rem;
    margin-block-start: .5rem;
    overflow: auto
}

.carousel-inner {
    border-radius: .35rem
}

.carousel-caption {
    color: #fff
}

.fixed-top {
    position: static;
    margin: -1rem -1rem 0 -1rem
}

.fixed-bottom {
    position: static;
    margin: 1rem 0 -1rem -1rem
}

.sticky-top {
    position: static;
    margin: -1rem -1rem 0 -1rem
}

.bd-example-ratios .ratio {
    display: inline-block;
    width: 10rem;
    color: var(--text-muted);
    background-color: var(--primary01);
    border: var(--default-border);
    border-radius: .5rem
}

@media (min-width:768px) {
    .bd-example-ratios-breakpoint .ratio-4x3 {
        --bs-aspect-ratio: 50%
    }
}

.bd-example-ratios-breakpoint .ratio-4x3 {
    width: 16rem
}

.bd-example-cssgrid .grid>*,
.bd-example-row [class^=col] {
    padding-block-start: .75rem;
    padding-block-end: .75rem;
    background-color: rgba(var(--light-rgb), .75);
    border: 1px solid var(--default-border)
}

.bd-example-cssgrid .grid>* {
    border-radius: .25rem
}

.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(var(--light-rgb), .5)
}

.grid {
    display: grid;
    grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
    grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
    gap: var(--bs-gap, 1.5rem)
}

.grid .g-start-2 {
    grid-column-start: 2 !important
}

.grid .g-col-2 {
    grid-column: auto/span 2
}

.grid .g-start-3 {
    grid-column-start: 3
}

.grid .g-col-3 {
    grid-column: auto/span 3
}

.grid .g-col-4,
.grid .g-col-md-4 {
    grid-column: auto/span 4
}

.grid .g-col-6 {
    grid-column: auto/span 6
}

.grid .g-start-6 {
    grid-column-start: 6
}

.callout {
    padding: 1.25rem;
    margin-block-start: 1.25rem;
    margin-block-end: 1.25rem;
    background-color: rgb(var(--light-rgb));
    border-inline-start: .25rem solid var(--default-border)
}

.callout-info {
    background-color: rgba(var(--info-rgb), .075);
    border-color: rgba(var(--info-rgb), .5)
}

.callout-warning {
    background-color: rgba(var(--warning-rgb), .075);
    border-color: rgba(var(--warning-rgb), .5)
}

.callout-danger {
    background-color: rgba(var(--danger-rgb), .075);
    border-color: rgba(var(--danger-rgb), .5)
}

.flex-container div {
    background-color: transparent;
    border: 0
}

.flex-container div>div {
    background-color: rgb(var(--light-rgb));
    border: 1px solid var(--custom-white)
}

.bd-example-position-utils {
    position: relative;
    padding: 2rem
}

.bd-example-position-utils .position-relative {
    height: 12.5rem;
    background-color: var(--default-background)
}

.bd-example-position-utils .position-absolute {
    width: 2rem;
    height: 2rem;
    background-color: var(--primary01);
    border-radius: .375rem
}

.bd-placeholder-img-lg {
    font-size: 3.5rem
}

.figure-caption {
    color: var(--text-muted)
}

@media (min-width:992px) {
    .footer {
        padding-inline-start: 15rem
    }
}

.footer {
    box-shadow: 0 .25rem 1rem var(--black-1)
}

.offcanvas {
    background-color: var(--custom-white);
    color: var(--default-text-color);
    border-color: var(--default-border) !important
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto
}

@keyframes spin {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(360deg)
    }
}

#switcher-main-tab {
    border-block-end: 0
}

#switcher-canvas {
    width: 27.5rem
}

#switcher-canvas .offcanvas-body {
    padding: 0 0 4.75rem 0
}

#switcher-canvas .canvas-footer {
    padding: .75rem 1.563rem;
    position: absolute;
    inset-block-end: 0;
    width: 100%;
    background-color: var(--custom-white);
    border-block-start: 1px dashed var(--default-border);
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .5)
}

#switcher-canvas #switcher-main-tab button.nav-link {
    border-radius: 0;
    color: var(--default-text-color);
    font-weight: 600
}

#switcher-canvas #switcher-main-tab button.nav-link.active {
    color: rgb(var(--success-rgb));
    background-color: rgba(var(--success-rgb), .2);
    border-color: transparent
}

#switcher-canvas #switcher-main-tab button.nav-link:hover {
    border-color: transparent
}

.switcher-style {
    padding: .875rem 1.563rem
}

.switcher-style h6 {
    margin-block-end: .625rem
}

.switcher-icon i {
    animation-name: spin;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

.switch-select .form-check-label {
    font-size: .813rem;
    font-weight: 600
}

.switch-select.form-check {
    margin-block-end: 0;
    min-height: auto
}

.menu-image .bgimage-input {
    width: 3.5rem;
    height: 5.625rem;
    border-radius: .5rem;
    border: 0
}

.menu-image .bgimage-input.form-check-input:focus {
    border-color: transparent;
    box-shadow: 0 0 0 .25rem var(--black-1)
}

.menu-image .bgimage-input.bg-img1 {
    background-image: url(../images/media/4.jpg) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.menu-image .bgimage-input.bg-img1.form-check-input:checked[type=radio] {
    background-color: none
}

.menu-image .bgimage-input.bg-img2 {
    background-image: url(../images/media/4.jpg) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.menu-image .bgimage-input.bg-img2.form-check-input:checked[type=radio] {
    background-color: none
}

.menu-image .bgimage-input.bg-img3 {
    background-image: url(../images/media/4.jpg) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.menu-image .bgimage-input.bg-img3.form-check-input:checked[type=radio] {
    background-color: none
}

.menu-image .bgimage-input.bg-img4 {
    background-image: url(../images/media/4.jpg) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.menu-image .bgimage-input.bg-img4.form-check-input:checked[type=radio] {
    background-color: none
}

.menu-image .bgimage-input.bg-img5 {
    background-image: url(../images/media/4.jpg) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.menu-image .bgimage-input.bg-img5.form-check-input:checked[type=radio] {
    background-color: none
}

.theme-colors.switcher-style {
    padding: .938rem 1.563rem
}

.theme-colors .switch-select .color-input {
    width: 2rem;
    height: 2rem;
    border-radius: 50px
}

.theme-colors .switch-select .color-input.form-check-input:checked {
    border: 1px solid var(--input-border);
    position: relative;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04)
}

.theme-colors .switch-select .color-input.form-check-input:checked:before {
    position: absolute;
    content: "\ea5e";
    font-family: tabler-icons !important;
    color: rgb(var(--success-rgb));
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.35rem;
    font-weight: 600
}

.theme-colors .switch-select .color-input.color-white {
    background-color: #fff
}

.theme-colors .switch-select .color-input.color-dark {
    background-color: #000
}

.theme-colors .switch-select .color-input.color-primary {
    background-color: var(--primary-color)
}

.theme-colors .switch-select .color-input.color-primary-1 {
    background-color: #3a5892
}

.theme-colors .switch-select .color-input.color-primary-2 {
    background-color: #5c90a3
}

.theme-colors .switch-select .color-input.color-primary-3 {
    background-color: #a15adf
}

.theme-colors .switch-select .color-input.color-primary-4 {
    background-color: #4eac4c
}

.theme-colors .switch-select .color-input.color-primary-5 {
    background-color: #df5a5a
}

.theme-colors .switch-select .color-input.color-gradient {
    background-image: linear-gradient(to right top, var(--primary-color), #6e72a8)
}

.theme-colors .switch-select .color-input.color-transparent {
    background-image: url(../images/media/4.jpg) !important
}

.theme-colors .switch-select .color-input.color-bg-1 {
    background-color: #141e60
}

.theme-colors .switch-select .color-input.color-bg-2 {
    background-color: #084e73
}

.theme-colors .switch-select .color-input.color-bg-3 {
    background-color: #5a2587
}

.theme-colors .switch-select .color-input.color-bg-4 {
    background-color: #186533
}

.theme-colors .switch-select .color-input.color-bg-5 {
    background-color: #784214
}

.theme-colors .switch-select .form-check-input:checked[type=radio] {
    background-image: none
}

.theme-colors .switch-select .form-check-input:focus {
    box-shadow: none
}

.theme-colors .switch-select .form-check-input:active {
    filter: brightness(100%)
}

.switcher-style-head {
    font-size: .8rem;
    font-weight: 600;
    margin-block-end: 0;
    background: rgb(var(--light-rgb));
    padding: .313rem .625rem;
    color: var(--default-text-color)
}

.switcher-style-head .switcher-style-description {
    float: right;
    font-size: .625rem;
    background-color: rgba(var(--secondary-rgb), .2);
    color: rgb(var(--secondary-rgb));
    padding: .125rem .313rem;
    border-radius: .5rem
}

#switcher-home,
#switcher-profile {
    padding: 0
}

.theme-container-background button,
.theme-container-primary button {
    display: none
}

.pickr-container-background .pickr .pcr-button,
.pickr-container-primary .pickr .pcr-button {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid var(--input-border)
}

.pickr-container-background .pickr .pcr-button:focus,
.pickr-container-primary .pickr .pcr-button:focus {
    box-shadow: none
}

.pickr-container-background .pickr .pcr-button::after,
.pickr-container-primary .pickr .pcr-button::after {
    content: "\efc5";
    font-family: remixicon !important;
    color: rgba(255, 255, 255, .7);
    line-height: 1.5;
    font-size: 1.25rem
}

.card {
    background-color: var(--custom-white);
    border: 1px solid var(--bootstrap-card-border)
}

.img-thumbnail {
    background-color: var(--custom-white);
    border: 1px solid var(--default-border)
}

#btcCoin .apexcharts-grid line,
#btcCoin .apexcharts-xaxis line,
#dshCoin .apexcharts-grid line,
#dshCoin .apexcharts-xaxis line,
#ethCoin .apexcharts-grid line,
#ethCoin .apexcharts-xaxis line,
#glmCoin .apexcharts-grid line,
#glmCoin .apexcharts-xaxis line {
    stroke: transparent
}

.apexcharts-title-text {
    fill: var(--text-muted)
}

@media (max-width:991.98px) {
    .navigation-menu-styles {
        display: none
    }
}

@media (max-width:575.98px) {
    .cart-dropdown,
    .header-fullscreen,
    .header-shortcuts-dropdown,
    .notifications-dropdown,
    .timelines-dropdown {
        display: none !important
    }
}

.btn-close:focus {
    box-shadow: none !important
}

.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap
}

.icons-list .icons-list-item {
    text-align: center;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--default-border);
    margin: .25rem;
    border-radius: .25rem
}

.icons-list .icons-list-item i {
    font-size: 1.05rem;
    color: var(--default-text-color)
}

.fe {
    font-size: inherit !important
}

.bd-placeholder-img {
    margin: .125rem
}

.shadow-primary {
    box-shadow: 0 .25rem 1rem rgba(var(--primary-rgb), .3) !important
}

.shadow-secondary {
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb), .3) !important
}

.shadow-success {
    box-shadow: 0 .25rem 1rem rgba(var(--success-rgb), .3) !important
}

.shadow-info {
    box-shadow: 0 .25rem 1rem rgba(var(--info-rgb), .3) !important
}

.shadow-warning {
    box-shadow: 0 .25rem 1rem rgba(var(--warning-rgb), .3) !important
}

.shadow-danger {
    box-shadow: 0 .25rem 1rem rgba(var(--danger-rgb), .3) !important
}

.shadow-orange {
    box-shadow: 0 .25rem 1rem rgba(var(--orange-rgb), .3) !important
}

.shadow-purple {
    box-shadow: 0 .25rem 1rem rgba(var(--purple-rgb), .3) !important
}

.placeholder-xl {
    min-height: 1.5em
}

.placeholder {
    background-color: var(--gray-7)
}

.scrollspy-example-2 {
    height: 21.875rem;
    border: 1px solid var(--default-border);
    padding: .75rem;
    border-radius: .5rem
}

.object-fit-container {
    display: flex;
    align-items: center;
    justify-content: center
}

.object-fit-container img,
.object-fit-container video {
    width: 15.625rem;
    height: 15.625rem
}

.invoice-amount-input {
    width: 9.375rem
}

.choices-control .choices__inner {
    background-color: rgb(var(--light-rgb)) !important;
    border: 0 !important
}

.svg-icon-background {
    width: 2.5rem;
    height: 2.5rem;
    padding: .625rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.svg-icon-background svg {
    width: 1.25rem;
    height: 1.25rem
}

.invoice-quantity-container {
    width: 8.75rem
}

.pricing-basic,
.pricing-premium,
.pricing-pro {
    position: relative
}

.pricing-basic:before,
.pricing-premium:before,
.pricing-pro:before {
    position: absolute;
    content: "";
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center
}

.pricing-basic:before {
    background-image: url(../images/media/1.jpg);
    width: 2.5rem;
    height: 2.5rem;
    inset-block-start: .375rem;
    inset-inline-end: .375rem;
    opacity: .2
}

.pricing-pro:before {
    background-image: url(../images/media/2.jpg);
    width: 2.5rem;
    height: 2.5rem;
    inset-block-start: .375rem;
    inset-inline-end: .375rem;
    opacity: .2
}

.pricing-premium:before {
    background-image: url(../images/media/3.jpg);
    width: 2.5rem;
    height: 2.5rem;
    inset-block-start: .375rem;
    inset-inline-end: .375rem;
    opacity: .2
}

.pricing-svg1 {
    position: relative;
    z-index: 2
}

.pricing-svg1 svg {
    width: 4rem;
    height: 4rem
}

.pricing-svg1:before {
    content: "";
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 6.5rem;
    height: 6.5rem;
    inset-block-start: -1rem;
    inset-inline-start: -1.25rem;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: -1
}

.pricing-offer {
    position: relative
}

.pricing-offer .pricing-offer-details {
    position: absolute;
    width: 5.438rem;
    height: 3.75rem;
    background-color: var(--primary-color);
    color: #fff;
    font-size: .75rem;
    transform: rotate(45deg);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    inset-inline-end: -2.063rem;
    inset-block-start: -1.25rem;
    padding: .313rem
}

[dir=rtl] .pricing-offer-details {
    transform: rotate(315deg)
}

.teams-nav {
    max-height: calc(100vh - 9rem)
}

.teams-nav ul li {
    padding: .625rem 1.25rem
}

.team-member-card {
    overflow: hidden
}

.team-member-card .avatar {
    position: absolute;
    inset-block-start: 65%;
    inset-inline-start: 38%;
    outline: .25rem solid rgba(0, 0, 0, .075)
}

@media (min-width:576px) {
    .team-member-card .team-member-details {
        padding-inline-start: 4.75rem
    }
    .team-member-card .avatar {
        inset-inline-start: 1rem
    }
    .team-member-card .team-member-stats div {
        border-inline-end: 1px dashed var(--default-border);
        width: 100%
    }
    .team-member-card .team-member-stats div:last-child {
        border-inline-end: 0
    }
}

@media (max-width:575.98px) {
    .team-member-card .team-member-stats div {
        border-block-end: 1px dashed var(--default-border)
    }
    .team-member-card .team-member-stats div:last-child {
        border-block-end: 0
    }
}

@media (min-width:1400px) and (max-width:1700px) {
    .team-member-card .team-member-details {
        max-width: 12rem
    }
}

.team-member-card .teammember-cover-image {
    position: relative
}

.team-member-card .teammember-cover-image .card-img-top {
    width: 100%;
    height: 6.25rem
}

.team-member-card .teammember-cover-image:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--primary03);
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0
}

.team-member-card .teammember-cover-image .team-member-star {
    position: absolute;
    inset-block-start: .75rem;
    padding: .375rem;
    inset-inline-end: .75rem;
    background-color: rgba(0, 0, 0, .1);
    border-radius: .5rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.contact-action {
    position: relative;
    padding: 1.25rem
}

.contact-action .contact-overlay {
    position: absolute;
    width: 0;
    height: 100%;
    background-color: var(--primary07);
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
    z-index: 1;
    border-radius: .5rem
}

.contact-action:hover .contact-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    inset-inline-start: 0
}

.contact-action:hover .contact-hover-btn,
.contact-action:hover .contact-hover-dropdown,
.contact-action:hover .contact-hover-dropdown1 {
    display: block;
    opacity: 1
}

.contact-action .contact-hover-btn {
    opacity: 0;
    z-index: 2
}

.contact-action .contact-hover-dropdown {
    opacity: 0;
    z-index: 2
}

.contact-action .contact-hover-dropdown1 {
    opacity: 0;
    z-index: 2
}

.contact-action .contact-hover-buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0
}

@media (min-width:1400px) and (max-width:1700px) {
    .contact-mail {
        max-width: 8.125rem
    }
}

@media (max-width:400px) {
    .contact-mail {
        max-width: 8.125rem
    }
}

.notification-container li .un-read {
    border-inline-start: .25rem solid var(--primary03)
}

.task-navigation ul.task-main-nav li {
    padding: .5rem;
    border-radius: var(--default-border);
    border-radius: .3rem;
    font-weight: 500
}

.task-navigation ul.task-main-nav li:hover a {
    color: var(--primary-color)
}

.task-navigation ul.task-main-nav li.active div {
    color: var(--primary-color)
}

.task-pending-card {
    border-inline-start: .45rem solid rgba(var(--secondary-rgb), .4) !important
}

.task-inprogress-card {
    border-inline-start: .45rem solid rgba(var(--primary-rgb), .4) !important
}

.task-completed-card {
    border-inline-start: .45rem solid rgba(var(--success-rgb), .4) !important
}

.task-completed-card .card-body,
.task-inprogress-card .card-body,
.task-pending-card .card-body {
    position: relative
}

.task-completed-card .card-body .badge,
.task-inprogress-card .card-body .badge,
.task-pending-card .card-body .badge {
    position: absolute;
    inset-block-end: 1.25rem;
    inset-inline-end: 1.25rem
}

.task-completed-card:hover,
.task-inprogress-card:hover,
.task-pending-card:hover {
    box-shadow: 0 .25rem 1rem var(--black-1)
}

.task-tabs-container .tab-pane {
    min-height: calc(100vh - 22rem);
    border: 0
}

.terms-conditions {
    max-height: 44.75rem
}

.terms-heading {
    position: relative
}

.terms-heading:before {
    position: absolute;
    content: "";
    width: 50%;
    height: .25rem;
    background: linear-gradient(to right, var(--primary-color), var(--primary01));
    inset-inline-start: 25%;
    inset-block-end: -.65rem;
    border-radius: .5rem
}

.card.card-fullscreen {
    border-radius: 0
}

.contact-page-banner {
    position: relative;
    width: 100%;
    height: 25rem;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-block-start: 6.25rem
}

.contact-page-banner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary08);
    inset-inline-start: 0;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0
}

.contact-page-banner div {
    z-index: 1;
    position: relative
}

@media (min-width:576px) {
    .contactus-form {
        position: relative;
        inset-block-start: -6.25rem
    }
}

@media (max-width:575px) {
    .contact-page-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-block-start: 0
    }
}

.contactus-form .tab-style-2 .nav-item .nav-link {
    padding: .85rem 2rem
}

.contactus-form iframe {
    width: 100%
}

.reviews-container .card {
    position: relative
}

.reviews-container .card:before {
    content: "\ec52";
    font-family: remixicon !important;
    position: absolute;
    width: 2.188rem;
    height: 2.188rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    border-radius: 3.125rem;
    justify-content: center;
    color: #fff;
    background-color: var(--primary-color);
    box-shadow: 0 0 .5rem var(--primary05);
    inset-inline-end: 1.125rem;
    inset-block-start: -1.125rem
}

#accordionFAQ {
    max-height: 31.25rem
}

.timeline {
    position: relative
}

.timeline li:before {
    content: "";
    position: absolute;
    inset-block-start: 4.625rem;
    inset-block-end: -3rem;
    width: 1px;
    border: 1px dashed var(--primary02);
    inset-inline-start: 20%;
    margin-inline-start: -1.5px
}

.timeline>li {
    position: relative;
    min-height: 3.125rem;
    padding: .938rem 0
}

.timeline .timeline-time {
    position: absolute;
    inset-inline-start: 0;
    width: 18%;
    inset-block-start: 3.35rem
}

.timeline .timeline-time .date,
.timeline .timeline-time .time {
    display: block;
    font-weight: 500
}

.timeline .timeline-time .date {
    line-height: 1rem;
    font-size: .6rem;
    margin-bottom: 0;
    color: var(--text-muted)
}

.timeline .timeline-time .time {
    line-height: 1.5rem;
    font-size: 1.125rem;
    color: var(--text-muted)
}

.timeline .timeline-icon {
    inset-inline-start: 15%;
    position: absolute;
    width: 10%;
    text-align: center;
    inset-block-start: 3.188rem
}

.timeline .timeline-icon a {
    width: .625rem;
    height: .625rem;
    display: inline-block;
    border-radius: 50%;
    background: rgb(var(--light-rgb));
    color: var(--primary-color);
    line-height: .625rem;
    font-size: .875rem;
    border: .188rem solid var(--primary05)
}

.timeline .timeline-body {
    box-shadow: 0 1px 1rem rgba(0, 0, 0, .05);
    margin-inline-start: 22%;
    margin-inline-end: 18%;
    background: var(--custom-white);
    position: relative;
    padding: .875rem 1.25rem .875rem 1.25rem;
    border-radius: .5rem
}

.timeline .timeline-body>div+div {
    margin-block-start: .938rem
}

.timeline-loadmore-container {
    margin-block-start: 3.5rem;
    margin-block-end: 1.5rem
}

.timeline li:last-child:before {
    display: none
}

@media screen and (min-width:576px) {
    .timeline .timeline-body {
        inset-block-start: 2.5rem
    }
}

@media (max-width:575.98px) {
    .timeline .timeline-icon a {
        display: none
    }
    .timeline li:before {
        display: none
    }
    .timeline-body .media {
        flex-direction: column
    }
    .timeline-body .media .main-img-user {
        margin-block-end: .625rem !important
    }
    .timeline .timeline-time {
        z-index: 99;
        width: 100%;
        inset-inline-end: .313rem !important;
        position: absolute;
        inset-block-start: 1.5rem !important
    }
    .timeline-main-content {
        flex-direction: column
    }
    .timeline .timeline-body {
        margin-inline-start: 0;
        margin-inline-end: 0;
        position: relative
    }
    .timeline-badge {
        position: absolute;
        inset-inline-start: 1.15rem;
        inset-block-start: .8rem
    }
    .timeline .timeline-time .date,
    .timeline .timeline-time .time {
        display: inline
    }
    .timeline .timeline-time .time {
        line-height: 1rem;
        font-size: .688rem;
        margin-inline-start: .313rem;
        margin-inline-end: .625rem;
        color: var(--text-muted)
    }
}

.blog-caption {
    position: absolute;
    inset-inline-start: 1.5rem;
    inset-block-end: 1.5rem;
    padding: 0
}

.popular-blog-content {
    max-width: 9rem
}

.blog-details-img {
    height: 450px
}

.blog-popular-tags .badge {
    margin: .313rem;
    font-size: .65rem
}

.blog-images-container .filepond--root {
    width: 100%
}

.blog-images-container .filepond--panel-root {
    border-color: var(--input-border) !important;
    border-radius: .5rem
}

.blog-images-container .filepond--root .filepond--drop-label label {
    color: var(--text-muted)
}

#blog-content {
    height: auto
}

@media screen and (max-width:400px) {
    .choices__inner .choices__list--multiple .choices__item {
        margin-block-end: .25rem !important
    }
}

@media screen and (max-width:991px) {
    .ql-toolbar.ql-snow .ql-formats {
        margin-block-end: .25rem !important
    }
}

.main-profile-cover {
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 9
}

.main-profile-cover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary08);
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0
}

.main-profile-info {
    z-index: 10;
    position: relative
}

.profile-works img {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: .3rem;
    margin-bottom: 1.25rem
}

.profile-timeline {
    margin-block-end: 0
}

.profile-timeline li {
    padding-inline-start: 5.313rem;
    position: relative;
    margin-block-end: 1.75rem
}

.profile-timeline li .profile-timeline-avatar {
    position: absolute;
    inset-inline-start: 2.125rem;
    inset-block-start: .188rem
}

.profile-timeline li:last-child {
    margin-block-end: 0
}

.profile-timeline li:last-child::before {
    display: none
}

.profile-timeline li::before {
    content: "";
    background-color: transparent;
    border: 1px dashed rgba(var(--dark-rgb), .1);
    height: 100%;
    position: absolute;
    inset-inline-start: 2.813rem;
    inset-block-start: 1.813rem
}

.profile-timeline .profile-activity-media img {
    width: 4rem;
    height: 3rem;
    border-radius: .5rem;
    margin: .25rem
}

.profile-recent-posts {
    max-width: 10rem
}

.profile-post-content {
    width: 42rem
}

#profile-posts-scroll {
    max-height: 35rem
}

@media (max-width:420px) {
    .profile-post-link {
        max-width: 9.375rem
    }
}

.about-container {
    background-color: var(--custom-white);
    border-radius: .5rem;
    overflow: hidden;
    padding: 1.25rem
}

.aboutus-banner {
    width: 100%;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: .5rem .5rem 0 0;
    z-index: 9;
    margin-block-end: 3rem;
    border-radius: .5rem
}

.aboutus-banner:before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, .8);
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    bottom: 0;
    border-radius: .3rem
}

.aboutus-banner .aboutus-banner-content {
    z-index: 10;
    text-align: center;
    color: #fff
}

@media (max-width:575.98px) {
    .about-company-stats .about-company-stats-border {
        border-block-end: 1px dashed var(--black-1)
    }
}

@media (min-width:576px) {
    .about-company-stats {
        position: absolute;
        bottom: -5rem;
        z-index: 10;
        inset-inline-start: 0;
        inset-inline-end: 0
    }
    .about-company-stats .about-company-stats-border {
        border-inline-end: 1px dashed var(--black-1)
    }
    .aboutus-banner {
        padding: 4rem;
        margin-block-end: 8rem
    }
}

.about-heading-white {
    position: relative
}

.about-heading-white:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .25rem;
    border-radius: 3.125rem;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, .2));
    bottom: -.625rem;
    inset-inline-start: .375rem
}

@media (max-width:1199.98px) {
    .aboutus-banner-img {
        display: none
    }
}

@media (min-width:1200px) {
    .aboutus-banner-img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative
    }
    .aboutus-banner-img:before {
        position: absolute;
        content: "";
        width: 12.5rem;
        height: 12.5rem;
        inset-block-start: 6rem;
        inset-inline-start: 11.875rem;
        background-color: rgba(255, 255, 255, .06)
    }
    .aboutus-banner-img:after {
        position: absolute;
        content: "";
        width: 12.5rem;
        top: -2.625rem;
        inset-inline-end: 17.5rem;
        height: 12.5rem;
        background-color: rgba(255, 255, 255, .06)
    }
    .aboutus-banner-img img {
        width: 100%;
        height: 17.5rem;
        z-index: 1
    }
}

.about-main,
.about-motto {
    display: flex;
    align-items: center;
    justify-content: center
}

.motto-icon {
    width: 1.875rem;
    height: 1.875rem
}

.about-heading {
    position: relative;
    margin-block-end: 1rem
}

.about-heading:before {
    content: "";
    position: absolute;
    width: 60%;
    height: .25rem;
    border-radius: 3.125rem;
    background-image: linear-gradient(to right, rgb(var(--success-rgb)), rgba(var(--success-rgb), .2));
    inset-block-end: -.625rem;
    inset-inline-start: .375rem
}

[dir=rtl] .fullcalendar-events-activity li {
    padding: .25rem 2rem .25rem 1rem
}

.fullcalendar-events-activity li {
    margin-block-end: .75rem;
    font-size: .8125rem;
    padding: .25rem 1rem .25rem 2rem;
    position: relative
}

.fullcalendar-events-activity li::before {
    position: absolute;
    content: "";
    width: .75rem;
    height: .75rem;
    border: .125rem solid var(--primary03);
    border-radius: 3.125rem;
    background-color: var(--custom-white);
    inset-inline-start: .25rem;
    inset-block-start: .563rem
}

.fullcalendar-events-activity li::after {
    position: absolute;
    content: "";
    height: 100%;
    background-color: transparent;
    border-inline-end: 2px dashed var(--primary01);
    inset-inline-start: .563rem;
    inset-block-start: 1.25rem
}

.fullcalendar-events-activity li:last-child {
    margin-block-end: 0
}

.fullcalendar-events-activity li:last-child::after {
    border-inline-end: 0 dashed var(--default-border)
}

#full-calendar-activity {
    max-height: 21rem
}

#draggable-left .card,
#draggable-right .card {
    cursor: move
}

.scrollToTop {
    background-color: var(--primary-color);
    color: #fff;
    position: fixed;
    bottom: 20px;
    inset-inline-end: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: none;
    z-index: 10000;
    height: 2.5rem;
    width: 2.5rem;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color .1s linear;
    border-radius: .5rem;
    box-shadow: rgba(100, 100, 110, .2) 0 .438rem 1.813rem 0
}

[dir=rtl] .recruiter .btn-icon.btn-sm {
    transform: rotate(180deg)
}

[dir=rtl] .rtl-rotate {
    transform: rotate(180deg)
}

#address-add {
    min-height: calc(1.5em + 2.75rem + var(--bs-border-width) * 2)
}

.project-list-title {
    max-width: 13.375rem
}

#project-descriptioin-editor {
    height: 200px;
    overflow: auto
}

.project-list-main .choices__inner {
    width: 150px !important
}

.swiper-related-jobs .swiper-button-next,
.swiper-related-jobs .swiper-button-prev {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color) !important
}

@media (max-width:767.98px) {
    .swiper-related-jobs .swiper-button-next,
    .swiper-related-jobs .swiper-button-prev {
        display: none
    }
}

@media screen and (min-width:623px) {
    .input-group.companies-search-input .choices {
        margin-block-end: 0
    }
    .input-group.companies-search-input .choices__inner {
        border-radius: 0 !important;
        min-height: 100% !important
    }
    .input-group.companies-search-input .choices__list--dropdown .choices__item--selectable {
        padding-inline-end: 1rem
    }
    [dir=rtl] .input-group.companies-search-input .choices__inner {
        border-radius: 0 !important
    }
}

@media screen and (max-width:622px) {
    .input-group.companies-search-input {
        display: block
    }
    .input-group.companies-search-input .form-control {
        width: 100% !important;
        border-radius: .5rem !important;
        margin-bottom: .5rem
    }
    .input-group.companies-search-input .choices {
        border-radius: .5rem !important;
        margin-bottom: .5rem
    }
    .input-group.companies-search-input .btn {
        width: 100% !important;
        border-radius: .5rem !important
    }
}

.list-bullets li {
    position: relative;
    margin-block-end: -1px;
    border: 1px solid var(--default-border);
    list-style-type: circle;
    list-style-position: inside;
    padding: .75rem 1.25rem
}

.crm-contact-close-btn.btn-close {
    filter: invert(1);
    position: absolute;
    inset-inline-end: -.625rem;
    inset-block-start: -.625rem
}

#offcanvasExample {
    border-color: transparent !important
}

.contact-initiated,
.deal-finalized,
.lead-discovered,
.lead-qualified,
.need-identified,
.negotiation {
    position: relative;
    margin-inline-start: 1rem
}

.contact-initiated:before,
.deal-finalized:before,
.lead-discovered:before,
.lead-qualified:before,
.need-identified:before,
.negotiation:before {
    position: absolute;
    content: "";
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    inset-inline-start: -.875rem;
    inset-block-start: .4375rem
}

.lead-discovered:before {
    background-color: var(--primary-color)
}

.lead-qualified:before {
    background-color: rgb(var(--warning-rgb))
}

.contact-initiated:before {
    background-color: rgb(var(--success-rgb))
}

.need-identified:before {
    background-color: rgb(var(--info-rgb))
}

.negotiation:before {
    background-color: rgb(var(--danger-rgb))
}

.deal-finalized:before {
    background-color: rgb(var(--secondary-rgb))
}

#leads-discovered .card.custom-card {
    border-inline-start: .4rem solid rgba(var(--primary-rgb), .4) !important
}

#leads-discovered .card.custom-card .company-name {
    color: var(--primary-color)
}

#leads-discovered .card.custom-card .avatar {
    background-color: var(--primary-color)
}

#leads-qualified .card.custom-card {
    border-inline-start: .4rem solid rgba(var(--warning-rgb), .4) !important
}

#leads-qualified .card.custom-card .company-name {
    color: rgb(var(--warning-rgb))
}

#leads-qualified .card.custom-card .avatar {
    background-color: rgb(var(--warning-rgb))
}

#contact-initiated .card.custom-card {
    border-inline-start: .4rem solid rgba(var(--success-rgb), .4) !important
}

#contact-initiated .card.custom-card .company-name {
    color: rgb(var(--success-rgb))
}

#contact-initiated .card.custom-card .avatar {
    background-color: rgb(var(--success-rgb))
}

#needs-identified .card.custom-card {
    border-inline-start: .4rem solid rgba(var(--info-rgb), .4) !important
}

#needs-identified .card.custom-card .company-name {
    color: rgb(var(--info-rgb))
}

#needs-identified .card.custom-card .avatar {
    background-color: rgb(var(--info-rgb))
}

#negotiation .card.custom-card {
    border-inline-start: .4rem solid rgba(var(--danger-rgb), .4) !important
}

#negotiation .card.custom-card .company-name {
    color: rgb(var(--danger-rgb))
}

#negotiation .card.custom-card .avatar {
    background-color: rgb(var(--danger-rgb))
}

#deal-finalized .card.custom-card {
    border-inline-start: .4rem solid rgba(var(--secondary-rgb), .4) !important
}

#deal-finalized .card.custom-card .company-name {
    color: rgb(var(--secondary-rgb))
}

#deal-finalized .card.custom-card .avatar {
    background-color: rgb(var(--secondary-rgb))
}

#contact-initiated .card.custom-card,
#deal-finalized .card.custom-card,
#leads-discovered .card.custom-card,
#leads-qualified .card.custom-card,
#needs-identified .card.custom-card,
#negotiation .card.custom-card {
    margin-block-end: .5rem;
    touch-action: none
}

#contact-initiated .card.custom-card:last-child,
#deal-finalized .card.custom-card:last-child,
#leads-discovered .card.custom-card:last-child,
#leads-qualified .card.custom-card:last-child,
#needs-identified .card.custom-card:last-child,
#negotiation .card.custom-card:last-child {
    margin-block-end: 1.5rem
}

#contact-initiated .card.custom-card .card-body,
#deal-finalized .card.custom-card .card-body,
#leads-discovered .card.custom-card .card-body,
#leads-qualified .card.custom-card .card-body,
#needs-identified .card.custom-card .card-body,
#negotiation .card.custom-card .card-body {
    padding: 1rem
}

#contact-initiated .card.custom-card .card-body .deal-description,
#deal-finalized .card.custom-card .card-body .deal-description,
#leads-discovered .card.custom-card .card-body .deal-description,
#leads-qualified .card.custom-card .card-body .deal-description,
#needs-identified .card.custom-card .card-body .deal-description,
#negotiation .card.custom-card .card-body .deal-description {
    margin-inline-start: 2.25rem
}

.create-nft-item .filepond--root[data-style-panel-layout~=circle] {
    border-radius: .5rem;
    width: 100%
}

.create-nft-item .filepond--drop-label.filepond--drop-label label {
    padding: 2em
}

.create-nft-item .filepond--drop-label {
    color: var(--text-muted) !important
}

.create-nft-item .filepond--panel-root {
    border: .125rem dashed var(--input-border) !important
}

.create-nft-item .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
    border-radius: .3rem
}

.create-nft-item .filepond--root[data-style-panel-layout~=circle] {
    height: 15.75rem !important;
    width: 15.25rem !important
}

.create-nft-item .filepond--drop-label label {
    font-size: .75rem
}

.create-nft-item .filepond--file,
.create-nft-item .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
    height: 15.75rem;
    width: 15.25rem !important
}

.nft-details-auction-time {
    position: absolute;
    width: 400px;
    height: 1.875rem;
    background-color: rgb(var(--success-rgb));
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    top: 3.125rem;
    font-weight: 500;
    inset-inline-end: -8.4375rem;
    transform: rotate(45deg);
    z-index: 10
}

[dir=rtl] .nft-details-auction-time {
    transform: rotate(315deg)
}

.nft-wallet {
    position: relative;
    text-align: center;
    box-shadow: none !important;
    border: 1px solid var(--default-border) !important;
    border-radius: .5rem
}

.nft-wallet:hover {
    background-color: rgb(var(--light-rgb))
}

.nft-wallet.active {
    border-color: var(--primary-color) !important
}

.nft-list li:hover {
    background-color: var(--default-background);
    color: var(--primary-color)
}

.nft-tag.nft-tag-primary.active,
.nft-tag.nft-tag-primary:hover {
    color: var(--primary-color)
}

.nft-tag.nft-tag-primary.active .nft-tag-icon,
.nft-tag.nft-tag-primary:hover .nft-tag-icon {
    background-color: var(--primary01)
}

.nft-tag.nft-tag-primary .nft-tag-icon {
    color: var(--primary-color)
}

.nft-tag.nft-tag-secondary.active,
.nft-tag.nft-tag-secondary:hover {
    color: rgb(var(--secondary-rgb))
}

.nft-tag.nft-tag-secondary.active .nft-tag-icon,
.nft-tag.nft-tag-secondary:hover .nft-tag-icon {
    background-color: rgba(var(--secondary-rgb), .1)
}

.nft-tag.nft-tag-secondary .nft-tag-icon {
    color: rgb(var(--secondary-rgb))
}

.nft-tag.nft-tag-warning.active,
.nft-tag.nft-tag-warning:hover {
    color: rgb(var(--warning-rgb))
}

.nft-tag.nft-tag-warning.active .nft-tag-icon,
.nft-tag.nft-tag-warning:hover .nft-tag-icon {
    background-color: rgba(var(--warning-rgb), .1)
}

.nft-tag.nft-tag-warning .nft-tag-icon {
    color: rgb(var(--warning-rgb))
}

.nft-tag.nft-tag-info.active,
.nft-tag.nft-tag-info:hover {
    color: rgb(var(--info-rgb))
}

.nft-tag.nft-tag-info.active .nft-tag-icon,
.nft-tag.nft-tag-info:hover .nft-tag-icon {
    background-color: rgba(var(--info-rgb), .1)
}

.nft-tag.nft-tag-info .nft-tag-icon {
    color: rgb(var(--info-rgb))
}

.nft-tag.nft-tag-success.active,
.nft-tag.nft-tag-success:hover {
    color: rgb(var(--success-rgb))
}

.nft-tag.nft-tag-success.active .nft-tag-icon,
.nft-tag.nft-tag-success:hover .nft-tag-icon {
    background-color: rgba(var(--success-rgb), .1)
}

.nft-tag.nft-tag-success .nft-tag-icon {
    color: rgb(var(--success-rgb))
}

.nft-tag.nft-tag-danger.active,
.nft-tag.nft-tag-danger:hover {
    color: rgb(var(--danger-rgb))
}

.nft-tag.nft-tag-danger.active .nft-tag-icon,
.nft-tag.nft-tag-danger:hover .nft-tag-icon {
    background-color: rgba(var(--danger-rgb), .1)
}

.nft-tag.nft-tag-danger .nft-tag-icon {
    color: rgb(var(--danger-rgb))
}

.nft-tag.nft-tag-dark.active,
.nft-tag.nft-tag-dark:hover {
    color: rgb(var(--dark-rgb))
}

.nft-tag.nft-tag-dark.active .nft-tag-icon,
.nft-tag.nft-tag-dark:hover .nft-tag-icon {
    background-color: rgba(var(--dark-rgb), .1)
}

.nft-tag.nft-tag-dark .nft-tag-icon {
    color: rgb(var(--dark-rgb))
}

.nft-tag .nft-tag-text {
    font-weight: 600;
    display: inline-block;
    padding: 0 1.25rem 0 .5rem
}

.nft-tag .nft-tag-icon {
    display: inline-block;
    padding: .75rem 1rem;
    border-radius: 50%;
    background-color: rgb(var(--light-rgb))
}

.nft-tag {
    position: relative;
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--custom-white);
    border-radius: 50rem;
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.nft-timer-container {
    position: absolute;
    inset-inline-start: 0;
    top: 5%;
    display: inline-flex;
    align-items: center;
    padding: .2rem .7rem;
    border-start-start-radius: 0;
    border-start-end-radius: 50rem;
    border-end-end-radius: 50rem;
    border-end-start-radius: 0;
    color: #fff;
    font-weight: 500;
    background: rgba(255, 255, 255, .1)
}

.nft-btn {
    position: absolute;
    inset-inline-end: 5%;
    bottom: 5%;
    height: 2rem;
    width: 2rem;
    padding: 5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .1);
    color: #fff;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease .3s;
    z-index: 1
}

.nft-btn:hover {
    background-color: var(--custom-white);
    color: var(--primary-color)
}

.nft-img {
    border-radius: .5rem;
    max-height: 10rem;
    width: 100%
}

[data-theme-mode=dark] .qr-image {
    filter: invert(1)
}

#btc-currency-chart,
#dash-currency-chart,
#eos-currency-chart,
#eth-currency-chart,
#glm-currency-chart,
#ltc-currency-chart,
#monero-currency-chart,
#xrs-currency-chart {
    position: absolute;
    inset-block-end: 0
}

.currency-exchange-card {
    position: relative;
    background-color: var(--primary-color) !important;
    box-shadow: none;
    min-height: 21.875rem;
    z-index: 10
}

.currency-exchange-card:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    opacity: .1
}

.currency-exchange-card .currency-exchange-area {
    background-color: rgba(var(--dark-rgb), .1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px)
}

.currency-exchange-card .currency-exchange-area .form-control {
    line-height: 1.96
}

#loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--custom-white);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999
}

@media (min-width:992px) {
    body {
        padding-right: 0 !important;
        overflow: auto !important
    }
}

.kanban-board {
    width: 370px
}

[class*=" ri-"],
[class^=ri-] {
    display: inline-flex
}

#create-task .choices__list--dropdown,
#create-task .choices__list[aria-expanded] {
    border: 0 !important
}

[data-theme-mode=dark] .bg-dark .h1,
[data-theme-mode=dark] .bg-dark .h2,
[data-theme-mode=dark] .bg-dark .h3,
[data-theme-mode=dark] .bg-dark .h4,
[data-theme-mode=dark] .bg-dark .h5,
[data-theme-mode=dark] .bg-dark .h6,
[data-theme-mode=dark] .bg-dark h1,
[data-theme-mode=dark] .bg-dark h2,
[data-theme-mode=dark] .bg-dark h3,
[data-theme-mode=dark] .bg-dark h4,
[data-theme-mode=dark] .bg-dark h5,
[data-theme-mode=dark] .bg-dark h6,
[data-theme-mode=dark] .card-bg-dark .h1,
[data-theme-mode=dark] .card-bg-dark .h2,
[data-theme-mode=dark] .card-bg-dark .h3,
[data-theme-mode=dark] .card-bg-dark .h4,
[data-theme-mode=dark] .card-bg-dark .h5,
[data-theme-mode=dark] .card-bg-dark .h6,
[data-theme-mode=dark] .card-bg-dark h1,
[data-theme-mode=dark] .card-bg-dark h2,
[data-theme-mode=dark] .card-bg-dark h3,
[data-theme-mode=dark] .card-bg-dark h4,
[data-theme-mode=dark] .card-bg-dark h5,
[data-theme-mode=dark] .card-bg-dark h6 {
    color: var(--custom-white) !important
}

[data-theme-mode=dark] .bg-dark .text-fixed-white {
    color: #fff !important
}

[data-theme-mode=dark] .bg-dark.card.custom-card,
[data-theme-mode=dark] .card-bg-dark.card.custom-card {
    color: var(--custom-white) !important
}

[data-theme-mode=dark] .bg-dark.card.custom-card .card-body,
[data-theme-mode=dark] .bg-dark.card.custom-card .card-footer,
[data-theme-mode=dark] .card-bg-dark.card.custom-card .card-body,
[data-theme-mode=dark] .card-bg-dark.card.custom-card .card-footer {
    color: var(--custom-white) !important
}

[data-theme-mode=dark] .bg-light,
[data-theme-mode=dark] .card-bg-light {
    color: var(--custom-black) !important
}

[data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
    filter: invert(1)
}

[data-theme-mode=dark] #circle-custom .apexcharts-legend.apx-legend-position-left {
    background-color: var(--custom-white)
}

[data-theme-mode=dark] .navbar-nav .nav-link.active,
[data-theme-mode=dark] .navbar-nav .nav-link.show {
    color: #fff
}

.d-inline-table {
    display: inline-table
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--default-text-color)
}

.table>:not(caption)>*>* {
    --bs-body-bg: var(--custom-white);
    --bs-table-bg: var(--custom-white);
    color: var(--default-text-color)
}

.card.custom-card {
    color: var(--default-text-color) !important
}

[data-theme-mode=light][data-default-header-styles=transparent] .page-header-breadcrumb h4,
[data-theme-mode=light][data-header-styles=transparent] .page-header-breadcrumb h4 {
    color: var(--default-text-color) !important
}

[data-theme-mode=light][data-default-header-styles=transparent] .app-header .main-header-container .form-control,
[data-theme-mode=light][data-header-styles=transparent] .app-header .main-header-container .form-control {
    background: rgba(0, 0, 0, .05) !important;
    color: var(--default-text-color);
    box-shadow: none
}

[data-theme-mode=light] .page-header-breadcrumb h4 {
    color: #fff !important
}

[data-header-styles=color] .header-link.dropdown-toggle .user-name,
[data-header-styles=dark] .header-link.dropdown-toggle .user-name,
[data-header-styles=gradient] .header-link.dropdown-toggle .user-name {
    color: #fff !important
}

[data-default-header-styles=color] .page-header-breadcrumb,
[data-default-header-styles=dark] .page-header-breadcrumb,
[data-default-header-styles=gradient] .page-header-breadcrumb,
[data-default-header-styles=light] .page-header-breadcrumb,
[data-default-header-styles=transparent] .page-header-breadcrumb {
    margin: 0 0 -32px 0
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
    background-color: transparent !important
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
    background-color: transparent !important
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    color: var(--text-muted) !important;
    font-weight: 600
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: var(--text-muted) !important;
    font-weight: 600
}

.dropdown-item-text {
    color: var(--default-text-color) !important
}

.blockquote-footer {
    color: var(--default-text-color) !important
}

hr {
    border-color: var(--default-border);
    opacity: 1
}

[dir=rtl] .dropdown-menu {
    --bs-position: start
}

[dir=rtl] .dropdown-menu-end {
    --bs-position: end
}

[dir=rtl] .transform-arrow {
    transform: rotate(180deg)
}

[dir=rtl] .reviews-container .card:before {
    transform: rotate(180deg)
}

[dir=rtl] .bi-skip-backward::before {
    content: "\f55e"
}

[dir=rtl] .bi-skip-forward::before {
    content: "\f552"
}

.main-card-icon {
    inset-block-end: .75rem;
    inset-inline-end: 1rem;
    position: absolute;
    padding: .75rem;
    border-radius: .5rem
}

.timeline-main {
    padding-inline-start: 1.77rem
}

.latest-timeline ul.timeline-main {
    position: relative
}

.latest-timeline ul.timeline-main::before {
    content: "";
    border-inline-start: .125rem dotted var(--default-border);
    position: absolute;
    inset-inline-start: .25rem;
    width: 1px;
    height: 84%;
    z-index: 0;
    inset-block-start: .75rem
}

.latest-timeline .timeline-main li.activity {
    padding: 0;
    position: relative;
    margin-block-end: .5rem
}

.featured_icon1 {
    width: .438rem;
    height: .438rem;
    line-height: 3.75rem;
    margin-inline-start: -1.625rem !important;
    color: #000;
    font-size: .625rem;
    background-color: var(--primary-color);
    position: relative;
    border-radius: 1.125rem;
    inset-block-start: .75rem
}

.featured_icon1.featured-danger {
    background-color: rgb(var(--danger-rgb))
}

.featured_icon1.featured-success {
    background-color: rgb(var(--success-rgb))
}

.tansaction-icon {
    padding: .375rem;
    border-radius: .25rem;
    margin-inline-end: .75rem
}

.tansaction-icon svg {
    width: 1.25rem;
    height: 1.25rem
}

.recent-transactions-card .list-group-item {
    padding: .75rem 1.25rem
}

.nav.panel-tabs-task a.active {
    border-radius: .5rem;
    color: rgb(var(--info-rgb)) !important;
    background-color: rgba(var(--info-rgb), .1)
}

.nav.panel-tabs-task a {
    background-color: var(--custom-white);
    padding: .4rem .5rem !important;
    font-size: .711rem;
    transition: all ease .3s
}

.billing-invoice-details {
    width: 11rem
}

#visitors-countries {
    height: 23.9rem
}

#visitors-countries #jvm-markers-labels-group {
    display: none
}

#visitors-countries circle:nth-child(1) {
    fill: var(--primary-color);
    stroke: var(--primary03);
    stroke-width: 30
}

#visitors-countries circle:nth-child(2) {
    fill: rgb(var(--secondary-rgb));
    stroke: rgba(var(--secondary-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(3) {
    fill: rgb(var(--danger-rgb));
    stroke: rgba(var(--danger-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(4) {
    fill: rgb(var(--info-rgb));
    stroke: rgba(var(--info-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(5) {
    fill: rgb(var(--orange-rgb));
    stroke: rgba(var(--orange-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(6) {
    fill: rgb(var(--warning-rgb));
    stroke: rgba(var(--warning-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(7) {
    fill: rgb(var(--success-rgb));
    stroke: rgba(var(--success-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(8) {
    fill: rgb(var(--pink-rgb));
    stroke: rgba(var(--pink-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(9) {
    fill: rgb(var(--dark-rgb));
    stroke: rgba(var(--dark-rgb), .2);
    stroke-width: 30
}

#visitors-countries circle:nth-child(10) {
    fill: #4d5ddb;
    stroke: rgba(77, 93, 219, .2);
    stroke-width: 30
}

.panel-tabs-billing li a.active {
    background-color: rgba(var(--success-rgb), .1);
    font-weight: 500;
    color: rgb(var(--success-rgb))
}

.panel-tabs-billing li a {
    padding: .25rem .5rem;
    border-radius: .5rem;
    font-size: .75rem
}

#sale-value .apexcharts-radialbar-hollow {
    fill: var(--custom-white)
}

#sale-value #apexcharts-radialbarTrack-0 {
    stroke: rgb(var(--light-rgb))
}

.latest-timeline {
    max-height: 24rem
}

#sale-value .apexcharts-datalabels-group text {
    fill: var(--primary-color) !important
}

[dir=rtl] #salesOverview .apexcharts-yaxis-texts-g {
    transform: translate(-1rem, 0)
}

[dir=rtl] #salesOverview .apexcharts-yaxis-title {
    transform: translate(-3rem, 0)
}

@media screen and (max-width:400px) {
    #salesOverview .apexcharts-toolbar {
        display: none
    }
}

@media (max-width:1399.99px) {
    .sales-visitors-countries {
        border-block-end: 1px dashed var(--default-border)
    }
}

@media (min-width:1400px) {
    .sales-visitors-countries {
        border-inline-end: 1px dashed var(--default-border)
    }
}

#crm-main .apexcharts-canvas,
#crm-main .apexcharts-svg {
    width: auto !important
}

.ecommerce-icon svg {
    width: 1.75rem;
    height: 1.75rem;
    padding: .35rem;
    border-radius: .5rem
}

.ecommerce-icon svg.primary {
    background-color: var(--primary-color)
}

.ecommerce-icon svg.secondary {
    background-color: rgb(var(--secondary-rgb))
}

.ecommerce-icon svg.success {
    background-color: rgb(var(--success-rgb))
}

.ecommerce-icon svg.warning {
    background-color: rgb(var(--warning-rgb))
}

.ecommerce-sale-image {
    position: relative
}

.ecommerce-sale-image img {
    height: 16.625rem
}

.ecommerce-sale-image::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6)
}

.ecommerce-bankoffer-details {
    font-size: .75rem;
    width: 70%;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, .25);
    opacity: .8;
    padding: .625rem;
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem)
}

.ecommerce-sale-days {
    position: absolute;
    bottom: 1.25rem;
    font-size: .75rem;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, .25);
    opacity: .8;
    padding: .25rem .625rem;
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem)
}

.ecommerce-sales-calendar {
    position: absolute;
    inset-inline-end: 1.875rem;
    background-color: rgba(255, 255, 255, .25);
    padding: .5rem .625rem;
    border-radius: .3rem;
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem)
}

.active-tab tr:hover .orders-arrow i {
    color: var(--primary-color);
    font-weight: 600;
    transition: cubic-bezier(.075, .82, .165, 1)
}

[dir=rtl] .orders-arrow i::before {
    content: "\ea64"
}

@media (max-width:400px) {
    [dir=rtl] #earnings .apexcharts-inner .apexcharts-xaxis {
        transform: translate(0, 1.25rem)
    }
}

.earning {
    position: relative
}

.earning:before {
    content: "";
    position: absolute;
    top: .438rem;
    inset-inline-start: -.813rem;
    bottom: .313rem;
    width: .5rem;
    height: .5rem;
    border-radius: 3.125rem
}

.earning.first-half:before {
    background-color: var(--primary03)
}

.earning.top-gross:before {
    background-color: var(--primary-color)
}

.earning.second-half:before {
    background-color: #e4e7ed
}

.top-country-name {
    width: 4.5rem
}

.crypto-list .list-group-item {
    padding: .8rem 1.25rem
}

@media (min-width:576px) {
    .crypto-buy-sell-input {
        width: 60% !important
    }
}

#buy-crypto .choices__inner,
#sell-crypto .choices__inner {
    min-width: 5rem !important
}

#buy-crypto .choices__list--dropdown .choices__item--selectable::after,
#buy-crypto .choices__list[aria-expanded] .choices__item--selectable::after,
#sell-crypto .choices__list--dropdown .choices__item--selectable::after,
#sell-crypto .choices__list[aria-expanded] .choices__item--selectable::after {
    display: none !important
}

#buy-crypto .choices__list--dropdown .choices__item--selectable,
#buy-crypto .choices__list[aria-expanded] .choices__item--selectable,
#sell-crypto .choices__list--dropdown .choices__item--selectable,
#sell-crypto .choices__list[aria-expanded] .choices__item--selectable {
    padding-inline-end: 0 !important
}

#bitcoin-price-graph .apexcharts-grid,
#btc-chart .apexcharts-grid,
#dash-chart .apexcharts-grid,
#dash-price-graph .apexcharts-grid,
#eth-chart .apexcharts-grid,
#etherium-price-graph .apexcharts-grid,
#iota-price-graph .apexcharts-grid,
#neo-price-graph .apexcharts-grid,
#ripple-price-graph .apexcharts-grid {
    display: none
}

.basic-subscription,
.pro-subscription {
    position: relative
}

.basic-subscription::before,
.pro-subscription::before {
    content: "";
    position: absolute;
    width: .5rem;
    height: .5rem;
    inset-inline-start: -.938rem;
    border-radius: .3rem;
    inset-block-start: .6rem
}

.basic-subscription:before {
    background-color: var(--primary-color)
}

.pro-subscription:before {
    background-color: rgb(var(--secondary-rgb))
}

#recent-jobs {
    max-height: 22.5rem
}

#candidates-chart .apexcharts-datalabels-group text {
    fill: var(--default-text-color) !important
}

.recent-recruiter {
    max-width: 7rem
}

[dir=rtl] #nft-collections-slide .swiper-slide .card {
    direction: rtl
}

#nft-collections-slide .card.custom-card .card-footer {
    padding: 1.05rem 1.25rem
}

.nft-table.table td,
.nft-table.table th {
    padding: .83rem
}

.nft-table2.table td,
.nft-table2.table th {
    padding: .78rem
}

.featured-nft {
    width: 100%;
    height: 13rem;
    border-radius: .5rem
}

.featured-nft img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: .5rem
}

.nft-auction-time {
    position: absolute;
    top: 3.5rem;
    inset-inline-end: 1rem;
    padding: .25rem .5rem;
    background-color: rgba(255, 255, 255, .15);
    border-radius: .3rem;
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem)
}

.nft-like-section {
    position: absolute;
    inset-block-start: 1rem
}

.nft-like-badge {
    background-color: rgba(255, 255, 255, .15);
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem)
}

.nft-collector-progress {
    width: 3.125rem
}

.nft-featuredcollect-image {
    height: 8.813rem !important;
    border-radius: .5rem;
    position: relative
}

.nft-featuredcollect-image::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1)
}

#top-collector {
    max-height: 28.375rem
}

#nft-balance-chart .apexcharts-grid {
    display: none
}

#analytics-bouncerate {
    position: absolute;
    inset-block-end: 0
}

#sessions .apexcharts-datalabels-group text {
    fill: var(--default-text-color) !important
}

#analytics-followers circle,
#analytics-views circle {
    fill: var(--custom-white)
}

#analytics-followers #apexcharts-radialbarTrack-0,
#analytics-views #apexcharts-radialbarTrack-0 {
    stroke: rgb(var(--light-rgb))
}

#analytics-views .apexcharts-datalabels-group text {
    fill: rgb(var(--warning-rgb))
}

#analytics-followers .apexcharts-datalabels-group text {
    fill: rgb(var(--secondary-rgb))
}

.analytics-visitors-countries li {
    margin-block-end: 1.385rem
}

.analytics-visitors-countries li:last-child {
    margin-block-end: 0
}

.analytics-visitors-countries li .progress {
    width: 6.25rem
}

.custom-card.upgrade-card {
    height: 11.625rem;
    position: relative;
    overflow: hidden;
    background-color: var(--primary-color) !important;
    background-repeat: no-repeat
}

.custom-card.upgrade-card:before {
    content: "";
    position: absolute;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    inset-inline-start: 0;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    opacity: .15
}

.custom-card.upgrade-card .avatar {
    position: absolute;
    inset-block-start: -.6875rem;
    inset-inline-end: 1.5rem
}

.custom-card.upgrade-card .upgrade-card-content {
    position: absolute;
    inset-block-end: 1rem
}

#analytics-users .apexcharts-grid {
    display: none
}

.team-members-card li {
    margin-block-end: 1.5rem
}

.team-members-card li:last-child {
    margin-block-end: 0
}

.team-members-card #user1 .apexcharts-grid,
.team-members-card #user2 .apexcharts-grid,
.team-members-card #user3 .apexcharts-grid,
.team-members-card #user4 .apexcharts-grid,
.team-members-card #user5 .apexcharts-grid {
    display: none
}

.daily-task-card,
.projects-maintask-card {
    margin-block-end: 0
}

.daily-task-card li,
.projects-maintask-card li {
    margin-block-end: 1.25rem
}

.daily-task-card li .card,
.daily-task-card li:last-child,
.projects-maintask-card li .card,
.projects-maintask-card li:last-child {
    margin-block-end: 0
}

.project-transactions-card {
    margin-block-end: 0
}

.project-transactions-card li {
    margin-block-end: 1.45rem
}

.project-transactions-card li:last-child {
    margin-block-end: 0
}

.projects-tracking-card {
    background-color: #fff;
    position: relative
}

.projects-tracking-card .card-body {
    z-index: 1
}

.projects-tracking-card img {
    width: 15.5rem;
    height: 9.5rem
}

.shape-1,
.shape-10,
.shape-11,
.shape-12,
.shape-13,
.shape-14,
.shape-15,
.shape-16,
.shape-2,
.shape-3,
.shape-4,
.shape-5,
.shape-6,
.shape-7,
.shape-8,
.shape-9 {
    position: absolute;
    animation: animate 2s linear infinite wase
}

.shape-1 i,
.shape-10 i,
.shape-11 i,
.shape-12 i,
.shape-13 i,
.shape-14 i,
.shape-15 i,
.shape-16 i,
.shape-2 i,
.shape-3 i,
.shape-4 i,
.shape-5 i,
.shape-6 i,
.shape-7 i,
.shape-8 i,
.shape-9 i {
    opacity: .2
}

@keyframes animate {
    0% {
        -webkit-transform: translateY(0) rotate(0);
        opacity: 1
    }
    100% {
        -webkit-transform: translateY(-90px) rotate(360deg);
        opacity: 0
    }
}

.shape-1 {
    inset-inline-start: 20%;
    inset-block-start: 20%;
    animation: animate 4s linear infinite
}

.shape-2 {
    inset-inline-start: 5%;
    inset-block-start: 42%;
    animation: animate 5s linear infinite
}

.shape-3 {
    inset-inline-end: 15%;
    inset-block-end: 55%;
    animation: animate 7s linear infinite
}

.shape-4 {
    inset-inline-start: 55%;
    inset-block-start: 25%;
    animation: animate 10s linear infinite
}

.shape-5 {
    inset-inline-start: 50%;
    inset-block-end: 30%;
    animation: animate 8s linear infinite
}

.shape-6 {
    inset-inline-end: 10%;
    inset-block-end: 10%;
    animation: animate 4s linear infinite
}

.shape-7 {
    inset-inline-start: 20%;
    inset-block-end: 17%;
    animation: animate 5s linear infinite
}

.shape-8 {
    inset-inline-end: 20%;
    inset-block-end: 17%;
    animation: animate 7s linear infinite
}

.shape-16 {
    inset-inline-start: 20%;
    inset-block-start: 5%;
    animation: animate 7s linear infinite
}

.shape-15 {
    inset-inline-start: 5%;
    inset-block-start: 42%;
    animation: animate 11s linear infinite
}

.shape-14 {
    inset-inline-end: 15%;
    inset-block-end: 55%;
    animation: animate 3s linear infinite
}

.shape-13 {
    inset-inline-start: 5%;
    inset-block-start: 25%;
    animation: animate 3s linear infinite
}

.shape-12 {
    inset-inline-start: 50%;
    inset-block-end: 3%;
    animation: animate 9s linear infinite
}

.shape-11 {
    inset-inline-end: 10%;
    inset-block-end: 10%;
    animation: animate 5s linear infinite
}

.shape-10 {
    inset-inline-start: 22%;
    inset-block-end: 9%;
    animation: animate 3s linear infinite
}

.shape-9 {
    inset-inline-end: 2%;
    inset-block-end: 7%;
    animation: animate 6s linear infinite
}

[dir=rtl] #projectAnalysis .apexcharts-canvas .apexcharts-yaxis .apexcharts-yaxis-texts-g {
    transform: translate(.5rem, 0)
}

#crm-conversion-ratio .apexcharts-grid line,
#crm-conversion-ratio .apexcharts-grid-borders line,
#crm-conversion-ratio .apexcharts-xaxis line,
#crm-total-customers .apexcharts-grid line,
#crm-total-customers .apexcharts-grid-borders line,
#crm-total-customers .apexcharts-xaxis line,
#crm-total-deals .apexcharts-grid line,
#crm-total-deals .apexcharts-grid-borders line,
#crm-total-deals .apexcharts-xaxis line,
#crm-total-revenue .apexcharts-grid line,
#crm-total-revenue .apexcharts-grid-borders line,
#crm-total-revenue .apexcharts-xaxis line {
    stroke: transparent
}

.crm-recent-activity li {
    padding-block-end: 1.3rem
}

.crm-recent-activity li:last-child {
    padding-block-end: 0
}

.crm-recent-activity .crm-timeline-content {
    width: 15.5rem
}

.crm-recent-activity .crm-recent-activity-content {
    position: relative
}

.crm-recent-activity .crm-recent-activity-content::before {
    position: absolute;
    content: "";
    width: 1px;
    background-color: transparent;
    inset-block-start: 1.4375rem;
    inset-inline-start: .5625rem
}

.crm-recent-activity .crm-recent-activity-content:nth-child(1)::before {
    border-inline-end: 1px dashed var(--primary02);
    height: 60%
}

.crm-recent-activity .crm-recent-activity-content:nth-child(2)::before {
    border-inline-end: 1px dashed rgba(var(--secondary-rgb), .2);
    height: 68%
}

.crm-recent-activity .crm-recent-activity-content:nth-child(3)::before {
    border-inline-end: 1px dashed rgba(var(--success-rgb), .2);
    height: 42%
}

.crm-recent-activity .crm-recent-activity-content:nth-child(4)::before {
    border-inline-end: 1px dashed rgba(var(--pink-rgb), .2);
    height: 60%
}

.crm-recent-activity .crm-recent-activity-content:nth-child(5)::before {
    border-inline-end: 1px dashed rgba(var(--warning-rgb), .2);
    height: 47%
}

.crm-recent-activity .crm-recent-activity-content:nth-child(6)::before {
    border-inline-end: 1px dashed rgba(var(--info-rgb), .2);
    height: 60%
}

.crm-recent-activity .crm-recent-activity-content:nth-child(7)::before {
    border-inline-end: 1px dashed rgba(var(--dark-rgb), .2);
    height: 50%
}

.crm-recent-activity .crm-recent-activity-content:last-child::before {
    display: none
}

.card.custom-card.crm-highlight-card {
    position: relative;
    overflow: hidden;
    background-color: var(--primary-color) !important;
    box-shadow: none
}

.card.custom-card.crm-highlight-card::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    opacity: .2
}

.card.custom-card.crm-highlight-card .card-body {
    z-index: 10
}

#crm-leadsbysource .apexcharts-datalabels-group text {
    fill: var(--default-text-color) !important
}

.crm-lead-legend {
    position: relative
}

.crm-lead-legend::before {
    position: absolute;
    content: "";
    width: .375rem;
    height: .375rem;
    border-radius: .5rem;
    inset-block-start: .375rem;
    inset-inline-start: -.625rem
}

.crm-lead-legend.mobile:before {
    background-color: var(--primary-color)
}

.crm-lead-legend.desktop:before {
    background-color: rgb(var(--secondary-rgb))
}

.crm-lead-legend.laptop:before {
    background-color: rgb(var(--warning-rgb))
}

.crm-lead-legend.tablet:before {
    background-color: rgb(var(--success-rgb))
}

.crm-leadsinprogress {
    margin-block-end: 0
}

.crm-leadsinprogress li {
    margin-block-end: 1.3rem
}

.crm-leadsinprogress li:last-child {
    margin-block-end: 0
}

.leads-source-chart canvas {
    position: relative
}

.leads-source-chart .lead-source-value {
    position: absolute;
    text-align: center
}

#crm-main circle {
    fill: transparent
}

#crm-main .apexcharts-datalabels-group text {
    fill: rgba(255, 255, 255, .9)
}

#crm-main #apexcharts-radialbarTrack-0 {
    stroke: rgba(0, 0, 0, .2)
}

.crm-main-content {
    position: absolute;
    bottom: 20px
}

.crm-top-deals li {
    margin-block-end: .9rem
}

.crm-top-deals li:last-child {
    margin-block-end: 0
}

#crm-revenue-analytics .apexcharts-title-text {
    transform: translateY(.625rem)
}

@media (max-width:575.98px) {
    #crm-revenue-analytics .apexcharts-canvas .apexcharts-toolbar {
        inset-block-start: .75rem !important;
        inset-inline-end: 0 !important
    }
    #crm-revenue-analytics .apexcharts-canvas .apexcharts-title-text {
        font-size: .74rem
    }
}

#leads-source {
    height: 18rem !important;
    width: auto !important
}

.crm-deals-status li {
    position: relative;
    margin-block-end: 1rem;
    margin-inline-start: 1rem
}

.crm-deals-status li:last-child {
    margin-block-end: 0
}

.crm-deals-status li:before {
    content: "";
    position: absolute;
    width: .5rem;
    height: .5rem;
    inset-block-start: .375rem;
    inset-inline-start: -1rem;
    border-radius: 50%
}

.crm-deals-status li.primary:before {
    background-color: var(--primary-color)
}

.crm-deals-status li.info:before {
    background-color: rgb(var(--info-rgb))
}

.crm-deals-status li.warning:before {
    background-color: rgb(var(--warning-rgb))
}

.crm-deals-status li.success:before {
    background-color: rgb(var(--success-rgb))
}

[dir=rtl] #earnings .apexcharts-yaxis-title {
    transform: translateX(-5.3125rem)
}

[dir=rtl] #bar-basic .apexcharts-yaxis.apexcharts-xaxis-inversed .apexcharts-text.apexcharts-yaxis-label.apexcharts-yaxis-label {
    transform: translateX(-4.375rem)
}

.crm-deals-forecast-revenue li {
    margin-block-end: 1.25rem
}

.crm-deals-forecast-revenue li:last-child {
    margin-block-end: 0
}

#top-country-deals {
    height: 14.5rem;
    display: flex;
    align-items: center;
    justify-content: center
}

#top-country-deals #jvm-markers-labels-group {
    display: none
}

#top-country-deals #jvm-regions-group path {
    fill: rgb(var(--light-rgb)) !important
}

#top-country-deals circle {
    right: 5
}

#top-country-deals circle:nth-child(1) {
    fill: var(--primary-color);
    stroke: var(--primary03);
    stroke-width: 20
}

#top-country-deals circle:nth-child(2) {
    fill: rgb(var(--secondary-rgb));
    stroke: rgba(var(--secondary-rgb), .2);
    stroke-width: 20
}

#top-country-deals circle:nth-child(3) {
    fill: rgb(var(--danger-rgb));
    stroke: rgba(var(--danger-rgb), .2);
    stroke-width: 20
}

#top-country-deals circle:nth-child(4) {
    fill: rgb(var(--info-rgb));
    stroke: rgba(var(--info-rgb), .2);
    stroke-width: 20
}

#top-country-deals circle:nth-child(5) {
    fill: rgb(var(--warning-rgb));
    stroke: rgba(var(--warning-rgb), .2);
    stroke-width: 20
}

#top-country-deals circle:nth-child(6) {
    fill: rgb(var(--success-rgb));
    stroke: rgba(var(--success-rgb), .2);
    stroke-width: 20
}

.card.custom-card.hrm-main-card {
    border-top-width: 3px;
    border-top-style: solid
}

.card.custom-card.hrm-main-card.primary {
    border-color: var(--primary03)
}

.card.custom-card.hrm-main-card.secondary {
    border-color: rgba(var(--secondary-rgb), .3)
}

.card.custom-card.hrm-main-card.warning {
    border-color: rgba(var(--warning-rgb), .3)
}

.card.custom-card.hrm-main-card.danger {
    border-color: rgba(var(--danger-rgb), .3)
}

.timeline-widget {
    position: relative
}

.timeline-widget:before {
    position: absolute;
    content: "";
    width: .125rem;
    height: 100%;
    background-color: var(--default-border);
    inset-block-start: 0;
    inset-inline-start: 3rem
}

.timeline-widget .timeline-widget-list {
    margin-block-end: 1.5rem;
    position: relative
}

.timeline-widget .timeline-widget-list:before {
    content: "";
    position: absolute;
    width: .75rem;
    height: .75rem;
    inset-inline-start: 2.688rem;
    top: .25rem;
    border-radius: 3.125rem;
    border: .125rem solid var(--default-border);
    background-color: var(--custom-white)
}

.timeline-widget .timeline-widget-list:last-child {
    margin-block-end: 0
}

.timeline-widget .timeline-widget-list .timeline-widget-content {
    max-width: 20rem
}

#jobs-summary .apexcharts-pie text {
    fill: var(--default-text-color) !important
}

.hrm-jobs-legend {
    position: relative
}

.hrm-jobs-legend::before {
    position: absolute;
    content: "";
    width: .375rem;
    height: .375rem;
    border-radius: .5rem;
    inset-block-start: .375rem;
    inset-inline-start: -.625rem
}

.hrm-jobs-legend.published:before {
    background-color: var(--primary-color)
}

.hrm-jobs-legend.private:before {
    background-color: var(--primary05)
}

.hrm-jobs-legend.closed:before {
    background-color: #f3f6f8
}

.hrm-jobs-legend.onhold:before {
    background-color: #232323
}

@media (max-width:404px) {
    [dir=rtl] #performanceReport .apexcharts-xaxis {
        transform: translate(0, 20px)
    }
}

@media (max-width:525px) {
    [dir=rtl] #subscriptionOverview .apexcharts-xaxis {
        transform: translate(0, 40px)
    }
}

#portfolio-value .apexcharts-grid line,
#portfolio-value .apexcharts-grid-borders line,
#returns-rate .apexcharts-grid line,
#returns-rate .apexcharts-grid-borders line,
#total-invested .apexcharts-grid line,
#total-invested .apexcharts-grid-borders line,
#total-investments .apexcharts-grid line,
#total-investments .apexcharts-grid-borders line {
    stroke: transparent
}

.my-stocks-ul li {
    padding: .87rem 1rem;
    border-bottom: 1px dashed var(--default-border)
}

.my-stocks-ul li:last-child {
    border-bottom: 0
}

.category-link {
    padding: 1.25rem;
    border-radius: .5rem;
    border: 1px solid var(--default-border);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-muted);
    transition: all ease .3s
}

.category-link.primary .category-svg {
    fill: var(--primary-color)
}

.category-link.secondary .category-svg {
    fill: rgb(var(--secondary-rgb))
}

.category-link.warning .category-svg {
    fill: rgb(var(--warning-rgb))
}

.category-link.success .category-svg {
    fill: rgb(var(--success-rgb))
}

.category-link .category-svg {
    height: 1.875rem;
    width: 1.875rem;
    margin-block-end: 1rem;
    transition: all ease .3s
}

.category-link.primary.active,
.category-link.primary:not(.active):hover {
    color: var(--primary-color);
    background-color: var(--primary01);
    border-color: var(--primary02)
}

.category-link.secondary.active,
.category-link.secondary:not(.active):hover {
    color: rgb(var(--secondary-rgb));
    background-color: rgba(var(--secondary-rgb), .1);
    border-color: rgba(var(--secondary-rgb), .2)
}

.category-link.warning.active,
.category-link.warning:not(.active):hover {
    color: rgb(var(--warning-rgb));
    background-color: rgba(var(--warning-rgb), .1);
    border-color: rgba(var(--warning-rgb), .2)
}

.category-link.success.active,
.category-link.success:not(.active):hover {
    color: rgb(var(--success-rgb));
    background-color: rgba(var(--success-rgb), .1);
    border-color: rgba(var(--success-rgb), .2)
}

.course-status-progress {
    width: 3.125rem
}

.courses-instructors li {
    margin-block-end: 1rem
}

.courses-instructors li:last-child {
    margin-block-end: 0
}

[dir=rtl] #courses-earnings .apexcharts-yaxis {
    transform: translate(1.625rem, 0)
}

@media (max-width:420px) {
    [dir=rtl] #courses-earnings .apexcharts-xaxis {
        transform: translate(-10px, 20px)
    }
}

.personal-upcoming-events li {
    margin-block-end: 1rem
}

.personal-upcoming-events li:last-child {
    margin-block-end: 0
}

.personal-messages-list li {
    margin-block-end: 1rem
}

.personal-messages-list li:last-child {
    margin-block-end: 0
}

.personal-messages-list li .message {
    max-width: 15rem
}

.circle-progress {
    position: relative;
    z-index: 1
}

.circle-progress::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    top: 0;
    inset-inline-end: 0;
    background-image: linear-gradient(102deg, transparent 41%, var(--primary05) 0), linear-gradient(90deg, #f3f3f3 50%, transparent 50%);
    z-index: -1
}

.personal-goals-list li {
    margin-block-end: 1rem
}

.personal-goals-list li:last-child {
    margin-block-end: 0
}

.personal-favourite-contacts li {
    margin-block-end: 1rem
}

.personal-favourite-contacts li:last-child {
    margin-block-end: 0
}

#sleepTrack .apexcharts-grid,
#waterTrack .apexcharts-grid {
    display: none
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--custom-white)
}

.error-page {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center
}

.error-text {
    font-size: 8.25rem;
    font-weight: 500;
    line-height: 1
}

@media (max-width:575.98px) {
    .error-text {
        font-size: 3rem
    }
}

.app-header {
    max-width: 100%;
    height: 3.75rem;
    z-index: 100;
    position: fixed;
    inset-block-start: 0;
    inset-inline: 0;
    background: var(--header-bg);
    border-block-end: 1px solid var(--header-border-color);
    transition: all .1s ease
}

.app-header #mainHeaderProfile {
    color: var(--header-prime-color)
}

@media (min-width:992px) {
    .app-header {
        padding-inline-start: 15rem
    }
}

.header-icon-badge {
    position: absolute;
    padding: .15rem .25rem;
    inset-block-start: .75rem;
    inset-inline-end: .45rem;
    font-size: .625rem
}

.related-app {
    text-align: center;
    border-radius: .5rem;
    border: 1px solid var(--default-border)
}

.related-app:hover {
    background-color: rgb(var(--light-rgb))
}

.header-profile-dropdown {
    min-width: 11rem
}

.main-header-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 100%
}

.main-header-container .header-content-left,
.main-header-container .header-content-right {
    display: flex;
    align-items: stretch
}

.main-header-container .header-element {
    display: flex;
    align-items: stretch
}

.main-header-container .header-link {
    display: flex;
    align-items: center;
    padding: 1rem .65rem
}

.main-header-container .header-link.show .header-link-icon,
.main-header-container .header-link:hover .header-link-icon {
    fill: var(--header-prime-color);
    color: var(--header-prime-color)
}

.main-header-container .header-link-icon {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1.25rem;
    color: var(--header-prime-color);
    fill: var(--header-prime-color);
    transition: all ease 50ms;
    position: relative;
    padding: .25rem;
    border-radius: 50%
}

.main-header-container .header-link-icon:hover {
    background-color: rgb(var(--light-rgb))
}

.main-header-container .dropdown-toggle {
    text-decoration: none
}

.main-header-container .dropdown-toggle::after {
    content: none
}

.main-header-container .main-profile-user .dropdown-menu {
    width: 13rem
}

.main-header-container .main-profile-user .dropdown-item {
    font-weight: 400;
    font-size: .813rem;
    color: var(--default-text-color);
    padding: 1.25rem 1rem;
    height: 2.375rem;
    display: flex;
    align-items: center
}

.main-header-container .main-profile-user .dropdown-item:hover {
    color: var(--primary-color)
}

.main-header-container .main-profile-user .dropdown-item:hover i {
    color: var(--primary-color);
    opacity: 1
}

.main-header-container .main-header-dropdown {
    box-shadow: 0 .25rem .625rem rgba(20, 20, 20, .1);
    inset-block-start: -3px !important
}

.main-header-container .main-header-dropdown.header-shortcuts-dropdown {
    width: 22rem
}

.main-header-container .main-header-dropdown .dropdown-item {
    padding: .65rem;
    border-block-end: 1px solid var(--default-border)
}

.main-header-container .main-header-dropdown .dropdown-item:last-child {
    border-block-end: 0
}

.main-header-container .cart-dropdown .main-header-dropdown {
    width: 22rem
}

.main-header-container .notifications-dropdown .main-header-dropdown {
    width: 22rem
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(6rem)
    }
    100% {
        opacity: 1
    }
    0% {
        opacity: 0;
        transform: translateY(6rem)
    }
}

[dir=rtl] .sidemenu-toggle .open-toggle {
    transform: rotate(180deg)
}

[dir=rtl] .app-header .dropdown-menu-end {
    --bs-position: end
}

.header-product-item {
    padding-inline-start: 0;
    display: flex
}

.header-product-item li {
    position: relative;
    list-style-type: none;
    font-size: .75rem;
    font-weight: 400;
    color: var(--text-muted);
    padding-inline-end: .938rem
}

.header-product-item li:nth-child(2):before,
.header-product-item li:nth-child(3):before,
.header-product-item li:nth-child(4):before {
    position: absolute;
    content: "";
    inset-inline-start: -.438rem;
    width: 1px;
    inset-block-start: .25rem;
    height: .625rem;
    background-color: var(--text-muted);
    opacity: .1
}

.header-cart-remove .ti {
    padding: .25rem;
    border-radius: .25rem;
    font-size: .85rem;
    margin-inline-start: .75rem;
    color: rgb(var(--danger-rgb))
}

.header-cart-remove:hover .ti {
    background-color: rgba(var(--danger-rgb), .1)
}

#searchModal .form-control {
    position: relative
}

#searchModal .input-group {
    border: 2px solid var(--primary-color);
    border-radius: .25rem
}

#searchModal .input-group i {
    color: var(--text-muted)
}

.search-tags {
    font-size: .75rem;
    color: var(--default-text-color);
    border: 1px solid var(--default-border);
    border-radius: .25rem;
    background-color: rgb(var(--light-rgb));
    padding: .125rem .55rem;
    line-height: 1.2rem;
    display: inline-flex;
    align-items: center;
    cursor: default;
    font-weight: 400;
    margin: .25rem 0
}

.search-tags .tag-addon:last-child {
    border-radius: .5rem
}

.search-tags a.tag-addon {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding: 0 .5rem;
    color: inherit;
    text-decoration: none;
    background: rgb(var(--light-rgb));
    margin-inline-end: -.4rem;
    margin-inline-start: .5rem;
    text-align: center;
    min-width: 1.5rem
}

.search-tags a.tag-addon i {
    vertical-align: middle;
    margin: 0 -.25rem;
    line-height: 1.5px;
    font-size: .813rem
}

.search-tags i {
    font-size: .813rem
}

.country-selector .header-link img {
    width: 1.75rem;
    height: 1.75rem
}

.country-selector .dropdown-menu img {
    width: 1rem;
    height: 1rem
}

#header-cart-items-scroll,
#header-notification-scroll,
#header-shortcut-scroll {
    max-height: 20rem
}

.pulse {
    display: block;
    cursor: pointer;
    animation: pulse-secondary 2s infinite
}

.pulse.pulse-secondary {
    box-shadow: 0 0 0 rgba(var(--secondary-rgb), .4)
}

@keyframes pulse-secondary {
    0% {
        box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), .4)
    }
    70% {
        box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0)
    }
    100% {
        box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0)
    }
}

[data-theme-mode=light] .layout-setting .dark-layout {
    display: none
}

[data-theme-mode=light] .layout-setting .light-layout {
    display: block
}

.layout-setting .dark-layout {
    display: none
}

.layout-setting .light-layout {
    display: block
}

[data-theme-mode=dark] .layout-setting .light-layout {
    display: none
}

[data-theme-mode=dark] .layout-setting .dark-layout {
    display: block
}

@media (max-width:767.98px) {
    .header-element.header-fullscreen {
        display: none
    }
}

@media (max-width:575.98px) {
    .app-header .dropdown-menu {
        width: 100%
    }
}

.animated-arrow.hor-toggle {
    text-align: center;
    width: 2rem;
    font-size: 1.2rem;
    position: relative;
    margin: .3125rem 0;
    margin-inline-end: .625rem
}

.animated-arrow {
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    z-index: 102;
    transition: all 50ms ease-in-out;
    cursor: pointer;
    padding: .3125rem;
    margin-block-start: .375rem;
    margin-block-end: 0;
    margin-inline-start: .5rem;
    margin-inline-end: 0
}

.animated-arrow.hor-toggle span {
    vertical-align: middle
}

.animated-arrow span {
    cursor: pointer;
    height: .125rem;
    width: .75rem;
    background: var(--header-prime-color);
    position: absolute;
    display: block;
    content: "";
    transition: all 50ms ease-in-out
}

.animated-arrow span:after,
.animated-arrow span:before {
    transition: all 50ms ease-in-out
}

.animated-arrow span:before {
    inset-block-start: -.375rem;
    width: 1.4375rem
}

.animated-arrow span:after {
    bottom: -.375rem;
    width: 15px
}

.animated-arrow span:after,
.animated-arrow span:before {
    cursor: pointer;
    height: .125rem;
    width: 1.0625rem;
    background: var(--header-prime-color);
    position: absolute;
    display: block;
    content: ""
}

[data-toggled=close-menu-close] .animated-arrow span,
[data-toggled=detached-close] .animated-arrow span,
[data-toggled=icon-click-closed] .animated-arrow span,
[data-toggled=icon-hover-closed] .animated-arrow span,
[data-toggled=icon-overlay-close] .animated-arrow span,
[data-toggled=icon-text-close] .animated-arrow span,
[data-toggled=menu-click-closed] .animated-arrow span,
[data-toggled=menu-hover-closed] .animated-arrow span {
    background-color: transparent;
    background: 0 0 !important
}

[data-toggled=close-menu-close] .animated-arrow span:before,
[data-toggled=detached-close] .animated-arrow span:before,
[data-toggled=icon-click-closed] .animated-arrow span:before,
[data-toggled=icon-hover-closed] .animated-arrow span:before,
[data-toggled=icon-overlay-close] .animated-arrow span:before,
[data-toggled=icon-text-close] .animated-arrow span:before,
[data-toggled=menu-click-closed] .animated-arrow span:before,
[data-toggled=menu-hover-closed] .animated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0
}

[data-toggled=close-menu-close] .animated-arrow span:before,
[data-toggled=detached-close] .animated-arrow span:before,
[data-toggled=icon-click-closed] .animated-arrow span:before,
[data-toggled=icon-hover-closed] .animated-arrow span:before,
[data-toggled=icon-overlay-close] .animated-arrow span:before,
[data-toggled=icon-text-close] .animated-arrow span:before,
[data-toggled=menu-click-closed] .animated-arrow span:before,
[data-toggled=menu-hover-closed] .animated-arrow span:before {
    inset-block-start: -1px
}

[data-toggled=close-menu-close] .animated-arrow span:after,
[data-toggled=detached-close] .animated-arrow span:after,
[data-toggled=icon-click-closed] .animated-arrow span:after,
[data-toggled=icon-hover-closed] .animated-arrow span:after,
[data-toggled=icon-overlay-close] .animated-arrow span:after,
[data-toggled=icon-text-close] .animated-arrow span:after,
[data-toggled=menu-click-closed] .animated-arrow span:after,
[data-toggled=menu-hover-closed] .animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg)
}

[data-toggled=close-menu-close] .animated-arrow span:after,
[data-toggled=detached-close] .animated-arrow span:after,
[data-toggled=icon-click-closed] .animated-arrow span:after,
[data-toggled=icon-hover-closed] .animated-arrow span:after,
[data-toggled=icon-overlay-close] .animated-arrow span:after,
[data-toggled=icon-text-close] .animated-arrow span:after,
[data-toggled=menu-click-closed] .animated-arrow span:after,
[data-toggled=menu-hover-closed] .animated-arrow span:after {
    width: 1.0625rem;
    inset-block-start: -1px
}

.header-notification-text {
    max-width: 14.5rem
}

.choices__inner {
    padding: .5rem .85rem !important;
    background-color: var(--form-control-bg) !important;
    border-radius: .35rem !important;
    font-size: .813rem !important;
    border: 1px solid var(--input-border) !important;
    min-height: auto !important;
    line-height: 1.8
}

.choices__input {
    padding: 0 !important
}

.choices__list--single {
    padding-block: 0 !important;
    padding-inline-start: 0;
    padding-inline-end: 1rem !important
}

.choices[data-type*=select-one]::after {
    inset-inline-end: .7188rem !important
}

.choices[data-type*=select-one]::after {
    color: var(--text-muted) !important
}

.choices[data-type*=select-one] .choices__input {
    padding: .625rem !important
}

.choices__list--dropdown {
    display: none
}

.choices__list--dropdown.is-active {
    display: block
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    z-index: 99 !important;
    border-radius: 0 0 .5rem .5rem !important
}

.choices[data-type*=select-one] .choices__input {
    border-block-end: 1px solid var(--default-border) !important;
    background-color: var(--custom-white) !important;
    color: var(--default-text-color)
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.choices__list--dropdown .choices__item--selectable.is-selected {
    color: var(--primary-color) !important
}

.choices__list--dropdown .choices__item--selectable.is-selected.is-highlighted {
    color: #fff !important
}

.choices__list--dropdown .choices__list {
    max-height: 250px !important
}

.choices[data-type*=select-one]::after {
    border-color: var(--text-muted) transparent transparent !important
}

.choices__input {
    background-color: transparent !important;
    color: var(--custom-black)
}

.choices__list--multiple .choices__item {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important
}

.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
    border-inline-start: 1px solid rgba(255, 255, 255, .5) !important
}

.choices__list--multiple .choices__item {
    margin-block-end: 1px !important
}

.choices__list--single .choices__item {
    color: var(--default-text-color) !important
}

.choices__input {
    margin-block-end: 0 !important
}

.choices__list--multiple .choices__item {
    border-radius: .25rem !important;
    padding: .025rem .625rem !important
}

.form-control-select-sm .choices__inner {
    padding: .275rem !important
}

.choices[data-type*=select-one].is-open::after {
    margin-top: -.156rem !important
}

.input-group .choices__inner {
    border-radius: 0 .5rem .5rem 0 !important
}

.choices__heading {
    border-bottom: 1px solid var(--default-border) !important;
    color: var(--text-muted) !important
}

[dir=rtl] .input-group .choices__inner {
    border-radius: .5rem 0 0 .5rem !important
}

[dir=rtl] .choices[data-type*=select-one]::after {
    left: .7188rem;
    right: inherit
}

[dir=rtl] .choices[data-type*=select-one] .choices__button {
    left: 0;
    right: inherit;
    margin-left: 1.5625rem;
    margin-right: inherit
}

[dir=rtl][data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
    filter: invert(1)
}

[dir=rtl] .choices[data-type*=select-multiple] .choices__button,
[dir=rtl] .choices[data-type*=text] .choices__button {
    margin: 0 8px 0 -4px;
    padding-right: 1rem;
    padding-left: inherit;
    border-right: 0 solid #008fa1;
    border-left: 0
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
    box-shadow: rgba(149, 157, 165, .2) 0 .5rem 1.5rem !important
}

[data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
    filter: invert(1)
}

#marketCap .apexcharts-canvas line {
    stroke: var(--default-border)
}

.apexcharts-legend {
    padding: 0 1.25rem !important
}

.apexcharts-tooltip {
    box-shadow: none !important
}

.apexcharts-tooltip-marker {
    margin-inline-end: .625rem
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid var(--default-border) !important;
    background: var(--custom-white) !important
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: var(--custom-white) !important;
    border-block-end: 1px solid var(--default-border) !important
}

.apexcharts-grid line,
.apexcharts-grid-borders line,
.apexcharts-xaxis line {
    stroke: var(--default-border)
}

.apexcharts-radialbar-track.apexcharts-track path {
    stroke: rgb(var(--light-rgb))
}

.apexcharts-selection-rect {
    fill: var(--custom-black)
}

.apexcharts-menu {
    background: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    border-radius: .5rem !important
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
    background: rgb(var(--light-rgb)) !important
}

.apexcharts-inner.apexcharts-graphical line.apexcharts-xaxis-tick {
    stroke: transparent !important
}

#column-rotated-labels .apexcharts-xaxis-texts-g {
    transform: translateY(2.5rem)
}

#chart-quarter,
#chart-year {
    background: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important
}

.apexcharts-bar-series.apexcharts-plot-series .apexcharts-series .apexcharts-bar-area {
    stroke: var(--default-border) !important
}

.apexcharts-treemap .apexcharts-series.apexcharts-treemap-series rect {
    stroke: var(--custom-white) !important
}

.apexcharts-series.apexcharts-pie-series .apexcharts-pie-area {
    stroke: var(--custom-white) !important
}

.apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
    fill: var(--default-text-color)
}

.apexcharts-radialbar-hollow {
    fill: var(--custom-white)
}

.apexcharts-radar-series.apexcharts-plot-series line,
.apexcharts-radar-series.apexcharts-plot-series polygon {
    stroke: var(--default-border)
}

.apexcharts-pie circle,
.apexcharts-pie line {
    stroke: var(--default-border)
}

.apexcharts-pie text {
    fill: var(--default-text-color)
}

.apexcharts-canvas .apexcharts-toolbar {
    z-index: 1
}

.apexcharts-subtitle-text {
    fill: var(--text-muted)
}

#polararea-basic .apexcharts-pie text {
    fill: var(--custom-black)
}

.apexcharts-pie .apexcharts-datalabels rect {
    fill: transparent
}

.apexcharts-pie text {
    fill: #fff
}

[dir=rtl] .apexcharts-canvas {
    direction: ltr
}

.apexcharts-boxPlot-area {
    stroke: var(--default-text-color) !important
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
    border: 0 !important;
    border-top: 1px solid var(--default-border) !important
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
    padding: 0 .25rem !important
}

.fc .fc-button-primary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important
}

.fc .fc-non-business {
    background: var(--custom-white) !important
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: .9
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none !important
}

.fc-theme-standard td,
.fc-theme-standard th {
    border: 1px solid var(--default-border) !important;
    border-top: 0 !important
}

.fc-list-table td,
.fc-list-table th {
    border-left: 0 !important;
    border-right: 0 !important
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: var(--primary01) !important
}

.fc-theme-standard .fc-list {
    border: 1px solid var(--default-border) !important
}

.fc .fc-list-event:hover td {
    background-color: rgb(var(--light-rgb)) !important
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
    box-shadow: none !important
}

.fc-theme-standard .fc-list-day-cushion {
    background-color: rgb(var(--light-rgb)) !important
}

.fc-theme-standard .fc-scrollgrid {
    border: 1px solid var(--default-border) !important
}

.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
    border: 1px solid var(--default-border) !important
}

@media (max-width:420px) {
    .fc-scroller.fc-scroller-liquid {
        overflow: scroll !important
    }
}

@media (max-width:380px) {
    .fc .fc-daygrid-day-bottom {
        font-size: .75em !important;
        padding: 0 3px 0 !important
    }
    .fc .fc-daygrid-more-link {
        z-index: 99 !important
    }
}

@media (max-width:767.98px) {
    .fc .fc-toolbar {
        display: block !important
    }
    .fc-toolbar-chunk {
        margin-block-start: .5rem
    }
}

.fc-daygrid-block-event {
    color: #fff !important;
    border: 0 !important
}

.fc-daygrid-block-event .fc-list-event-dot {
    border-color: #fff !important
}

.fc-daygrid-block-event:hover {
    color: #000 !important
}

.fc-daygrid-block-event:hover .fc-list-event-dot {
    border-color: #000 !important
}

[dir=rtl] .flatpickr-input {
    text-align: end
}

.flatpickr-calendar {
    background-color: var(--custom-white) !important;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04) !important;
    border: 1px solid var(--default-border) !important;
    font-size: .813rem !important
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    color: var(--black-9) !important;
    fill: var(--black-9) !important
}

.flatpickr-monthDropdown-months,
.numInput {
    color: var(--custom-black) !important
}

.flatpickr-day.today.inRange {
    color: var(--primary-color) !important
}

.dayContainer {
    padding: .25rem !important
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: var(--custom-white) !important;
    font-size: .813rem
}

.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
    width: 1.25rem !important;
    height: 1.25rem !important;
    padding: .25rem;
    fill: var(--primary-color)
}

.flatpickr-day.inRange {
    box-shadow: none !important
}

.flatpickr-calendar.open {
    z-index: 105 !important
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background-color: var(--primary01) !important;
    border-radius: .25rem !important
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: var(--text-muted) !important
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: var(--text-muted) !important
}

.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: none !important
}

.flatpickr-day {
    color: var(--default-text-color) !important;
    font-weight: 500 !important
}

.flatpickr-day.nextMonthDay,
.flatpickr-day.prevMonthDay {
    opacity: .5 !important
}

.flatpickr-day.today {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
    background: var(--primary-color) !important;
    color: #fff !important;
    border-color: var(--primary-color) !important
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
    background: var(--default-background) !important;
    border-color: var(--default-border) !important
}

.flatpickr-day.today:hover {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: #fff !important
}

.flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid var(--default-border) !important
}

.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
    border-bottom-color: var(--default-border) !important
}

.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
    border-top-color: var(--default-border) !important
}

.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
    background: var(--default-background) !important
}

.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
    color: var(--text-muted) !important
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    inset-inline-start: 0 !important
}

.flatpickr-months,
.flatpickr-weekdays {
    background-color: var(--primary01) !important
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    inset-block-start: 0 !important;
    padding-block-start: .313rem !important;
    padding-inline: .313rem !important;
    padding-block-end: 0 !important;
    color: var(--primary-color) !important;
    fill: var(--primary-color) !important
}

@media (min-width:420px) {
    .flatpickr-time .flatpickr-am-pm {
        padding: 0 1.875rem 0 .5rem
    }
}

.flatpickr-weekdays {
    border-block-end: 1px solid var(--default-border) !important
}

.numInputWrapper span.arrowUp {
    top: -.125rem !important
}

.flatpickr-current-month .numInputWrapper {
    width: 3.5rem !important
}

.flatpickr-calendar.hasTime {
    width: auto
}

[dir=rtl] .flatpickr-months .numInputWrapper span {
    left: 0 !important;
    right: inherit !important
}

@media (max-width:575.98) {
    .flatpickr-calendar {
        width: 250px !important
    }
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    margin: -1px 2.25rem 0 0 !important
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
    padding-block: 0 !important;
    padding-inline-start: 0 !important;
    padding-inline-end: .5ch !important;
    font-size: .875rem !important;
    font-weight: 500 !important
}

.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
    stroke: var(--primary-color)
}

.flatpickr-day {
    border-radius: .5rem !important
}

.numInputWrapper:hover {
    background: 0 0 !important
}

.numInputWrapper span {
    border: 0 !important
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-block-end-color: var(--primary-color) !important
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #fff !important
}

.numInputWrapper span:hover {
    background: 0 0 !important
}

.numInputWrapper span.arrowUp:after {
    border-inline-start: .25rem solid transparent !important;
    border-inline-end: .25rem solid transparent !important;
    border-block-end: .25rem solid var(--primary05) !important;
    inset-block-start: 75% !important
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-block-start-color: var(--primary-color) !important
}

.numInputWrapper span.arrowDown:after {
    border-inline-start: .25rem solid transparent !important;
    border-inline-end: .25rem solid transparent !important;
    border-block-start: .25rem solid var(--primary05) !important;
    inset-block-start: 15% !important
}

span.flatpickr-weekday {
    color: var(--primary08) !important;
    font-weight: 700 !important
}

.flatpickr-months .flatpickr-month {
    color: var(--primary-color) !important;
    fill: var(--primary-color) !important
}

.flatpickr-monthDropdown-months,
.numInput {
    color: var(--primary-color) !important
}

.pcr-app {
    background: var(--custom-white) !important
}

.pcr-app .pcr-interaction .pcr-result {
    color: var(--default-text-color) !important;
    background: var(--default-background) !important
}

.theme-container button,
.theme-container1 button,
.theme-container2 button {
    display: none
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview {
    margin-inline-end: .75em !important
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity {
    margin-inline-start: .75em !important
}

.flatpickr-weekwrapper .flatpickr-weeks {
    box-shadow: 1px 0 0 var(--default-border) !important
}

.noUi-horizontal .noUi-handle {
    width: 1rem !important;
    height: 1rem !important;
    inset-inline-end: -.063rem !important;
    top: -.375rem !important
}

.noUi-handle:after,
.noUi-handle:before {
    height: .35rem !important;
    width: 1px !important;
    inset-inline-start: .3rem !important;
    top: .25rem !important
}

.noUi-handle:after {
    inset-inline-start: .45rem !important
}

.noUi-horizontal {
    height: .35rem !important
}

.noUi-vertical {
    width: .35rem !important
}

.noUi-vertical .noUi-handle {
    width: 1rem !important;
    height: 1rem !important
}

.noUi-target {
    background: var(--default-background) !important;
    border: 1px solid var(--default-border) !important;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04) !important
}

.noUi-handle {
    border: 1px solid var(--default-border) !important;
    background: var(--custom-white) !important;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04) !important
}

#result {
    border: 1px solid var(--default-border) !important
}

.noUi-handle:after,
.noUi-handle:before {
    background: var(--black-3) !important
}

.noUi-marker {
    position: absolute;
    background: var(--default-border) !important
}

.noUi-tooltip {
    border: 1px solid var(--default-border) !important;
    border-radius: .5rem !important;
    background: var(--custom-white) !important;
    color: var(--default-text-color) !important;
    padding: .25rem .75rem !important;
    font-size: .813rem !important;
    line-height: 1 !important
}

#slider-fit {
    padding: 0 1rem
}

.noUi-connect {
    background: var(--primary-color) !important
}

.noUi-vertical .noUi-handle {
    bottom: -.275rem !important;
    inset-inline-end: -.375rem
}

#secondary-colored-slider .noUi-connect {
    background-color: rgb(var(--secondary-rgb)) !important
}

#warning-colored-slider .noUi-connect {
    background-color: rgb(var(--warning-rgb)) !important
}

#info-colored-slider .noUi-connect {
    background-color: rgb(var(--info-rgb)) !important
}

#success-colored-slider .noUi-connect {
    background-color: rgb(var(--success-rgb)) !important
}

#danger-colored-slider .noUi-connect {
    background-color: rgb(var(--danger-rgb)) !important
}

#slider-round {
    height: .625rem !important
}

#slider-round .noUi-handle {
    height: 1.125rem !important;
    width: 1.125rem !important;
    inset-block-start: -.313rem !important;
    inset-inline-end: -.563rem;
    border-radius: 50px !important;
    background-color: var(--primary-color) !important;
    border: 1px solid var(--custom-white) !important
}

#slider-round .noUi-handle:after,
#slider-round .noUi-handle:before {
    display: none !important
}

#slider-square {
    border-radius: 0 !important
}

#slider-square .noUi-handle {
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background: var(--primary-color) !important;
    height: 1.125rem !important;
    width: 1.125rem !important;
    inset-block-start: -.45rem !important;
    inset-inline-end: -.563rem !important
}

#slider-square .noUi-handle:after,
#slider-square .noUi-handle:before {
    display: none !important
}

#color1,
#color2,
#color3 {
    margin: .625rem !important;
    display: inline-block !important;
    height: 12.5rem !important
}

#colorpicker {
    height: 15rem !important;
    width: 19.375rem !important;
    margin: 0 auto !important;
    padding: .625rem !important;
    border: 1px solid var(--default-border) !important
}

#result {
    margin: 4.25rem 0 4.25rem 4rem;
    height: 6.25rem;
    width: 6.25rem;
    display: inline-block;
    vertical-align: top;
    color: var(--gray-5);
    background: var(--gray-5);
    border: 1px solid #fff;
    box-shadow: 0 0 .625rem
}

#color1 .noUi-connect {
    background: rgb(var(--danger-rgb)) !important
}

#color2 .noUi-connect {
    background: rgb(var(--secondary-rgb)) !important
}

#color3 .noUi-connect {
    background: var(--primary-color) !important
}

#slider-hide .noUi-tooltip {
    display: none
}

#slider-hide .noUi-active .noUi-tooltip {
    display: block
}

.c-1-color {
    background: rgb(var(--secondary-rgb)) !important
}

.c-2-color {
    background: rgb(var(--warning-rgb)) !important
}

.c-3-color {
    background: rgb(var(--info-rgb)) !important
}

.c-4-color {
    background: rgb(var(--danger-rgb)) !important
}

.c-5-color {
    background: #4d5ddb !important
}

#slider-toggle {
    height: 3.125rem
}

#slider-toggle.off .noUi-handle {
    border-color: rgb(var(--success-rgb)) !important
}

.glightbox {
    overflow: hidden
}

@media (min-width:769px) {
    .gslide-image img {
        border-radius: .5rem
    }
    .glightbox-clean .gclose,
    .glightbox-clean .gnext,
    .glightbox-clean .gprev {
        background-color: rgba(255, 255, 255, .05) !important;
        width: 2.5rem !important;
        height: 2.5rem !important;
        padding: .875rem
    }
}

#external-events .fc-event {
    cursor: move;
    margin: 0 0 .4rem 0;
    padding: .375rem .75rem;
    font-size: .75rem;
    border-radius: .35rem
}

#calendar-container {
    position: relative;
    z-index: 1
}

#calendar {
    max-width: 68.75rem;
    margin: 1.25rem auto
}

#interactive-map,
#map,
#map-custom-icon,
#map-popup,
#map1 {
    height: 18.75rem;
    z-index: 10
}

#canada-map,
#lines-map,
#marker-image-map,
#marker-map,
#russia-map,
#spain-map,
#us-map,
#vector-map {
    height: 21.875rem
}

.jvm-tooltip {
    background-color: #1a1c1e !important
}

#lines-map #jvm-regions-group path,
#marker-image-map #jvm-regions-group path,
#marker-map #jvm-regions-group path,
#users-map #jvm-regions-group path,
#vector-map #jvm-regions-group path,
#visitors-countries #jvm-regions-group path {
    fill: rgb(var(--light-rgb)) !important
}

.jvm-zoom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important
}

.jvm-zoom-btn.jvm-zoomin {
    inset-block-start: 5px !important
}

#jvm-markers-labels-group text {
    fill: var(--text-muted)
}

#google-map,
#google-map-overlay,
#map-geofencing,
#map-layers,
#map-markers,
#streetview-map {
    height: 18.75rem
}

.google-map-overlay {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 1.25rem;
    line-height: .875rem;
    opacity: .8;
    background: var(--primary-color);
    border: solid .188rem var(--primary-color);
    border-radius: .25rem;
    box-shadow: .125rem .125rem .625rem var(--black-3);
    text-shadow: .063rem .063rem .063rem var(--gray-6);
    padding: 0 .25rem
}

.google-overlay_arrow {
    inset-inline-start: 50%;
    margin-inline-start: -1rem;
    width: 0;
    height: 0;
    position: absolute
}

.google-overlay_arrow.above {
    inset-block-end: -.938rem;
    border-inline-start: .938rem solid transparent;
    border-inline-end: 1rem solid transparent;
    border-block-start: 1rem solid #369
}

.google-overlay_arrow.below {
    inset-block-start: -.938rem;
    border-inline-start: 1rem solid transparent;
    border-inline-end: 1rem solid transparent;
    border-block-end: 1rem solid #369
}

.content-wrapper {
    width: 100%
}

.apexcharts-canvas,
.apexcharts-svg {
    width: 100% !important
}

#donut-gradient .apexcharts-canvas,
#donut-pattern .apexcharts-canvas,
#donut-update .apexcharts-canvas,
#pie-basic .apexcharts-canvas,
#pie-image .apexcharts-canvas,
#pie-monochrome .apexcharts-canvas,
#polararea-basic .apexcharts-canvas,
#polararea-monochrome .apexcharts-canvas {
    margin: 0 auto
}

.apexcharts-legend-text {
    color: var(--default-text-color) !important;
    padding-inline-start: .9375rem !important;
    margin-inline-start: -.625rem !important
}

.apexcharts-text.apexcharts-xaxis-label tspan,
.apexcharts-text.apexcharts-yaxis-label tspan {
    fill: var(--text-muted)
}

.apexcharts-canvas .apexcharts-series.apexcharts-heatmap-series rect {
    stroke: var(--custom-white)
}

.apexcharts-canvas .apexcharts-series-markers.apexcharts-series-bubble circle {
    stroke: var(--custom-white)
}

.apexcharts-yaxis .apexcharts-text {
    fill: var(--text-muted)
}

.chartjs-chart {
    max-height: 18.75rem
}

#chart-quarter,
#chart-year {
    width: 96%;
    max-width: 48%;
    box-shadow: none;
    padding-inline-start: 0;
    padding-block-start: 1.25rem;
    background: #fff;
    border: 1px solid #ddd
}

#chart-year {
    float: left;
    position: relative;
    transition: 1s ease transform;
    z-index: 3
}

#chart-year.chart-quarter-activated {
    transform: translateX(0);
    transition: 1s ease transform
}

#chart-quarter {
    float: left;
    position: relative;
    z-index: -2;
    transition: 1s ease transform
}

#chart-quarter.active {
    transition: 1.1s ease-in-out transform;
    transform: translateX(0);
    z-index: 1
}

@media screen and (min-width:480px) {
    #chart-year {
        transform: translateX(50%)
    }
    #chart-quarter {
        transform: translateX(-50%)
    }
}

.echart-charts {
    height: 20rem
}

.simplebar-scrollbar:before {
    background: var(--gray-4) !important;
    border-radius: .3rem !important;
    inset-inline-end: 0 !important;
    width: .375rem
}

.simplebar-track.simplebar-horizontal {
    display: none
}

.dropzone {
    border: .125rem dashed var(--default-border) !important;
    background-color: transparent !important
}

.dropzone .dz-message .dz-button {
    font-size: 1.25rem !important;
    color: var(--default-text-color) !important
}

.dropzone .dz-preview {
    border-radius: 1.25rem
}

.filepond--drop-label {
    color: var(--default-text-color) !important;
    border-radius: .3rem !important
}

.filepond--credits {
    display: none
}

.filepond--panel-root {
    background-color: var(--custom-white) !important;
    border: .125rem dashed var(--default-border) !important
}

.filepond--drop-label.filepond--drop-label label {
    padding: 1.5em !important;
    font-size: .813rem
}

.filepond--root {
    margin-block-end: 0 !important
}

.filepond--file {
    background-color: var(--primary-color) !important
}

.single-fileupload {
    width: 8rem !important;
    height: 8rem !important;
    margin: 0 auto !important
}

.ql-bubble .ql-editor blockquote {
    border-inline-start: 4px solid var(--default-background) !important
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
    border: 1px solid var(--default-border) !important
}

.ql-snow .ql-picker {
    color: var(--default-text-color) !important
}

.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
    stroke: var(--default-text-color) !important
}

.ql-snow .ql-fill {
    fill: var(--default-text-color) !important
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-top: 0 !important
}

.ql-snow .ql-picker-options .ql-picker-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.ql-editor {
    min-height: 15.62rem !important;
    overflow: visible !important
}

.ql-snow .ql-formats {
    border: 1px solid var(--default-border);
    border-radius: .5rem
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: var(--default-border) !important;
    border-radius: .5rem !important
}

.ql-snow .ql-picker-options {
    background-color: var(--custom-white) !important
}

.ql-snow .ql-tooltip {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04) !important;
    color: var(--default-text-color) !important
}

.ql-snow .ql-tooltip input[type=text] {
    border: 1px solid var(--default-border) !important;
    background-color: var(--default-background) !important;
    color: var(--default-text-color) !important;
    outline: 0
}

.ql-snow .ql-tooltip {
    transform: translatex(12.5rem) !important;
    z-index: 100 !important
}

.ql-toolbar.ql-snow {
    border-radius: .3rem .3rem 0 0 !important
}

.ql-snow .ql-picker-label {
    padding-inline-start: .5rem !important;
    padding-inline-end: .125rem !important
}

.ql-snow .ql-formats .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    inset-inline-end: 0 !important;
    inset-inline-start: auto !important
}

.ql-container {
    border-radius: 0 0 .3rem .3rem !important
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: var(--default-text-color) !important
}

.ql-editor {
    text-align: start !important
}

.ql-editor .ql-align-right {
    text-align: end !important
}

.ql-container {
    font-family: var(--default-font-family) !important
}

.ql-snow .ql-editor {
    padding: 1.25rem
}

.ql-bubble {
    border: 1px solid var(--default-border) !important;
    border-radius: .5rem !important
}

.ql-editor li:not(.ql-direction-rtl)::before {
    margin-inline-start: -1.5em !important;
    margin-inline-end: .3em !important;
    text-align: start !important
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
    padding-inline-start: 1.5em !important
}

.ql-toolbar.ql-snow .ql-formats {
    margin: .25rem
}

[dir=rtl] .ql-tooltip.ql-editing {
    left: 0 !important;
    top: 0 !important
}

[dir=rtl] .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-right: 12px
}

[dir=rtl] .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0 8px 12px
}

.select2.select2-container {
    width: 100% !important
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: var(--default-text-color) !important;
    line-height: 2.33rem !important;
    border: 1px solid var(--input-border) !important;
    border-radius: .5rem !important
}

.select2-container--default .select2-selection--single {
    background-color: var(--form-control-bg) !important;
    border: 0 solid var(--input-border) !important;
    border-radius: .5rem !important
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.37rem !important
}

.select2-dropdown {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--input-border) !important;
    border-radius: .5rem !important
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid var(--input-border) !important;
    border-radius: .5rem !important
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: var(--default-background) !important;
    color: var(--default-text-color) !important
}

.select2-results__option--selectable {
    font-size: .813rem
}

.select2-container--default .select2-results__option--selected {
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.select2-container--default .select2-results__option--selected.select2-results__option--highlighted {
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.select2-search__field {
    background-color: var(--custom-white) !important;
    color: var(--default-text-color) !important
}

.select2-search__field:focus-visible {
    outline: 0 !important
}

.select2-container--default .select2-selection--multiple {
    background-color: var(--form-control-bg) !important;
    border: 1px solid var(--input-border) !important;
    border-radius: .5rem !important
}

.select2-container .select2-selection--multiple {
    min-height: 2.25rem !important
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--primary-color) !important;
    color: #fff !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: .15rem !important;
    margin-block-start: .375rem !important
}

.select2-selection--multiple .select2-search__field {
    background-color: transparent !important
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    border-inline-end: 1px solid rgba(255, 255, 255, .1) !important;
    color: #fff !important;
    inset-block-start: -.3rem !important;
    font-weight: 500 !important;
    font-size: 1.125rem !important
}

.select2-selection--multiple .select2-selection__choice__display {
    font-size: .75rem !important
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    background-color: var(--primary-color) !important
}

.select2-results__option span img,
.select2-selection__rendered span img {
    width: 1.45rem;
    height: 1.45rem;
    border-radius: 1.25rem;
    margin-inline-end: .25rem;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04)
}

.select2-container .select2-search--inline .select2-search__field {
    margin-block-start: .5rem !important
}

.select2-container--disabled .select2-selection.select2-selection--multiple,
.select2-container--disabled.select2-container--default .select2-selection--single .select2-selection__rendered {
    background-color: var(--default-background) !important
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    font-weight: 400 !important;
    height: 1.25rem !important;
    font-size: 1.5625rem !important;
    width: 1.25rem !important;
    position: absolute !important;
    inset-inline-end: .625rem !important
}

.select2-selection__clear {
    color: var(--text-muted)
}

.select2-dropdown {
    z-index: 10 !important
}

[dir=rtl] .select2-container--default .select2-selection--single .select2-selection__clear {
    margin-left: 20px !important;
    padding-left: 0 !important
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 10px !important
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
    left: 10px !important;
    right: auto !important
}

.select2-container--open .select2-dropdown--above {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    overflow: hidden
}

.select2-container--open .select2-dropdown--below {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    overflow: hidden
}

.gridjs-table {
    width: 100%
}

table.gridjs-table {
    text-align: start !important;
    font-size: .813rem !important;
    font-weight: 500 !important
}

.gridjs-wrapper {
    box-shadow: none !important;
    border-radius: 0 !important
}

.gridjs-wrapper:nth-last-of-type(2) {
    border-radius: 0 !important
}

.gridjs-container {
    color: var(--default-text-color) !important
}

th.gridjs-th {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    color: var(--default-text-color) !important;
    padding: .45rem !important
}

td.gridjs-td {
    border: 1px solid var(--default-border) !important;
    padding: .25rem !important
}

.gridjs-tbody,
td.gridjs-td {
    background-color: var(--custom-white) !important
}

.gridjs-footer {
    background-color: var(--custom-white) !important;
    border-bottom-width: 0 !important;
    border-color: transparent !important;
    border-radius: 0 !important;
    border-top: 0 !important;
    box-shadow: none !important;
    padding: .75rem 0 0 0 !important
}

.gridjs-pagination {
    color: var(--default-text-color) !important
}

.gridjs-pagination .gridjs-pages button:first-child {
    border-bottom-left-radius: .5rem !important;
    border-top-left-radius: .5rem !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.gridjs-pagination .gridjs-pages button:last-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: .5rem !important;
    border-top-right-radius: .5rem !important
}

@media (max-width:575.98px) {
    .gridjs-search-input {
        width: 12.5rem !important
    }
}

[dir=rtl] .gridjs-pagination .gridjs-pages button:first-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: .5rem !important;
    border-top-right-radius: .5rem !important
}

[dir=rtl] .gridjs-pagination .gridjs-pages button:last-child {
    border-bottom-left-radius: .5rem !important;
    border-top-left-radius: .5rem !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button:hover:disabled,
.gridjs-pagination .gridjs-pages button[disabled] {
    background-color: var(--custom-white) !important;
    color: var(--default-text-color) !important
}

.gridjs-pagination .gridjs-pages button {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    padding: .375 .75rem !important;
    color: var(--default-text-color) !important
}

.gridjs-pagination .gridjs-pages button:focus {
    box-shadow: none !important;
    margin-inline-end: 0 !important
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
    font-weight: 600 !important;
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.gridjs-pagination .gridjs-pages {
    float: end !important
}

input.gridjs-input {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    border-radius: .5rem !important;
    font-size: .813rem !important;
    padding: .375rem .75rem !important;
    line-height: 1.6 !important;
    color: var(--default-text-color) !important;
    width: 300px;
}

input.gridjs-input:focus {
    box-shadow: none !important;
    border: 1px solid var(--primary-color) !important
}

button.gridjs-sort {
    float: end !important;
    height: 1.25rem !important;
    width: .625rem !important
}

button.gridjs-sort-desc {
    background-position-y: 54% !important;
    background-size: .5rem !important
}

button.gridjs-sort-asc {
    background-position-y: 44% !important;
    background-size: .5rem !important
}

th.gridjs-th-fixed {
    background-color: rgb(var(--light-rgb)) !important
}

#grid-header-fixed .gridjs-wrapper {
    border-block-start: 1px solid var(--default-border) !important;
    border-block-end: 1px solid var(--default-border) !important
}

#grid-header-fixed .gridjs-container .gridjs-wrapper .gridjs-thead .gridjs-tr th {
    top: -1px !important
}

.dataTables_wrapper .dataTables_paginate {
    margin: 0;
    text-align: end
}

.dataTables_wrapper .dataTables_paginate .pagination {
    margin-block-end: 0;
    justify-content: end
}

div.dataTables_scrollBody>table#datatable-basic {
    margin-block-end: .375rem !important
}

.dataTables_filter {
    text-align: end !important
}

.dataTables_info {
    padding-block-start: .625rem
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc_disabled:before {
    content: "\f235" !important;
    font-family: bootstrap-icons !important;
    position: absolute;
    inset-inline-end: .8rem !important;
    top: .813rem !important;
    font-size: .5rem !important
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_desc_disabled:after {
    content: "\f229" !important;
    font-family: bootstrap-icons !important;
    position: absolute;
    inset-inline-end: .8rem !important;
    top: 1.25rem !important;
    font-size: .5rem !important
}

table.dataTable>thead .sorting,
table.dataTable>thead .sorting_asc,
table.dataTable>thead .sorting_asc_disabled,
table.dataTable>thead .sorting_desc,
table.dataTable>thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc_disabled:after,
table.dataTable>thead .sorting_desc_disabled:before {
    position: absolute;
    display: block;
    opacity: .2
}

table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_desc:after {
    opacity: .8
}

div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display: inline-block;
    margin: 0 .25rem
}

.dataTables_wrapper .dataTables_scrollHead table.dataTable {
    margin-block-end: 0
}

.dataTables_wrapper .dataTables_scrollBody #datatable-basic {
    margin-block-start: -3px;
    border-block-start-color: transparent !important
}

.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc_disabled:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc_disabled:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc_disabled:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc_disabled:before {
    display: none
}

div.dt-button-info {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important
}

div.dt-button-info h2 {
    background-color: var(--custom-white) !important;
    border-bottom: 1px solid var(--default-border) !important
}

@media (max-width:767.98px) {
    .data-table-btn {
        margin: .5625rem 0 .5rem 0 !important
    }
    .dataTables_filter,
    .dataTables_info,
    .dataTables_length {
        text-align: center
    }
    .dataTables_filter {
        margin-block-start: .5rem
    }
    .dataTables_paginate .pagination {
        justify-content: center !important
    }
    .dataTables_info {
        padding-block-start: 0;
        padding-block-end: .5rem
    }
    div.dtr-modal div.dtr-modal-display {
        width: 95% !important;
        height: 95% !important;
        background-color: var(--custom-white) !important;
        border: 1px solid var(--default-border) !important;
        box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04) !important
    }
    div.dataTables_wrapper div.dataTables_filter,
    div.dataTables_wrapper div.dataTables_info,
    div.dataTables_wrapper div.dataTables_length,
    div.dataTables_wrapper div.dataTables_paginate {
        text-align: center !important
    }
}

@media (max-width:575.98px) {
    .dataTables_paginate .pagination .paginate_button .page-link {
        font-size: .625rem;
        padding: .25rem .5rem
    }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control {
    padding-inline-start: 1.875rem !important
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    color: #fff !important;
    border: .15em solid #fff !important;
    box-shadow: 0 0 .2em var(--primary-color) !important;
    background-color: var(--primary-color) !important
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before {
    content: "-";
    background-color: rgb(var(--success-rgb)) !important
}

table.dataTable>tbody>tr.child ul.dtr-details>li:first-child {
    padding-block-start: 0 !important
}

table.dataTable>tbody>tr.child ul.dtr-details>li {
    border-block-end: 1px solid var(--default-border) !important
}

table.dataTable>tbody>tr.child span.dtr-title {
    min-width: 4.688rem !important
}

div.dtr-modal div.dtr-modal-close {
    top: 0 !important;
    border: 0 solid var(--default-border) !important;
    font-size: 1.5rem !important;
    background-color: transparent !important
}

div.dtr-modal div.dtr-modal-background {
    background: rgba(0, 0, 0, .3) !important
}

.dtr-modal-content h2 {
    font-size: .875rem !important;
    font-weight: 600 !important
}

.dt-button {
    font-size: .8125rem !important;
    border-radius: .3rem !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    padding: .375rem .75rem !important;
    background-color: var(--primary-color);
    color: #fff;
    border: 0
}

.dt-buttons {
    float: left !important
}

table.dataTable thead>tr>td.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_asc_disabled,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting_desc_disabled,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_asc_disabled,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting_desc_disabled {
    padding-inline-end: 1.625rem
}

table.dataTable tfoot td,
table.dataTable tfoot th,
table.dataTable thead td,
table.dataTable thead th {
    text-align: start !important
}

table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable th:first-child {
    border-inline-start-width: 1px !important
}

table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable th:last-child {
    border-inline-end-width: 1px !important
}

div.dataTables_wrapper div.dataTables_filter#scroll-vertical_filter input {
    margin-right: .2em
}

[dir=rtl] div.dtr-modal div.dtr-modal-close {
    left: 6px;
    right: auto
}

[dir=rtl] div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
    padding-left: 0;
    padding-right: initial
}

[dir=rtl] div.dataTables_wrapper div.dataTables_filter#scroll-vertical_filter input {
    margin-right: .5rem;
    margin-left: .2em
}

[dir=rtl] div.dataTables_wrapper div.dataTables_filter input {
    margin-right: .5rem;
    margin-left: 0
}

[dir=rtl] table.table-bordered.dataTable td:first-child,
[dir=rtl] table.table-bordered.dataTable th:first-child {
    border-right-width: 1px;
    border-left-width: 0
}

[dir=rtl] table.table-bordered.dataTable td:last-child,
[dir=rtl] table.table-bordered.dataTable th:last-child {
    border-inline-start-width: 1px
}

[dir=rtl] div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
    padding-inline-end: 0
}

[dir=rtl] div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
    padding-inline-start: 0
}

[dir=rtl] .dt-buttons {
    float: right !important
}

table.dataTable>tbody>tr.selected>* {
    background-color: var(--primary01);
    color: var(--default-text-color) !important;
    box-shadow: none !important
}

@media (min-width:768px) {
    .data-table-btn {
        position: absolute;
        inset-inline-start: 12.5rem;
        z-index: 1
    }
}

#file-export_wrapper .dt-buttons button {
    margin: .25rem
}

.data-table-btn {
    margin: -.4375rem 0 0 0
}

.swal2-container .swal2-title {
    font-size: 1.15rem;
    padding: 2rem 2rem .5rem
}

.swal2-container .swal2-footer {
    padding: 1.5rem 1.5rem 0
}

.swal2-container .swal2-popup {
    background: var(--custom-white);
    color: var(--default-text-color);
    padding: 0 0 2rem
}

.swal2-container .swal2-center>.swal2-popup {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.swal2-container .swal2-actions {
    margin: 1rem auto 0
}

.swal2-container .swal2-styled.swal2-confirm {
    margin: 0;
    padding: .375em .75rem;
    box-shadow: none;
    font-size: .8125rem;
    border-radius: .3rem
}

.swal2-container .swal2-styled.swal2-cancel:focus,
.swal2-container .swal2-styled.swal2-confirm:focus,
.swal2-container .swal2-styled.swal2-deny:focus {
    box-shadow: none
}

.swal2-container .swal2-styled.swal2-confirm {
    background-color: var(--primary-color) !important;
    color: #fff
}

.swal2-container .swal2-footer {
    border-top: 1px solid var(--default-border)
}

.swal2-container .swal2-icon {
    width: 4rem;
    height: 4rem;
    margin: 2rem auto 0
}

.swal2-container .swal2-icon.swal2-question {
    border-color: rgb(var(--success-rgb));
    color: rgb(var(--success-rgb))
}

.swal2-container .swal2-icon.swal2-error {
    border-color: rgb(var(--danger-rgb));
    color: rgb(var(--danger-rgb))
}

.swal2-container .swal2-icon.swal2-info {
    border-color: rgb(var(--info-rgb));
    color: rgb(var(--info-rgb))
}

.swal2-container .swal2-icon.swal2-warning {
    border-color: rgb(var(--warning-rgb));
    color: rgb(var(--warning-rgb))
}

.swal2-container .swal2-icon .swal2-icon-content {
    font-size: 3rem
}

.swal2-container .swal2-image {
    border-radius: .5rem
}

.swal2-container .swal2-html-container {
    margin: 0 1.6rem .3rem;
    font-size: .8rem;
    color: var(--text-muted)
}

.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
    inset-inline-start: 1em
}

.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    top: 1.9em;
    width: 2em;
    height: .3em;
    background-color: rgb(var(--danger-rgb))
}

.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
    inset-inline-end: 1em;
    inset-block-start: 1.875rem
}

.swal2-container .swal2-close:focus {
    box-shadow: none
}

.swal2-container .swal2-cancel,
.swal2-container .swal2-deny {
    margin-inline-start: .625rem
}

.swal2-container .swal2-close {
    font-size: 2rem;
    color: var(--text-muted);
    opacity: .5
}

.swal2-container .swal2-close:hover {
    color: var(--primary-color)
}

.swal2-container .swal2-styled.swal2-deny {
    background-color: rgb(var(--danger-rgb));
    padding: .375em .75rem;
    box-shadow: none;
    font-size: .8125rem;
    border-radius: .3rem
}

.swal2-container .swal2-styled.swal2-cancel {
    background-color: rgb(var(--light-rgb)) !important;
    color: var(--default-text-color);
    padding: .375em .75rem;
    box-shadow: none;
    font-size: .8125rem;
    border-radius: .3rem
}

.swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
    top: 2.05em;
    inset-inline-end: .45em;
    width: 2em
}

.swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.375em;
    inset-inline-start: .9125em;
    width: 1.2625em
}

.swal2-container .swal2-file:focus,
.swal2-container .swal2-input:focus,
.swal2-container .swal2-textarea:focus {
    border: 1px solid var(--default-border);
    box-shadow: none
}

[dir=rtl] .swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    inset-inline-start: 1.9125em
}

.swiper {
    border-radius: .5rem
}

[dir=rtl] .swiper {
    direction: ltr
}

[dir=rtl] .swiper-backface-hidden .swiper-slide {
    direction: rtl
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.swiper-button-next,
.swiper-button-prev {
    width: 1.563rem !important;
    height: 1.563rem !important;
    color: #fff !important;
    background-color: rgba(255, 255, 255, .3);
    border-radius: .5rem
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: .75rem !important;
    font-weight: 800 !important
}

.swiper-horizontal1 .swiper-slide {
    height: auto
}

.swiper-pagination-bullet {
    width: 1.25rem !important;
    height: .25rem !important;
    border-radius: .5rem !important;
    background: #fff !important
}

.swiper-pagination-bullet-active {
    background: #fff !important
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: rgb(var(--success-rgb)) !important
}

.swiper-pagination {
    color: #fff
}

.custom-pagination .swiper-pagination-bullet {
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: .188rem;
    background-color: rgba(255, 255, 255, .3) !important;
    color: #fff !important;
    opacity: 1 !important
}

.custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff !important;
    color: #000 !important
}

.swiper-scrollbar {
    background-color: rgba(255, 255, 255, .3) !important
}

.swiper-scrollbar-drag {
    background-color: #fff !important
}

.swiper.vertical {
    height: 21.875rem
}

.swiper-preview {
    height: 80%;
    width: 100%
}

.swiper-view {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0
}

.swiper-view .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: .4
}

.swiper-view .swiper-slide-thumb-active {
    opacity: 1
}

.swiper-preview .swiper-wrapper {
    margin-block-end: .65rem
}

.swiper-thumbs .swiper-slide img {
    border-radius: .5rem
}

pre[class*=language-]:after,
pre[class*=language-]:before {
    display: none !important
}

pre[class*=language-]>code {
    border-inline-start: 1px solid var(--default-border) !important;
    box-shadow: none !important;
    background-color: rgb(var(--light-rgb)) !important;
    border: 1px solid var(--default-border);
    border-radius: .5rem;
    background-image: none !important;
    white-space: pre-wrap
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
    background-color: var(--custom-white) !important;
    margin-bottom: 0 !important;
    max-height: 400px;
    overflow-y: hidden !important;
    border-radius: .5rem
}

code[class*=language-],
pre[class*=language-] {
    color: var(--default-text-color) !important;
    font-size: .82rem !important
}

pre[class*=language-] {
    margin: 0 !important
}

code[class*=language-] {
    padding: 1rem !important
}

.prism-toggle {
    margin: .25rem
}

[dir=rtl] pre[class*=language-]>code {
    border-left: 0;
    text-align: right
}

#draggable-left .card,
#draggable-right .card {
    touch-action: none
}

.star-rating {
    touch-action: none
}

.star-rating .star-value {
    touch-action: none
}

.upcoming-events-list li {
    margin-block-end: 1.5rem;
    padding-inline-start: 1.5rem;
    position: relative
}

.upcoming-events-list li::before {
    position: absolute;
    content: "";
    height: 100%;
    width: .25rem;
    inset-inline-start: 0;
    border-radius: .5rem
}

.upcoming-events-list li:nth-child(1)::before {
    background-color: var(--primary-color);
    opacity: .3
}

.upcoming-events-list li:nth-child(2)::before {
    background-color: rgb(var(--secondary-rgb));
    opacity: .3
}

.upcoming-events-list li:nth-child(3)::before {
    background-color: rgb(var(--success-rgb));
    opacity: .3
}

.upcoming-events-list li:nth-child(4)::before {
    background-color: rgb(var(--danger-rgb));
    opacity: .3
}

.upcoming-events-list li:nth-child(5)::before {
    background-color: rgb(var(--info-rgb));
    opacity: .3
}

.upcoming-events-list li:nth-child(5)::before {
    background-color: rgb(var(--warning-rgb));
    opacity: .3
}

.upcoming-events-list li:last-child {
    margin-block-end: 0
}

.border-top-card {
    border-top-width: .188rem !important;
    border-top-style: solid !important
}

.border-top-card.border-top-primary {
    border-top-color: var(--primary-color)
}

.border-top-card.border-top-secondary {
    border-top-color: rgb(var(--secondary-rgb))
}

.border-top-card.border-top-warning {
    border-top-color: rgb(var(--warning-rgb))
}

.border-top-card.border-top-info {
    border-top-color: rgb(var(--info-rgb))
}

.border-top-card.border-top-success {
    border-top-color: rgb(var(--success-rgb))
}

.border-top-card.border-top-danger {
    border-top-color: rgb(var(--danger-rgb))
}

#users-map {
    height: 14rem
}

#users-map #jvm-markers-labels-group {
    display: none
}

[dir=ltr] #views .apexcharts-yaxis {
    transform: translate(0, 0)
}

[data-vertical-style=closed][data-toggled=close-menu-close] .app-sidebar {
    display: none
}

@media (min-width:992px) {
    [data-vertical-style=closed][data-toggled=close-menu-close] .app-content {
        margin-inline-start: 0
    }
    [data-vertical-style=closed][data-toggled=close-menu-close] .app-header {
        padding-inline-start: 0
    }
}

[data-vertical-style=closed][data-toggled=close-menu-open] .app-sidebar {
    display: block
}

@media (min-width:992px) {
    [data-vertical-style=closed][data-toggled=close-menu-open] .app-content {
        margin-inline-start: 15rem
    }
    [data-vertical-style=closed][data-toggled=close-menu-open] .app-header {
        padding-inline-start: 15rem
    }
}

@media (min-width:992px) {
    [data-vertical-style=detached] .page {
        width: 95%;
        margin: 0 auto;
        padding-inline-start: 1.25rem
    }
    [data-vertical-style=detached] .app-sidebar {
        inset-block-start: calc(64px + 1.5rem);
        inset-block-end: 1.5rem;
        inset-inline-start: inherit;
        border-radius: .5rem;
        border: 1px solid var(--menu-border-color);
        height: auto
    }
    [data-vertical-style=detached] .app-sidebar .main-sidebar-header {
        display: none
    }
    [data-vertical-style=detached] .main-sidebar {
        height: 100%;
        margin-block-start: 0;
        box-shadow: none !important
    }
    [data-vertical-style=detached] .app-content {
        margin-inline-start: 15rem;
        position: relative
    }
    [data-vertical-style=detached][data-page-style=classic] .app-sidebar {
        border: 1px solid var(--menu-border-color)
    }
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-logo,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-white,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-dark,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-logo,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-white {
        display: none
    }
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-dark {
        display: block
    }
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-dark,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-logo,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-dark,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-logo,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-white,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-dark,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-logo,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-dark,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-logo,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-white {
        display: none
    }
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-white,
    [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-white {
        display: block
    }
    [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-logo,
    [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-white,
    [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-dark,
    [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-logo,
    [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-white {
        display: none
    }
    [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-dark {
        display: block
    }
    [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-dark,
    [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-white,
    [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-dark,
    [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-logo,
    [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-white {
        display: none
    }
    [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-logo {
        display: block
    }
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar {
        width: 5rem
    }
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .category-name,
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__angle,
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .badge,
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__label,
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide-menu {
        display: none !important
    }
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item {
        justify-content: center
    }
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .side-menu__icon {
        margin-inline-end: 0
    }
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category {
        padding: 1.2rem 1.65rem;
        position: relative
    }
    [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category:before {
        content: "";
        position: absolute;
        inset-inline-start: 2.25rem;
        inset-inline-end: 0;
        inset-block-start: 1.25rem;
        inset-block-end: 0;
        width: .35rem;
        border-radius: 3.125rem;
        height: .35rem;
        border: 1px solid var(--menu-prime-color);
        opacity: 1
    }
    [data-vertical-style=detached][data-toggled=detached-close] .app-content {
        margin-inline-start: 5rem;
        position: relative
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar {
        width: 15rem
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header {
        width: 15rem
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
        display: block !important
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: none !important
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .side-menu__item {
        justify-content: flex-start
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .side-menu__icon {
        margin-inline-end: .625rem
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .slide__category {
        padding: .75rem 1.65rem
    }
    [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .slide__category:before {
        display: none
    }
    [data-vertical-style=detached] .app-header {
        padding-inline-start: 0
    }
    [data-vertical-style=detached] .app-header .main-header-container {
        width: 94%
    }
    [data-vertical-style=detached] .app-header .horizontal-logo {
        padding: .85rem 0;
        display: block
    }
    [data-vertical-style=detached] .app-header .horizontal-logo img {
        height: 2rem;
        line-height: 2rem
    }
    [data-vertical-style=detached] .app-header .horizontal-logo .desktop-logo {
        display: block
    }
    [data-vertical-style=detached] .app-header .horizontal-logo .desktop-dark,
    [data-vertical-style=detached] .app-header .horizontal-logo .desktop-white,
    [data-vertical-style=detached] .app-header .horizontal-logo .toggle-dark,
    [data-vertical-style=detached] .app-header .horizontal-logo .toggle-logo,
    [data-vertical-style=detached] .app-header .horizontal-logo .toggle-white {
        display: none
    }
    [data-vertical-style=detached] .footer {
        box-shadow: none;
        background-color: transparent !important
    }
    [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before,
    [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
    [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
    [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
    [data-vertical-style=detached][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before {
        border-radius: .5rem
    }
}

@media (min-width:992px) {
    [data-vertical-style=doublemenu] .app-sidebar {
        width: 5rem
    }
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar {
        overflow: visible;
        height: 100%;
        box-shadow: none;
        margin-block-start: 0
    }
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header {
        width: 5rem;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px)
    }
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: block !important
    }
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none !important
    }
    [data-vertical-style=doublemenu] .app-sidebar .main-menu-container {
        margin-block-start: 3.75rem
    }
    [data-vertical-style=doublemenu] .app-sidebar .main-menu>.slide {
        padding: 0 1.2rem
    }
    [data-vertical-style=doublemenu] .app-sidebar .category-name,
    [data-vertical-style=doublemenu] .app-sidebar .side-menu__angle,
    [data-vertical-style=doublemenu] .app-sidebar .side-menu__label {
        display: none
    }
    [data-vertical-style=doublemenu] .app-sidebar .side-menu__icon {
        margin-inline-end: 0
    }
    [data-vertical-style=doublemenu] .app-sidebar .slide__category {
        display: none
    }
    [data-vertical-style=doublemenu] .app-sidebar .simplebar-content-wrapper {
        position: initial
    }
    [data-vertical-style=doublemenu] .app-sidebar .simplebar-mask {
        position: inherit
    }
    [data-vertical-style=doublemenu] .app-sidebar .simplebar-placeholder {
        height: auto !important
    }
    [data-vertical-style=doublemenu] .app-header {
        padding-inline-start: 17rem
    }
    [data-vertical-style=doublemenu] .app-content {
        margin-inline-start: 17rem
    }
    [data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
        position: absolute !important;
        inset-inline-start: 5rem !important;
        background: var(--menu-bg);
        box-shadow: none;
        transition: none !important;
        height: 100% !important;
        inset-inline-end: 0;
        border-inline-end: 1px solid var(--default-border)
    }
    [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child2,
    [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child3 {
        inset-inline-start: 0 !important;
        position: relative !important;
        height: auto !important;
        inset-block-start: 0 !important
    }
    [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child2 .slide:nth-child(2),
    [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child3 .slide:nth-child(2) {
        padding-block-start: 0
    }
    [data-vertical-style=doublemenu] .slide-menu.child1 .slide,
    [data-vertical-style=doublemenu] .slide-menu.child2 .slide,
    [data-vertical-style=doublemenu] .slide-menu.child3 .slide {
        padding-inline-start: 0
    }
    [data-vertical-style=doublemenu] .slide-menu.child1 .slide .side-menu__item,
    [data-vertical-style=doublemenu] .slide-menu.child2 .slide .side-menu__item,
    [data-vertical-style=doublemenu] .slide-menu.child3 .slide .side-menu__item {
        text-align: start
    }
    [data-vertical-style=doublemenu] .slide-menu.child1 .slide .side-menu__item:before,
    [data-vertical-style=doublemenu] .slide-menu.child2 .slide .side-menu__item:before,
    [data-vertical-style=doublemenu] .slide-menu.child3 .slide .side-menu__item:before {
        inset-block-start: .938rem;
        inset-inline-start: .75rem
    }
    [data-vertical-style=doublemenu] .slide-menu.child1 .side-menu__angle,
    [data-vertical-style=doublemenu] .slide-menu.child2 .side-menu__angle,
    [data-vertical-style=doublemenu] .slide-menu.child3 .side-menu__angle {
        display: block;
        inset-inline-end: 1rem;
        inset-block-start: .65rem
    }
    [data-vertical-style=doublemenu] .slide-menu.child2,
    [data-vertical-style=doublemenu] .slide-menu.child3 {
        min-width: 10rem
    }
    [data-vertical-style=doublemenu] .slide-menu.child1 {
        min-width: 12rem
    }
    [data-vertical-style=doublemenu] .slide-menu.child1 .slide:nth-child(2) {
        padding-block-start: .75rem
    }
    [data-vertical-style=doublemenu] .side-menu__label1 {
        display: block !important;
        font-size: .938rem;
        font-weight: 500;
        color: var(--menu-prime-color);
        border-block-end: 1px solid var(--default-border);
        padding: 1.25rem .75rem !important;
        width: 12rem;
        height: 3.75rem
    }
    [data-vertical-style=doublemenu] .slide-menu {
        display: none
    }
    [data-vertical-style=doublemenu] .slide-menu.double-menu-active {
        visibility: visible !important;
        display: block !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        border-radius: 0 !important;
        border-block-start: 0;
        overflow: hidden scroll
    }
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: none !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
        display: block !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: none !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
    [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: block !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
        display: block !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: block !important
    }
    [data-vertical-style=doublemenu][data-theme-mode=dark][data-page-style=classic] .slide.has-sub .slide-menu {
        border-inline-end: 1px solid var(--default-border)
    }
    [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-header {
        padding-inline-start: 5rem
    }
    [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-content {
        margin-inline-start: 5rem
    }
    [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-sidebar .slide.has-sub .slide-menu {
        display: none !important
    }
    [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-sidebar .main-sidebar {
        box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04)
    }
    [data-vertical-style=doublemenu][data-toggled=double-menu-open] .app-sidebar .main-sidebar .slide.side-menu__label1 {
        border-inline-end: 1px solid var(--default-border)
    }
}

@media (min-width:992px) {
    [data-nav-layout=horizontal] .sidemenu-layout-styles {
        display: none
    }
    [data-nav-layout=horizontal] .main-menu {
        display: flex !important;
        transition: all .5s ease
    }
    [data-nav-layout=horizontal] .main-menu-container {
        display: inline-flex
    }
    [data-nav-layout=horizontal] .side-menu__label .badge {
        display: none
    }
    [data-nav-layout=horizontal] .mega-menu {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3
    }
    [data-nav-layout=horizontal] .app-header {
        padding-inline-start: 0 !important;
        z-index: 104;
        box-shadow: none
    }
    [data-nav-layout=horizontal] .app-content {
        min-height: calc(100vh - 10.5rem);
        margin-block-start: 7rem;
        margin-inline-start: 0 !important
    }
    [data-nav-layout=horizontal] .app-content>.container-fluid {
        width: 94%;
        margin-inline-start: auto;
        margin-inline-end: auto
    }
    [data-nav-layout=horizontal] .app-sidebar .main-sidebar,
    [data-nav-layout=horizontal] .app-sidebar .simplebar-mask {
        overflow: visible
    }
    [data-nav-layout=horizontal] .app-sidebar .main-menu>.slide {
        margin: 0 .1875rem
    }
    [data-nav-layout=horizontal] .app-sidebar .main-sidebar {
        box-shadow: none
    }
    [data-nav-layout=horizontal] .main-sidebar .simplebar-vertical {
        visibility: hidden !important
    }
    [data-nav-layout=horizontal] .main-header-container,
    [data-nav-layout=horizontal] .main-sidebar {
        width: 94%;
        margin: 0 auto
    }
    [data-nav-layout=horizontal] .horizontal-logo {
        padding: .85rem 0;
        display: block
    }
}

@media (min-width:992px) and (min-width:992px) {
    [data-nav-layout=horizontal] .horizontal-logo .header-logo img {
        height: 2rem;
        line-height: 2rem
    }
    [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-logo {
        display: block
    }
    [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-dark,
    [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-white,
    [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-dark,
    [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-logo,
    [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-white {
        display: none
    }
}

@media (min-width:992px) {
    [data-nav-layout=horizontal] .main-header-container .sidemenu-toggle {
        display: none
    }
    [data-nav-layout=horizontal] .app-sidebar {
        width: 100% !important;
        height: 3rem;
        border-block-end: 1px solid var(--menu-border-color);
        inset-block-start: 3.75rem
    }
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 li,
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 li,
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 li {
        padding-inline-start: .5rem
    }
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__item:before,
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__item:before,
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__item:before {
        inset-block-start: 1rem
    }
    [data-nav-layout=horizontal] .app-sidebar .simplebar-content {
        padding: 0 !important;
        overflow: hidden
    }
    [data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper {
        overflow: visible !important;
        height: auto
    }
    [data-nav-layout=horizontal] .app-sidebar .main-sidebar {
        height: 3rem !important;
        padding: 0
    }
    [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
        inset-inline-start: 1.5rem !important;
        padding: .1875rem 0
    }
    [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
    [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3 {
        inset-inline-end: 100% !important;
        padding: .1875rem 0
    }
    [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
        inset-inline-start: auto !important;
        inset-inline-end: auto !important
    }
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 {
        position: absolute !important
    }
    [data-nav-layout=horizontal] .app-sidebar .side-menu__item {
        width: 100%;
        display: flex;
        border-radius: 0;
        padding: .92rem
    }
    [data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
        inset-inline-end: .45rem;
        display: block
    }
    [data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
        margin-inline-end: .25rem;
        margin-block-end: 0
    }
    [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
        background-color: var(--menu-bg);
        inset-block-start: 100% !important;
        box-shadow: .063rem .188rem .5rem var(--black-1);
        min-width: 12rem
    }
    [data-nav-layout=horizontal] .app-sidebar .slide {
        padding: 0
    }
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 {
        border-radius: 0 0 .25rem .25rem
    }
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide,
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.has-sub {
        width: 100%;
        display: flex;
        padding: 0 .1875rem
    }
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
    [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 {
        border-radius: .25rem
    }
    [data-nav-layout=horizontal] .app-sidebar .slide,
    [data-nav-layout=horizontal] .app-sidebar .slide.has-sub {
        position: static
    }
    [data-nav-layout=horizontal] .app-sidebar .main-menu {
        margin-block-end: 0
    }
    [data-nav-layout=horizontal] .main-sidebar-header {
        display: none !important
    }
    [data-nav-layout=horizontal] .main-sidebar {
        margin-block-start: 0;
        padding-block-end: 3rem
    }
    [data-nav-layout=horizontal] .slide__category {
        display: none
    }
    [data-nav-layout=horizontal] .main-menu-container .slide-left {
        inset-inline-start: 1.438rem
    }
    [data-nav-layout=horizontal] .main-menu-container .slide-left,
    [data-nav-layout=horizontal] .main-menu-container .slide-right {
        position: absolute;
        top: .563rem;
        padding: .375rem;
        color: var(--custom-white);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        border: 1px solid var(--default-border);
        border-radius: 3.125rem
    }
    [data-nav-layout=horizontal] .main-menu-container .slide-left,
    [data-nav-layout=horizontal] .main-menu-container .slide-right {
        position: absolute;
        top: .313rem;
        padding: .375rem;
        color: var(--custom-white);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        border: 1px solid var(--default-border);
        border-radius: 3.125rem;
        background: var(--custom-white)
    }
    [data-nav-layout=horizontal] .main-menu-container .slide-right {
        inset-inline-end: -2%
    }
    [data-nav-layout=horizontal] .main-menu-container .slide-left {
        inset-inline-start: -2%
    }
    [data-nav-layout=horizontal][page-style=classic] .app-sidebar {
        border-block-end: 1px solid var(--default-border)
    }
    [data-nav-layout=horizontal][dir=rtl] .main-menu-container .slide-left,
    [data-nav-layout=horizontal][dir=rtl] .main-menu-container .slide-right {
        transform: rotate(180deg)
    }
    [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=dark][data-icon-overlay=open] .app-sidebar:hover {
        -webkit-backdrop-filter: blur(0);
        backdrop-filter: blur(0)
    }
    [data-nav-layout=horizontal][data-menu-styles=light] .main-menu-container .slide-left,
    [data-nav-layout=horizontal][data-menu-styles=light] .main-menu-container .slide-right {
        --custom-white: #fff;
        --default-border: #f3f3f3
    }
    [data-nav-layout=horizontal][data-page-style=modern] .app-sidebar {
        border-block-end: 1px solid var(--header-border-color)
    }
    [data-nav-layout=horizontal][data-menu-styles=transparent][data-theme-mode=dark] .app-sidebar {
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px)
    }
}

@media (min-width:992px) {
    .icon-click .app-sidebar,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar {
        width: 5rem
    }
    .icon-click .app-sidebar .main-sidebar,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar {
        overflow: visible;
        height: 90%
    }
    .icon-click .app-sidebar .main-sidebar-header,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header {
        width: 5rem
    }
    .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: block
    }
    .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-white,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
    .icon-click .app-sidebar .category-name,
    .icon-click .app-sidebar .side-menu__angle,
    .icon-click .app-sidebar .side-menu__label,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .category-name,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .category-name,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__angle,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__label {
        display: none
    }
    .icon-click .app-sidebar .side-menu__icon,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__icon {
        margin-inline-end: 0
    }
    .icon-click .app-sidebar .slide__category,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .slide__category,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide__category {
        padding: 1.2rem 1.65rem;
        position: relative
    }
    .icon-click .app-sidebar .slide__category:before,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .slide__category:before,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide__category:before {
        content: "";
        position: absolute;
        inset-inline-start: 2.25rem;
        inset-inline-end: 0;
        inset-block-start: 1.25rem;
        inset-block-end: 0;
        width: .35rem;
        border-radius: 3.125rem;
        height: .35rem;
        border: 1px solid var(--menu-prime-color);
        opacity: 1
    }
    .icon-click .app-sidebar .simplebar-content-wrapper,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-content-wrapper {
        position: initial
    }
    .icon-click .app-sidebar .simplebar-mask,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-mask {
        position: inherit
    }
    .icon-click .app-sidebar .simplebar-placeholder,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-placeholder {
        height: auto !important
    }
    .icon-click .app-header,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-header,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-header {
        padding-inline-start: 5rem
    }
    .icon-click .app-content,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .app-content,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-content {
        margin-inline-start: 5rem
    }
    .icon-click .slide.side-menu__label1,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.side-menu__label1,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.side-menu__label1 {
        display: block;
        padding: .5rem 1rem !important;
        border-block-end: 1px solid var(--default-border)
    }
    .icon-click .slide.has-sub .slide-menu,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu {
        position: absolute !important;
        inset-inline-start: 5rem !important;
        background: var(--menu-bg);
        inset-block-start: auto !important;
        box-shadow: .125rem .063rem .5rem var(--black-1);
        transition: none !important
    }
    .icon-click .slide.has-sub .slide-menu.child2,
    .icon-click .slide.has-sub .slide-menu.child3,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu.child2,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu.child3,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu.child2,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu.child3 {
        inset-inline-start: 12rem !important
    }
    .icon-click .slide-menu.child1,
    .icon-click .slide-menu.child2,
    .icon-click .slide-menu.child3,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 {
        min-width: 12rem
    }
    .icon-click .slide-menu.child1 .slide .side-menu__item,
    .icon-click .slide-menu.child2 .slide .side-menu__item,
    .icon-click .slide-menu.child3 .slide .side-menu__item,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .slide .side-menu__item,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .slide .side-menu__item,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .slide .side-menu__item,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .slide .side-menu__item,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .slide .side-menu__item,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .slide .side-menu__item {
        text-align: start
    }
    .icon-click .slide-menu.child1 .slide .side-menu__item:before,
    .icon-click .slide-menu.child2 .slide .side-menu__item:before,
    .icon-click .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .slide .side-menu__item:before {
        inset-block-start: .938rem;
        inset-inline-start: .75rem
    }
    .icon-click .slide-menu.child1 .side-menu__angle,
    .icon-click .slide-menu.child2 .side-menu__angle,
    .icon-click .slide-menu.child3 .side-menu__angle,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .side-menu__angle,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .side-menu__angle,
    [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .side-menu__angle,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .side-menu__angle,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .side-menu__angle,
    [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .side-menu__angle {
        display: block;
        inset-inline-end: .5rem;
        inset-block-start: .65rem
    }
}

[data-nav-layout=horizontal][data-nav-style=icon-click] .mega-menu {
    -moz-columns: 1;
    columns: 1
}

@media (min-width:992px) {
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-menu>.slide {
        padding: 0 1.2rem
    }
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child1,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child2,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child3 {
        border-radius: 0 .5rem .5rem 0
    }
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
        border-radius: .5rem 0 0 .5rem
    }
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
        display: block
    }
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar {
        position: absolute
    }
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child1 li.slide,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child2 li.slide,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child3 li.slide {
        padding-inline-start: 0
    }
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child1 li.slide a,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child2 li.slide a,
    [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child3 li.slide a {
        border-radius: 0
    }
}

@media (min-width:992px) {
    .icon-hover .app-sidebar,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar {
        width: 5rem
    }
    .icon-hover .app-sidebar .main-sidebar,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar {
        overflow: visible;
        height: 90%
    }
    .icon-hover .app-sidebar .main-sidebar-header,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header {
        width: 5rem
    }
    .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: block
    }
    .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-white,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
    .icon-hover .app-sidebar .category-name,
    .icon-hover .app-sidebar .side-menu__angle,
    .icon-hover .app-sidebar .side-menu__label,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .category-name,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__angle,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__label,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .category-name,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__angle,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__label {
        display: none
    }
    .icon-hover .app-sidebar .side-menu__icon,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__icon,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__icon {
        margin-inline-end: 0
    }
    .icon-hover .app-sidebar .slide__category,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide__category,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide__category {
        padding: 1.2rem 1.65rem;
        position: relative
    }
    .icon-hover .app-sidebar .slide__category:before,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide__category:before,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide__category:before {
        content: "";
        position: absolute;
        inset-inline-start: 2.25rem;
        inset-inline-end: 0;
        inset-block-start: 1.25rem;
        inset-block-end: 0;
        width: .35rem;
        border-radius: 3.125rem;
        height: .35rem;
        border: 1px solid var(--menu-prime-color);
        opacity: 1
    }
    .icon-hover .app-sidebar .simplebar-content-wrapper,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-content-wrapper,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-content-wrapper {
        position: initial
    }
    .icon-hover .app-sidebar .simplebar-mask,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-mask,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-mask {
        position: inherit
    }
    .icon-hover .app-sidebar .simplebar-placeholder,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-placeholder,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-placeholder {
        height: auto !important
    }
    .icon-hover .app-header,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-header,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-header {
        padding-inline-start: 5rem
    }
    .icon-hover .app-content,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-content,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-content {
        margin-inline-start: 5rem
    }
    .icon-hover .slide.side-menu__label1,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.side-menu__label1,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.side-menu__label1 {
        display: block;
        padding: .5rem 1rem !important;
        border-block-end: 1px solid var(--default-border)
    }
    .icon-hover .slide.has-sub .slide-menu,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu {
        position: absolute !important;
        inset-inline-start: 5rem !important;
        background: var(--menu-bg);
        inset-block-start: auto !important;
        box-shadow: .125rem .063rem .5rem var(--black-1);
        transition: none !important
    }
    .icon-hover .slide.has-sub .slide-menu.child2,
    .icon-hover .slide.has-sub .slide-menu.child3,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu.child2,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu.child3,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu.child2,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu.child3 {
        inset-inline-start: 11.81rem !important
    }
    .icon-hover .slide-menu.child1,
    .icon-hover .slide-menu.child2,
    .icon-hover .slide-menu.child3,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 {
        min-width: 12rem
    }
    .icon-hover .slide-menu.child1 .slide .side-menu__item,
    .icon-hover .slide-menu.child2 .slide .side-menu__item,
    .icon-hover .slide-menu.child3 .slide .side-menu__item,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .slide .side-menu__item,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .slide .side-menu__item,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .slide .side-menu__item,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .slide .side-menu__item,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .slide .side-menu__item,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .slide .side-menu__item {
        text-align: start
    }
    .icon-hover .slide-menu.child1 .slide .side-menu__item:before,
    .icon-hover .slide-menu.child2 .slide .side-menu__item:before,
    .icon-hover .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .slide .side-menu__item:before {
        inset-block-start: .938rem;
        inset-inline-start: .75rem
    }
    .icon-hover .slide-menu.child1 .side-menu__angle,
    .icon-hover .slide-menu.child2 .side-menu__angle,
    .icon-hover .slide-menu.child3 .side-menu__angle,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .side-menu__angle,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .side-menu__angle,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .side-menu__angle,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .side-menu__angle,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .side-menu__angle,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .side-menu__angle {
        display: block;
        inset-inline-end: .5rem;
        inset-block-start: .65rem
    }
    .icon-hover .slide.has-sub:hover .slide-menu.child1,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 {
        display: block !important
    }
    .icon-hover .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 {
        display: block !important
    }
    .icon-hover .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
    [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
    [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3 {
        display: block !important
    }
}

[data-nav-layout=horizontal][data-nav-style=icon-hover] .mega-menu {
    -moz-columns: 1;
    columns: 1
}

@media (min-width:992px) {
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-menu>.slide {
        padding: 0 1.2rem
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child1,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child2,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child3 {
        border-radius: 0 .5rem .5rem 0
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
        border-radius: .5rem 0 0 .5rem
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
        display: block
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar {
        position: absolute
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 {
        padding: .1875rem
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1 li.slide,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2 li.slide,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 li.slide {
        padding-inline-start: 0
    }
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1 li.slide a,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2 li.slide a,
    [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 li.slide a {
        border-radius: 0
    }
}

@media (min-width:992px) {
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar {
        width: 5rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header {
        width: 5rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
        display: block
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .category-name,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__angle,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__label {
        display: none
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide.has-sub.open .slide-menu {
        display: none !important
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__icon {
        margin-inline-end: 0
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category {
        padding: 1.2rem 1.65rem;
        position: relative
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category:before {
        content: "";
        position: absolute;
        inset-inline-start: 2.25rem;
        inset-inline-end: 0;
        inset-block-start: 1.25rem;
        inset-block-end: 0;
        width: .35rem;
        border-radius: 3.125rem;
        height: .35rem;
        border: 1px solid var(--menu-prime-color);
        opacity: 1
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .badge {
        display: none
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .side-menu__item {
        justify-content: center
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-header {
        padding-inline-start: 5rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-content {
        margin-inline-start: 5rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar {
        width: 15rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header {
        width: 15rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
        display: block
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .side-menu__item {
        justify-content: flex-start
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .side-menu__icon {
        margin-inline-end: .625rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .slide__category {
        padding: .75rem 1.65rem
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .slide__category:before {
        display: none
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-dark {
        display: block
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-dark,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-logo,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-dark {
        display: block
    }
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-logo,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-white,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-dark,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-logo,
    [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-white {
        display: none
    }
}

@media (min-width:992px) {
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar {
        position: absolute;
        width: 9rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .main-sidebar {
        overflow: visible
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .main-sidebar-header {
        width: 9rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__icon {
        margin-inline-end: 0;
        margin-block-end: .5rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide {
        padding: 0
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__angle,
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide__category {
        display: none
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide.has-sub.open .slide-menu {
        display: none !important
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__item {
        border-radius: 0
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__item,
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__label {
        display: block;
        text-align: center
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open])[data-menu-position=fixed] .app-sidebar {
        position: fixed
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-header {
        padding-inline-start: 9rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close] .app-content {
        margin-inline-start: 9rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar {
        width: 15rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .main-sidebar-header {
        width: 15rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .side-menu__icon {
        margin-block-end: 0;
        margin-inline-end: .625rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide {
        padding: 0 .625rem
    }
    [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu .child2 li,
    [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu .child3 li,
    [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu.child1 li {
        padding: 0;
        padding-inline-start: 1.5rem
    }
}

@media (min-width:992px) {
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__item {
        display: flex !important;
        padding-top: .93rem;
        padding-inline-end: 1.75rem;
        padding-bottom: .93rem;
        padding-inline-start: .75rem
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
        margin-block-end: 0 !important;
        margin-inline-end: .35rem !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
        display: block !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu {
        inset-inline-start: auto !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
        inset-inline-start: auto !important;
        inset-inline-end: auto !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
        inset-block-start: 100% !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-header {
        padding-inline-start: 9.5rem
    }
}

[data-nav-style=menu-click][data-toggled=menu-click-closed] .app-header {
    padding-inline-start: 9.5rem
}

@media (min-width:992px) {
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar {
        position: absolute
    }
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .side-menu__item {
        border-radius: .5rem;
        margin: .25rem .25rem 0 .25rem !important
    }
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .side-menu__item:last-child {
        margin: .25rem !important
    }
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child1,
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child2,
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child3 {
        border-radius: 0 .5rem .5rem 0
    }
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
    [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
        border-radius: .5rem 0 0 .5rem
    }
}

@media (min-width:992px) {
    .menu-click .app-sidebar,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar {
        width: 9.5rem
    }
    .menu-click .app-sidebar .main-sidebar,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar {
        overflow: visible;
        height: 90%
    }
    .menu-click .app-sidebar .main-sidebar-header,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header {
        width: 9.5rem
    }
    .menu-click .app-sidebar .side-menu__icon,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__icon {
        margin-inline-end: 0;
        margin-block-end: .5rem
    }
    .menu-click .app-sidebar .slide,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide {
        padding: 0
    }
    .menu-click .app-sidebar .slide-menu.child1,
    .menu-click .app-sidebar .slide-menu.child2,
    .menu-click .app-sidebar .slide-menu.child3,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 {
        min-width: 12rem
    }
    .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item,
    .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item,
    .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item {
        text-align: start
    }
    .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
    .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
    .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before {
        inset-block-start: .938rem;
        inset-inline-start: .75rem
    }
    .menu-click .app-sidebar .slide-menu.child1 .side-menu__angle,
    .menu-click .app-sidebar .slide-menu.child2 .side-menu__angle,
    .menu-click .app-sidebar .slide-menu.child3 .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__angle,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .side-menu__angle,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .side-menu__angle,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .side-menu__angle {
        display: block;
        inset-inline-end: .5rem;
        inset-block-start: .65rem
    }
    .menu-click .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
    .menu-click .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
    .menu-click .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
    .menu-click .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
    .menu-click .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
    .menu-click .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide.side-menu__label1 {
        display: none
    }
    .menu-click .app-sidebar .side-menu__angle,
    .menu-click .app-sidebar .slide__category,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide__category,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__angle,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide__category {
        display: none
    }
    .menu-click .app-sidebar .side-menu__item,
    .menu-click .app-sidebar .side-menu__label,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__item,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__item,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__label {
        display: block;
        text-align: center
    }
    .menu-click .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu {
        position: absolute !important;
        inset-inline-start: 9.5rem !important;
        background: var(--menu-bg);
        inset-block-start: auto !important;
        box-shadow: .125rem .063rem .5rem var(--black-1);
        transition: none !important
    }
    .menu-click .app-sidebar .slide.has-sub .slide-menu.child2,
    .menu-click .app-sidebar .slide.has-sub .slide-menu.child3,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
        inset-inline-start: 12rem !important
    }
    .menu-click .app-sidebar .simplebar-content-wrapper,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-content-wrapper {
        position: initial
    }
    .menu-click .app-sidebar .simplebar-mask,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-mask {
        position: inherit
    }
    .menu-click .app-sidebar .simplebar-placeholder,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-placeholder {
        height: auto !important
    }
    .menu-click .app-content,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-content,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-content {
        margin-inline-start: 9.5rem
    }
}

@media (max-width:991.98px) {
    .menu-click .app-sidebar,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar,
    [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar {
        width: 15rem
    }
}

@media (min-width:992px) {
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item {
        display: flex !important;
        padding-top: .93rem;
        padding-inline-end: 1.75rem;
        padding-bottom: .93rem;
        padding-inline-start: .75rem
    }
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
        margin-block-end: 0 !important;
        margin-inline-end: .35rem !important
    }
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
        display: block !important
    }
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
        inset-inline-start: auto !important;
        inset-inline-end: auto !important
    }
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
        inset-block-start: 100% !important
    }
}

@media (min-width:992px) {
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar {
        position: absolute
    }
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item {
        border-radius: .5rem;
        margin: .25rem .25rem 0 .25rem !important
    }
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item:last-child {
        margin: .25rem !important
    }
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child1,
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child2,
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child3 {
        border-radius: 0 .5rem .5rem 0
    }
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
    [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
        border-radius: .5rem 0 0 .5rem
    }
}

@media (min-width:992px) {
    .menu-hover .app-sidebar,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar {
        width: 9.5rem
    }
    .menu-hover .app-sidebar .main-sidebar,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar {
        overflow: visible;
        height: 90%
    }
    .menu-hover .app-sidebar .main-sidebar-header,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header {
        width: 9.5rem
    }
    .menu-hover .app-sidebar .side-menu__icon,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__icon {
        margin-inline-end: 0;
        margin-block-end: .5rem
    }
    .menu-hover .app-sidebar .slide,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide {
        padding: 0
    }
    .menu-hover .app-sidebar .slide-menu.child1,
    .menu-hover .app-sidebar .slide-menu.child2,
    .menu-hover .app-sidebar .slide-menu.child3,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 {
        min-width: 12rem;
        display: none !important
    }
    .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item,
    .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item,
    .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item {
        text-align: start
    }
    .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
    .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
    .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before {
        inset-block-start: .938rem;
        inset-inline-start: .75rem
    }
    .menu-hover .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
    .menu-hover .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
    .menu-hover .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
    .menu-hover .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
    .menu-hover .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
    .menu-hover .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide.side-menu__label1 {
        display: none
    }
    .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 {
        display: block !important
    }
    .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 {
        display: block !important
    }
    .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3 {
        display: block !important
    }
    .menu-hover .app-sidebar .side-menu__angle,
    .menu-hover .app-sidebar .slide__category,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide__category,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__angle,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide__category {
        display: none
    }
    .menu-hover .app-sidebar .slide-menu.child1 .side-menu__angle,
    .menu-hover .app-sidebar .slide-menu.child2 .side-menu__angle,
    .menu-hover .app-sidebar .slide-menu.child3 .side-menu__angle,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__angle,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__angle,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__angle,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .side-menu__angle,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .side-menu__angle,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .side-menu__angle {
        display: block;
        inset-inline-end: .5rem;
        inset-block-start: .65rem
    }
    .menu-hover .app-sidebar .side-menu__item,
    .menu-hover .app-sidebar .side-menu__label,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__label {
        display: block;
        text-align: center
    }
    .menu-hover .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu {
        position: absolute !important;
        inset-inline-start: 9.5rem !important;
        background: var(--menu-bg);
        inset-block-start: auto !important;
        box-shadow: .125rem .063rem .5rem var(--black-1);
        transition: none !important
    }
    .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2,
    .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
        inset-inline-start: 12rem !important
    }
    .menu-hover .app-sidebar .simplebar-content-wrapper,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-content-wrapper {
        position: initial
    }
    .menu-hover .app-sidebar .simplebar-mask,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-mask {
        position: inherit
    }
    .menu-hover .app-sidebar .simplebar-placeholder,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-placeholder {
        height: auto !important
    }
    .menu-hover .app-header,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-header,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-header {
        padding-inline-start: 9.5rem
    }
    .menu-hover .app-content,
    [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-content,
    [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-content {
        margin-inline-start: 9.5rem
    }
}

.main-sidebar {
    margin-block-start: 3.72rem;
    padding-block-start: .5rem;
    padding-block-end: 5rem;
    height: 100vh;
    position: relative;
    overflow: auto
}

[dir=rtl] .app-sidebar .fe-chevron-right {
    transform: rotate(180deg)
}

[dir=rtl] .sidemenu-toggle .header-link .header-link-icon {
    transform: rotate(180deg)
}

.app-sidebar {
    width: 15rem;
    height: 100%;
    background: var(--menu-bg);
    border-inline-end: 1px solid var(--menu-border-color);
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    font-family: Montserrat, sans-serif;
    z-index: 103;
    transition: all 50ms ease
}

.app-sidebar .main-sidebar-header {
    height: 3.75rem;
    width: 15rem;
    position: fixed;
    display: flex;
    background: var(--menu-bg);
    z-index: 9;
    align-items: center;
    justify-content: center;
    padding: .813rem 1.25rem;
    border-inline-end: 1px solid var(--menu-border-color);
    border-block-end: 1px solid var(--menu-border-color);
    transition: all 50ms ease
}

.app-sidebar .main-sidebar-header .header-logo img {
    height: 2rem;
    line-height: 2rem
}

.app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block
}

.app-sidebar .main-sidebar-header .header-logo .desktop-dark,
.app-sidebar .main-sidebar-header .header-logo .desktop-white,
.app-sidebar .main-sidebar-header .header-logo .toggle-dark,
.app-sidebar .main-sidebar-header .header-logo .toggle-logo,
.app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none
}

.app-sidebar .slide__category {
    color: var(--menu-prime-color);
    font-size: .6rem;
    font-weight: 600;
    letter-spacing: .065rem;
    text-transform: uppercase;
    padding: .75rem 1.65rem;
    white-space: nowrap;
    opacity: .5;
    position: relative
}

.app-sidebar .slide {
    margin: 0;
    padding: 0 .75rem
}

.app-sidebar .side-menu__item {
    padding: .75rem;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: .78rem;
    color: var(--menu-prime-color);
    font-weight: 500;
    border-radius: .5rem
}

.app-sidebar .side-menu__item.active,
.app-sidebar .side-menu__item:hover {
    color: var(--menu-prime-color);
    background-color: rgba(var(--light-rgb), .5)
}

.app-sidebar .side-menu__item.active .side-menu__angle,
.app-sidebar .side-menu__item.active .side-menu__label,
.app-sidebar .side-menu__item:hover .side-menu__angle,
.app-sidebar .side-menu__item:hover .side-menu__label {
    color: var(--menu-prime-color)
}

.app-sidebar .side-menu__item.active .side-menu__icon,
.app-sidebar .side-menu__item:hover .side-menu__icon {
    fill: var(--menu-prime-color);
    color: var(--menu-prime-color)
}

.app-sidebar .side-menu__item.active {
    font-weight: 600
}

.app-sidebar .slide-menu.child1 .side-menu__item.active,
.app-sidebar .slide-menu.child2 .side-menu__item.active,
.app-sidebar .slide-menu.child3 .side-menu__item.active {
    background-color: transparent
}

.app-sidebar .slide-menu {
    padding: 0
}

.app-sidebar .slide-menu.child1 .side-menu__item,
.app-sidebar .slide-menu.child2 .side-menu__item {
    padding: .45rem 1.6rem
}

.app-sidebar .slide-menu.child1 .side-menu__item:before,
.app-sidebar .slide-menu.child2 .side-menu__item:before,
.app-sidebar .slide-menu.child3 .side-menu__item:before {
    position: absolute;
    content: "";
    width: .3rem;
    height: .3rem;
    border: 1px solid var(--menu-prime-color);
    border-radius: 50%;
    background-color: transparent;
    inset-inline-start: .5rem;
    opacity: .5
}

.app-sidebar .slide-menu.child1 .side-menu__item:hover,
.app-sidebar .slide-menu.child2 .side-menu__item:hover,
.app-sidebar .slide-menu.child3 .side-menu__item:hover {
    color: var(--menu-prime-color)
}

.app-sidebar .slide-menu.child1 .side-menu__item:hover:before,
.app-sidebar .slide-menu.child2 .side-menu__item:hover:before,
.app-sidebar .slide-menu.child3 .side-menu__item:hover:before {
    content: "";
    border: 1px solid var(--menu-prime-color)
}

.app-sidebar .slide-menu.child1 .side-menu__item.active:before,
.app-sidebar .slide-menu.child2 .side-menu__item.active:before,
.app-sidebar .slide-menu.child3 .side-menu__item.active:before {
    position: absolute;
    content: "";
    width: .3rem;
    height: .3rem;
    border: 1px solid var(--menu-prime-color);
    border-radius: 50%;
    background-color: transparent;
    opacity: 1
}

.app-sidebar .slide-menu.child1 li,
.app-sidebar .slide-menu.child2 li,
.app-sidebar .slide-menu.child3 li {
    padding: 0;
    padding-inline-start: 1.5rem;
    position: relative
}

.app-sidebar .side-menu__label {
    white-space: nowrap;
    color: var(--menu-prime-color);
    position: relative;
    font-size: .85rem;
    line-height: 1;
    vertical-align: middle
}

.app-sidebar .side-menu__icon {
    margin-inline-end: .625rem;
    /* width: 1.15rem;
    height: 1.15rem; */
    font-size: 1.15rem;
    text-align: center;
    color: var(--menu-prime-color);
    fill: var(--menu-prime-color);
    border-radius: .5rem
}

.app-sidebar .side-menu__angle {
    transform-origin: center;
    position: absolute;
    inset-inline-end: .75rem;
    font-size: .85rem;
    color: var(--menu-prime-color);
    transition: all 50ms ease
}

.app-sidebar .slide.side-menu__label1 {
    display: none
}

.horizontal-logo {
    padding: .85rem 0
}

.slide.has-sub .slide-menu {
    transform: translate(0, 0) !important;
    visibility: visible !important
}

.nav ul li {
    list-style-type: none
}

.nav>ul {
    padding-inline-start: 0
}

.slide-menu {
    display: none
}

.slide.has-sub {
    display: grid
}

.slide.has-sub.open>.side-menu__item .side-menu__angle {
    transform: rotate(90deg)
}

@media (min-width:992px) {
    [data-toggled=open] .page {
        position: absolute
    }
    [data-toggled=icon-click-closed]:not([data-nav-layout=horizontal]) .app-content,
    [data-toggled=icon-hover-closed]:not([data-nav-layout=horizontal]) .app-content,
    [data-toggled=icon-text-close]:not([data-nav-layout=horizontal]) .app-content,
    [data-toggled=menu-click-closed]:not([data-nav-layout=horizontal]) .app-content,
    [data-toggled=menu-hover-closed]:not([data-nav-layout=horizontal]) .app-content {
        min-height: calc(100vh + 50rem)
    }
}

@media (max-width:991.98px) {
    .horizontal-logo .header-logo .desktop-dark,
    .horizontal-logo .header-logo .desktop-logo,
    .horizontal-logo .header-logo .desktop-white,
    .horizontal-logo .header-logo .toggle-dark,
    .horizontal-logo .header-logo .toggle-white {
        display: none
    }
    .horizontal-logo .header-logo .toggle-logo {
        display: block
    }
    .main-content {
        padding-block-start: 3.75rem
    }
    .main-sidebar-header {
        display: none !important
    }
    .main-sidebar {
        margin-block-start: 0
    }
    .app-sidebar {
        inset-block-start: 0
    }
    .main-menu {
        margin: 0 !important
    }
}

.slide-left,
.slide-right {
    display: none
}

[data-nav-layout=vertical] .slide.has-sub.active>.side-menu__item {
    margin-block: .25rem
}

@media (min-width:992px) {
    .horizontal-logo {
        display: none
    }
}

#responsive-overlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    background-color: rgba(15, 23, 42, .5);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .1s
}

#responsive-overlay.active {
    visibility: visible
}

.main-chart-wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 -1.25rem
}

.main-chart-wrapper .chat-info,
.main-chart-wrapper .chat-user-details,
.main-chart-wrapper .main-chat-area {
    background-color: var(--custom-white);
    height: calc(100vh - 8rem);
    border-radius: .5rem
}

.main-chart-wrapper .chat-calls-tab,
.main-chart-wrapper .chat-groups-tab,
.main-chart-wrapper .chat-users-tab {
    max-height: calc(100vh - 21rem)
}

.main-chart-wrapper .chat-content {
    max-height: calc(100vh - 17rem)
}

.main-chart-wrapper .chat-content .simplebar-content-wrapper .simplebar-content {
    margin-top: auto
}

.main-chart-wrapper .chat-content ul li {
    margin-block-end: 1rem
}

.main-chart-wrapper .chat-content ul li:last-child {
    margin-block-end: 0
}

.main-chart-wrapper .responsive-chat-close,
.main-chart-wrapper button.responsive-userinfo-open {
    display: none
}

.main-chart-wrapper .chat-info {
    position: relative
}

.main-chart-wrapper .chat-info .chat-add-icon {
    position: absolute;
    inset-block-end: .75rem;
    inset-inline-end: .8rem;
    z-index: 1;
    box-shadow: 0 0 .5rem rgba(var(--secondary-rgb), .5)
}

.main-chart-wrapper .chat-info .nav-link {
    color: var(--text-muted);
    border-radius: 0
}

.main-chart-wrapper .chat-info .nav-link.active {
    background-color: var(--primary01)
}

.main-chart-wrapper .chat-info .tab-pane {
    padding: 0
}

.main-chart-wrapper .chat-info .chat-groups-tab li {
    padding: .625rem 1.25rem
}

.main-chart-wrapper .chat-info .chat-groups-tab .group-indivudial {
    color: var(--primary-color);
    font-weight: 600
}

.main-chart-wrapper .chat-info .chat-calls-tab li {
    padding: .625rem 1.25rem
}

.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-success i,
.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-success i {
    color: rgb(var(--success-rgb));
    font-size: .875rem
}

.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-failed i,
.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-failed i {
    color: rgb(var(--danger-rgb));
    font-size: .875rem
}

.main-chart-wrapper .chat-info .chat-groups-tab li,
.main-chart-wrapper .chat-info .chat-users-tab li {
    padding: .625rem 1.25rem
}

.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg,
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg {
    color: var(--text-muted);
    max-width: 11.25rem;
    display: inline-block
}

.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-msg,
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-msg {
    color: rgb(var(--success-rgb))
}

.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-read-icon,
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-read-icon {
    display: none
}

.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon,
.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon {
    line-height: 1
}

.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon i,
.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon i {
    font-size: 1rem;
    color: rgb(var(--success-rgb))
}

.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread,
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-msg,
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-msg {
    color: var(--default-text-color)
}

.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-read-icon i,
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-read-icon i {
    color: var(--text-muted)
}

.main-chart-wrapper .chat-info .chat-groups-tab li.chat-inactive .chat-read-icon,
.main-chart-wrapper .chat-info .chat-users-tab li.chat-inactive .chat-read-icon {
    display: none
}

.main-chart-wrapper .chat-user-details {
    padding: 1.5rem
}

.main-chart-wrapper .chat-user-details .avatar {
    outline: .25rem solid var(--primary02)
}

.main-chart-wrapper .chat-user-details .shared-files li {
    margin-block-end: 1rem
}

.main-chart-wrapper .chat-user-details .shared-files li:last-child {
    margin-block-end: 0
}

.main-chart-wrapper .chat-user-details .shared-files .shared-file-icon i {
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: .3rem;
    border: 1px solid var(--default-border);
    display: flex;
    align-items: center;
    padding: 1.125rem;
    justify-content: center;
    font-size: 1.125rem;
    color: var(--text-muted)
}

.main-chart-wrapper .chat-user-details .chat-media img {
    width: 100%;
    border-radius: .5rem;
    margin-bottom: 1.25rem
}

.main-chart-wrapper .main-chat-area {
    position: relative
}

.main-chart-wrapper .main-chat-area .chat-content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 2.5rem
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info {
    color: var(--default-text-color);
    font-weight: 600;
    font-size: .813rem
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time {
    color: var(--text-muted);
    font-size: .75rem;
    font-weight: 500
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time .chat-read-mark i {
    color: rgb(var(--success-rgb));
    margin-inline-end: .3rem
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div {
    margin-block-start: .4rem;
    padding: .75rem;
    width: -moz-fit-content;
    width: fit-content
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div p {
    font-size: .813rem
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: .5rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .main-chat-msg div {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    border-start-start-radius: 0;
    border-start-end-radius: .3rem;
    border-end-end-radius: .3rem;
    border-end-start-radius: .3rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .msg-sent-time {
    margin-inline-start: .25rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
    justify-content: end;
    text-align: end
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .main-chat-msg div {
    background-color: var(--primary-color);
    color: #fff;
    border-start-start-radius: .3rem;
    border-start-end-radius: 0;
    border-end-end-radius: .3rem;
    border-end-start-radius: .3rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .msg-sent-time {
    margin-inline-end: .25rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end,
.main-chart-wrapper .main-chat-area .chat-content .chat-item-start {
    display: flex
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .chat-list-inner,
.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .chat-list-inner {
    display: flex;
    align-items: top;
    max-width: 75%
}

.main-chart-wrapper .main-chat-area .chat-footer {
    width: 100%;
    box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .1)
}

.main-chart-wrapper .main-chat-area .chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 4.75rem;
    padding: 0 1.5rem;
    border-top: 1px solid var(--default-border);
    background-color: var(--custom-white);
    position: absolute;
    inset-inline-start: auto;
    inset-inline-end: auto;
    inset-block-end: 0
}

.main-chart-wrapper .main-chat-area .chat-day-label {
    text-align: center;
    color: var(--text-muted);
    margin-block-end: 2rem;
    opacity: .6;
    position: relative
}

.main-chart-wrapper .main-chat-area .chat-day-label span {
    padding: .188rem .5rem;
    font-size: .7rem;
    background-color: var(--primary02);
    border-radius: .3rem;
    color: var(--primary-color)
}

@media (min-width:992px) {
    .main-chart-wrapper .chat-info {
        min-width: 21.875rem;
        max-width: 21.875rem
    }
}

.main-chart-wrapper .main-chat-area {
    width: 100%;
    max-width: 100%;
    overflow: hidden
}

.main-chart-wrapper .chat-user-details {
    min-width: 21.875rem;
    max-width: 21.875rem
}

@media (max-width:575.98px) {
    .main-chart-wrapper .chat-user-details {
        min-width: 17.875rem;
        max-width: 17.875rem
    }
}

@media (max-width:1275.98px) and (min-width:992px) {
    .main-chart-wrapper .chat-info {
        min-width: 21.875rem;
        max-width: 21.875rem
    }
    .main-chart-wrapper .main-chat-area {
        width: 100%;
        max-width: 100%;
        overflow: hidden
    }
    .main-chart-wrapper .chat-user-details {
        display: none
    }
}

@media (max-width:991.98px) {
    .main-chart-wrapper .chat-info {
        width: 100%
    }
    .main-chart-wrapper .main-chat-area {
        display: none;
        min-width: 100%;
        max-width: 100%
    }
    .main-chart-wrapper .responsive-chat-close {
        display: block
    }
}

@media (max-width:1400px) {
    .main-chart-wrapper .chat-user-details {
        display: none;
        position: absolute
    }
    .main-chart-wrapper .chat-user-details.open {
        display: block;
        inset-inline-end: 0;
        inset-block-start: .5rem;
        box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
        border-inline-start: 1px solid var(--default-border)
    }
    .main-chart-wrapper button.responsive-userinfo-open {
        display: block
    }
}

@media (max-width:991.98px) {
    .main-chart-wrapper.responsive-chat-open .chat-info {
        display: none
    }
    .main-chart-wrapper.responsive-chat-open .main-chat-area {
        display: block
    }
}

@media (max-width:767.98px) {
    .main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
        width: 2.5rem;
        height: 2.5rem
    }
}

@media (max-width:354px) {
    .main-chart-wrapper .chat-calls-tab,
    .main-chart-wrapper .chat-groups-tab,
    .main-chart-wrapper .chat-users-tab {
        max-height: calc(100vh - 22rem)
    }
}

[data-page-style=modern] .main-chat-area .rightIcons .btn-outline-light {
    border-color: var(--default-border)
}

[dir=rtl] .chat-footer .btn-send {
    transform: rotate(180deg)
}

.product-icons {
    display: none
}

.product-image img {
    background-color: rgb(var(--light-rgb))
}

.product-card {
    position: relative
}

.product-card:hover .product-icons {
    display: block
}

.product-card:hover .product-icons .cart,
.product-card:hover .product-icons .view,
.product-card:hover .product-icons .wishlist {
    position: absolute;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.product-card:hover .product-icons .cart i,
.product-card:hover .product-icons .view i,
.product-card:hover .product-icons .wishlist i {
    font-size: .9rem
}

.product-card:hover .product-icons .wishlist {
    background-color: rgba(var(--danger-rgb), .2);
    color: rgb(var(--danger-rgb));
    inset-block-start: 1.75rem;
    inset-inline-end: 1.75rem
}

.product-card:hover .product-icons .cart {
    background-color: rgba(var(--primary-rgb), .2);
    color: var(--primary-color);
    inset-block-start: 4rem;
    inset-inline-end: 1.75rem
}

.product-card:hover .product-icons .view {
    background-color: rgba(var(--success-rgb), .2);
    color: rgb(var(--success-rgb));
    inset-block-start: 6.25rem;
    inset-inline-end: 1.75rem
}

.ecommerce-more-link {
    position: relative;
    padding: .3rem 1.5rem .3rem .75rem;
    background-color: var(--primary01);
    color: var(--primary-color);
    border-radius: .5rem;
    font-weight: 500;
    font-size: .625rem
}

.ecommerce-more-link:hover {
    color: var(--primary-color)
}

.ecommerce-more-link:before {
    position: absolute;
    content: "\f64d";
    inset-inline-end: .5rem;
    font-family: bootstrap-icons !important;
    inset-block-start: .313rem;
    font-weight: 700
}

.ecommerce-more-link:after {
    position: absolute;
    content: "\f2ea";
    inset-inline-end: .5rem;
    font-family: bootstrap-icons !important;
    inset-block-start: .313rem;
    font-weight: 700;
    display: none
}

[dir=rtl] .ecommerce-more-link {
    padding: .3rem .75rem .3rem 1.5rem
}

[dir=rtl] .ecommerce-more-link::before,
[dir=rtl] .ecommerce-more-link:after {
    inset-inline-end: -.5rem
}

.ecommerce-more-link[aria-expanded=true]:after {
    display: block
}

.ecommerce-more-link[aria-expanded=true]:before {
    display: none
}

.products-navigation-card .form-check-label {
    font-size: .813rem;
    font-weight: 500
}

.swiper-view-details .swiper-slide {
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border);
    border-radius: .5rem !important
}

.swiper-view-details .swiper-slide.swiper-slide-thumb-active {
    background-color: rgb(var(--light-rgb)) !important
}

.swiper-preview-details .swiper-button-next {
    background-color: var(--black-1);
    color: var(--custom-white) !important
}

.swiper-preview-details .swiper-button-prev {
    background-color: var(--black-1);
    color: var(--custom-white) !important
}

.product-colors {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: 1px solid var(--default-border);
    margin-inline-end: .5rem;
    background-color: rgb(var(--light-rgb))
}

.product-colors i {
    font-size: 1.5rem
}

.product-colors.color-2.selected {
    border: .125rem solid #fff
}

.product-colors.color-2 i {
    color: #fff
}

.product-colors.color-4.selected {
    border: .125rem solid #514f4d
}

.product-colors.color-4 i {
    color: #514f4d
}

.product-colors.color-1.selected {
    border: .125rem solid #e9d2d1
}

.product-colors.color-1 i {
    color: #e9d2d1
}

.product-colors.color-3.selected {
    border: .125rem solid #c2c3c7
}

.product-colors.color-3 i {
    color: #c2c3c7
}

.product-colors.color-5.selected {
    border: .125rem solid #4a6181
}

.product-colors.color-5 i {
    color: #4a6181
}

.product-sizes {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: 1px solid var(--default-border);
    margin-inline-end: .5rem;
    font-size: .75rem;
    background-color: rgb(var(--light-rgb));
    color: var(--text-muted)
}

.product-sizes.selected {
    background-color: rgba(var(--success-rgb), .1);
    color: rgb(var(--success-rgb))
}

.offer-promotion {
    position: relative;
    padding: .5rem;
    background-color: rgba(var(--warning-rgb), .1);
    border: 1px solid var(--default-border);
    border-radius: .5rem
}

.offer-promotion .promotion-code {
    color: var(--default-text-color)
}

.offer-promotion .offer-details {
    position: absolulte;
    border: 1px dashed rgb(var(--warning-rgb));
    padding: .25rem .5rem;
    border-radius: .5rem
}

.ecommerce-assurance {
    border: 1px dashed rgb(var(--success-rgb));
    border-radius: .5rem;
    text-align: center;
    padding: 1rem 1.5rem
}

.ecommerce-assurance svg {
    width: 3rem;
    height: 3rem
}

.product-images {
    padding-inline-start: 1.875rem
}

.product-images .products-review-images img {
    width: 3.125rem;
    height: 3.125rem;
    margin-inline-end: .25rem;
    border-radius: .5rem;
    background-color: rgb(var(--light-rgb))
}

.similar-products-image img {
    width: 3rem;
    height: 3rem;
    border-radius: .5rem;
    background-color: rgb(var(--light-rgb))
}

.similar-product-name {
    max-width: 80%
}

.product-quantity-container {
    width: 8.75rem
}

.product-quantity-container .input-group input.form-control:focus {
    box-shadow: none
}

.product-quantity-container .input-group .product-quantity-minus.btn:focus,
.product-quantity-container .input-group .product-quantity-minus.btn:hover,
.product-quantity-container .input-group .product-quantity-plus.btn:focus,
.product-quantity-container .input-group .product-quantity-plus.btn:hover {
    border-color: var(--input-border)
}

.product-quantity-container .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0
}

.cart-empty svg {
    width: 6.25rem;
    height: 6.25rem;
    margin-block-end: 1.25rem;
    fill: var(--default-text-color)
}

.product-checkout .tab-style-2 .nav-item .nav-link {
    padding: .85rem 2rem
}

.product-checkout .form-floating input,
.product-checkout .form-floating textarea {
    font-size: .813rem;
    font-weight: 600
}

.product-checkout .payment-card-container,
.product-checkout .shipping-method-container {
    position: relative;
    padding: .625rem;
    border: 1px solid var(--input-border);
    border-radius: .5rem
}

.product-checkout .payment-card-container .form-check-input,
.product-checkout .shipping-method-container .form-check-input {
    position: absolute;
    inset-inline-end: .75rem;
    inset-block-start: 1.2rem
}

@media (min-width:576px) {
    .product-checkout .payment-card-container .saved-card-details,
    .product-checkout .payment-card-container .shipping-partner-details,
    .product-checkout .shipping-method-container .saved-card-details,
    .product-checkout .shipping-method-container .shipping-partner-details {
        width: 12.5rem
    }
}

.checkout-payment-success {
    text-align: center
}

.checkout-payment-success img {
    width: 200px;
    height: 200px
}

@media screen and (max-width:575px) {
    #shipped-tab-pane .btn-group .btn {
        width: 100%;
        border-radius: .5rem
    }
}

.orders-delivery-address {
    width: 75%
}

.delivery-date {
    width: 60px;
    height: 60px;
    padding: 10px;
    background-color: var(--primary005);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: .3rem;
    animation-fill-mode: backwards
}

.order-track {
    position: relative
}

.order-track:before {
    content: "";
    inset-inline-start: 20px;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    position: absolute;
    width: 1px;
    border-inline-start: 1px dashed var(--primary-color);
    height: 100%;
    opacity: .2
}

.add-products .tab-style-2 .nav-item .nav-link {
    padding: .85rem 2rem
}

.color-selection .choices__input {
    width: 6.25rem
}

.product-image-default {
    width: 100%;
    height: 16.25rem
}

.productupload-image-container .filepond--root[data-style-panel-layout~=circle],
.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle],
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] {
    border-radius: .5rem;
    width: 100%
}

.productupload-image-container .filepond--drop-label.filepond--drop-label label,
.productupload-image-container1 .filepond--drop-label.filepond--drop-label label,
.productupload-image-container2 .filepond--drop-label.filepond--drop-label label {
    padding: 2em
}

.productupload-image-container .filepond--drop-label,
.productupload-image-container1 .filepond--drop-label,
.productupload-image-container2 .filepond--drop-label {
    color: var(--text-muted) !important
}

.productupload-image-container .filepond--panel-root,
.productupload-image-container1 .filepond--panel-root,
.productupload-image-container2 .filepond--panel-root {
    border: .125rem dashed var(--input-border) !important
}

.productupload-image-container .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
    border-radius: .3rem
}

.productupload-image-container .filepond--root[data-style-panel-layout~=circle] {
    height: 16.25rem !important
}

.productupload-image-container .filepond--drop-label label {
    font-size: .75rem
}

.productupload-image-container .filepond--file,
.productupload-image-container .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
    height: 16.25rem
}

.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle],
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] {
    height: 7.375rem !important
}

.productupload-image-container1 .filepond--drop-label label,
.productupload-image-container2 .filepond--drop-label label {
    font-size: .75rem
}

.productupload-image-container1 .filepond--file,
.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container2 .filepond--file,
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
    height: 7.375rem !important
}

.productupload-image-container1 .filepond--file [data-align*=bottom],
.productupload-image-container2 .filepond--file [data-align*=bottom] {
    bottom: 9.125em
}

.product-img-icon {
    font-size: 1.625rem !important;
    opacity: .5
}

.product-documents-container .filepond--root {
    width: 100%
}

.product-documents-container .filepond--panel-root {
    border-color: var(--input-border) !important;
    border-radius: .5rem
}

.product-documents-container .filepond--root .filepond--drop-label label {
    color: var(--text-muted)
}

#product-features {
    max-height: 12.5rem;
    overflow-y: scroll
}

.file-manager-container {
    position: relative;
    overflow: hidden;
    margin: 0 -1.25rem
}

.file-manager-container .file-manager-folders,
.file-manager-container .file-manager-navigation,
.file-manager-container .selected-file-details {
    background-color: var(--custom-white);
    height: calc(100vh - 8rem)
}

.file-manager-container .files-main-nav {
    max-height: calc(100vh - 17.5rem)
}

.file-manager-container .file-folders-container,
.file-manager-container .filemanager-file-details {
    max-height: calc(100vh - 11.9rem)
}

@media (min-width:576px) {
    .file-manager-container .file-manager-navigation {
        width: 30rem
    }
}

@media (max-width:575.98px) {
    .file-manager-container .file-manager-navigation {
        width: 100%
    }
    .file-manager-container .file-manager-folders {
        display: none
    }
}

.file-manager-container .selected-file-details {
    width: 40rem
}

.file-manager-container .file-manager-folders {
    width: 100%
}

.file-manager-container ul.files-main-nav {
    padding: 1rem;
    margin-block-end: 0
}

.file-manager-container ul.files-main-nav li {
    padding: .5rem .75rem;
    border-radius: .5rem;
    margin-block-end: .15rem
}

.file-manager-container ul.files-main-nav li:last-child {
    margin-block-end: 0
}

.file-manager-container ul.files-main-nav li div {
    color: var(--text-muted)
}

.file-manager-container ul.files-main-nav li:hover div {
    color: var(--primary-color)
}

.file-manager-container ul.files-main-nav li.active {
    background-color: var(--primary01)
}

.file-manager-container ul.files-main-nav li.active div {
    color: var(--primary-color)
}

.file-manager-container ul.files-main-nav li div.filemanager-upgrade-storage {
    width: 235px;
    height: auto;
    background-color: rgb(var(--light-rgb));
    border: 2px dashed var(--default-border);
    border-radius: .5rem;
    text-align: center;
    color: var(--default-text-color);
    padding: 1rem
}

.file-manager-container ul.files-main-nav li div.filemanager-upgrade-storage img {
    width: 150px;
    height: 150px
}

.file-manager-container .file-format-icon svg {
    width: 2.2rem;
    height: 2.2rem
}

.file-manager-container .folder-svg-container svg {
    width: 3rem;
    height: 3rem
}

.file-manager-container .file-details img {
    width: 150px;
    height: 150px;
    background-color: rgb(var(--light-rgb));
    border-radius: .5rem
}

@media (max-width:1200px) {
    .file-manager-container .selected-file-details {
        display: none
    }
    .file-manager-container .selected-file-details.open {
        width: 19.5rem;
        position: absolute;
        display: block;
        inset-inline-end: 0;
        inset-block-start: .5rem;
        box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04);
        border-inline-start: 1px solid var(--default-border)
    }
}

.file-manager-container .file-manager-navigation.close {
    display: none
}

.file-manager-container .file-manager-folders.open {
    display: block
}

@media (max-width:365px) {
    .file-manager-container .file-folders-container {
        max-height: calc(100vh - 12.9rem)
    }
}

@media (min-width:992px) {
    [data-nav-style=menu-click][data-nav-layout=horizontal][data-theme-mode=dark] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1::before {
        border-block-start-color: rgba(0, 0, 0, .1);
        border-inline-start-color: rgba(0, 0, 0, .1)
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal][data-theme-mode=dark][dir=rtl] .slide.has-sub.open .slide-menu.child1::before {
        border-inline-end-color: var(--border);
        border-inline-start-color: transparent !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item .side-menu__angle {
        color: #fff
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item .side-menu__angle {
        color: var(--menu-prime-color) !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item.active,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item:hover,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item.active,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item:hover,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item.active,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item:hover {
        color: var(--primary-color)
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item.active .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item:hover .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item.active .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item:hover .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item.active .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item:hover .side-menu__angle {
        color: var(--primary-color) !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item.active:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 .slide .side-menu__item:hover:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item.active:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child2 .slide .side-menu__item:hover:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item.active:before,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child3 .slide .side-menu__item:hover:before {
        border-color: var(--primary-color) !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item {
        border-radius: .25rem;
        padding: .3rem
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide-menu.child1 {
        border-radius: .25rem;
        padding: .55rem .3rem
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide-menu.child1 .side-menu__item {
        padding: .33rem 1.6rem
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1 {
        overflow: visible !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide.has-sub.open .slide-menu.child1::before {
        content: "";
        position: inherit !important;
        inset-block-start: -7px;
        inset-inline-start: 10%;
        width: 13px;
        height: 13px;
        z-index: 99999;
        border: 1px solid transparent;
        border-block-start-color: var(--border);
        border-inline-start-color: var(--border);
        transform: rotate(45deg);
        background-color: var(--custom-white)
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item {
        padding-inline-end: 1.3rem;
        padding-inline-start: 1.3rem
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover {
        background-color: transparent !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .slide-menu.child1 .slide .side-menu__item:before {
        inset-block-start: .838rem;
        inset-inline-start: .65rem
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover .side-menu__angle {
        color: var(--primary-color) !important
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__label {
        color: #fff;
        opacity: .8
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover {
        background-color: var(--primary-color);
        color: var(--primary-color)
    }
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item.active .side-menu__label,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover .side-menu__angle,
    [data-nav-style=menu-click][data-nav-layout=horizontal] .landing-body .app-sidebar .side-menu__item:hover .side-menu__label {
        color: #fff !important
    }
    .flex-none {
        flex: none
    }
}

.landing-body {
    background-color: var(--custom-white)
}

.landing-body .app-sidebar {
    border-bottom: 0
}

.landing-body .app-sidebar .side-menu__item {
    padding: .8rem 1rem
}

.landing-body .app-sidebar .side-menu__item.active,
.landing-body .app-sidebar .side-menu__item:hover {
    color: var(--primary-color) !important
}

.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active {
    font-weight: 400
}

.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active .side-menu__label {
    color: var(--primary-color) !important
}

.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active .side-menu__angle {
    color: var(--primary-color) !important
}

.landing-body .accordion.accordion-primary .accordion-button.collapsed:after {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.landing-body .featur-icon {
    width: 75px;
    height: 75px;
    padding: 14px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, .05);
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
    margin: 0 auto
}

.landing-body .feature-logos {
    display: flex;
    justify-content: center
}

.landing-body .landing-Features {
    position: relative;
    width: 100%;
    height: 100%;
    inset-block-start: 0;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 9
}

.landing-body .landing-Features:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(var(--primary-rgb), .9);
    top: 0;
    z-index: -1
}

.landing-body .app-sidebar .slide-menu.child1 .side-menu__item.active:before {
    width: .35rem;
    height: .35rem;
    border: 0;
    border-radius: 1.0625rem;
    background-color: var(--primary-color);
    inset-block-start: 12px !important
}

@media (min-width:992px) {
    .landing-body .app-sidebar {
        inset-block-start: 0;
        padding: .5rem 0;
        height: auto !important;
        background-color: var(--primary01);
        box-shadow: none;
        border-inline-end: 0
    }
    .landing-body .app-sidebar .main-sidebar {
        height: auto !important;
        width: 100%
    }
    .landing-body .app-sidebar.sticky.sticky-pin {
        background-color: var(--custom-white);
        box-shadow: 0 .25rem 1rem var(--black-1)
    }
    .landing-body .app-sidebar.sticky.sticky-pin .side-menu__item .side-menu__angle {
        color: var(--menu-prime-color)
    }
    .landing-body .app-sidebar.sticky.sticky-pin .side-menu__item:hover .side-menu__angle {
        color: var(--primary-color) !important
    }
    .landing-body .app-sidebar.sticky.sticky-pin.app-sidebar .side-menu__label {
        color: var(--custom-black)
    }
    .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-white {
        display: none
    }
    .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-logo {
        display: block
    }
    .landing-body .app-sidebar.sticky.sticky-pin.app-sidebar .side-menu__item:hover .side-menu__label {
        color: var(--primary-color) !important
    }
    .landing-body .app-sidebar.app-sidebar .slide.has-sub.open .slide-menu.child1::before {
        border-block-start-color: var(--default-border) !important;
        border-inline-start-color: var(--default-border) !important
    }
    .landing-body .app-header {
        display: none
    }
    .landing-body .main-sidebar-header {
        display: block !important
    }
    .landing-body .main-menu-container {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
    .landing-body .main-menu-container .slide-left,
    .landing-body .main-menu-container .slide-right {
        display: none
    }
    .landing-body .main-content {
        padding: 0
    }
    .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-logo {
        display: none
    }
    .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-white {
        display: block
    }
}

@media (max-width:991.98px) {
    .landing-body .app-sidebar .side-menu__item.active,
    .landing-body .app-sidebar .side-menu__item:hover {
        background-color: transparent
    }
    .landing-body .main-menu-container .main-menu {
        padding-inline: 20px !important;
        width: 100%
    }
    .landing-body .app-sidebar .slide-menu {
        padding-inline-start: 1rem
    }
    .landing-body .app-sidebar .slide {
        padding: 0
    }
    .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-logo {
        display: none !important
    }
    .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-white {
        display: none !important
    }
}

.landing-body .main-menu-container .main-menu {
    padding-inline-start: 0
}

.landing-body .app-content {
    margin-block-start: 4rem
}

.landing-body .section {
    padding: 3.375rem 0;
    background-size: cover;
    position: relative
}

.landing-body .landing-banner {
    position: relative;
    width: 100%;
    inset-block-start: 0;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.landing-body .landing-banner:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary07)
}

.landing-body .landing-banner .main-banner-container {
    padding: 4rem 3rem
}

.landing-body .landing-banner .landing-banner-heading {
    line-height: 1.25;
    text-shadow: 1px 1px var(--black-1);
    font-size: 3rem;
    font-weight: 600;
    color: #fff
}

@media (max-width:767.98px) {
    .landing-body .landing-main-image {
        display: none
    }
    .landing-body .landing-banner .main-banner-container {
        padding: 1rem
    }
}

@media (max-width:1115.98px) {
    .landing-body .landing-main-image::after,
    .landing-body .landing-main-image::before {
        display: none
    }
}

@media (max-width:480px) {
    .landing-body .landing-banner .section {
        padding: 2.375rem 0
    }
}

.landing-body .landing-main-image {
    position: relative;
    z-index: 10
}

.landing-body .landing-main-image img {
    z-index: 11;
    position: relative;
    width: 27.063rem;
    height: 26.063rem;
    margin-block-start: -10px
}

.landing-body .landing-main-image:before {
    content: "";
    position: absolute;
    width: 24.5rem;
    height: 24.5rem;
    background-color: var(--black-1);
    opacity: .3;
    inset-inline-start: 5rem;
    inset-block-start: 0;
    border-radius: 50%;
    box-shadow: 0 3rem 10rem var(--white-1) !important
}

.landing-body .landing-main-image:after {
    content: "";
    position: absolute;
    width: 12.5rem;
    height: 12.5rem;
    background-color: transparent;
    border: .125rem dashed var(--primary05);
    opacity: .3;
    inset-inline-start: 5.1rem;
    inset-block-end: 4.4rem
}

.landing-body .landing-page-wrapper {
    position: relative;
    min-height: calc(100vh - 3.4rem)
}

.landing-body .main-landing-image {
    width: 37.5rem;
    padding: .5rem;
    border-radius: .5rem;
    background-color: var(--primary01)
}

.landing-body .brand-img-white {
    display: block
}

.landing-body .brand-img-light {
    display: block
}

.landing-body .app-sidebar .side-menu__label {
    font-family: 600
}

.landing-body .landing-section-heading {
    position: relative
}

.landing-body .landing-section-heading:before {
    content: "";
    position: absolute;
    width: 60%;
    height: .25rem;
    background-image: linear-gradient(to right, rgb(var(--success-rgb)), rgba(var(--success-rgb), .1));
    opacity: .5;
    inset-block-start: -.625rem;
    border-radius: 3.125rem;
    inset-inline-end: 0
}

.landing-body .landing-missions,
.landing-body .landing-missions-dark {
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, .05) !important
}

.landing-body .landing-missions-dark:before,
.landing-body .landing-missions:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .15rem;
    inset-block-start: 0;
    inset-inline-start: 0;
    border-radius: 3.125rem
}

.landing-body .landing-missions svg,
.landing-body .landing-missions-dark svg {
    width: 1.5rem;
    height: 1.5rem
}

.landing-body .customize-image img {
    width: 30.5rem;
    height: 20rem;
    margin-block-start: 20px;
    padding: 15px;
    position: relative
}

.landing-body .landing-footer {
    background-color: #232323;
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

.landing-body .landing-footer .landing-footer-list li {
    margin-bottom: .5rem
}

.landing-body .landing-footer .landing-footer-list li:last-child {
    margin-bottom: 0
}

.landing-body .landing-main-footer {
    background-color: #232323
}

.landing-body .landing-testimonials .swiper-pagination-bullet {
    background: var(--primary-color) !important
}

.landing-body .landing-testimonials .swiper-pagination-bullet:active {
    background: var(--primary-color) !important
}

.landing-body .team-card {
    transition: box-shadow .2s ease-in
}

.landing-body .team-card:hover {
    box-shadow: 0 .25rem 1rem 0 rgba(0, 0, 0, .05);
    transition: box-shadow .2s ease-in
}

.landing-body .team-card .team-avatar {
    box-shadow: 0 0 0 .5rem rgba(0, 0, 0, .05)
}

.landing-body .btn-close {
    padding: 1.5rem;
    right: -.875rem
}

.landing-body .section-bg {
    background-color: var(--gray-1)
}

@media (min-width:992px) {
    [data-theme-mode=dark] .landing-body .main-menu-container .landing-logo-container .horizontal-logo .header-logo .desktop-logo {
        display: none !important
    }
}

@media (max-width:991.98px) {
    [data-theme-mode=dark] .landing-body .app-header .main-header-container .horizontal-logo .header-logo .toggle-logo {
        display: none
    }
    [data-theme-mode=dark] .landing-body .app-header .main-header-container .horizontal-logo .header-logo .toggle-dark {
        display: block
    }
}

[data-theme-mode=dark] .landing-body .landing-trusted-companies img {
    filter: imvert(1)
}

[data-theme-mode=dark] .landing-body .team-card:hover {
    box-shadow: 0 .25rem 1rem 0 rgba(255, 255, 255, .05)
}

[data-theme-mode=dark] .landing-body .team-card .team-avatar {
    box-shadow: 0 0 0 .5rem rgba(255, 255, 255, .05)
}

[data-theme-mode=dark] .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-white {
    display: block
}

[data-theme-mode=dark] .brand-img-light {
    display: block
}

[data-theme-mode=dark] .brand-img-white {
    display: none
}

[data-theme-mode=dark] .section-bg {
    background-color: var(--black-1)
}

@media (max-width:420px) {
    .landing-body .landing-banner .main-banner-container {
        padding: 1rem
    }
    .landing-body .landing-banner .landing-banner-heading {
        font-size: 2rem
    }
}

@media (max-width:992px) {
    .landing-body .app-sidebar .side-menu__item {
        padding: .8rem 5rem .8rem 1rem
    }
    .landing-body .app-sidebar .slide-menu.child1 li,
    .landing-body .app-sidebar .slide-menu.child2 li,
    .landing-body .app-sidebar .slide-menu.child3 li {
        padding: 0;
        padding-inline-start: 0;
        position: relative
    }
    [dir=rtl] .landing-body .app-sidebar .side-menu__item {
        padding: .8rem 1rem .8rem 5rem
    }
}

[dir=rtl] .landing-body .testimonial-card {
    direction: rtl
}

.landing-body.jobs-landing .landing-banner .landing-banner-heading {
    line-height: 1.25;
    font-size: 2.4rem;
    font-weight: 600
}

.landing-body.jobs-landing .landing-banner {
    height: auto
}

.custom-form-group {
    position: relative;
    display: flex;
    align-items: center
}

.custom-form-group .form-control {
    padding-inline-end: 3.75rem;
    padding-inline-start: 1.25rem;
    line-height: 2.25
}

.custom-form-group .form-control-lg~.custom-form-btn {
    inset-inline-end: .7rem
}

.custom-form-group .custom-form-btn {
    position: absolute;
    inset-inline-end: .5rem;
    border-radius: .3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--custom-white)
}

.custom-form-group .custom-form-btn .gps-location {
    color: var(--text-muted);
    opacity: .6;
    font-size: 1.0625rem;
    line-height: 0;
    margin-inline-end: 10px
}

.border-end.border-white3 {
    border-color: rgba(255, 255, 255, .1) !important
}

.bg-banner {
    background-color: var(--primary-color);
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.bg-banner::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    inset-block-start: 0;
    background-image: url(../images/media/7.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    opacity: .1;
    transition: transform ease .3s;
    z-index: -1
}

.bg-banner-1 {
    position: relative;
    inset-block-start: 0;
    height: 100%;
    border-radius: 0 .3rem .3rem 0;
    z-index: 0
}

.bg-banner-2 {
    position: relative;
    inset-block-start: 0;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0 .3rem .3rem 0;
    z-index: 0
}

.bg-banner-2::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary08);
    inset-inline: 0;
    inset-block: 0;
    z-index: -1
}

.landing-body.jobs-landing .featured-jobs {
    overflow: hidden;
    border: 1px solid var(--default-border)
}

.featured-card-4 svg {
    width: 1.5rem;
    height: 1.5rem
}

.feature-style {
    position: relative;
    overflow: hidden
}

.feature-style::before {
    content: "";
    position: absolute;
    width: 4rem;
    height: 4rem;
    inset-inline-end: -1.5rem;
    inset-block-end: -1.5rem;
    background-color: var(--primary01);
    border-radius: 50%
}

.feature-style .feature-style-icon {
    position: relative;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
    margin-block-end: .5rem
}

.feature-style .feature-style-icon img,
.feature-style .feature-style-icon svg {
    width: 2.5rem;
    height: 2.5rem
}

.landing-body.jobs-landing .landing-main-footer .landing-footer-list li {
    display: inline-block;
    padding: 0 12px
}

.landing-body .landing-main-footer .landing-footer-list li:not(:first-child)::before {
    position: absolute;
    content: "";
    width: .3rem;
    height: .3rem;
    border: 1px solid var(--white-5);
    border-radius: 50%;
    background-color: transparent;
    inset-inline-start: -.2rem;
    inset-block-start: .5rem
}

.landing-body .landing-main-footer .landing-footer-list li:not(:first-child) {
    position: relative
}

.review-quote {
    position: absolute;
    inset-inline-end: .8rem;
    inset-block-start: 1rem;
    font-size: 1.875rem;
    line-height: 0;
    color: var(--primary07);
    background-color: transparent;
    padding: .625rem
}

.blog-badge {
    position: absolute;
    inset-block-start: 1rem;
    inset-inline-end: 1rem
}

@media (max-width:480px) {
    .jobs-landing .landing-banner .section {
        padding: 4.375rem 0
    }
    .jobs-landing .landing-banner .section .main-banner-container .custom-form-group .form-control-lg {
        padding: .5rem .5rem
    }
    .jobs-landing .landing-banner .section .main-banner-container .custom-form-btn .btn span {
        display: none
    }
}

.main-mail-container {
    position: relative;
    overflow: hidden;
    margin: 0 -1.25rem
}

.mail-navigation,
.mail-recepients,
.mails-information,
.total-mails {
    background-color: var(--custom-white);
    height: calc(100vh - 8rem);
    overflow: hidden;
    border-radius: .5rem
}

.mail-info-body {
    max-height: calc(100vh - 16.3rem)
}

@media (min-width:1400px) {
    .total-mails {
        min-width: 24rem;
        max-width: 24rem
    }
    .total-mails .mail-msg span {
        max-width: 17rem;
        display: inline-block
    }
}

@media (max-width:1399.98px) {
    .mails-information {
        display: none
    }
}

@media (min-width:1400px) and (max-width:1489.98px) {
    .responsive-mail-action-icons .dropdown {
        display: block
    }
    .responsive-mail-action-icons .close-button {
        display: none
    }
    .mail-action-icons {
        display: none
    }
}

@media (max-width:1399.98px) {
    .responsive-mail-action-icons {
        display: block;
        display: flex
    }
    .mail-action-icons {
        display: none
    }
}

@media (max-width:575.98px) {
    .mail-recepients {
        display: none
    }
}

@media (min-width:1489px) {
    .responsive-mail-action-icons {
        display: none
    }
}

@media (max-width:991.98px) {
    .mail-navigation {
        width: 100%
    }
}

@media (min-width:992px) {
    .mail-navigation {
        min-width: 16rem;
        max-width: 16rem
    }
}

.total-mails {
    width: 100%
}

.total-mails .mail-messages {
    max-height: calc(100vh - 16.65rem)
}

.total-mails .mail-messages li {
    padding: 1.1rem 1.25rem .625rem 1.25rem;
    border-bottom: 1px solid var(--default-border)
}

.total-mails .mail-messages li.active {
    background-color: rgb(var(--light-rgb))
}

.total-mails .mail-messages li:last-child {
    border-bottom: 0
}

.total-mails .mail-messages li .avatar.mail-msg-avatar {
    width: 1.8rem;
    height: 1.8rem
}

.total-mails .mail-messages li .avatar.mail-msg-avatar.offline:before,
.total-mails .mail-messages li .avatar.mail-msg-avatar.online:before {
    width: .55rem;
    height: .55rem
}

.total-mails .mail-messages li .mail-msg .mail-starred i {
    color: var(--text-muted);
    opacity: .5
}

.total-mails .mail-messages li .mail-msg .mail-starred.true i {
    color: rgb(var(--warning-rgb));
    opacity: 1
}

.mail-recepients {
    min-width: 4.4rem;
    max-width: 4.4rem
}

.mail-recepients .total-mail-recepients {
    max-height: calc(100vh - 12.4rem)
}

.mail-recepients .mail-recepeint-person .avatar {
    width: 2rem;
    height: 2rem;
    margin-block-end: 1rem
}

.mail-recepients .mail-recepeint-person .avatar.offline:before,
.mail-recepients .mail-recepeint-person .avatar.online:before {
    width: .55rem;
    height: .55rem
}

.mail-recepients .mail-recepeint-person:last-child {
    margin-block-end: 0
}

.mail-navigation ul.mail-main-nav {
    max-height: calc(100vh - 17rem);
    padding: 1rem;
    margin-block-end: 0
}

.mail-navigation ul.mail-main-nav li {
    padding: .5rem;
    border-radius: var(--default-border);
    border-radius: .5rem;
    font-weight: 500
}

.mail-navigation ul.mail-main-nav li div {
    color: var(--text-muted)
}

.mail-navigation ul.mail-main-nav li.active div {
    color: var(--primary-color)
}

.mail-navigation ul.mail-main-nav li:hover div {
    color: var(--primary-color)
}

.mails-information {
    width: 100%
}

.mails-information .mail-info-header {
    padding: .75rem;
    border-block-end: 1px solid var(--default-border)
}

.mails-information .mail-info-footer {
    padding: .75rem;
    border-block-start: 1px solid var(--default-border)
}

.mails-information .mail-attachment {
    padding: .25rem;
    width: 12rem;
    height: 2.75rem;
    border: 1px solid var(--default-border);
    border-radius: .5rem;
    display: flex;
    align-items: center
}

.mails-information .mail-attachment .attachment-icon i,
.mails-information .mail-attachment .attachment-icon svg {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 2rem;
    margin-inline-end: .5rem
}

.mails-information .mail-attachment .attachment-name {
    max-width: 7rem;
    display: inline-block;
    font-size: .75rem;
    font-weight: 500
}

.mail-reply .ql-toolbar.ql-snow .ql-formats {
    margin-top: 5px;
    margin-bottom: 5px
}

#mail-compose-editor .ql-editor {
    min-height: 12.62rem !important
}

.mail-compose .ql-toolbar.ql-snow .ql-formats {
    margin-top: 5px;
    margin-bottom: 5px
}

@media (max-width:420px) {
    .mail-msg span {
        max-width: 180px
    }
}

@media (max-width:357px) {
    .mails-information {
        height: calc(100vh - 2rem)
    }
}

.mail-notification-settings,
.mail-security-settings {
    width: 60%
}

@media (max-width:575.98px) {
    #account-settings .btn-group label {
        font-size: .625rem
    }
}

.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable {
    padding: 10px !important
}

.choices__list--dropdown .choices__item--selectable::after,
.choices__list[aria-expanded] .choices__item--selectable::after {
    display: none
}

.ynex-kanban-board {
    display: flex;
    overflow-x: auto;
    align-items: stretch;
    margin-bottom: .75rem;
    padding-bottom: 1rem
}

.ynex-kanban-board .kanban-tasks-type {
    min-width: 20rem;
    margin-inline-end: .5rem;
    width: 100%
}

.ynex-kanban-board .kanban-tasks-type .kanban-tasks .card {
    touch-action: none
}

.ynex-kanban-board::-webkit-scrollbar-thumb {
    background-color: rgba(var(--dark-rgb), .1);
    border-radius: .3125rem
}

.ynex-kanban-board::-webkit-scrollbar-track {
    border-radius: .3125rem
}

.ynex-kanban-board .task-image .kanban-image {
    height: 150px;
    width: 100%
}

.ynex-kanban-board .kanban-content {
    margin-top: .75rem
}

.ynex-kanban-board .kanban-task-description {
    color: var(--text-muted);
    font-size: .82rem
}

.ynex-kanban-board .kanban-tasks-type.new .kanban-tasks .card {
    border-top: 3px solid rgba(var(--primary-rgb), .3)
}

.ynex-kanban-board .kanban-tasks-type.todo .kanban-tasks .card {
    border-top: 3px solid rgba(var(--warning-rgb), .3)
}

.ynex-kanban-board .kanban-tasks-type.in-progress .kanban-tasks .card {
    border-top: 3px solid rgba(var(--info-rgb), .3)
}

.ynex-kanban-board .kanban-tasks-type.inreview .kanban-tasks .card {
    border-top: 3px solid rgba(var(--danger-rgb), .3)
}

.ynex-kanban-board .kanban-tasks-type.completed .kanban-tasks .card {
    border-top: 3px solid rgba(var(--success-rgb), .3)
}

.ynex-kanban-board #completed-tasks .card.custom-card:last-child,
.ynex-kanban-board #inprogress-tasks .card.custom-card:last-child,
.ynex-kanban-board #inreview-tasks .card.custom-card:last-child,
.ynex-kanban-board #new-tasks .card.custom-card:last-child,
.ynex-kanban-board #todo-tasks .card.custom-card:last-child {
    margin-bottom: 0
}

.ynex-kanban-board #completed-tasks,
.ynex-kanban-board #inprogress-tasks,
.ynex-kanban-board #inreview-tasks,
.ynex-kanban-board #new-tasks,
.ynex-kanban-board #todo-tasks {
    position: relative;
    max-height: 35rem
}

.ynex-kanban-board #completed-tasks .simplebar-content,
.ynex-kanban-board #inprogress-tasks .simplebar-content,
.ynex-kanban-board #inreview-tasks .simplebar-content,
.ynex-kanban-board #new-tasks .simplebar-content,
.ynex-kanban-board #todo-tasks .simplebar-content {
    padding: 0 1rem 0 0 !important
}

.ynex-kanban-board .task-Null {
    position: relative;
    min-height: 12.5rem
}

.ynex-kanban-board .task-Null::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--custom-white);
    border-radius: .5rem;
    background-image: url(../images/media/4.jpg);
    background-size: cover;
    background-position: center;
    height: 12.5rem;
    width: 100%;
    margin: 0 auto
}

.ynex-kanban-board .view-more-button {
    margin-right: 1rem
}

.task-title {
    position: relative;
    inset-inline-start: 1rem
}

.task-title::before {
    position: absolute;
    content: "";
    width: .5rem;
    height: .5rem;
    inset-inline-start: -1rem;
    top: .5rem;
    border-radius: 50%;
    background-color: rgb(var(--secondary-rgb))
}

.task-details-key-tasks {
    list-style-type: decimal
}

.task-details-key-tasks li {
    margin-bottom: .5rem;
    font-size: .875rem;
    color: var(--text-muted)
}

.task-details-key-tasks li:last-child {
    margin-bottom: 0
}

.task-description {
    font-size: .875rem
}

.task-details-progress {
    width: 6.25rem
}

.avatar {
    position: relative;
    height: 2.625rem;
    width: 2.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    color: #fff;
    font-weight: 500
}

.avatar a.badge:hover {
    color: #fff
}

.avatar img {
    width: 100%;
    height: 100%;
    border-radius: .5rem
}

.avatar.avatar-rounded {
    border-radius: 50%
}

.avatar.avatar-rounded img {
    border-radius: 50%
}

.avatar.avatar-radius-0 {
    border-radius: 0
}

.avatar.avatar-radius-0 img {
    border-radius: 0
}

.avatar .avatar-badge {
    position: absolute;
    inset-block-start: -4%;
    inset-inline-end: -.375rem;
    width: 1.4rem;
    height: 1.4rem;
    font-size: .625rem;
    border: 2px solid #fff;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center
}

.avatar.offline:before,
.avatar.online:before {
    position: absolute;
    content: "";
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    inset-inline-end: 0;
    inset-block-end: 0;
    border: 2px solid var(--custom-white)
}

.avatar.online:before {
    background-color: rgb(var(--success-rgb))
}

.avatar.offline:before {
    background-color: var(--gray-5)
}

.avatar.avatar-xs {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    font-size: .65rem
}

.avatar.avatar-xs .avatar-badge {
    padding: .25rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    font-size: .5rem;
    inset-block-start: -25%;
    inset-inline-end: -.5rem
}

.avatar.avatar-sm {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: .65rem
}

.avatar.avatar-sm .avatar-badge {
    padding: .3rem;
    width: 1.1rem;
    height: 1.1rem;
    line-height: 1.1rem;
    font-size: .5rem;
    inset-block-start: -38%;
    inset-inline-end: -.5rem
}

.avatar.avatar-sm.offline:before,
.avatar.avatar-sm.online:before {
    width: .5rem;
    height: .5rem
}

.avatar.avatar-md {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: .8rem
}

.avatar.avatar-md .avatar-badge {
    padding: .4rem;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: .65rem;
    inset-block-start: -6%;
    inset-inline-end: -13%
}

.avatar.avatar-md.offline:before,
.avatar.avatar-md.online:before {
    width: .75rem;
    height: .75rem
}

.avatar.avatar-md svg {
    width: 1.5rem;
    height: 1.5rem
}

.avatar.avatar-lg {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem
}

.avatar.avatar-lg .avatar-badge {
    inset-block-start: -15%;
    inset-inline-end: -.25%
}

.avatar.avatar-lg.offline:before,
.avatar.avatar-lg.online:before {
    width: .8rem;
    height: .8rem
}

.avatar.avatar-lg svg {
    width: 1.8rem;
    height: 1.8rem
}

.avatar.avatar-xl {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.25rem
}

.avatar.avatar-xl .avatar-badge {
    inset-block-start: -8%;
    inset-inline-end: -.2%
}

.avatar.avatar-xl.offline:before,
.avatar.avatar-xl.online:before {
    width: .95rem;
    height: .95rem
}

.avatar.avatar-xxl {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    font-size: 1.5rem
}

.avatar.avatar-xxl .avatar-badge {
    inset-block-start: -4%;
    inset-inline-end: 0
}

.avatar.avatar-xxl.offline:before,
.avatar.avatar-xxl.online:before {
    width: 1.05rem;
    height: 1.05rem;
    inset-block-end: .25rem
}

.avatar-list-stacked {
    padding: 0
}

.avatar-list-stacked .avatar {
    margin-inline-end: -.65rem !important;
    border: 1px solid rgba(0, 0, 0, .05);
    vertical-align: middle;
    transition: transform ease .2s
}

.avatar-list-stacked .avatar:last-child {
    margin-inline-end: 0 !important
}

.avatar-list-stacked .avatar:hover {
    z-index: 1;
    transform: translateY(-.188rem)
}

[dir=rtl] .avatar-list-stacked .ri-arrow-right-s-line {
    transform: rotate(180deg)
}

.color-container {
    width: 3rem;
    height: 3rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 3rem
}

.text-container {
    padding: .25rem .5rem;
    border-radius: .5rem;
    box-shadow: 0 .125rem 0 rgba(10, 10, 10, .04)
}

.bg-body {
    background-color: var(--default-body-bg-color) !important
}

.bg-primary {
    background-color: var(--primary-color) !important
}

.bg-primary.bg-opacity-10 {
    background-color: var(--primary01) !important
}

.bg-primary.bg-opacity-25 {
    background-color: var(--primary03) !important
}

.bg-primary.bg-opacity-50 {
    background-color: var(--primary05) !important
}

.bg-primary.bg-opacity-75 {
    background-color: var(--primary08) !important
}

.bg-primary.bg-opacity-100 {
    background-color: var(--primary-color) !important
}

.bg-secondary {
    background-color: rgb(var(--secondary-rgb)) !important
}

.bg-secondary.bg-opacity-10 {
    background-color: rgba(var(--secondary-rgb), .1) !important
}

.bg-secondary.bg-opacity-25 {
    background-color: rgba(var(--secondary-rgb), .25) !important
}

.bg-secondary.bg-opacity-50 {
    background-color: rgba(var(--secondary-rgb), .5) !important
}

.bg-secondary.bg-opacity-75 {
    background-color: rgba(var(--secondary-rgb), .75) !important
}

.bg-secondary.bg-opacity-100 {
    background-color: rgba(var(--secondary-rgb), 1) !important
}

.bg-info {
    background-color: rgb(var(--info-rgb)) !important
}

.bg-info.bg-opacity-10 {
    background-color: rgba(var(--info-rgb), .1) !important
}

.bg-info.bg-opacity-25 {
    background-color: rgba(var(--info-rgb), .25) !important
}

.bg-info.bg-opacity-50 {
    background-color: rgba(var(--info-rgb), .5) !important
}

.bg-info.bg-opacity-75 {
    background-color: rgba(var(--info-rgb), .75) !important
}

.bg-info.bg-opacity-100 {
    background-color: rgba(var(--info-rgb), 1) !important
}

.bg-success {
    background-color: rgb(var(--success-rgb)) !important
}

.bg-success.bg-opacity-10 {
    background-color: rgba(var(--success-rgb), .1) !important
}

.bg-success.bg-opacity-25 {
    background-color: rgba(var(--success-rgb), .25) !important
}

.bg-success.bg-opacity-50 {
    background-color: rgba(var(--success-rgb), .5) !important
}

.bg-success.bg-opacity-75 {
    background-color: rgba(var(--success-rgb), .75) !important
}

.bg-success.bg-opacity-100 {
    background-color: rgba(var(--success-rgb), 1) !important
}

.bg-warning {
    background-color: rgb(var(--warning-rgb)) !important
}

.bg-warning.bg-opacity-10 {
    background-color: rgba(var(--warning-rgb), .1) !important
}

.bg-warning.bg-opacity-25 {
    background-color: rgba(var(--warning-rgb), .25) !important
}

.bg-warning.bg-opacity-50 {
    background-color: rgba(var(--warning-rgb), .5) !important
}

.bg-warning.bg-opacity-75 {
    background-color: rgba(var(--warning-rgb), .75) !important
}

.bg-warning.bg-opacity-100 {
    background-color: rgba(var(--warning-rgb), 1) !important
}

.bg-danger {
    background-color: rgb(var(--danger-rgb)) !important
}

.bg-danger.bg-opacity-10 {
    background-color: rgba(var(--danger-rgb), .1) !important
}

.bg-danger.bg-opacity-25 {
    background-color: rgba(var(--danger-rgb), .25) !important
}

.bg-danger.bg-opacity-50 {
    background-color: rgba(var(--danger-rgb), .5) !important
}

.bg-danger.bg-opacity-75 {
    background-color: rgba(var(--danger-rgb), .75) !important
}

.bg-danger.bg-opacity-100 {
    background-color: rgba(var(--danger-rgb), 1) !important
}

.bg-dark {
    background-color: rgb(var(--dark-rgb)) !important
}

.bg-dark.bg-opacity-10 {
    background-color: rgba(var(--dark-rgb), .1) !important
}

.bg-dark.bg-opacity-25 {
    background-color: rgba(var(--dark-rgb), .25) !important
}

.bg-dark.bg-opacity-50 {
    background-color: rgba(var(--dark-rgb), .5) !important
}

.bg-dark.bg-opacity-75 {
    background-color: rgba(var(--dark-rgb), .75) !important
}

.bg-dark.bg-opacity-100 {
    background-color: rgba(var(--dark-rgb), 1) !important
}

.bg-light {
    background-color: rgb(var(--light-rgb)) !important
}

.bg-light.bg-opacity-10 {
    background-color: rgba(var(--light-rgb), .1) !important
}

.bg-light.bg-opacity-25 {
    background-color: rgba(var(--light-rgb), .25) !important
}

.bg-light.bg-opacity-50 {
    background-color: rgba(var(--light-rgb), .5) !important
}

.bg-light.bg-opacity-75 {
    background-color: rgba(var(--light-rgb), .75) !important
}

.bg-light.bg-opacity-100 {
    background-color: rgba(var(--light-rgb), 1) !important
}

.bg-white {
    background-color: var(--custom-white) !important
}

.bg-pink {
    background-color: rgb(var(--pink-rgb)) !important
}

.bg-orange {
    background-color: rgb(var(--orange-rgb)) !important
}

.bg-purple {
    background-color: rgb(var(--purple-rgb)) !important
}

.bg-green {
    background-color: #1dd871 !important
}

.bg-teal {
    background-color: rgb(var(--teal-rgb)) !important
}

.bg-blue {
    background-color: #2b3e65 !important
}

.bg-indigo {
    background-color: #4d5ddb !important
}

.bg-yellow {
    background-color: #ffc102 !important
}

.bg-primary-gradient {
    background: linear-gradient(to right, var(--primary-color) 0, #0086ed 100%) !important;
    color: #fff
}

.bg-secondary-gradient {
    background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0, #6789d8 100%) !important;
    color: #fff
}

.bg-warning-gradient {
    background: linear-gradient(to right, rgb(var(--warning-rgb)) 0, #7fa53a 100%) !important;
    color: #fff
}

.bg-info-gradient {
    background: linear-gradient(to right, rgb(var(--info-rgb)) 0, #52f0ce 100%) !important;
    color: #fff
}

.bg-success-gradient {
    background: linear-gradient(to right, rgb(var(--success-rgb)) 0, #00a1c0 100%) !important;
    color: #fff
}

.bg-danger-gradient {
    background: linear-gradient(to right, rgb(var(--danger-rgb)) 0, #a34a88 100%) !important;
    color: #fff
}

.bg-orange-gradient {
    background: linear-gradient(to right, rgb(var(--orange-rgb)) 0, #9ba815 100%) !important;
    color: #fff
}

.bg-purple-gradient {
    background: linear-gradient(to right, rgb(var(--purple-rgb)) 0, #ff496d 100%) !important;
    color: #fff
}

.bg-teal-gradient {
    background: linear-gradient(to right, rgb(var(--teal-rgb)) 0, #0695dd 100%) !important;
    color: #fff
}

.bg-light-gradient {
    background: linear-gradient(to right, rgb(var(--light-rgb)) 0, #d1d6de 100%) !important;
    color: #000
}

.bg-dark-gradient {
    background: linear-gradient(to right, rgb(var(--dark-rgb)) 0, #54505d 100%) !important;
    color: #fff
}

.bg-outline-primary {
    background-color: var(--custom-white);
    border: 1px solid var(--primary-color);
    color: var(--primary-color)
}

.bg-outline-secondary {
    background-color: var(--custom-white);
    border: 1px solid rgb(var(--secondary-rgb));
    color: rgb(var(--secondary-rgb))
}

.bg-outline-warning {
    background-color: var(--custom-white);
    border: 1px solid rgb(var(--warning-rgb));
    color: rgb(var(--warning-rgb))
}

.bg-outline-info {
    background-color: var(--custom-white);
    border: 1px solid rgb(var(--info-rgb));
    color: rgb(var(--info-rgb))
}

.bg-outline-success {
    background-color: var(--custom-white);
    border: 1px solid rgb(var(--success-rgb));
    color: rgb(var(--success-rgb))
}

.bg-outline-danger {
    background-color: var(--custom-white);
    border: 1px solid rgb(var(--danger-rgb));
    color: rgb(var(--danger-rgb))
}

.bg-outline-dark {
    background-color: var(--custom-white);
    border: 1px solid rgb(var(--dark-rgb));
    color: rgb(var(--dark-rgb))
}

.bg-outline-light {
    background-color: var(--custom-white);
    border: 1px solid rgb(var(--light-rgb));
    color: var(--custom-black)
}

.bg-gray-100 {
    background-color: var(--gray-1)
}

.bg-gray-200 {
    background-color: var(--gray-2)
}

.bg-gray-300 {
    background-color: var(--gray-3)
}

.bg-gray-400 {
    background-color: var(--gray-4)
}

.bg-gray-500 {
    background-color: var(--gray-5)
}

.bg-gray-600 {
    background-color: var(--gray-6)
}

.bg-gray-700 {
    background-color: var(--gray-7)
}

.bg-gray-800 {
    background-color: var(--gray-8)
}

.bg-gray-900 {
    background-color: var(--gray-9)
}

.bg-primary-transparent {
    background-color: var(--primary01) !important;
    color: var(--primary-color) !important
}

.bg-primary-transparent:hover {
    background-color: var(--primary01) !important;
    color: var(--primary-color) !important
}

.bg-secondary-transparent {
    background-color: rgba(var(--secondary-rgb), .1) !important;
    color: rgb(var(--secondary-rgb)) !important
}

.bg-secondary-transparent:hover {
    background-color: rgba(var(--secondary-rgb), .1) !important;
    color: rgb(var(--secondary-rgb)) !important
}

.bg-info-transparent {
    background-color: rgba(var(--info-rgb), .1) !important;
    color: rgb(var(--info-rgb)) !important
}

.bg-info-transparent:hover {
    background-color: rgba(var(--info-rgb), .1) !important;
    color: rgb(var(--info-rgb)) !important
}

.bg-success-transparent {
    background-color: rgba(var(--success-rgb), .1) !important;
    color: rgb(var(--success-rgb)) !important
}

.bg-success-transparent:hover {
    background-color: rgba(var(--success-rgb), .1) !important;
    color: rgb(var(--success-rgb)) !important
}

.bg-warning-transparent {
    background-color: rgba(var(--warning-rgb), .1) !important;
    color: rgb(var(--warning-rgb)) !important
}

.bg-warning-transparent:hover {
    background-color: rgba(var(--warning-rgb), .1) !important;
    color: rgb(var(--warning-rgb)) !important
}

.bg-danger-transparent {
    background-color: rgba(var(--danger-rgb), .1) !important;
    color: rgb(var(--danger-rgb)) !important
}

.bg-danger-transparent:hover {
    background-color: rgba(var(--danger-rgb), .1) !important;
    color: rgb(var(--danger-rgb)) !important
}

.bg-light-transparent {
    background-color: rgba(var(--light-rgb), .1) !important;
    color: rgb(var(--light-rgb)) !important
}

.bg-light-transparent:hover {
    background-color: rgba(var(--light-rgb), .1) !important;
    color: var(--default-text-color) !important
}

.bg-dark-transparent {
    background-color: rgba(var(--dark-rgb), .1) !important;
    color: rgb(var(--dark-rgb)) !important
}

.bg-dark-transparent:hover {
    background-color: rgba(var(--dark-rgb), .1) !important;
    color: rgb(var(--dark-rgb)) !important
}

.bg-pink-transparent {
    background-color: rgba(var(--pink-rgb), .1) !important;
    color: rgb(var(--pink-rgb)) !important
}

.bg-pink-transparent:hover {
    background-color: rgba(var(--pink-rgb), .1) !important;
    color: rgb(var(--pink-rgb)) !important
}

.bg-orange-transparent {
    background-color: rgba(var(--orange-rgb), .1) !important;
    color: rgb(var(--orange-rgb)) !important
}

.bg-orange-transparent:hover {
    background-color: rgba(var(--orange-rgb), .1) !important;
    color: rgb(var(--orange-rgb)) !important
}

.bg-purple-transparent {
    background-color: rgba(var(--purple-rgb), .1) !important;
    color: rgb(var(--purple-rgb)) !important
}

.bg-purple-transparent:hover {
    background-color: rgba(var(--purple-rgb), .1) !important;
    color: rgb(var(--purple-rgb)) !important
}

.bg-teal-transparent {
    background-color: rgba(var(--teal-rgb), .1) !important;
    color: rgb(var(--teal-rgb)) !important
}

.bg-teal-transparent:hover {
    background-color: rgba(var(--teal-rgb), .1) !important;
    color: rgb(var(--teal-rgb)) !important
}

.bg-green-transparent {
    background-color: rgba(29, 216, 113, .1) !important;
    color: #1dd871 !important
}

.bg-green-transparent:hover {
    background-color: rgba(29, 216, 113, .1) !important;
    color: #1dd871 !important
}

.bg-indigo-transparent {
    background-color: rgba(77, 93, 219, .1) !important;
    color: #4d5ddb !important
}

.bg-indigo-transparent:hover {
    background-color: rgba(77, 93, 219, .1) !important;
    color: #4d5ddb !important
}

.bg-yellow-transparent {
    background-color: rgba(255, 193, 2, .1) !important;
    color: #ffc102 !important
}

.bg-yellow-transparent:hover {
    background-color: rgba(255, 193, 2, .1) !important;
    color: #ffc102 !important
}

.bg-blue-transparent {
    background-color: rgba(43, 62, 101, .1) !important;
    color: #2b3e65 !important
}

.bg-blue-transparent:hover {
    background-color: rgba(43, 62, 101, .1) !important;
    color: #2b3e65 !important
}

.text-bg-primary {
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.text-bg-secondary {
    background-color: rgb(var(--secondary-rgb)) !important;
    color: #fff !important
}

.text-bg-warning {
    background-color: rgb(var(--warning-rgb)) !important;
    color: #fff !important
}

.text-bg-info {
    background-color: rgb(var(--info-rgb)) !important;
    color: #fff !important
}

.text-bg-success {
    background-color: rgb(var(--success-rgb)) !important;
    color: #fff !important
}

.text-bg-danger {
    background-color: rgb(var(--danger-rgb)) !important;
    color: #fff !important
}

.text-bg-light {
    background-color: rgb(var(--light-rgb)) !important;
    color: #000 !important
}

.text-bg-dark {
    background-color: rgb(var(--dark-rgb)) !important;
    color: #fff !important
}

.svg-primary {
    fill: var(--primary-color)
}

.svg-secondary {
    fill: rgb(var(--secondary-rgb))
}

.svg-success {
    fill: rgb(var(--success-rgb))
}

.svg-danger {
    fill: rgb(var(--danger-rgb))
}

.svg-warning {
    fill: rgb(var(--warning-rgb))
}

.svg-white {
    fill: #fff
}

.svg-black {
    fill: #000
}

.svg-pink {
    fill: rgb(var(--pink-rgb))
}

.svg-orange {
    fill: rgb(var(--orange-rgb))
}

.svg-purple {
    fill: rgb(var(--purple-rgb))
}

.svg-indigo {
    fill: #4d5ddb
}

.svg-info {
    fill: rgb(var(--info-rgb))
}

.svg-yellow {
    fill: #ffc102
}

.svg-light {
    fill: rgb(var(--light-rgb))
}

.svg-dark {
    fill: rgb(var(--dark-rgb))
}

.svg-teal {
    fill: rgb(var(--teal-rgb))
}

.svg-default {
    fill: var(--default-text-color)
}

.bd-blue-100 {
    background-color: rgba(43, 62, 101, .1);
    color: var(--default-text-color)
}

.bd-blue-200 {
    background-color: rgba(43, 62, 101, .2);
    color: var(--default-text-color)
}

.bd-blue-300 {
    background-color: rgba(43, 62, 101, .3);
    color: var(--default-text-color)
}

.bd-blue-400 {
    background-color: rgba(43, 62, 101, .4);
    color: var(--default-text-color)
}

.bd-blue-500 {
    background-color: rgba(43, 62, 101, .5);
    color: var(--default-text-color)
}

.bd-blue-600 {
    background-color: rgba(43, 62, 101, .6);
    color: #fff
}

.bd-blue-700 {
    background-color: rgba(43, 62, 101, .7);
    color: #fff
}

.bd-blue-800 {
    background-color: rgba(43, 62, 101, .8);
    color: #fff
}

.bd-blue-900 {
    background-color: rgba(43, 62, 101, .9);
    color: #fff
}

.bd-blue {
    background-color: #2b3e65;
    color: #fff
}

.bd-indigo-100 {
    background-color: rgba(77, 93, 219, .1);
    color: var(--default-text-color)
}

.bd-indigo-200 {
    background-color: rgba(77, 93, 219, .2);
    color: var(--default-text-color)
}

.bd-indigo-300 {
    background-color: rgba(77, 93, 219, .3);
    color: var(--default-text-color)
}

.bd-indigo-400 {
    background-color: rgba(77, 93, 219, .4);
    color: var(--default-text-color)
}

.bd-indigo-500 {
    background-color: rgba(77, 93, 219, .5);
    color: var(--default-text-color)
}

.bd-indigo-600 {
    background-color: rgba(77, 93, 219, .6);
    color: #fff
}

.bd-indigo-700 {
    background-color: rgba(77, 93, 219, .7);
    color: #fff
}

.bd-indigo-800 {
    background-color: rgba(77, 93, 219, .8);
    color: #fff
}

.bd-indigo-900 {
    background-color: rgba(77, 93, 219, .9);
    color: #fff
}

.bd-indigo {
    background-color: #4d5ddb;
    color: #fff
}

.bd-purple-100 {
    background-color: rgba(var(--purple-rgb), .1);
    color: var(--default-text-color)
}

.bd-purple-200 {
    background-color: rgba(var(--purple-rgb), .2);
    color: var(--default-text-color)
}

.bd-purple-300 {
    background-color: rgba(var(--purple-rgb), .3);
    color: var(--default-text-color)
}

.bd-purple-400 {
    background-color: rgba(var(--purple-rgb), .4);
    color: var(--default-text-color)
}

.bd-purple-500 {
    background-color: rgba(var(--purple-rgb), .5);
    color: var(--default-text-color)
}

.bd-purple-600 {
    background-color: rgba(var(--purple-rgb), .6);
    color: #fff
}

.bd-purple-700 {
    background-color: rgba(var(--purple-rgb), .7);
    color: #fff
}

.bd-purple-800 {
    background-color: rgba(var(--purple-rgb), .8);
    color: #fff
}

.bd-purple-900 {
    background-color: rgba(var(--purple-rgb), .9);
    color: #fff
}

.bd-purple {
    background-color: rgb(var(--purple-rgb));
    color: #fff
}

.bd-pink-100 {
    background-color: rgba(var(--pink-rgb), .1);
    color: var(--default-text-color)
}

.bd-pink-200 {
    background-color: rgba(var(--pink-rgb), .2);
    color: var(--default-text-color)
}

.bd-pink-300 {
    background-color: rgba(var(--pink-rgb), .3);
    color: var(--default-text-color)
}

.bd-pink-400 {
    background-color: rgba(var(--pink-rgb), .4);
    color: var(--default-text-color)
}

.bd-pink-500 {
    background-color: rgba(var(--pink-rgb), .5);
    color: var(--default-text-color)
}

.bd-pink-600 {
    background-color: rgba(var(--pink-rgb), .6);
    color: #fff
}

.bd-pink-700 {
    background-color: rgba(var(--pink-rgb), .7);
    color: #fff
}

.bd-pink-800 {
    background-color: rgba(var(--pink-rgb), .8);
    color: #fff
}

.bd-pink-900 {
    background-color: rgba(var(--pink-rgb), .9);
    color: #fff
}

.bd-pink {
    background-color: rgb(var(--pink-rgb));
    color: #fff
}

.bd-red-100 {
    background-color: rgba(208, 61, 70, .1);
    color: var(--default-text-color)
}

.bd-red-200 {
    background-color: rgba(208, 61, 70, .2);
    color: var(--default-text-color)
}

.bd-red-300 {
    background-color: rgba(208, 61, 70, .3);
    color: var(--default-text-color)
}

.bd-red-400 {
    background-color: rgba(208, 61, 70, .4);
    color: var(--default-text-color)
}

.bd-red-500 {
    background-color: rgba(208, 61, 70, .5);
    color: var(--default-text-color)
}

.bd-red-600 {
    background-color: rgba(208, 61, 70, .6);
    color: #fff
}

.bd-red-700 {
    background-color: rgba(208, 61, 70, .7);
    color: #fff
}

.bd-red-800 {
    background-color: rgba(208, 61, 70, .8);
    color: #fff
}

.bd-red-900 {
    background-color: rgba(208, 61, 70, .9);
    color: #fff
}

.bd-red {
    background-color: #d03d46;
    color: #fff
}

.bd-orange-100 {
    background-color: rgba(var(--orange-rgb), .1);
    color: var(--default-text-color)
}

.bd-orange-200 {
    background-color: rgba(var(--orange-rgb), .2);
    color: var(--default-text-color)
}

.bd-orange-300 {
    background-color: rgba(var(--orange-rgb), .3);
    color: var(--default-text-color)
}

.bd-orange-400 {
    background-color: rgba(var(--orange-rgb), .4);
    color: var(--default-text-color)
}

.bd-orange-500 {
    background-color: rgba(var(--orange-rgb), .5);
    color: var(--default-text-color)
}

.bd-orange-600 {
    background-color: rgba(var(--orange-rgb), .6);
    color: #fff
}

.bd-orange-700 {
    background-color: rgba(var(--orange-rgb), .7);
    color: #fff
}

.bd-orange-800 {
    background-color: rgba(var(--orange-rgb), .8);
    color: #fff
}

.bd-orange-900 {
    background-color: rgba(var(--orange-rgb), .9);
    color: #fff
}

.bd-orange {
    background-color: rgb(var(--orange-rgb));
    color: #fff
}

.bd-yellow-100 {
    background-color: rgba(255, 193, 2, .1);
    color: var(--default-text-color)
}

.bd-yellow-200 {
    background-color: rgba(255, 193, 2, .2);
    color: var(--default-text-color)
}

.bd-yellow-300 {
    background-color: rgba(255, 193, 2, .3);
    color: var(--default-text-color)
}

.bd-yellow-400 {
    background-color: rgba(255, 193, 2, .4);
    color: var(--default-text-color)
}

.bd-yellow-500 {
    background-color: rgba(255, 193, 2, .5);
    color: var(--default-text-color)
}

.bd-yellow-600 {
    background-color: rgba(255, 193, 2, .6);
    color: #fff
}

.bd-yellow-700 {
    background-color: rgba(255, 193, 2, .7);
    color: #fff
}

.bd-yellow-800 {
    background-color: rgba(255, 193, 2, .8);
    color: #fff
}

.bd-yellow-900 {
    background-color: rgba(255, 193, 2, .9);
    color: #fff
}

.bd-yellow {
    background-color: #ffc102;
    color: #fff
}

.bd-green-100 {
    background-color: rgba(29, 216, 113, .1);
    color: var(--default-text-color)
}

.bd-green-200 {
    background-color: rgba(29, 216, 113, .2);
    color: var(--default-text-color)
}

.bd-green-300 {
    background-color: rgba(29, 216, 113, .3);
    color: var(--default-text-color)
}

.bd-green-400 {
    background-color: rgba(29, 216, 113, .4);
    color: var(--default-text-color)
}

.bd-green-500 {
    background-color: rgba(29, 216, 113, .5);
    color: var(--default-text-color)
}

.bd-green-600 {
    background-color: rgba(29, 216, 113, .6);
    color: #fff
}

.bd-green-700 {
    background-color: rgba(29, 216, 113, .7);
    color: #fff
}

.bd-green-800 {
    background-color: rgba(29, 216, 113, .8);
    color: #fff
}

.bd-green-900 {
    background-color: rgba(29, 216, 113, .9);
    color: #fff
}

.bd-green {
    background-color: #1dd871;
    color: #fff
}

.bd-teal-100 {
    background-color: rgba(var(--teal-rgb), .1);
    color: var(--default-text-color)
}

.bd-teal-200 {
    background-color: rgba(var(--teal-rgb), .2);
    color: var(--default-text-color)
}

.bd-teal-300 {
    background-color: rgba(var(--teal-rgb), .3);
    color: var(--default-text-color)
}

.bd-teal-400 {
    background-color: rgba(var(--teal-rgb), .4);
    color: var(--default-text-color)
}

.bd-teal-500 {
    background-color: rgba(var(--teal-rgb), .5);
    color: var(--default-text-color)
}

.bd-teal-600 {
    background-color: rgba(var(--teal-rgb), .6);
    color: #fff
}

.bd-teal-700 {
    background-color: rgba(var(--teal-rgb), .7);
    color: #fff
}

.bd-teal-800 {
    background-color: rgba(var(--teal-rgb), .8);
    color: #fff
}

.bd-teal-900 {
    background-color: rgba(var(--teal-rgb), .9);
    color: #fff
}

.bd-teal {
    background-color: rgb(var(--teal-rgb));
    color: #fff
}

.bd-cyan-100 {
    background-color: rgba(0, 209, 209, .1);
    color: var(--default-text-color)
}

.bd-cyan-200 {
    background-color: rgba(0, 209, 209, .2);
    color: var(--default-text-color)
}

.bd-cyan-300 {
    background-color: rgba(0, 209, 209, .3);
    color: var(--default-text-color)
}

.bd-cyan-400 {
    background-color: rgba(0, 209, 209, .4);
    color: var(--default-text-color)
}

.bd-cyan-500 {
    background-color: rgba(0, 209, 209, .5);
    color: var(--default-text-color)
}

.bd-cyan-600 {
    background-color: rgba(0, 209, 209, .6);
    color: #fff
}

.bd-cyan-700 {
    background-color: rgba(0, 209, 209, .7);
    color: #fff
}

.bd-cyan-800 {
    background-color: rgba(0, 209, 209, .8);
    color: #fff
}

.bd-cyan-900 {
    background-color: rgba(0, 209, 209, .9);
    color: #fff
}

.bd-cyan {
    background-color: #00d1d1;
    color: #fff
}

.bd-gray-100 {
    background-color: rgba(134, 153, 163, .1);
    color: var(--default-text-color)
}

.bd-gray-200 {
    background-color: rgba(134, 153, 163, .2);
    color: var(--default-text-color)
}

.bd-gray-300 {
    background-color: rgba(134, 153, 163, .3);
    color: var(--default-text-color)
}

.bd-gray-400 {
    background-color: rgba(134, 153, 163, .4);
    color: var(--default-text-color)
}

.bd-gray-500 {
    background-color: rgba(134, 153, 163, .5);
    color: var(--default-text-color)
}

.bd-gray-600 {
    background-color: rgba(134, 153, 163, .6);
    color: #fff
}

.bd-gray-700 {
    background-color: rgba(134, 153, 163, .7);
    color: #fff
}

.bd-gray-800 {
    background-color: rgba(134, 153, 163, .8);
    color: #fff
}

.bd-gray-900 {
    background-color: rgba(134, 153, 163, .9);
    color: #fff
}

.bd-gray {
    background-color: #8699a3;
    color: #fff
}

.border-container {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: .25rem;
    background-color: rgba(var(--light-rgb), .3)
}

.border {
    border: 1px solid var(--default-border) !important
}

.border.border-dashed {
    border-style: dashed !important
}

.border.border-dotted {
    border-style: dotted !important
}

.border.border-primary {
    border: 1px solid var(--primary-color) !important;
    opacity: 1
}

.border.border-primary.border-opacity-10 {
    border-color: var(--primary01) !important
}

.border.border-primary.border-opacity-25 {
    border-color: var(--primary03) !important
}

.border.border-primary.border-opacity-50 {
    border-color: var(--primary05) !important
}

.border.border-primary.border-opacity-75 {
    border-color: var(--primary08) !important
}

.border.border-primary.border-opacity-100 {
    border-color: var(--primary-color) !important
}

.border.border-secondary {
    border: 1px solid rgb(var(--secondary-rgb)) !important;
    opacity: 1
}

.border.border-secondary.border-opacity-10 {
    border-color: rgba(var(--secondary-rgb), .1) !important
}

.border.border-secondary.border-opacity-25 {
    border-color: rgba(var(--secondary-rgb), .25) !important
}

.border.border-secondary.border-opacity-50 {
    border-color: rgba(var(--secondary-rgb), .5) !important
}

.border.border-secondary.border-opacity-75 {
    border-color: rgba(var(--secondary-rgb), .75) !important
}

.border.border-secondary.border-opacity-100 {
    border-color: rgba(var(--secondary-rgb), 1) !important
}

.border.border-info {
    border: 1px solid rgb(var(--info-rgb)) !important;
    opacity: 1
}

.border.border-info.border-opacity-10 {
    border-color: rgba(var(--info-rgb), .1) !important
}

.border.border-info.border-opacity-25 {
    border-color: rgba(var(--info-rgb), .25) !important
}

.border.border-info.border-opacity-50 {
    border-color: rgba(var(--info-rgb), .5) !important
}

.border.border-info.border-opacity-75 {
    border-color: rgba(var(--info-rgb), .75) !important
}

.border.border-info.border-opacity-100 {
    border-color: rgba(var(--info-rgb), 1) !important
}

.border.border-success {
    border: 1px solid rgb(var(--success-rgb)) !important;
    opacity: 1
}

.border.border-success.border-opacity-10 {
    border-color: rgba(var(--success-rgb), .1) !important
}

.border.border-success.border-opacity-25 {
    border-color: rgba(var(--success-rgb), .25) !important
}

.border.border-success.border-opacity-50 {
    border-color: rgba(var(--success-rgb), .5) !important
}

.border.border-success.border-opacity-75 {
    border-color: rgba(var(--success-rgb), .75) !important
}

.border.border-success.border-opacity-100 {
    border-color: rgba(var(--success-rgb), 1) !important
}

.border.border-warning {
    border: 1px solid rgb(var(--warning-rgb)) !important;
    opacity: 1
}

.border.border-warning.border-opacity-10 {
    border-color: rgba(var(--warning-rgb), .1) !important
}

.border.border-warning.border-opacity-25 {
    border-color: rgba(var(--warning-rgb), .25) !important
}

.border.border-warning.border-opacity-50 {
    border-color: rgba(var(--warning-rgb), .5) !important
}

.border.border-warning.border-opacity-75 {
    border-color: rgba(var(--warning-rgb), .75) !important
}

.border.border-warning.border-opacity-100 {
    border-color: rgba(var(--warning-rgb), 1) !important
}

.border.border-danger {
    border: 1px solid rgb(var(--danger-rgb)) !important;
    opacity: 1
}

.border.border-danger.border-opacity-10 {
    border-color: rgba(var(--danger-rgb), .1) !important
}

.border.border-danger.border-opacity-25 {
    border-color: rgba(var(--danger-rgb), .25) !important
}

.border.border-danger.border-opacity-50 {
    border-color: rgba(var(--danger-rgb), .5) !important
}

.border.border-danger.border-opacity-75 {
    border-color: rgba(var(--danger-rgb), .75) !important
}

.border.border-danger.border-opacity-100 {
    border-color: rgba(var(--danger-rgb), 1) !important
}

.border.border-white {
    border: 1px solid #fff !important;
    opacity: 1
}

.border.border-white.border-opacity-10 {
    border-color: rgba(255, 255, 255, .1) !important
}

.border.border-white.border-opacity-25 {
    border-color: rgba(255, 255, 255, .25) !important
}

.border.border-white.border-opacity-50 {
    border-color: rgba(255, 255, 255, .5) !important
}

.border.border-white.border-opacity-75 {
    border-color: rgba(255, 255, 255, .75) !important
}

.border.border-white.border-opacity-100 {
    border-color: #fff !important
}

.border.border-light {
    border: 1px solid rgb(var(--light-rgb)) !important;
    opacity: 1
}

.border.border-light.border-opacity-10 {
    border-color: rgba(var(--light-rgb), .1) !important
}

.border.border-light.border-opacity-25 {
    border-color: rgba(var(--light-rgb), .25) !important
}

.border.border-light.border-opacity-50 {
    border-color: rgba(var(--light-rgb), .5) !important
}

.border.border-light.border-opacity-75 {
    border-color: rgba(var(--light-rgb), .75) !important
}

.border.border-light.border-opacity-100 {
    border-color: rgba(var(--light-rgb), 1) !important
}

.border.border-dark {
    border: 1px solid rgb(var(--dark-rgb)) !important;
    opacity: 1
}

.border.border-dark.border-opacity-10 {
    border-color: rgba(var(--dark-rgb), .1) !important
}

.border.border-dark.border-opacity-25 {
    border-color: rgba(var(--dark-rgb), .25) !important
}

.border.border-dark.border-opacity-50 {
    border-color: rgba(var(--dark-rgb), .5) !important
}

.border.border-dark.border-opacity-75 {
    border-color: rgba(var(--dark-rgb), .75) !important
}

.border.border-dark.border-opacity-100 {
    border-color: rgba(var(--dark-rgb), 1) !important
}

.border.border-opacity-10 {
    --bs-border-opacity: 0.1 !important
}

.border.border-opacity-25 {
    --bs-border-opacity: 0.25 !important
}

.border.border-opacity-50 {
    --bs-border-opacity: 0.5 !important
}

.border.border-opacity-75 {
    --bs-border-opacity: 0.75 !important
}

.border.border-opacity-100 {
    --bs-border-opacity: 1 !important
}

.border.border-1 {
    border-width: 1px !important
}

.border.border-2 {
    border-width: 2px !important
}

.border.border-3 {
    border-width: 3px !important
}

.border.border-4 {
    border-width: 4px !important
}

.border.border-5 {
    border-width: 5px !important
}

.border.border-block-start-dashed {
    border-top-style: dashed
}

.border.border-block-start-dotted {
    border-top-style: dotted
}

.border.border-inline-end-dashed {
    border-inline-end-style: dashed
}

.border.border-inline-end-dotted {
    border-inline-end-style: dotted
}

.border.border-block-end-dashed {
    border-bottom-style: dashed
}

.border.border-block-end-dotted {
    border-bottom-style: dotted
}

.border.border-inline-start-dashed {
    border-inline-start-style: dashed
}

.border.border-inline-start-dotted {
    border-inline-start-style: dotted
}

.border.border-0 {
    border: 0 !important
}

.border.border-top-0 {
    border-block-start: 0 !important
}

.border.border-end-0 {
    border-inline-end: 0 !important
}

.border.border-bottom-0 {
    border-block-end: 0 !important
}

.border.border-start-0 {
    border-inline-start: 0 !important
}

.border-top {
    border-top: 1px solid var(--default-border) !important
}

.border-top.border-block-start-dashed {
    border-top-style: dashed !important
}

.border-top.border-block-start-dotted {
    border-top-style: dotted !important
}

.border-top.border-primary {
    border-color: var(--primary-color) !important
}

.border-top.border-secondary {
    border-color: rgb(var(--secondary-rgb)) !important
}

.border-top.border-warning {
    border-color: rgb(var(--warning-rgb)) !important
}

.border-top.border-info {
    border-color: rgb(var(--info-rgb)) !important
}

.border-top.border-success {
    border-color: rgb(var(--success-rgb)) !important
}

.border-top.border-danger {
    border-color: rgb(var(--danger-rgb)) !important
}

.border-top.border-light {
    border-color: rgb(var(--light-rgb)) !important
}

.border-top.border-dark {
    border-color: rgb(var(--dark-rgb)) !important
}

.border-end {
    border-inline-end: 1px solid var(--default-border) !important
}

.border-end.border-inline-end-dashed {
    border-inline-end-style: dashed !important
}

.border-end.border-inline-end-dotted {
    border-inline-end-style: dotted !important
}

.border-end.border-primary {
    border-color: var(--primary-color) !important
}

.border-end.border-secondary {
    border-color: rgb(var(--secondary-rgb)) !important
}

.border-end.border-warning {
    border-color: rgb(var(--warning-rgb)) !important
}

.border-end.border-info {
    border-color: rgb(var(--info-rgb)) !important
}

.border-end.border-success {
    border-color: rgb(var(--success-rgb)) !important
}

.border-end.border-danger {
    border-color: rgb(var(--danger-rgb)) !important
}

.border-end.border-light {
    border-color: rgb(var(--light-rgb)) !important
}

.border-end.border-dark {
    border-color: rgb(var(--dark-rgb)) !important
}

.border-bottom {
    border-bottom: 1px solid var(--default-border) !important
}

.border-bottom.border-block-end-dashed {
    border-bottom-style: dashed !important
}

.border-bottom.border-block-end-dotted {
    border-bottom-style: dotted !important
}

.border-bottom.border-primary {
    border-color: var(--primary-color) !important
}

.border-bottom.border-secondary {
    border-color: rgb(var(--secondary-rgb)) !important
}

.border-bottom.border-warning {
    border-color: rgb(var(--warning-rgb)) !important
}

.border-bottom.border-info {
    border-color: rgb(var(--info-rgb)) !important
}

.border-bottom.border-success {
    border-color: rgb(var(--success-rgb)) !important
}

.border-bottom.border-danger {
    border-color: rgb(var(--danger-rgb)) !important
}

.border-bottom.border-light {
    border-color: rgb(var(--light-rgb)) !important
}

.border-bottom.border-dark {
    border-color: rgb(var(--dark-rgb)) !important
}

.border-start {
    border-inline-start: 1px solid var(--default-border) !important
}

.border-start.border-inline-start-dashed {
    border-inline-start-style: dashed !important
}

.border-start.border-inline-start-dotted {
    border-inline-start-style: dotted !important
}

.border-start.border-primary {
    border-color: var(--primary-color) !important
}

.border-start.border-secondary {
    border-color: rgb(var(--secondary-rgb)) !important
}

.border-start.border-warning {
    border-color: rgb(var(--warning-rgb)) !important
}

.border-start.border-info {
    border-color: rgb(var(--info-rgb)) !important
}

.border-start.border-success {
    border-color: rgb(var(--success-rgb)) !important
}

.border-start.border-danger {
    border-color: rgb(var(--danger-rgb)) !important
}

.border-start.border-light {
    border-color: rgb(var(--light-rgb)) !important
}

.border-start.border-dark {
    border-color: rgb(var(--dark-rgb)) !important
}

.br-1 {
    border-radius: .0625rem
}

.br-2 {
    border-radius: .125rem
}

.br-3 {
    border-radius: .1875rem
}

.br-4 {
    border-radius: .25rem
}

.br-5 {
    border-radius: .3125rem
}

.br-6 {
    border-radius: .375rem
}

.br-7 {
    border-radius: .4375rem
}

.br-8 {
    border-radius: .5rem
}

.br-9 {
    border-radius: .5625rem
}

.br-10 {
    border-radius: .625rem
}

.br-15 {
    border-radius: .9375rem
}

.br-20 {
    border-radius: 1.25rem
}

.rounded {
    border-radius: .5rem !important
}

.op-1-1 {
    opacity: 1
}

.op-0 {
    opacity: 0
}

.op-1 {
    opacity: .1
}

.op-2 {
    opacity: .2
}

.op-3 {
    opacity: .3
}

.op-4 {
    opacity: .4
}

.op-5 {
    opacity: .5
}

.op-6 {
    opacity: .6
}

.op-7 {
    opacity: .7
}

.op-8 {
    opacity: .8
}

.op-9 {
    opacity: .9
}

.fs-sm {
    font-size: .6875rem
}

.fs-base {
    font-size: .875rem
}

.fs-lg {
    font-size: 1.125rem
}

.fs-1 {
    font-size: .0625rem
}

.fs-2 {
    font-size: .125rem
}

.fs-3 {
    font-size: .1875rem
}

.fs-4 {
    font-size: .25rem
}

.fs-5 {
    font-size: .3125rem
}

.fs-6 {
    font-size: .375rem
}

.fs-7 {
    font-size: .4375rem
}

.fs-8 {
    font-size: .5rem
}

.fs-9 {
    font-size: .5625rem
}

.fs-10 {
    font-size: .625rem
}

.fs-11 {
    font-size: .6875rem
}

.fs-12 {
    font-size: .75rem
}

.fs-13 {
    font-size: .8125rem
}

.fs-14 {
    font-size: .875rem
}

.fs-15 {
    font-size: .9375rem
}

.fs-16 {
    font-size: 1rem
}

.fs-17 {
    font-size: 1.0625rem
}

.fs-18 {
    font-size: 1.125rem
}

.fs-19 {
    font-size: 1.1875rem
}

.fs-20 {
    font-size: 1.25rem
}

.fs-21 {
    font-size: 1.3125rem
}

.fs-22 {
    font-size: 1.375rem
}

.fs-23 {
    font-size: 1.4375rem
}

.fs-24 {
    font-size: 1.5rem
}

.fs-25 {
    font-size: 1.5625rem
}

.fs-26 {
    font-size: 1.625rem
}

.fs-27 {
    font-size: 1.6875rem
}

.fs-28 {
    font-size: 1.75rem
}

.fs-29 {
    font-size: 1.8125rem
}

.fs-30 {
    font-size: 1.875rem
}

.fs-32 {
    font-size: 2rem
}

.fs-34 {
    font-size: 2.125rem
}

.fs-36 {
    font-size: 2.25rem
}

.fs-38 {
    font-size: 2.375rem
}

.fs-40 {
    font-size: 2.5rem
}

.text-default {
    color: var(--default-text-color) !important
}

.text-muted {
    color: var(--text-muted) !important
}

.text-primary {
    color: var(--primary-color) !important;
    opacity: 1
}

.text-primary.text-opacity-75 {
    color: var(--primary08) !important
}

.text-primary.text-opacity-25 {
    color: var(--primary03) !important
}

.text-primary.text-opacity-50 {
    color: var(--primary05) !important
}

.text-secondary {
    color: rgb(var(--secondary-rgb)) !important;
    opacity: 1
}

.text-secondary.text-opacity-75 {
    color: rgba(var(--secondary-rgb), .75) !important
}

.text-secondary.text-opacity-25 {
    color: rgba(var(--secondary-rgb), .25) !important
}

.text-secondary.text-opacity-50 {
    color: rgba(var(--secondary-rgb), .5) !important
}

.text-info {
    color: rgb(var(--info-rgb)) !important;
    opacity: 1
}

.text-info.text-opacity-75 {
    color: rgba(var(--info-rgb), .75) !important
}

.text-info.text-opacity-25 {
    color: rgba(var(--info-rgb), .25) !important
}

.text-info.text-opacity-50 {
    color: rgba(var(--info-rgb), .5) !important
}

.text-success {
    color: rgb(var(--success-rgb)) !important;
    opacity: 1
}

.text-success.text-opacity-75 {
    color: rgba(var(--success-rgb), .75) !important
}

.text-success.text-opacity-25 {
    color: rgba(var(--success-rgb), .25) !important
}

.text-success.text-opacity-50 {
    color: rgba(var(--success-rgb), .5) !important
}

.text-warning {
    color: rgb(var(--warning-rgb)) !important;
    opacity: 1
}

.text-warning.text-opacity-75 {
    color: rgba(var(--warning-rgb), .75) !important
}

.text-warning.text-opacity-25 {
    color: rgba(var(--warning-rgb), .25) !important
}

.text-warning.text-opacity-50 {
    color: rgba(var(--warning-rgb), .5) !important
}

.text-danger {
    color: rgb(var(--danger-rgb)) !important;
    opacity: 1
}

.text-danger.text-opacity-75 {
    color: rgba(var(--danger-rgb), .75) !important
}

.text-danger.text-opacity-25 {
    color: rgba(var(--danger-rgb), .25) !important
}

.text-danger.text-opacity-50 {
    color: rgba(var(--danger-rgb), .5) !important
}

.text-blue {
    color: #2b3e65 !important
}

.text-green {
    color: #1dd871 !important
}

.text-pink {
    color: rgb(var(--pink-rgb)) !important
}

.text-teal {
    color: rgb(var(--teal-rgb)) !important
}

.text-orange {
    color: rgb(var(--orange-rgb)) !important
}

.text-indigo {
    color: #4d5ddb !important
}

.text-purple {
    color: rgb(var(--purple-rgb)) !important
}

.text-dark {
    color: rgb(var(--dark-rgb)) !important
}

.text-light {
    color: rgb(var(--light-rgb)) !important
}

.text-gray {
    color: var(--gray-5) !important
}

.text-black {
    color: var(--custom-black) !important
}

.text-white {
    color: var(--custom-white) !important
}

.text-fixed-white {
    color: #fff !important
}

.text-fixed-black {
    color: #000 !important
}

.text-gray-1 {
    color: var(--gray-1)
}

.text-gray-2 {
    color: var(--gray-2)
}

.text-gray-3 {
    color: var(--gray-3)
}

.text-gray-4 {
    color: var(--gray-4)
}

.text-gray-5 {
    color: var(--gray-5)
}

.text-gray-6 {
    color: var(--gray-6)
}

.text-gray-7 {
    color: var(--gray-7)
}

.text-gray-8 {
    color: var(--gray-8)
}

.text-gray-9 {
    color: var(--gray-9)
}

a {
    text-decoration: none;
    color: var(--default-text-color)
}

a:hover {
    --bs-text-opacity: 1;
    opacity: 1;
    color: var(--default-text-color)
}

.link-primary {
    color: var(--primary-color) !important
}

.link-primary:active,
.link-primary:focus,
.link-primary:hover {
    color: var(--primary-color) !important
}

.link-secondary {
    color: rgb(var(--secondary-rgb)) !important
}

.link-secondary:active,
.link-secondary:focus,
.link-secondary:hover {
    color: rgb(var(--secondary-rgb)) !important
}

.link-success {
    color: rgb(var(--success-rgb)) !important
}

.link-success:active,
.link-success:focus,
.link-success:hover {
    color: rgb(var(--success-rgb)) !important
}

.link-danger {
    color: rgb(var(--danger-rgb)) !important
}

.link-danger:active,
.link-danger:focus,
.link-danger:hover {
    color: rgb(var(--danger-rgb)) !important
}

.link-warning {
    color: rgb(var(--warning-rgb)) !important
}

.link-warning:active,
.link-warning:focus,
.link-warning:hover {
    color: rgb(var(--warning-rgb)) !important
}

.link-info {
    color: rgb(var(--info-rgb)) !important
}

.link-info:active,
.link-info:focus,
.link-info:hover {
    color: rgb(var(--info-rgb)) !important
}

.link-light {
    color: rgb(var(--light-rgb)) !important
}

.link-light:active,
.link-light:focus,
.link-light:hover {
    color: rgb(var(--light-rgb)) !important
}

.link-dark {
    color: rgb(var(--dark-rgb)) !important
}

.link-dark:active,
.link-dark:focus,
.link-dark:hover {
    color: rgb(var(--dark-rgb)) !important
}

.blockquote-container {
    padding: 1.25rem;
    border: 1px solid var(--default-border);
    border-block-start: 4px solid var(--default-border);
    border-radius: .5rem;
    position: relative
}

.blockquote-container:before {
    position: absolute;
    content: "\f6b0";
    font-family: bootstrap-icons !important;
    width: 1.5rem;
    height: 1.5rem;
    font-size: .813rem;
    font-weight: 600;
    border: 1px solid var(--default-border);
    background-color: var(--custom-white);
    top: -.875rem;
    align-items: center;
    display: flex;
    padding: 0;
    justify-content: center;
    border-radius: 3.125rem;
    inset-inline-start: 50%;
    box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .1)
}

.blockquote.custom-blockquote {
    font-size: .85rem;
    font-weight: 600;
    padding: 1rem;
    border-radius: .5rem;
    position: relative
}

.blockquote.custom-blockquote:before {
    content: "\f6b0";
    font-family: bootstrap-icons !important;
    z-index: 0;
    font-size: 4rem;
    position: absolute;
    inset-block-start: -1.5rem;
    inset-inline-start: -.25rem
}

.blockquote.custom-blockquote .quote-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    position: absolute;
    inset-block-start: -1rem;
    inset-inline-end: -1rem;
    background-color: var(--custom-white)
}

.blockquote.custom-blockquote .quote-icon i {
    font-size: 1.75rem;
    font-weight: 500
}

.blockquote.custom-blockquote.primary {
    background-color: var(--primary01);
    border-inline-start: 2px solid var(--primary-color);
    border-block-start: 2px solid var(--primary-color)
}

.blockquote.custom-blockquote.primary .quote-icon i {
    color: var(--primary-color)
}

.blockquote.custom-blockquote.primary:before {
    color: var(--primary01)
}

.blockquote.custom-blockquote.secondary {
    background-color: rgba(var(--secondary-rgb), .1);
    border-inline-start: 2px solid rgb(var(--secondary-rgb));
    border-block-start: 2px solid rgb(var(--secondary-rgb))
}

.blockquote.custom-blockquote.secondary .quote-icon i {
    color: rgb(var(--secondary-rgb))
}

.blockquote.custom-blockquote.secondary:before {
    color: rgba(var(--secondary-rgb), .1)
}

.blockquote.custom-blockquote.info {
    background-color: rgba(var(--info-rgb), .1);
    border-inline-start: 2px solid rgb(var(--info-rgb));
    border-block-start: 2px solid rgb(var(--info-rgb))
}

.blockquote.custom-blockquote.info .quote-icon i {
    color: rgb(var(--info-rgb))
}

.blockquote.custom-blockquote.info:before {
    color: rgba(var(--info-rgb), .1)
}

.blockquote.custom-blockquote.warning {
    background-color: rgba(var(--warning-rgb), .1);
    border-inline-start: 2px solid rgb(var(--warning-rgb));
    border-block-start: 2px solid rgb(var(--warning-rgb))
}

.blockquote.custom-blockquote.warning .quote-icon i {
    color: rgb(var(--warning-rgb))
}

.blockquote.custom-blockquote.warning:before {
    color: rgba(var(--warning-rgb), .1)
}

.blockquote.custom-blockquote.success {
    background-color: rgba(var(--success-rgb), .1);
    border-inline-start: 2px solid rgb(var(--success-rgb));
    border-block-start: 2px solid rgb(var(--success-rgb))
}

.blockquote.custom-blockquote.success .quote-icon i {
    color: rgb(var(--success-rgb))
}

.blockquote.custom-blockquote.success:before {
    color: rgba(var(--success-rgb), .1)
}

.blockquote.custom-blockquote.danger {
    background-color: rgba(var(--danger-rgb), .1);
    border-inline-start: 2px solid rgb(var(--danger-rgb));
    border-block-start: 2px solid rgb(var(--danger-rgb))
}

.blockquote.custom-blockquote.danger .quote-icon i {
    color: rgb(var(--danger-rgb))
}

.blockquote.custom-blockquote.danger:before {
    color: rgba(var(--danger-rgb), .1)
}

[data-theme-mode=dark] .shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(33, 37, 41, .3) !important
}

[data-theme-mode=dark] .shadow {
    box-shadow: 0 .5rem 1rem rgba(33, 37, 41, .3) !important
}

[data-theme-mode=dark] .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(33, 37, 41, .3) !important
}

@media screen and (min-width:576px) {
    .w-sm-auto {
        width: auto
    }
}

@media (min-width:576px) {
    .w-sm-50 {
        width: 50% !important
    }
}

.w-auto {
    width: auto
}

[dir=rtl] .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.transform-none {
    transform: none !important
}

/*# sourceMappingURL=styles.min.css.map */